import type { RemoveKey } from './types'

/**
 * Removes a specified key from an object and returns a new object with the remaining properties.
 *
 * @template T - The type of the object.
 * @template K - The key to be removed, which must be a key of T.
 * @param {T} obj - The object from which the key will be removed.
 * @param {K} key - The key to be removed from the object.
 * @returns {Omit<T, K>} A new object with the specified key removed.
 *
 * @example
 * const originalObject = { a: 1, b: 2, c: 3 };
 * const newObject = removeKeyFromObject(originalObject, 'b');
 * console.log(newObject); // Output: { a: 1, c: 3 }
 */
export function removeKeyFromObject<T, K extends keyof T>(
  obj: T,
  key: K,
): RemoveKey<T, K> {
  // Destructure the object to separate the specified key and its value
  const { [key]: _, ...rest } = obj || {}

  // Return the remaining properties of the object
  return rest as RemoveKey<T, K>
}
