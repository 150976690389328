import type { FC } from 'react'
import clsx from 'clsx'
import { IconLibraryProps, Sizes } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Request: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'request',
  size = ICON_DEFAULT_SIZE,
  className,
}): JSX.Element => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44454 3.72498H7.77788C6.70348 3.72624 5.67314 4.1536 4.91343 4.91331C4.15372 5.67302 3.72636 6.70336 3.7251 7.77775V16.2225C3.72636 17.2969 4.15372 18.3269 4.91343 19.0866C5.67314 19.8464 6.70348 20.2737 7.77788 20.275H16.2226C17.297 20.2737 18.3271 19.8464 19.0868 19.0866C19.8465 18.3269 20.2738 17.2966 20.2751 16.2222V7.77743C20.2738 6.70304 19.8465 5.67302 19.0868 4.91331C18.3271 4.1536 17.2967 3.72624 16.2223 3.72498H14.2223C13.4421 3.72498 12.6939 4.0349 12.1422 4.58657C11.5906 5.13824 11.2807 5.88646 11.2807 6.66664V12.042L8.95455 9.71219C8.81929 9.57693 8.63583 9.50094 8.44454 9.50094C8.25325 9.50094 8.06979 9.57693 7.93453 9.71219C7.79927 9.84745 7.72328 10.0309 7.72328 10.2222C7.72328 10.4135 7.79927 10.5969 7.93453 10.7322L11.49 14.2878L11.4944 14.292C11.5613 14.356 11.6399 14.4065 11.7259 14.4407C11.8125 14.478 11.9058 14.4973 12.0001 14.4973C12.0944 14.4973 12.1877 14.478 12.2743 14.4407C12.3603 14.4065 12.439 14.356 12.5059 14.292L16.0657 10.7322C16.2009 10.5969 16.2769 10.4135 16.2769 10.2222C16.2769 10.0309 16.2009 9.84745 16.0657 9.71219C15.9304 9.57693 15.7469 9.50094 15.5557 9.50094C15.3644 9.50094 15.1808 9.57708 15.0455 9.71234L12.7195 12.042V6.66664C12.7195 6.26808 12.8779 5.88584 13.1597 5.60402C13.4415 5.32219 13.8238 5.16386 14.2223 5.16386H16.2221C16.9151 5.16499 17.5793 5.44076 18.0693 5.93075C18.5594 6.4208 18.8351 7.08514 18.8362 7.77818V16.2218C18.8351 16.9148 18.5594 17.5792 18.0693 18.0692C17.5793 18.5592 16.9149 18.835 16.2219 18.8361H7.7783C7.08527 18.835 6.42092 18.5592 5.93088 18.0692C5.44088 17.5792 5.16511 16.915 5.16399 16.222V7.77795C5.16511 7.085 5.44088 6.42075 5.93088 5.93075C6.42087 5.44076 7.08512 5.16499 7.77807 5.16386H8.44454C8.63535 5.16386 8.81834 5.08807 8.95327 4.95314C9.08819 4.81822 9.16399 4.63523 9.16399 4.44442C9.16399 4.25361 9.08819 4.07062 8.95327 3.9357C8.81834 3.80077 8.63535 3.72498 8.44454 3.72498Z"
    />
  </svg>
)

export default Request
