import clsx from 'clsx'
import { forwardRef, type ForwardedRef } from 'react'
import type { TagProps } from './types'
import { tagThemes, tagSize, tagTextSize, tagShape } from './constants'
import { Typography } from '../Typography'

export const Tag = forwardRef<HTMLDivElement, TagProps>(
  (
    {
      testId = 'tag',
      theme,
      shape = 'pill',
      size = 'lg',
      text,
      children,
      className,
    },
    ref,
  ) => {
    return (
      <div
        data-testid={testId}
        className={clsx(
          'flex items-center justify-center w-fit shrink-0',
          tagShape[shape][size],
          tagThemes[theme],
          tagSize[shape][size],
          className,
        )}
        ref={ref as ForwardedRef<HTMLDivElement>}
      >
        {text && (
          <Typography
            testId={`${testId}-label`}
            className={clsx('leading-none', tagTextSize[size])}
            size="xs"
            weight={theme.includes('primary') ? 'medium' : 'regular'}
          >
            {text}
          </Typography>
        )}
        {children}
      </div>
    )
  },
)
