import { SylveraRating } from 'types'
import { RATINGS_ARRAY } from '../constants'

/**
 * Takes a rating string and returns the indexof in the ratings array.
 * Return indicates index of the rating string in the ratings array
 *
 * @param {SylveraRating} rating The rating string.
 * @return {number} Number
 */
export function getRatingFromString(rating?: SylveraRating): number {
  return RATINGS_ARRAY.indexOf(rating as SylveraRating)
}

export default getRatingFromString
