import type { FormAcceptedValues, FormData } from '../types'
import { checkIsValueBase } from './typeguards/checkIsValueBase'

export function getFormValues(
  formData: FormData,
): Record<string, FormAcceptedValues> {
  const kvPairs = Object.entries(formData).map(([key, entry]) => {
    if (checkIsValueBase(entry)) {
      return [key, entry.value]
    } else {
      const valueArray = entry.map(({ value }) => value).flat()
      return [key, valueArray]
    }
  })

  return Object.fromEntries(kvPairs)
}
