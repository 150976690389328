import { useMemo } from 'react'
import { type LDContext } from 'launchdarkly-react-client-sdk'
import type { UserDetails } from 'types'

export function useLDContext(data?: UserDetails): LDContext | undefined {
  return useMemo<LDContext | undefined>(() => {
    if (data?.userId && data?.organisationId) {
      const context: LDContext = {
        kind: 'multi',
        user: {
          key: data.userId,
        },
        organisation: {
          key: data.organisationId,
        },
      }

      return context
    }

    return undefined
  }, [data])
}
