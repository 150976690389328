import Link from 'next/link'
import { FC, useMemo } from 'react'
import { getAppUrl, useGetElementDimensions } from '@sylveraio/react-utils'
import Nav from '../Nav'
import UserNav from '../UserNav'
import { AppLogo } from '../AppLogo'
import { AppSwitcher } from '../AppSwitcher'
import type { HeaderProps } from './types'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'

export const Header: FC<HeaderProps> = ({
  isLoggedInRoute,
  navigationRoutes,
  userNav,
  appSwitcherItems,
  appName,
  activePageId,
  beta,
  tierName,
  headerMenuItems,
}) => {
  const { ref } = useGetElementDimensions()
  const pathname = usePathname() || ''

  const homeUrl =
    pathname === getAppUrl('dashboard') ? '/' : getAppUrl('dashboard')

  const isInternalLink = homeUrl.startsWith('/')

  const Logo: React.ReactNode = useMemo(
    () =>
      isInternalLink ? (
        <Link
          href={homeUrl}
          className="mr-14 flex-none"
          data-testid="header-logo-home"
          title={
            getAppUrl('dashboard') !== pathname ? 'Return to dashboard' : ''
          }
        >
          <AppLogo appName={appName} beta={beta} tierName={tierName} />
        </Link>
      ) : (
        <a
          href={homeUrl}
          className="mr-14 flex-none"
          data-testid="header-logo-home"
          title={
            getAppUrl('dashboard') !== pathname ? 'Return to dashboard' : ''
          }
        >
          <AppLogo appName={appName} beta={beta} tierName={tierName} />
        </a>
      ),
    [isInternalLink, homeUrl, pathname, appName, beta, tierName],
  )

  if (!isLoggedInRoute) return null

  return (
    <div
      data-testid="header"
      className={clsx('relative w-full z-50 px-3.5', {
        'sticky left-0 top-0 bg-default':
          pathname.includes('/data-directory') ||
          pathname.includes('/all-projects') ||
          pathname.includes('/shortlist'),
      })}
    >
      <div className="w-full bg-default">
        <div ref={ref} className="container flex items-center py-8">
          {Logo}
          <Nav
            navigationRoutes={navigationRoutes}
            activePageId={activePageId}
          />
          <div
            className="flex items-center space-x-1.5"
            data-testid="header-menu-right"
          >
            {headerMenuItems}
            {appSwitcherItems &&
              typeof userNav.initials !== 'undefined' &&
              userNav.initials !== '' && (
                <AppSwitcher appName={appName} items={appSwitcherItems} />
              )}
          </div>
          <div className="ml-2">
            <UserNav {...userNav} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
