import type { FC } from 'react'
import type { ProvisionalProps } from './types'
import {
  globalSylveraScoreSize,
  globalSylveraScorePadding,
  DEFAULT_SCORE_SIZE,
} from '../../constants'
import clsx from 'clsx'

export const Provisional: FC<Omit<ProvisionalProps, 'type'>> = ({
  testId = 'provisional',
  provisional = 'neutral',
  size = DEFAULT_SCORE_SIZE,
}) => (
  <div
    data-testid={testId}
    className={clsx(
      globalSylveraScoreSize[size],
      globalSylveraScorePadding[size],
    )}
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current stroke-current text-content-action-subtle"
    >
      {provisional === 'neutral' && (
        <g data-testid={`${testId}-${provisional}`}>
          <path
            d="M2.12035e-05 68C2.44867e-05 30.4446 30.4447 6.03085e-06 68 9.31404e-06C105.555 1.25972e-05 136 30.4446 136 68C136 105.555 105.555 136 68 136C30.4446 136 1.79203e-05 105.555 2.12035e-05 68ZM127.16 68C127.16 35.3268 100.673 8.84001 68 8.84001C35.3269 8.84 8.84002 35.3268 8.84002 68C8.84001 100.673 35.3268 127.16 68 127.16C100.673 127.16 127.16 100.673 127.16 68Z"
            stroke="none"
          />
          <path
            d="M90.6668 70.2642C90.6668 70.2642 84.8134 74.6126 80.6426 74.7935C75.9242 74.9983 69.1334 70.2642 69.1334 70.2642C69.1334 70.2642 62.5794 65.6358 57.9955 65.7349C53.6857 65.8281 47.6001 70.2642 47.6001 70.2642"
            strokeWidth="5.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </g>
      )}
      {provisional === 'negative' && (
        <g data-testid={`${testId}-${provisional}`}>
          <path
            d="M2.12035e-05 68C2.44867e-05 30.4446 30.4447 6.03085e-06 68 9.31404e-06C105.555 1.25972e-05 136 30.4446 136 68C136 105.555 105.555 136 68 136C30.4446 136 1.79203e-05 105.555 2.12035e-05 68ZM127.16 68C127.16 35.3268 100.673 8.84001 68 8.84001C35.3269 8.84 8.84002 35.3268 8.84002 68C8.84001 100.673 35.3268 127.16 68 127.16C100.673 127.16 127.16 100.673 127.16 68Z"
            stroke="none"
          />
          <path
            d="M52.375 68H86.8125"
            strokeWidth="5.67"
            strokeLinecap="round"
            fill="none"
          />
        </g>
      )}
      {provisional === 'positive' && (
        <g data-testid={`${testId}-${provisional}`}>
          <path
            d="M2.12035e-05 68C2.44867e-05 30.4446 30.4447 6.03085e-06 68 9.31404e-06C105.555 1.25972e-05 136 30.4446 136 68C136 105.555 105.555 136 68 136C30.4446 136 1.79203e-05 105.555 2.12035e-05 68ZM127.16 68C127.16 35.3268 100.673 8.84001 68 8.84001C35.3269 8.84 8.84002 35.3268 8.84002 68C8.84001 100.673 35.3268 127.16 68 127.16C100.673 127.16 127.16 100.673 127.16 68Z"
            stroke="none"
          />
          <path
            d="M52.375 68H86.8125"
            stroke-width="5.67"
            stroke-linecap="round"
            fill="none"
          />
          <path
            d="M69.5938 85.2188L69.5937 50.7812"
            stroke-width="5.67"
            stroke-linecap="round"
            fill="none"
          />
        </g>
      )}
    </svg>
  </div>
)
