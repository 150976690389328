import clsx from 'clsx'
import { useState, useEffect, FC } from 'react'
import { Range } from 'react-range'
import type { RangeSliderProps } from './types'

export const RangeSlider: FC<RangeSliderProps> = ({
  testId = 'range-slider',
  values,
  min = 0,
  max = 100,
  step = 1,
  rtl,
  onChange,
  disabled = false,
  thumbClassName,
  sliderClassName,
}) => {
  const [currentValues, setCurrentValues] = useState(values)

  const handleOnChange = (newValues: number[]): void => {
    if (!disabled) {
      setCurrentValues(newValues)
      onChange(newValues)
    }
  }

  useEffect(() => {
    setCurrentValues(values)
  }, [values])

  return (
    <div
      data-testid={testId}
      className={clsx('flex flex-wrap justify-center px-3', {
        'opacity-20': disabled,
      })}
    >
      <Range
        values={currentValues}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={handleOnChange}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
            className={clsx(
              'flex min-w-[110px] w-full',
              {
                '!cursor-default': disabled,
              },
              sliderClassName,
            )}
          >
            <div
              ref={props.ref}
              className="w-full self-center h-0.5 bg-content-action-subtle"
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            data-testid={`${testId}-handle-${props.key === 0 ? 'min' : 'max'}`}
            {...props}
            style={{
              ...props.style,
            }}
            className={clsx(
              'h-3.5 w-3.5 bg-content-action-default rounded-full',
              {
                '!cursor-default': disabled,
              },
              thumbClassName,
            )}
          />
        )}
      />
    </div>
  )
}

export default RangeSlider
