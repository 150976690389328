'use client'

import { useEffect, useState } from 'react'
import { usePrevious } from '../usePrevious'
import isEqual from 'lodash/isEqual'

/**
 * Returns a unique version of an array or object so it only refreshes when the object actually physically changes
 * @param object
 */
export function useUniqueObject(
  object: Record<string, unknown>,
): Record<string, unknown>
export function useUniqueObject(object: Array<any>): Array<any>
export function useUniqueObject(
  object: Record<string, unknown> | Array<any>,
): Record<string, unknown> | Array<any> {
  const [curObject, setCurObject] = useState(object)
  const prevObject = usePrevious(object)

  useEffect(() => {
    if (isEqual(object, prevObject)) return
    setCurObject(object)
  }, [object])

  return curObject
}
