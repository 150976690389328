import type { FeaturedIconProps } from '@sylveraio/design-system'

export interface EarlyStageProject {
  projectType: string
  expectedFirstIssuanceDate: string
  estimatedPrice: {
    min: number
    max: number
  }
  region: string
  expectedProjectEndDate: string
  paymentTerms:
    | 'Offtake agreement'
    | 'Offtake agreement, payment at delivery'
    | 'Money for me'
  estimatedSupplyPerYear: number
  stage: 'Feasibility study' | 'Draft project docs'
  featuredIconColour: FeaturedIconProps['color']
  featuredIcon: FeaturedIconProps['icon']
}

export enum EarlyStageProjectsModalStep {
  Form = 'Form',
  Submitted = 'Submitted',
}

export interface EarlyStageProjectsModalProps {
  hasAccessToEarlyStageProjects: boolean
  isSubmitting?: boolean
  modalStep: EarlyStageProjectsModalStep
  onSubmit: () => void
  onClose: () => void
}
