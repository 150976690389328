import { Logo } from '@sylveraio/react-ui/server'
import { getAppTitle } from '@sylveraio/react-utils/server'

import clsx from 'clsx'
import { FC, Suspense } from 'react'
import { AppLogoProps } from './types'
import { TierName } from './TierName'

function getLogoSize(size: AppLogoProps['size']): {
  logo: number
  styles: string
} {
  switch (size) {
    case 'xl':
      return {
        logo: 284,
        styles: 'text-5xl border-l-4 top-1.5',
      }
    case 'large':
      return {
        logo: 208,
        styles: 'text-4xl border-l-2 top-1',
      }
    case 'small':
      return {
        logo: 98,
        styles: 'text-md border-l top-px',
      }
    default:
      return {
        logo: 120,
        styles: 'text-xl border-l top-0.5',
      }
  }
}

export const AppLogo: FC<AppLogoProps> = ({
  appName,
  testId = 'app-logo',
  size = 'medium',
  hideAppName = false,
  inverted,
  tierName,
  beta,
}) => {
  const sizes = getLogoSize(size)
  return (
    <div className="flex flex-col space-x-2 max-h-min" data-testid={testId}>
      <div className="flex flex-row gap-x-2">
        <Logo className="h-[24px]" size={sizes.logo} inverted={inverted} />
        {!hideAppName && appName && (
          <div
            className={clsx(
              // text-[#3ACB5A] is used as a custom HEX until we will remove these titles and update navigation
              'font-medium text-[#3ACB5A] flex leading-[39px] items-center',
            )}
            data-testid={`${testId}-app`}
          >
            <div
              className={clsx(
                'border-text-default pl-1.5 leading-tight h-fit relative',
                sizes.styles,
              )}
            >
              {appName !== 'dashboard' && getAppTitle(appName)}
            </div>
          </div>
        )}
      </div>
      <div className="flex h-4 justify-end text-xs text-default -mt-1.5 space-x-4">
        <Suspense>
          <TierName tierName={tierName} testId={testId} />
        </Suspense>
        {beta && <span className="text-sm font-normal">Beta</span>}
      </div>
    </div>
  )
}

export default AppLogo
