import { deserializeResponse } from '@sylveraio/react-utils'
import type { TransformUserPreference } from './types'
import type { UserPreferencesAPIResponse } from 'types'

export async function transformUserPreferences(
  response: UserPreferencesAPIResponse,
): Promise<Array<TransformUserPreference> | TransformUserPreference> {
  const data = await deserializeResponse<
    Array<TransformUserPreference> | TransformUserPreference,
    UserPreferencesAPIResponse
  >(response)

  if (Array.isArray(data)) {
    return data.map((preference) => {
      const record: TransformUserPreference = {
        id: preference.id,
        key: preference.key,
        value: preference.value,
      }

      return record
    })
  }

  return {
    id: data.id,
    key: data.key,
    value: data.value,
  }
}

export default transformUserPreferences
