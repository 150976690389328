/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  ChartDataset,
  ChartOptions,
  LegendItem,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import 'chartjs-plugin-datalabels'
import annotationPlugin from 'chartjs-plugin-annotation'
import { ChartLegend } from '../ChartLegend'
import {
  GRID_OPTIONS,
  AXIS_LABEL_OPTIONS,
  TICK_OPTIONS,
  ELEMENT_OPTIONS,
} from '../constants'
import type { LineGraphProps } from './types'
import { useShowNoDataOverlay } from '../useShowNoDataOverlay'
import { EmptyState } from '@sylveraio/design-system'
import BarLineChart from '@sylveraio/untitled-ui-icons-react/build/esm/BarLineChart'
import type { ScatterDataPoint } from '../types'

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  annotationPlugin,
)

export const LineGraph: FC<LineGraphProps> = ({
  testId = 'line-graph',
  data,
  yAxisOptions,
  xAxisOptions,
  hideGridLines,
  enableFiltering = false,
  enableHighlighting = false,
  highlightRange,
  highlightRangeColor,
  tooltipOptions,
  showNoDataOverlay = true,
}) => {
  const chartRef = useRef<ChartJS<'line', ScatterDataPoint[], unknown> | null>(
    null,
  )

  const [labels, setLabels] = useState<string[]>([])
  const [chartData, setChartData] = useState<{
    labels?: string[]
    datasets: ChartDataset<'line', (ScatterDataPoint | number)[]>[]
  }>({
    datasets: [],
  })
  const [chartOptions, setChartOptions] = useState<ChartOptions<'line'>>({})
  const [legendItems, setLegendItems] = useState<LegendItem[] | undefined>([])
  const showOverlay = useShowNoDataOverlay(data, showNoDataOverlay)

  const {
    type: yAxisType,
    yAxisLabel,
    ticks: yAxisTicksOptions,
    min: yAxisMin,
    max: yAxisMax,
    offset: yAxisOffset,
  } = yAxisOptions ?? {}

  const {
    type: xAxisType,
    xAxisLabel,
    ticks: xAxisTicksOptions,
    min: xAxisMin,
    max: xAxisMax,
    offset: xAxisOffset,
  } = xAxisOptions ?? {}

  useEffect(() => {
    const mergedLabels = data.reduce<string[]>((acc, cv) => {
      const _labels = cv.values.map((val) => val.x)

      _labels.forEach((label) => {
        const formattedLabel = label.toString()
        if (!acc.includes(formattedLabel)) acc.push(formattedLabel)
      })

      return acc
    }, [])
    setLabels(mergedLabels)
    setChartData({
      labels: mergedLabels,
      datasets: data
        ?.filter((ds) => !ds?.hide)
        .map((ds) => ({
          ...ds,
          data: ds?.values,
        })),
    })
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          ...tooltipOptions,
        },
        datalabels: {
          display: false,
        },
        annotation: {
          clip: false,
          annotations: {
            box1: {
              display:
                enableHighlighting &&
                !showOverlay &&
                typeof highlightRange?.[0] !== 'undefined',
              type: 'box' as const,
              drawTime: 'beforeDatasetsDraw' as const,
              xMin: highlightRange?.[0]?.[0],
              xMax: highlightRange?.[0]?.at(-1),
              backgroundColor:
                typeof highlightRangeColor?.[0] !== 'undefined'
                  ? `${highlightRangeColor[0]}1A`
                  : '#48BCFD1A',
              borderColor:
                typeof highlightRangeColor?.[0] !== 'undefined'
                  ? `${highlightRangeColor[0]}1A`
                  : '#48BCFD1A',
              borderWidth:
                highlightRange?.[0]?.[0] !== highlightRange?.[0]?.at(-1)
                  ? 0
                  : 5,
            },
            box2: {
              display:
                enableHighlighting &&
                !showOverlay &&
                typeof highlightRange?.[1] !== 'undefined',
              type: 'box' as const,
              drawTime: 'beforeDatasetsDraw' as const,
              xMin: highlightRange?.[1]?.[0],
              xMax: highlightRange?.[1]?.at(-1),
              backgroundColor:
                typeof highlightRangeColor?.[1] !== 'undefined'
                  ? `${highlightRangeColor[1]}1A`
                  : '#48BCFD1A',
              borderColor:
                typeof highlightRangeColor?.[0] !== 'undefined'
                  ? `${highlightRangeColor[1]}1A`
                  : '#48BCFD1A',
              borderWidth:
                highlightRange?.[1]?.[0] !== highlightRange?.[1]?.at(-1)
                  ? 0
                  : 5,
            },
          },
        },
      },
      scales: {
        y: {
          type: yAxisType as any,
          title: {
            display: !!yAxisLabel,
            text: yAxisLabel,
            ...AXIS_LABEL_OPTIONS,
          },
          grid: {
            ...GRID_OPTIONS,
            display: !hideGridLines,
          },
          ticks: {
            ...yAxisTicksOptions,
            ...TICK_OPTIONS,
          },
          min: yAxisMin,
          max: yAxisMax,
          offset: yAxisOffset,
          beginAtZero: true,
        },
        x: {
          type: xAxisType as any,
          title: {
            display: !!xAxisLabel,
            text: xAxisLabel,
            ...AXIS_LABEL_OPTIONS,
          },
          grid: {
            ...GRID_OPTIONS,
            display: !hideGridLines,
          },
          ticks: {
            ...xAxisTicksOptions,
            ...TICK_OPTIONS,
          },
          ...(xAxisType === 'time'
            ? {
                time: {
                  unit: 'year',
                },
              }
            : {}),
          min: xAxisMin,
          max: xAxisMax,
          offset: xAxisOffset,
        },
      },
      elements: {
        ...ELEMENT_OPTIONS,
      },
    })
  }, [data, highlightRange, showOverlay])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update()
    }
    setLegendItems(chartRef.current?.legend?.legendItems)
  }, [labels, chartData, chartOptions])

  if (typeof chartData === 'undefined') return null

  return (
    <div
      data-testid={`${testId}-wrapper`}
      className="flex relative items-end justify-center w-full min-h-[400px]"
    >
      {showOverlay && (
        <div className="absolute flex items-center justify-center w-full h-full">
          <EmptyState
            withContainer={false}
            icon={<BarLineChart className="w-20 h-20" />}
            title="No available data"
          />
        </div>
      )}
      <div className="w-full max-w-[1000px] px-4">
        <Line
          data-testid={testId}
          ref={chartRef}
          options={chartOptions}
          data={chartData}
        />
      </div>
      {enableFiltering && (
        <ChartLegend
          testId={`${testId}-legend`}
          type="line"
          chartInstance={chartRef?.current?.legend?.chart}
          legendItems={legendItems}
        />
      )}
    </div>
  )
}

export default LineGraph
