import type { FC } from 'react'
import clsx from 'clsx'
import pluralize from 'pluralize'
import { useTooltipHover } from '@sylveraio/react-utils'
import { Tooltip } from '../../../Tooltip'
import type { RatingDistributionHistogramBarProps } from './types'

export const RatingDistributionHistogramBar: FC<
  RatingDistributionHistogramBarProps
> = ({
  count,
  maxCount,
  totalCount,
  isCurrent,
  testId = 'ratings-distribution-histogram-bar',
}) => {
  const [setHoverRef, hoverRef, isHover] = useTooltipHover<HTMLDivElement>()

  return (
    <>
      <div
        className="flex flex-col items-center justify-end w-full px-2"
        data-testid={testId}
      >
        {typeof count !== 'undefined' && count > 0 && (
          <>
            <div
              ref={setHoverRef}
              className={clsx('w-5/6 max-w-[20px] rounded-t-sm relative', {
                'bg-low': !isCurrent,
                'bg-primary-400': isCurrent,
              })}
              style={{
                height: `${
                  (count && typeof maxCount !== 'undefined'
                    ? count / (maxCount || 1)
                    : 0) * 100
                }%`,
              }}
              data-testid={`${testId}-rectangle`}
            >
              {isCurrent && (
                <div
                  className="flex justify-center w-full absolute -top-4"
                  data-testid={`${testId}-triangle`}
                >
                  <div className="w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-t-8 border-t-content-action-subtle" />
                </div>
              )}
            </div>
            {typeof totalCount !== 'undefined' && totalCount > 0 && (
              <Tooltip
                testId={`${testId}-tooltip`}
                referenceElement={hoverRef}
                show={isHover}
                placement="right"
                className="rounded-lg shadow-modal z-50 p-2 text-xs"
                offset={[-15, 5]}
              >
                {`${pluralize('project', count, true)} (${Math.round(
                  (count / totalCount) * 100,
                )}%)`}
              </Tooltip>
            )}
          </>
        )}
      </div>
    </>
  )
}
