/**
 * Wraps the values of an object in quotes.
 * @param {Record<string, any>} obj - The object to wrap the values of.
 * @param {string} keyToCheck - The key to check for values to wrap.
 * @returns {Record<string, any>} A new object with the values wrapped in quotes.
 */
export function wrapObjectValues(obj: Record<string, any>, keyToCheck: string) {
  const { [keyToCheck]: values, ...rest } = obj

  if (!values) {
    return obj
  }

  const newObj: Record<string, any> = {}

  for (const [key, value] of Object.entries(values)) {
    if (Array.isArray(value)) {
      newObj[key] = value.map((item) => `"${item}"`)
    } else {
      newObj[key] = value ? `"${value}"` : ''
    }
  }

  return {
    ...rest,
    [keyToCheck]: newObj,
  }
}
