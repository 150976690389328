const requirements: React.ReactNode = (
  <>
    <p className="font-medium text-content-action-dark">
      Password must include:
    </p>
    <ul className="font-normal list-disc list-inside text-content-action-dark indent-4">
      <li>Uppercase letter</li>
      <li>Lowercase letter</li>
      <li>Number</li>
      <li>Special character</li>
    </ul>
  </>
)

const passwordPatternRegex =
  /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,}$/g

export default {
  requirements,
  passwordPatternRegex,
}
