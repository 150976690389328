import { AppName } from 'types'

export const APPS: Array<AppName> = [
  'ratings',
  'market-commentary',
  'analytics',
  'insights',
  'box',
  'dashboard',
]
export const RESTRICTED_APPS: Array<AppName> = ['analytics', 'ratings']
export const RESTRICTED_APP_NO_CAPABILITIES: Array<AppName> = ['box']
export const DISABLED_APPS: Array<AppName> = []
