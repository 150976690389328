import clsx from 'clsx'
import { FC } from 'react'
import IconProps from '../types'

const Warning: FC<IconProps> = ({ size = 16, className }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path d="M7.50148 4.29872C7.35942 4.29872 7.22317 4.35516 7.12271 4.45561C7.02226 4.55607 6.96582 4.69232 6.96582 4.83438V9.65534C6.96582 9.79741 7.02226 9.93365 7.12271 10.0341C7.22317 10.1346 7.35942 10.191 7.50148 10.191C7.64355 10.191 7.7798 10.1346 7.88025 10.0341C7.98071 9.93365 8.03714 9.79741 8.03714 9.65534V4.83438C8.03714 4.69232 7.98071 4.55607 7.88025 4.45561C7.7798 4.35516 7.64355 4.29872 7.50148 4.29872Z" />
    <path d="M0.0772455 13.6728C0.123985 13.7537 0.191097 13.8211 0.271913 13.868C0.352729 13.915 0.444434 13.9401 0.537915 13.9406H14.4651C14.5598 13.9419 14.6531 13.9182 14.7356 13.8717C14.8181 13.8252 14.8868 13.7577 14.9347 13.6761C14.9826 13.5944 15.008 13.5015 15.0083 13.4068C15.0086 13.3122 14.9839 13.2191 14.9365 13.1371L7.9729 0.281237C7.927 0.1962 7.85896 0.125162 7.77597 0.0756512C7.69299 0.0261403 7.59815 0 7.50152 0C7.40489 0 7.31005 0.0261403 7.22707 0.0756512C7.14408 0.125162 7.07603 0.1962 7.03014 0.281237L0.0665321 13.1371C0.021136 13.2195 -0.00177348 13.3124 0.000107125 13.4064C0.00198773 13.5004 0.0285923 13.5923 0.0772455 13.6728ZM7.50152 1.67396L13.5652 12.8693H1.43783L7.50152 1.67396Z" />
    <path d="M7.997 11.3239C7.98268 11.2921 7.96565 11.2617 7.94611 11.2328C7.90633 11.1752 7.85642 11.1253 7.79881 11.0855C7.76933 11.0655 7.73793 11.0484 7.70507 11.0346C7.67336 11.0208 7.64008 11.0109 7.60597 11.0052C7.51969 10.9883 7.43059 10.9929 7.34651 11.0186C7.26243 11.0443 7.18597 11.0903 7.12387 11.1525C7.09796 11.1761 7.07543 11.2031 7.05692 11.2328C7.03637 11.261 7.01929 11.2916 7.00603 11.3239C6.99219 11.3565 6.9823 11.3907 6.97657 11.4257C6.96903 11.46 6.96543 11.495 6.96585 11.5301C6.96585 11.6722 7.02229 11.8084 7.12275 11.9089C7.2232 12.0094 7.35945 12.0658 7.50152 12.0658C7.64358 12.0658 7.77983 12.0094 7.88029 11.9089C7.98074 11.8084 8.03718 11.6722 8.03718 11.5301C8.04114 11.4954 8.04114 11.4604 8.03718 11.4257C8.02673 11.3907 8.01329 11.3566 7.997 11.3239Z" />
  </svg>
)

export default Warning
