import clsx from 'clsx'
import type { LabelSizes } from '../../types'

/**
 * Get the size of the icons in the label
 * @param {LabelSizes} size - The size of the label component
 * @returns {string} - The class name for the labels icons size
 */
export function getIconSize(size: LabelSizes) {
  return clsx({
    'h-3 w-3': size === 'sm',
    'h-4 w-4': size === 'md',
    'h-5 w-5': size === 'lg',
  })
}
