import { FC, useMemo } from 'react'
import clsx from 'clsx'

import { LinkWrapper } from '@sylveraio/react-ui/server'
import type { AppSwitcherDropdownItemButtonProps } from './types'
import MessageChatSquare from '@sylveraio/untitled-ui-icons-react/build/esm/MessageChatSquare'
import PieChart03 from '@sylveraio/untitled-ui-icons-react/build/esm/PieChart03'
import BookOpen01 from '@sylveraio/untitled-ui-icons-react/build/esm/BookOpen01'
import Globe01 from '@sylveraio/untitled-ui-icons-react/build/esm/Globe01'
import Ratings from '@sylveraio/untitled-ui-icons-react/build/esm/Ratings'
import Home02 from '@sylveraio/untitled-ui-icons-react/build/esm/Home02'
import Users02 from '@sylveraio/untitled-ui-icons-react/build/esm/Users02'
import UserEdit from '@sylveraio/untitled-ui-icons-react/build/esm/UserEdit'

export const AppSwitcherDropdownItemButton: FC<
  AppSwitcherDropdownItemButtonProps
> = ({
  testId = 'app-switcher-dropdown-item-button',
  name,
  title,
  appUrl,
  appClientSide,
  disabled,
  onClick,
}) => {
  const iconsClassName = clsx('h-5 w-5 stroke-icon-default', {
    'stroke-icon-subtle': disabled,
  })
  const icon = useMemo(() => {
    switch (name) {
      case 'market-commentary':
        return <MessageChatSquare className={iconsClassName} />
      case 'ratings':
        return <Ratings className={iconsClassName} />
      case 'analytics':
        return <PieChart03 className={iconsClassName} />
      case 'dashboard':
        return <Home02 className={iconsClassName} />
      case 'insights':
        return <BookOpen01 className={iconsClassName} />
      case 'user-management':
        return <UserEdit className={iconsClassName} />
      case 'box':
        return <Globe01 className={iconsClassName} />
      case 'country-profiles':
        return null
      default:
        return null
    }
  }, [name])

  return (
    <LinkWrapper
      href={appUrl}
      toClientSidePage={appClientSide}
      testId={testId}
      className={clsx('flex items-center gap-x-2 px-3.5 py-2.5 w-full', {
        'pointer-events-none cursor-default': disabled,
      })}
      onClick={(e) => {
        if (disabled) {
          e?.preventDefault()
          return
        }

        onClick()
      }}
    >
      <>
        {icon}
        <span className={clsx('text-default', { 'text-subtle': disabled })}>
          {title}
        </span>
      </>
    </LinkWrapper>
  )
}
