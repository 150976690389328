import isArray from 'lodash/isArray'
import first from 'lodash/first'

export function getParamFromQuery<T extends string>(
  string?: string | Array<string>,
  defaultValue?: T,
): T | undefined {
  if (!string) return defaultValue
  if (isArray(string)) return first(string) as T
  return string as T
}
