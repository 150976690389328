import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Info: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'info',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-default', className)}
  >
    <path d="M6 1C3.23884 1 1 3.23884 1 6C1 8.76116 3.23884 11 6 11C8.76116 11 11 8.76116 11 6C11 3.23884 8.76116 1 6 1ZM6 10.1518C3.70759 10.1518 1.84821 8.29241 1.84821 6C1.84821 3.70759 3.70759 1.84821 6 1.84821C8.29241 1.84821 10.1518 3.70759 10.1518 6C10.1518 8.29241 8.29241 10.1518 6 10.1518Z" />
    <path d="M5.46375 4.03559C5.46375 4.17767 5.52019 4.31393 5.62065 4.4144C5.72112 4.51487 5.85738 4.57131 5.99946 4.57131C6.14154 4.57131 6.2778 4.51487 6.37827 4.4144C6.47873 4.31393 6.53517 4.17767 6.53517 4.03559C6.53517 3.89351 6.47873 3.75725 6.37827 3.65678C6.2778 3.55632 6.14154 3.49988 5.99946 3.49988C5.85738 3.49988 5.72112 3.55632 5.62065 3.65678C5.52019 3.75725 5.46375 3.89351 5.46375 4.03559ZM6.26732 5.28559H5.7316C5.6825 5.28559 5.64232 5.32577 5.64232 5.37488V8.41059C5.64232 8.4597 5.6825 8.49988 5.7316 8.49988H6.26732C6.31642 8.49988 6.3566 8.4597 6.3566 8.41059V5.37488C6.3566 5.32577 6.31642 5.28559 6.26732 5.28559Z" />
  </svg>
)
