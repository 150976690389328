import React from 'react'
import AlertTriangle from '@sylveraio/untitled-ui-icons-react/build/esm/AlertTriangle'
import AlertCircle from '@sylveraio/untitled-ui-icons-react/build/esm/AlertCircle'
import CheckCircle from '@sylveraio/untitled-ui-icons-react/build/esm/CheckCircle'
import InfoCircle from '@sylveraio/untitled-ui-icons-react/build/esm/InfoCircle'
import type { CalloutVariants } from '../../types'

export function getCalloutIcon(variant: CalloutVariants, colorClass?: string) {
  switch (variant) {
    case 'action':
    case 'discovery':
      return <InfoCircle className={colorClass} />
    case 'neutral':
    case 'alert':
      return <AlertCircle className={colorClass} />
    case 'warning':
      return <AlertTriangle className={colorClass} />
    case 'positive':
      return <CheckCircle className={colorClass} />
    default:
      return null
  }
}
