import type { SortDirection, SortingState } from '@tanstack/react-table'

/**
 * Determines the current sort direction based on the provided sorting state. If the sort direction is not explicitly defined, it returns a default sort direction.
 * @param {SortingState} appliedSort- The currently applied sort.
 * @param {SortDirection} defaultSortDirection - The default sort direction.
 * @returns {SortDirection} - The sort direction.
 */
export const getCurrentSortDirection = (
  appliedSort: SortingState,
  defaultSortDirection?: SortDirection,
): SortDirection | undefined => {
  if (typeof appliedSort[0]?.desc === 'undefined') {
    return defaultSortDirection
  }
  return appliedSort[0]?.desc ? 'desc' : 'asc'
}

/**
 * Determines if a given column is currently sorted based on the provided sorting state. It compares the field's ID with the ID in the sorting state or a default sort field.
 * @param {SortingState} appliedSort- The currently applied sort.
 * @param {SortDirection} defaultSortField - The defaul sort field.
 * @param {string} headerId - The ID of the column to check.
 * @returns {boolean} - Is the given column sorted.
 */
export const isColumnSorted = (
  appliedSort: SortingState,
  headerId: string,
  defaultSortField?: string,
): boolean => {
  if (typeof appliedSort[0]?.id === 'undefined') {
    return headerId === defaultSortField
  }
  return headerId === appliedSort[0]?.id
}

/**
 * Determines whether the content of a table cell should be rendered based on hover state and a specific condition for showing cell content on hover.
 * @param {boolean} showCellContentOnHover- Indicates if the content should be rendered only when the row was hovered.
 * @param {string} rowId - The ID of the current row.
 * @param {string} hoveredRow - The ID of the currently hovered row.
 * @returns {boolean} - Should cell content be rendered.
 */
export const shouldRenderCellContent = (
  showCellContentOnHover?: boolean,
  rowId?: string,
  hoveredRow?: string | null,
): boolean => {
  if (!showCellContentOnHover) return true
  const isHoveredRow = rowId === hoveredRow
  return showCellContentOnHover && isHoveredRow
}
