import type { FC } from 'react'
import { DropdownTooltip } from '../DropdownTooltip'
import type { DropdownListProps } from './types'

export const DropdownList: FC<DropdownListProps> = ({
  testId = 'dropdown-list',
  entries,
  onClick,
  isVisible = false,
  referenceElement,
  placement,
  offset,
}) => (
  <DropdownTooltip
    referenceElement={referenceElement}
    isVisible={isVisible}
    testId={testId}
    offset={offset}
    placement={placement}
    className="!p-2.5"
  >
    <div className="flex flex-col items-start text-xs text-default">
      {(entries ?? []).map(({ id, value }) => (
        <button
          key={`list-item-${id}`}
          data-testid={`${testId}-item-${id}`}
          className="flex w-full justify-start bg-high py-2.5 pl-2.5 pr-10 hover:bg-medium"
          onClick={() => onClick(id)}
        >
          {value}
        </button>
      ))}
    </div>
  </DropdownTooltip>
)
