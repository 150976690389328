import type { FormAcceptedValues, FormFieldBase, FormFieldType } from '../types'

export function valueToString(
  type: FormFieldType,
  value: FormAcceptedValues,
  customToString?: FormFieldBase['toStringFn'],
): string {
  if (typeof customToString !== 'undefined') return customToString(value)

  switch (type) {
    case 'tuple':
      return (value as Array<string | number>).join(' - ')
    case 'checkbox':
      return (value as Array<string | number>).join(', ')
    case 'tags':
      return (value as Array<string | number>).join(`,\r\n`)
    default:
      return String(value)
  }
}
