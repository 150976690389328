import React, { FC } from 'react'
import type { LockInRingProps } from './types'

const LockInRing: FC<LockInRingProps> = ({
  testId = 'lock-in-ring',
  segmented = false,
}) =>
  segmented ? (
    <svg
      data-testid={testId}
      width="85"
      height="84"
      viewBox="0 0 85 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
    >
      <path
        d="M13.2079 40.9465C12.6558 40.9311 12.219 40.4708 12.253 39.9195C12.5987 34.3141 14.5249 28.9132 17.8183 24.3473C21.1117 19.7814 25.6288 16.2494 30.8388 14.1526C31.3511 13.9464 31.9258 14.2156 32.1146 14.7346L32.9567 17.0485C33.1456 17.5675 32.877 18.1395 32.3659 18.3487C27.996 20.1376 24.2075 23.1175 21.4375 26.9578C18.6674 30.7982 17.0352 35.3335 16.7167 40.0446C16.6794 40.5956 16.2214 41.0309 15.6693 41.0154L13.2079 40.9465Z"
        fill="#E2E2E2"
      />
      <path
        d="M33.8698 14.1593C33.7146 13.6293 34.0182 13.0721 34.5532 12.9348C39.9931 11.5389 45.7246 11.7099 51.0823 13.4386C56.4401 15.1673 61.1906 18.3785 64.7888 22.6906C65.1426 23.1146 65.0633 23.7442 64.6276 24.0836L62.685 25.5968C62.2493 25.9362 61.6225 25.8567 61.2661 25.4348C58.2195 21.8273 54.2184 19.1395 49.712 17.6854C45.2057 16.2314 40.3882 16.0739 35.8076 17.2203C35.2719 17.3544 34.7168 17.0526 34.5616 16.5225L33.8698 14.1593Z"
        fill="#E2E2E2"
      />
      <path
        d="M66.7412 25.631C67.1985 25.3214 67.8218 25.4402 68.1159 25.9077C71.1068 30.6612 72.6948 36.171 72.6853 41.8007C72.6758 47.4304 71.0693 52.9348 68.0624 57.6782C67.7667 58.1447 67.143 58.2614 66.6867 57.9502L64.6524 56.5627C64.1962 56.2515 64.0804 55.6304 64.3733 55.1622C66.8777 51.1592 68.2149 46.5283 68.2229 41.7932C68.2309 37.058 66.9093 32.4227 64.4184 28.4113C64.1271 27.9421 64.2449 27.3213 64.7022 27.0117L66.7412 25.631Z"
        fill="#E2E2E2"
      />
      <path
        d="M32.0746 68.7518C31.885 69.2705 31.3099 69.5389 30.7979 69.3319C25.5911 67.2271 21.0794 63.6881 17.793 59.1171C14.5067 54.5462 12.5887 49.1424 12.2517 43.5364C12.2185 42.9851 12.656 42.5255 13.2081 42.5109L15.6697 42.4457C16.2218 42.4311 16.6791 42.8671 16.7155 43.4182C17.0268 48.1298 18.652 52.6676 21.4162 56.5122C24.1804 60.3568 27.9643 63.3425 32.3315 65.1381C32.8423 65.3481 33.1099 65.9205 32.9202 66.4392L32.0746 68.7518Z"
        fill="#E2E2E2"
      />
      <path
        d="M64.6292 59.4165C65.0649 59.7558 65.1443 60.3854 64.7905 60.8095C61.1926 65.1218 56.4423 68.3333 51.0847 70.0624C45.727 71.7915 39.9955 71.9628 34.5555 70.5673C34.0206 70.4301 33.717 69.8729 33.8721 69.3428L34.5638 66.9796C34.7189 66.4495 35.274 66.1476 35.8097 66.2817C40.3904 67.4278 45.2078 67.27 49.7141 65.8156C54.2204 64.3613 58.2212 61.6732 61.2676 58.0655C61.624 57.6436 62.2508 57.564 62.6865 57.9034L64.6292 59.4165Z"
        fill="#E2E2E2"
      />
      <path
        d="M48.0262 37.2531H47.3465V34.616C47.3465 31.4661 45.0889 29 41.8603 29C38.6318 29 36.4228 31.515 36.4228 34.616V37.2286C36.4228 37.2531 36.4228 37.2531 36.4228 37.2286H35.7431C34.238 37.2286 33.0243 38.4495 33 39.9878V48.2408C33 49.7547 34.2137 50.9756 35.7431 51H48.0262C49.5312 51 50.745 49.7791 50.7692 48.2408V39.9878C50.7692 38.4739 49.5312 37.2531 48.0262 37.2531ZM37.7822 37.2531V34.616C37.7822 32.2719 39.3843 30.3674 41.8603 30.3674C44.3121 30.3674 45.9871 32.2231 45.9871 34.616V37.2286C45.9871 37.2531 45.9871 37.2531 45.9871 37.2286H37.7822C37.7822 37.2531 37.7822 37.2531 37.7822 37.2531ZM49.3856 48.2408C49.3856 48.9978 48.7787 49.6082 48.0262 49.6082H35.7431C34.9905 49.6082 34.3837 48.9978 34.3837 48.2408V39.9878C34.3837 39.2309 34.9905 38.6204 35.7431 38.6204H48.0262C48.7787 38.6204 49.3856 39.2309 49.3856 39.9878V48.2408ZM43.244 42.7469C43.244 43.2597 42.977 43.6992 42.5643 43.9434H42.54H42.5643V46.1898C42.5643 46.5805 42.2487 46.8735 41.8846 46.8735C41.4962 46.8735 41.2049 46.556 41.2049 46.1898V43.9434H41.2292H41.2049C40.7922 43.6992 40.5252 43.2597 40.5252 42.7469C40.5252 41.99 41.1321 41.3796 41.8846 41.3796C42.6371 41.3552 43.244 41.9656 43.244 42.7469Z"
        fill="#BAB8FE"
      />
    </svg>
  ) : (
    <svg
      data-testid={testId}
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.1968 30C60.1968 46.5685 46.7653 60 30.1968 60C13.6282 60 0.196777 46.5685 0.196777 30C0.196777 13.4315 13.6282 0 30.1968 0C46.7653 0 60.1968 13.4315 60.1968 30ZM4.69678 30C4.69678 44.0833 16.1135 55.5 30.1968 55.5C44.28 55.5 55.6968 44.0833 55.6968 30C55.6968 15.9167 44.28 4.5 30.1968 4.5C16.1135 4.5 4.69678 15.9167 4.69678 30Z"
        fill="#E2E2E2"
      />
      <path
        d="M36.0262 25.2531H35.3465V22.616C35.3465 19.4661 33.0889 17 29.8603 17C26.6318 17 24.4228 19.515 24.4228 22.616V25.2286C24.4228 25.2531 24.4228 25.2531 24.4228 25.2286H23.7431C22.238 25.2286 21.0243 26.4495 21 27.9878V36.2408C21 37.7547 22.2137 38.9756 23.7431 39H36.0262C37.5312 39 38.745 37.7791 38.7692 36.2408V27.9878C38.7692 26.4739 37.5312 25.2531 36.0262 25.2531ZM25.7822 25.2531V22.616C25.7822 20.2719 27.3843 18.3674 29.8603 18.3674C32.3121 18.3674 33.9871 20.2231 33.9871 22.616V25.2286C33.9871 25.2531 33.9871 25.2531 33.9871 25.2286H25.7822C25.7822 25.2531 25.7822 25.2531 25.7822 25.2531ZM37.3856 36.2408C37.3856 36.9978 36.7787 37.6082 36.0262 37.6082H23.7431C22.9905 37.6082 22.3837 36.9978 22.3837 36.2408V27.9878C22.3837 27.2309 22.9905 26.6204 23.7431 26.6204H36.0262C36.7787 26.6204 37.3856 27.2309 37.3856 27.9878V36.2408ZM31.244 30.7469C31.244 31.2597 30.977 31.6992 30.5643 31.9434H30.54H30.5643V34.1898C30.5643 34.5805 30.2487 34.8735 29.8846 34.8735C29.4962 34.8735 29.2049 34.556 29.2049 34.1898V31.9434H29.2292H29.2049C28.7922 31.6992 28.5252 31.2597 28.5252 30.7469C28.5252 29.99 29.1321 29.3796 29.8846 29.3796C30.6371 29.3552 31.244 29.9656 31.244 30.7469Z"
        fill="#BAB8FE"
      />
    </svg>
  )

export default LockInRing
