import { addBreadcrumb, captureMessage } from '@sentry/nextjs'
import { getServerAccessToken } from '../getServerAccessToken'
import { COMMON_ERRORS } from '@sylveraio/constants'

export async function fetchAuthenticatedServer(
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> {
  const bearerToken = await getServerAccessToken()
  if (!bearerToken) {
    addBreadcrumb({
      message: 'No server-side bearer token for request',
    })
    return Promise.reject(new Error(COMMON_ERRORS.NO_VALID_TOKEN))
  }

  const response = await fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${bearerToken}`,
    },
  })

  if (response.status === 401) {
    captureMessage(`fetchAuthenticatedServer call for ${input} failed with 401`)
  }

  return response
}
