import { useMemo } from 'react'
import type { SWRResponse } from 'swr'
import useSWR from 'swr'
import isEmpty from 'lodash/isEmpty'
import type { UseRatings, TransformedRatedProjects } from './types'

/**
 * Attempts to fetch the project fields in fieldsParam.
 * If shouldOnlyFetchFieldsBasedOnUserPermissions is false, uses a full access API key to fetch fields for all the projects specified in pageSizeParam and filterParam,
 *  and do not return the project id field if shouldHideIdsFromResponse is true.
 * If shouldOnlyFetchFieldsBasedOnUserPermissions is true, uses the user's token and permissions to fetch fields for each project id in fallbackDataIds.
 */
export function useRatings(
  shouldOnlyFetchFieldsBasedOnUserPermissions: boolean,
  pageSizeParam?: number,
  sortParam?: string,
  filterParam?: Record<string, unknown>,
  fieldsParam?: string,
  fallbackData: TransformedRatedProjects = [],
  shouldHideIdsFromResponse?: boolean,
): UseRatings {
  const fallbackDataIds = fallbackData
    .filter((element) => element.projectId)
    .map((element) => element.projectId)

  const paramsFormatted = useMemo(() => {
    return {
      // If shouldOnlyFetchFieldsBasedOnUserPermissions is true, then pageSizeParam, sortParam, filterParam, and shouldHideIdsFromResponse are not needed
      //  because projects will be fetched individually for each project id in fallbackDataIds.
      ...(!shouldOnlyFetchFieldsBasedOnUserPermissions
        ? {
            ...(pageSizeParam
              ? {
                  page: {
                    limit: pageSizeParam,
                  },
                  filter: {
                    status: {
                      ne: 'inactive',
                    },
                  },
                }
              : {}),
            ...(sortParam
              ? {
                  sort: sortParam,
                }
              : {}),
            ...(filterParam
              ? {
                  filter: filterParam,
                }
              : {}),
            shouldHideIdsFromResponse,
          }
        : {}),
      ...(fieldsParam
        ? {
            fields: {
              project: fieldsParam,
            },
          }
        : {}),
    }
  }, [
    shouldOnlyFetchFieldsBasedOnUserPermissions,
    pageSizeParam,
    sortParam,
    filterParam,
    fieldsParam,
  ])

  const { data, isValidating, error, mutate, isLoading } = useSWR<
    TransformedRatedProjects,
    SWRResponse<TransformedRatedProjects>
  >(
    [
      shouldOnlyFetchFieldsBasedOnUserPermissions
        ? '/privateRatings'
        : '/publicRatings',
      fallbackDataIds,
      paramsFormatted,
    ],
    {
      fallbackData,
    },
  )

  return {
    data,
    isValidating,
    isLoading,
    error,
    mutate,
    isFetching: isValidating && (isEmpty(data) || data === fallbackData),
  }
}
