import type {
  FormFieldCheckbox,
  FormFieldCheckboxWithSearch,
} from '../../types'

export function checkIsCheckboxWithSearch(
  entry: FormFieldCheckbox | FormFieldCheckboxWithSearch,
): entry is FormFieldCheckboxWithSearch {
  return typeof (entry as FormFieldCheckboxWithSearch)?.searchFn !== 'undefined'
}
