import { useState } from 'react'
import difference from 'lodash/difference'
import type { FieldSearchFn, Values } from '../createForm/types'
import { useSylveraQuery } from '@sylveraio/react-utils'
import type { ApolloClient } from '@apollo/client'
import { GET_REGISTRIES, type Registries } from '../../queries/GET_REGISTRIES'

export function useRegistries(client: ApolloClient<any>) {
  const [allRegistrys, setAllRegistries] = useState<Values>([])
  const [registryOpts, setRegistryOpts] = useState<Values>([])
  const handleRegistrySearch: FieldSearchFn = (
    value,
    key,
    currentValues = [],
  ) => {
    if (value === '') {
      setRegistryOpts([])
    } else {
      const optsMinusSelected = difference(allRegistrys, currentValues)
      setRegistryOpts(
        optsMinusSelected.filter(({ text }) =>
          text.toLowerCase().match(value.toLowerCase()),
        ),
      )
    }
  }

  useSylveraQuery<Registries>(GET_REGISTRIES, client, {
    variables: {
      first: 50,
    },
    onCompleted: (data: Registries) => {
      if (!data) {
        setAllRegistries([])
        return
      }

      const { nodes } = data.registries
      setAllRegistries(
        nodes.map((registry) => ({
          value: registry.id,
          text: registry.name,
        })) as Values,
      )
    },
  })

  return {
    registryOpts,
    handleRegistrySearch,
  }
}
