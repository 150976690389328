import { FC } from 'react'
import clsx from 'clsx'
// import ProgressRingTooltip from '../ProgressRingTooltip'
import { ProgressRingLabelProps } from './types'

export const ProgressRingLabel: FC<ProgressRingLabelProps> = ({
  label,
  labelClassName,
  // size,
  // value,
  // withTooltip,
  // progressRingId,
}) => {
  if (typeof label === 'undefined') return null

  return (
    <div className="flex items-center gap-x-2">
      <span
        className={clsx(
          'text-syl-sm text-disabled whitespace-nowrap',
          labelClassName,
        )}
      >
        {label}
      </span>
      {/* {withTooltip && (
        <ProgressRingTooltip
          value={value}
          size={size}
          progressRingId={progressRingId}
        />
      )} */}
    </div>
  )
}

export default ProgressRingLabel
