import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const LeftArrow: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: size, width: size }}
    className={clsx('fill-selection-forest', className)}
  >
    <path d="M8.00065 2.666016L8.94065 3.60602L5.22065 7.33268H13.334V8.66602H5.22065L8.94065 12.3927L8.00065 13.3327L2.667317 7.99935L8.00065 2.666016Z" />
    <path d="M24.0007 18.666L24.9407 19.606L21.2207 23.3327H29.334V24.666H21.2207L24.9407 28.3927L24.0007 29.3327L18.6673 23.9993L24.0007 18.666Z" />
  </svg>
)

export default LeftArrow
