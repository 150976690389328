'use client'

/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react'

/**
 *
 * @param callback Function to call on timeout
 * @param delay Delay time in ms
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}

export default useInterval
