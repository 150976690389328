import { FC } from 'react'
import { CloseButtonProps } from './types'
import { IconLibrary } from '@sylveraio/design-system'

export const CloseButton: FC<CloseButtonProps> = ({
  testId = 'close-button',
  className,
  iconSize = 'md',
  onClick,
}) => (
  <button
    data-testid={testId}
    className={className}
    type="button"
    onClick={onClick}
  >
    <IconLibrary name="close" size={iconSize} />
  </button>
)

export default CloseButton
