import { FC } from 'react'
import { typographyBundles } from './constants'
import type { TypographyProps } from './types'
import clsx from 'clsx'

export const Typography: FC<TypographyProps> = ({
  testId = 'typography',
  size,
  weight = 'regular',
  children,
  className,
}) => {
  const { tag: Tag, classes } = typographyBundles[size][weight]

  return (
    <Tag
      data-testid={testId}
      className={clsx('text-default', classes, className)}
    >
      {children}
    </Tag>
  )
}
