import { screens } from '@sylveraio/tailwind'
export interface WindowDimensions {
  width: number | undefined
  height: number | undefined
}

export enum Breakpoint {
  DesktopLarge = parseInt(screens['desktop-lg'], 10),
  DesktopMedium = parseInt(screens['desktop-md'], 10),
  DesktopSmall = parseInt(screens['desktop-sm'], 10),
  Tablet = parseInt(screens['tablet'], 10),
}
