import clsx from 'clsx'
import type { ButtonVariant } from '../../types'

/**
 * Get the Tailwind class names for button colors based on the button variant, active state, and disabled state.
 *
 * @param {ButtonVariant} variant - The variant of the button. It can be 'primary', 'secondary', 'subtle', 'destructive', or 'discovery'.
 * @param {boolean} [active] - Optional. Indicates if the button is in an active state. Defaults to `false`.
 * @param {boolean} [disabled] - Optional. Indicates if the button is in a disabled state. Defaults to `false`.
 * @returns {string} - A string of concatenated class names based on the button state.
 *
 * @example
 * // Get colors for a primary button that is active
 * getButtonColors('primary', true);
 *
 * @example
 * // Get colors for a secondary button that is disabled
 * getButtonColors('secondary', false, true);
 */
export function getButtonColors(
  variant: ButtonVariant,
  active?: boolean,
  disabled?: boolean,
) {
  if (disabled) {
    return clsx('disabled:text-disabled', {
      'disabled:bg-disabled': variant !== 'subtle',
    })
  }

  if (active) {
    return clsx(
      'border-2',
      // active background colour classes
      {
        'bg-content-action-default-hover': variant === 'primary',
        'bg-transparent': variant === 'secondary' || variant === 'subtle',
        'bg-content-alert-default-hover': variant === 'destructive',
        'bg-content-discovery-default-hover': variant === 'discovery',
      },
      // active border colour classes
      {
        'border-content-action-subtle':
          variant === 'primary' || variant === 'subtle',
        'border-content-action-default': variant === 'secondary',
        'border-content-alert-subtle': variant === 'destructive',
        'border-content-discovery-subtle': variant === 'discovery',
      },
      // active text colour classes
      {
        'text-content-action-on-default': variant === 'primary',
        'text-content-action-default':
          variant === 'secondary' || variant === 'subtle',
        'text-content-alert-on-default': variant === 'destructive',
        'text-content-discovery-on-default': variant === 'discovery',
      },
    )
  }

  return clsx(
    // default background colour classes
    {
      'bg-content-action-default hover:bg-content-action-default-hover':
        variant === 'primary',
      'bg-medium hover:bg-medium-hover focus-visible:bg-high':
        variant === 'secondary',
      'bg-transparent hover:bg-default-hover focus-visible:bg-high':
        variant === 'subtle',
      'bg-content-alert-default hover:bg-content-alert-default-hover':
        variant === 'destructive',
      'bg-content-discovery-default hover:bg-content-discovery-default-hover':
        variant === 'discovery',
    },
    // default border colour classes
    {
      border: variant !== 'subtle' && !active,
      'border-content-action-dark': variant === 'primary' && !active,
      'border-default hover:border-default-hover focus-visible:border-content-action-default':
        variant === 'secondary' && !active,
      'focus-visible:border-content-action-default':
        variant === 'subtle' && !active,
      'border-content-alert-dark': variant === 'destructive' && !active,
      'border-[#99C102] hover:border-content-discovery-dark':
        variant === 'discovery' && !active,
    },
    // default text colour classes
    {
      'text-content-action-on-default': variant === 'primary',
      'text-default hover:text-default-hover focus-visible:text-content-action-default':
        variant === 'secondary' || variant === 'subtle',
      'text-content-alert-on-default': variant === 'destructive',
      'text-content-discovery-on-default': variant === 'discovery',
    },
  )
}
