import type { FC } from 'react'
import type { IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'
import clsx from 'clsx'

export const ExternalLink: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'external-link',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-transparent fill-[#605CFF]', className)}
  >
    <path d="M4.11111 3C3.50385 3 3 3.50385 3 4.11111V11.8889C3 12.4961 3.50385 13 4.11111 13H11.8889C12.4961 13 13 12.4961 13 11.8889V8H11.8889V11.8889H4.11111V4.11111H8V3H4.11111ZM9.11111 3V4.11111H11.1033L5.94054 9.27387L6.72613 10.0595L11.8889 4.8967V6.88889H13V3H9.11111Z" />
  </svg>
)
