import { palette } from '@sylveraio/tailwind'

export const COLORS: Record<
  string,
  {
    backgroundColor: Array<string>
    label: string
    labelBackgroundColor: string
  }
> = {
  PURPLE: {
    backgroundColor: [
      '#131233',
      '#302E80',
      '#4340B3',
      '#605CFF',
      '#807DFF',
      '#A09DFF',
      '#BFBEFF',
      '#DFDEFF',
    ],
    label: 'text-content-action-default',
    labelBackgroundColor: palette.selection['off-white-3'],
  },
  BLUE: {
    backgroundColor: [
      '#014063',
      '#0278BA',
      '#0090E0',
      '#00A3FF',
      '#48BCFD',
      '#84D3FF',
      '#B1E3FF',
      '#D7F0FF',
      '#EBF6FD',
    ],
    label: 'text-secondary-water',
    labelBackgroundColor: palette.selection['off-white-3'],
  },
  MAUVE: {
    backgroundColor: [
      '#32273C',
      '#3E314B',
      '#67517D',
      '#9071AF',
      '#CEA2FA',
      '#D8B5FB',
      '#E7D1FD',
      '#EEDFFE',
    ],
    label: 'text-secondary-mauve',
    labelBackgroundColor: palette.selection['off-white-3'],
  },
  LEMON: {
    backgroundColor: [
      '#0D0A00',
      '#322E1D',
      '#655B3A',
      '#978957',
      '#CAB674',
      '#FCE491',
      '#FEF4D0',
      '#FFFCF4',
    ],
    label: 'text-secondary-lemon',
    labelBackgroundColor: palette.selection['off-white-3'],
  },
  RAINBOW: {
    backgroundColor: [
      palette.secondary.lemon,
      palette.secondary.water,
      palette.data.coral,
      palette.secondary.mauve,
      palette.primary['neon-blue'],
      '#7483B8',
      '#872858',
      palette['scheme-purple'][2],
      '#0278BA',
      palette['scheme-purple'][1],
      palette['scheme-purple'][4],
    ],
    label: 'text-default',
    labelBackgroundColor: palette.selection['off-white-1'],
  },
}
export const LOADING_TIME_MS = 2000
export const LOADING_DATASET = [10, 20, 30, 40]

export const DEFAULT_LAYOUT_STYLING = {
  padding: 80,
}

export const DEAFULT_LABEL_STYLING = {
  borderWidth: 0,
  color: 'black',
  font: {
    weight: 'normal',
    size: 14,
  },
  offset: 10,
  padding: 2,
}
