import type { MultiChartProps } from '../../types'

export function createAnnotationHighlightRange(
  highlightRange: MultiChartProps['highlightRange'] = [],
  highlightRangeColor: MultiChartProps['highlightRangeColor'] = [],
  showOverlay = true,
) {
  let box1: Record<string, unknown> | undefined
  let box2: Record<string, unknown> | undefined

  if (showOverlay) return {}

  const [box1Data, box2Data] = highlightRange

  if (box1Data) {
    box1 = {
      display: true,
      type: 'box' as const,
      drawTime: 'beforeDatasetsDraw' as const,
      xMin: box1Data?.[0],
      xMax: box1Data?.at(-1),
      backgroundColor:
        typeof highlightRangeColor?.[0] !== 'undefined'
          ? `${highlightRangeColor[0]}1A`
          : '#48BCFD1A',
      borderColor:
        typeof highlightRangeColor?.[0] !== 'undefined'
          ? `${highlightRangeColor[0]}1A`
          : '#48BCFD1A',
      borderWidth: box1Data?.[0] !== box1Data?.at(-1) ? 0 : 5,
    }
  }
  if (box2Data) {
    box2 = {
      display: true,
      type: 'box' as const,
      drawTime: 'beforeDatasetsDraw' as const,
      xMin: box2Data?.[0],
      xMax: box2Data?.at(-1),
      backgroundColor:
        typeof highlightRangeColor?.[1] !== 'undefined'
          ? `${highlightRangeColor[1]}1A`
          : '#48BCFD1A',
      borderColor:
        typeof highlightRangeColor?.[0] !== 'undefined'
          ? `${highlightRangeColor[1]}1A`
          : '#48BCFD1A',
      borderWidth: box2Data?.[0] !== box2Data?.at(-1) ? 0 : 5,
    }
  }

  return {
    box1,
    box2,
  }
}
