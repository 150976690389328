import type { FC } from 'react'
import type { RangeSliderThumbProps } from './types'
import clsx from 'clsx'

export const RangeSliderThumb: FC<RangeSliderThumbProps> = ({
  testId = 'range-slider-thumb',
  props,
  disabled,
}) => (
  <div
    data-testid={`${testId}-handle-${props.key === 0 ? 'min' : 'max'}`}
    {...props}
    style={{
      ...props.style,
    }}
    className={clsx('h-2 w-2 bg-[#605CFF] rounded-full focus:outline-none', {
      '!cursor-default': disabled,
    })}
  />
)
