import { FC } from 'react'
import clsx from 'clsx'
import { ArrowInRingProps } from './types'

const ArrowInRing: FC<ArrowInRingProps> = ({
  testId = 'arrow-in-ring',
  size = 16,
  className,
  direction = 'right',
}) => (
  <svg
    data-testid={testId}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx(
      {
        'transform rotate-180': direction === 'left',
      },
      className,
    )}
  >
    <rect
      x="0.5"
      y="0.5"
      width="21"
      height="21"
      rx="10.5"
      fill="white"
      className="fill-white hover:fill-primary-50"
    />
    <path
      d="M11 5.66675L10.06 6.60675L13.78 10.3334H5.66663V11.6667H13.78L10.06 15.3934L11 16.3334L16.3333 11.0001L11 5.66675Z"
      fill="#605CFF"
      className="pointer-events-none"
    />
    <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke="#605CFF" />
  </svg>
)

export default ArrowInRing
