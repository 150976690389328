import { Typography } from '@sylveraio/design-system'
import { createKey } from '../utils/createKey'
import type { ChangeEvent } from 'react'
import type { Value } from '../types'
import clsx from 'clsx'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'

export function Select({
  name,
  handleSelect,
  values,
  currentValue,
  align = 'left',
}: {
  name: string
  handleSelect: (ev: ChangeEvent<HTMLSelectElement>) => void
  values: Array<Value>
  currentValue: unknown
  align?: 'left' | 'right'
}): JSX.Element {
  return (
    <div className="grid">
      <select
        name={name}
        onSelect={handleSelect}
        onChange={handleSelect}
        defaultValue={
          typeof currentValue !== 'undefined' && currentValue !== null
            ? currentValue.toString()
            : undefined
        }
        className={clsx(
          'row-start-1 col-start-1 min-w-max bg-transparent h-full p-3 appearance-none forced-colors:appearance-auto pr-8',
          {
            'text-right': align === 'right',
          },
        )}
      >
        {values.map((v) => {
          const { value, text } = v
          return (
            <option
              key={createKey('select', name, value)}
              value={value as string | number}
              className="p-2"
            >
              <Typography size="sm" weight="medium">
                {text}
              </Typography>
            </option>
          )
        })}
      </select>
      <ChevronDown
        height={20}
        width={20}
        className="pointer-events-none row-start-1 col-start-1 self-center justify-self-end relative right-3 z-10 forced-colors:hidden"
      />
    </div>
  )
}
