import { type FC, useCallback } from 'react'
import throttle from 'lodash/throttle'
import isEmpty from 'lodash/isEmpty'
import { RangeSlider } from '../RangeSlider'
import { ProgressRing } from '../ProgressRing'
import type { RangeFilterProps, RangeInputProps } from './types'
import { RANGE_MIN } from './constants'
import { parsePercentageScore } from '@sylveraio/react-utils'
import clsx from 'clsx'

export const BasicRangeInput: FC<{ value?: number }> = ({ value }) => (
  <div className="text-xs text-default">{value}</div>
)

export const RangeInput: FC<RangeInputProps> = ({ variant, label, value }) => {
  const getRangeInputComponent = () => {
    switch (variant) {
      case 'progressRing':
        return (
          <ProgressRing
            label={label}
            innerLabel={`${parsePercentageScore(value)}%`}
            stroke={6}
            value={parsePercentageScore(value)}
            wrapperClassName="gap-y-1"
            labelPosition="bottom"
            labelClassName="!text-content-action-subtle"
          />
        )
      case 'basic':
        return <BasicRangeInput value={value} />
      default:
        return null
    }
  }

  return <>{getRangeInputComponent()}</>
}

export const RangeFilter: FC<RangeFilterProps> = ({
  onChange,
  filterThrottleMS = 500,
  rangeMax = 1,
  selectedRange,
  testId = '-range-filter',
  variant = 'progressRing',
  rangeSliderOpts,
}) => {
  const range: Array<number> =
    typeof selectedRange !== 'undefined' && !isEmpty(selectedRange)
      ? selectedRange
      : [RANGE_MIN, rangeMax]
  const [minSelected, maxSelected] = range

  const handleChange = useCallback(
    throttle(onChange, filterThrottleMS, { leading: false }),
    [onChange],
  )

  return (
    <div className="flex items-center justify-start" data-testid={testId}>
      <RangeInput value={minSelected} label="Min" variant={variant} />
      <div className={clsx({ '-mt-5': variant === 'progressRing' })}>
        <RangeSlider
          rtl={false}
          values={range}
          onChange={handleChange}
          min={RANGE_MIN}
          max={rangeMax}
          testId={`${testId}-range`}
          step={rangeSliderOpts?.step}
          thumbClassName={rangeSliderOpts?.thumbClassName}
          sliderClassName={rangeSliderOpts?.sliderClassName}
        />
      </div>
      <RangeInput value={maxSelected} label="Max" variant={variant} />
    </div>
  )
}

export default RangeFilter
