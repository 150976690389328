/**
 * Searches for a key in an object, including nested objects.
 *
 * @returns The value of the key if found, otherwise undefined.
 */
export const searchDeepKey = (obj: Record<string, any>, key: string): any => {
  if (obj == null || typeof obj !== 'object') {
    return undefined
  }

  if (key in obj) {
    return obj[key]
  }

  for (const k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k)) {
      const val = searchDeepKey(obj[k], key)
      if (val !== undefined) {
        return val
      }
    }
  }

  return undefined
}
