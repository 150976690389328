/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  BarElement,
  Legend,
  ChartDataset,
  ChartOptions,
  LegendItem,
  Tooltip,
} from 'chart.js'
import { ChartLegend } from '../ChartLegend'
import { ChartPanning } from '../ChartPanning'
import {
  GRID_OPTIONS,
  AXIS_LABEL_OPTIONS,
  TICK_OPTIONS,
  MAX_PAN_LIMIT,
  MIN_PAN_LIMIT,
} from '../constants'
import type { BarChartProps } from './types'
import { useShowNoDataOverlay } from '../useShowNoDataOverlay'
import clsx from 'clsx'
import { EmptyState } from '@sylveraio/design-system'
import BarLineChart from '@sylveraio/untitled-ui-icons-react/build/esm/BarLineChart'
import type { ScatterDataPoint } from '../types'

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  BarElement,
  Legend,
  Tooltip,
)

if (typeof window !== 'undefined') {
  ;(async () => {
    const { default: zoomPlugin } = await import('chartjs-plugin-zoom')
    ChartJS.register(zoomPlugin)
  })()
}

export const BarChart: FC<BarChartProps> = ({
  testId = 'bar-chart',
  data,
  yAxisOptions,
  xAxisOptions,
  hideGridLines = true,
  enableFiltering,
  enablePanning,
  tooltipOptions,
  showNoDataOverlay = true,
  sortLegend,
  ignoreMaxWidth = false,
}) => {
  const chartRef = useRef<ChartJS<'bar', ScatterDataPoint[], unknown> | null>(
    null,
  )
  const [labels, setLabels] = useState<string[]>([])
  const [chartData, setChartData] = useState<{
    labels?: string[]
    datasets: ChartDataset<'bar', ScatterDataPoint[]>[]
  }>({
    datasets: [],
  })
  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({})
  const [legendItems, setLegendItems] = useState<LegendItem[] | undefined>([])
  const showOverlay = useShowNoDataOverlay(data, showNoDataOverlay)

  const {
    type: yAxisType,
    yAxisLabel,
    ticks: yAxisTicksOptions,
    min: yAxisMin,
    max: yAxisMax,
    offset: yAxisOffset,
  } = yAxisOptions ?? {}

  const {
    type: xAxisType,
    xAxisLabel,
    ticks: xAxisTicksOptions,
    min: xAxisMin,
    max: xAxisMax,
    offset: xAxisOffset,
  } = xAxisOptions ?? {}

  useEffect(() => {
    const mergedLabels = data.reduce<string[]>((acc, cv) => {
      const _labels = cv.values.map((val) => val.x)

      _labels.forEach((label) => {
        const formattedLabel = label.toString()
        if (!acc.includes(formattedLabel)) acc.push(formattedLabel)
      })

      return acc
    }, [])

    setLabels(mergedLabels)

    setChartData({
      labels: mergedLabels,
      datasets: data
        ?.filter((ds) => !ds?.hide)
        .map((ds, _) => ({
          ...ds,
          data: ds?.values,
          maxBarThickness: 30,
          categoryPercentage: 0.75,
          barPercentage: 0.75,
        })),
    })

    setChartOptions({
      responsive: true,
      plugins: {
        zoom: {
          pan: {
            enabled: enablePanning,
            mode: 'x' as const,
          },
          limits: {
            x: { minRange: 5 },
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          ...tooltipOptions,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        y: {
          type: yAxisType as any,
          title: {
            display: !!yAxisLabel,
            text: yAxisLabel,
            ...AXIS_LABEL_OPTIONS,
          },
          grid: {
            ...GRID_OPTIONS,
            display: !hideGridLines,
          },
          ticks: {
            ...TICK_OPTIONS,
            ...yAxisTicksOptions,
          },
          min: yAxisMin,
          max: yAxisMax,
          offset: yAxisOffset,
        },
        x: {
          type: enablePanning ? 'category' : (xAxisType as any) ?? 'category',
          title: {
            display: !!xAxisLabel,
            text: xAxisLabel,
            ...AXIS_LABEL_OPTIONS,
          },
          grid: {
            ...GRID_OPTIONS,
            display: !hideGridLines,
          },
          ticks: {
            ...TICK_OPTIONS,
            ...xAxisTicksOptions,
          },
          ...(xAxisType === 'time'
            ? {
                time: {
                  unit: 'year',
                },
              }
            : {}),
          min: enablePanning ? mergedLabels[MIN_PAN_LIMIT] : xAxisMin,
          max: enablePanning ? mergedLabels[MAX_PAN_LIMIT] : xAxisMax,
          suggestedMax: mergedLabels.at(-1),
          offset: xAxisOffset ?? true,
        },
      },
    })
  }, [data])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update()
    }
    setLegendItems(chartRef.current?.legend?.legendItems)
  }, [labels, chartData, chartOptions])

  if (typeof chartData === 'undefined') return null

  return (
    <div
      data-testid={`${testId}-wrapper`}
      className={clsx('relative flex', {
        'w-full': ignoreMaxWidth,
      })}
    >
      {showOverlay && (
        <div className="absolute flex items-center justify-center w-full h-full">
          <EmptyState
            withContainer={false}
            icon={<BarLineChart className="w-20 h-20" />}
            title="No available data"
          />
        </div>
      )}
      <div className="flex items-end justify-center space-x-10 w-full min-h-[400px]">
        <div
          className={clsx('w-full relative px-4', {
            'max-w-[1000px]': !ignoreMaxWidth,
          })}
        >
          <Bar
            data-testid={testId}
            ref={chartRef}
            options={chartOptions}
            data={chartData}
          />
          {enablePanning && labels.length > MAX_PAN_LIMIT && (
            <ChartPanning
              testId={`${testId}-panning`}
              chartInstance={chartRef.current}
            />
          )}
        </div>
        {enableFiltering && (
          <ChartLegend
            testId={`${testId}-legend`}
            type="bar"
            chartInstance={chartRef?.current?.legend?.chart}
            legendItems={legendItems}
            sortLegend={sortLegend}
          />
        )}
      </div>
    </div>
  )
}

export default BarChart
