import { Button, Modal } from '@sylveraio/react-ui'
import {
  BUTTON_TEXT,
  MODAL_HEADER_TEXT,
  MODAL_TEXT_ONE,
  MODAL_SEGMENT,
  MODAL_TEXT_TWO,
} from './constants'
import type { DataDisclaimerModalProps } from './types'
import type { FC } from 'react'

export const DataDisclaimerModal: FC<DataDisclaimerModalProps> = ({
  testId = 'data-disclaimer-modal',
  show,
  disableCloseModal,
  onClick,
}) => (
  <Modal
    withOverlay
    isOpen={show}
    testId={testId}
    showCloseButton
    handleClose={onClick}
  >
    <div
      className="flex max-w-2xl flex-col items-start gap-y-3 space-y-4 py-6 text-left"
      tabIndex={-1}
    >
      <div className="space-y-5">
        <div className="text-2xl font-bold text-default">
          {MODAL_HEADER_TEXT}
        </div>
        <div
          className="text-sm font-normal text-default"
          data-testid={`${testId}-title`}
        >
          {MODAL_TEXT_ONE}
        </div>
        <div className="text-sm font-normal text-default">{MODAL_TEXT_TWO}</div>
        <div className="text-sm font-normal text-default">{MODAL_SEGMENT}</div>
      </div>
      <Button
        size="sm"
        className="!h-8 !w-24 !rounded-full !py-2 !text-sm font-semibold"
        bordered={false}
        onClick={onClick}
        disabled={disableCloseModal}
        solid
      >
        {BUTTON_TEXT}
      </Button>
    </div>
  </Modal>
)
