import clsx from 'clsx'

export function IconWrapper({
  Icon,
  className,
}: {
  Icon?: React.FC
  className?: string
}): JSX.Element | null {
  if (!Icon) return null
  return (
    <div className={clsx('w-6 h-6 shrink-0', className)}>
      <Icon />
    </div>
  )
}
