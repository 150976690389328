import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Location: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'location',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-default', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.13725C2 2.85294 3.79412 1 6.01961 1C8.2451 1 10.0392 2.85294 10.0392 5.13725C10.0392 6.88235 8.84314 8.76471 6.53922 10.8039C6.40196 10.9314 6.20588 11 6.01961 11C5.83333 11 5.64706 10.9314 5.5 10.8039L5.34314 10.6667C3.13726 8.68627 2 6.84314 2 5.13725ZM7.2549 2.06863C6.86275 1.90196 6.44118 1.81373 6.01961 1.81373V1.82353C5.16667 1.82353 4.35294 2.17647 3.7451 2.79412C3.14706 3.41176 2.80392 4.2549 2.80392 5.13725C2.80392 6.53922 3.79412 8.18627 5.88235 10.049L6.02941 10.1863C8.19608 8.26471 9.2451 6.57843 9.2451 5.13725C9.2451 4.69608 9.15686 4.26471 9 3.86275C8.84314 3.46078 8.60784 3.08824 8.30392 2.78431C8 2.48039 7.64706 2.23529 7.2549 2.06863ZM7.15687 6.30392C6.85295 6.60784 6.45099 6.78431 6.01961 6.78431C5.58824 6.78431 5.18628 6.61764 4.88236 6.30392C4.57844 5.99019 4.41177 5.57842 4.41177 5.13725C4.41177 4.69607 4.57844 4.28431 4.88236 3.97058C5.18628 3.66666 5.58824 3.49019 6.01961 3.49019C6.45099 3.49019 6.85295 3.65686 7.15687 3.97058C7.46079 4.28431 7.62746 4.69607 7.62746 5.13725C7.62746 5.57842 7.46079 5.99019 7.15687 6.30392ZM6.58824 4.54901C6.44118 4.39215 6.2353 4.30392 6.01961 4.30392C5.80393 4.30392 5.59805 4.39215 5.45099 4.54901C5.30393 4.70588 5.21569 4.92156 5.21569 5.13725C5.21569 5.35293 5.30393 5.56862 5.45099 5.72548C5.59805 5.88235 5.80393 5.97058 6.01961 5.97058C6.2353 5.97058 6.44118 5.88235 6.58824 5.72548C6.7353 5.56862 6.82354 5.35293 6.82354 5.13725C6.82354 4.92156 6.7353 4.70588 6.58824 4.54901Z"
    />
  </svg>
)
