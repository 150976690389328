import type { FC } from 'react'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import type { CarbonScoreProps } from './types'
import 'react-circular-progressbar/dist/styles.css'
import {
  globalSylveraScoreSize,
  globalSylveraScorePadding,
  DEFAULT_SCORE_SIZE,
} from '../../constants'
import clsx from 'clsx'
import { carbonScoreTextVariant } from './constants'
import { primitives } from '@sylveraio/tailwind'
import { Typography } from '../../../Typography'

export const CarbonScore: FC<Omit<CarbonScoreProps, 'type'>> = ({
  testId = 'carbon-score',
  value = 0,
  size = DEFAULT_SCORE_SIZE,
  theme = 'dark',
}) => (
  <div
    data-testid={testId}
    className={clsx(
      globalSylveraScoreSize[size],
      globalSylveraScorePadding[size],
    )}
  >
    <CircularProgressbarWithChildren
      value={value}
      styles={buildStyles({
        pathColor: primitives['neon-blue'][300],
        trailColor:
          theme === 'dark' ? primitives['off-white-3'] : primitives['white'],
      })}
    >
      <Typography
        testId={`${testId}-label`}
        size={carbonScoreTextVariant[size]}
        weight="semibold"
        className="!text-content-action-dark"
      >
        {`${value}%`}
      </Typography>
    </CircularProgressbarWithChildren>
  </div>
)
