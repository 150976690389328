import { createContext, useContext } from 'react'
import type { ContextType } from './types'

export const TooltipContext = createContext<ContextType>(null)

export const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context === null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}
