import { ParsedUrlQuery } from 'querystring'
import { getValidAppName } from '../getValidAppName'
import { getParamFromQuery, getAppUrl } from '@sylveraio/react-utils'
import { captureMessage } from '@sentry/minimal'

export function getRedirectUrl(query?: ParsedUrlQuery): string | undefined {
  if (!query) return

  const { feat, page } = query

  if (typeof feat === 'undefined') return

  const appName = getValidAppName(getParamFromQuery(feat))

  if (!appName) {
    captureMessage(`Failed to validate ${feat} as an app name`)
    return
  }

  const domain = getAppUrl(appName)

  return page ? `${domain}/${getParamFromQuery(page)}` : domain
}
