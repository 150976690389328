import clsx from 'clsx'
import type { ButtonAppearance, ButtonTheme } from '../../types'
import { BUTTON_THEMES } from '../../constants'

/**
 * Get the theme styles for a button
 * @param {ButtonAppearance} appearance - The appearance of the button
 * @param {ButtonTheme} [theme] - The theme of the button
 * @returns {string} - The theme styles
 */
export function getButtonThemeStyles(
  appearance: ButtonAppearance,
  theme?: ButtonTheme,
): string
export function getButtonThemeStyles(appearance: 'none'): string
export function getButtonThemeStyles(
  appearance: ButtonAppearance,
  theme?: ButtonTheme,
): string {
  if (
    appearance !== 'none' &&
    typeof theme !== 'undefined' &&
    BUTTON_THEMES[appearance] &&
    BUTTON_THEMES[appearance][theme]
  ) {
    const themeStyles = BUTTON_THEMES[appearance][theme]

    return clsx(
      themeStyles?.default?.bg,
      themeStyles?.default?.text,
      themeStyles?.hover?.bg,
      themeStyles?.disabled?.bg,
      themeStyles?.disabled?.text,
    )
  }

  return ''
}
