import React, { FC, memo } from 'react'

import TextRenderer from './TextRenderer'
import HeadingRenderer from './HeadingRenderer'
import ListRenderer from './ListRenderer'
import ImageRenderer from './ImageRenderer'
import SDGRenderer from './SDGRenderer'
import { RichCommentaryProps } from './types'
import NoteRenderer from './NoteRenderer'

const RichCommentary: FC<RichCommentaryProps> = memo(
  ({ testId = 'rich-commentary', content }) => (
    <div data-testid={testId} className="prose max-w-none">
      {content.reduce<React.ReactNode[]>((pv, cv, idx) => {
        const key = `rendered-rich-commentary-${idx}`

        switch (cv.type) {
          case 'heading':
            pv.push(
              <HeadingRenderer
                content={cv.content}
                level={cv.level}
                key={key}
              />,
            )
            break

          case 'text': {
            const { type, ...props } = cv
            pv.push(<TextRenderer key={key} {...props} />)
            break
          }
          case 'image':
            if (cv.media) {
              pv.push(
                cv.media.map((m) => (
                  <ImageRenderer key={key} alt={cv.alt} {...m} />
                )),
              )
            }
            break

          case 'list':
            pv.push(<ListRenderer key={key} elements={cv.elements} />)
            break

          case 'sustainableDevelopmentGoals':
            pv.push(
              <SDGRenderer
                key={key}
                primary={cv.primary}
                secondary={cv.secondary}
              />,
            )
            break

          case 'note': {
            const { type, ...props } = cv
            pv.push(<NoteRenderer key={key} {...props} />)
            break
          }

          default:
            break
        }

        return pv
      }, [])}
    </div>
  ),
)

export default RichCommentary
