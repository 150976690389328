import React, { FC } from 'react'
import { palette } from '@sylveraio/tailwind'

export const ProgressBarGradient: FC = () => (
  <svg className="h-0 absolute top-0 left-0">
    <defs>
      <linearGradient id="progressBarGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor={palette.primary[500]} />
        <stop offset="100%" stopColor={palette.primary[900]} />
      </linearGradient>
    </defs>
  </svg>
)

export default ProgressBarGradient
