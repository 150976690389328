'use client'

import { useEffect, useRef, type FC } from 'react'
import omit from 'lodash/omit'
import type { ModalFooterProps, ModalHeaderProps, ModalProps } from './types'
import { Typography } from '../Typography'
import { Button } from '../Button'
import XClose from '@sylveraio/untitled-ui-icons-react/build/esm/XClose'
import clsx from 'clsx'
import { Checkbox } from '../Checkbox'
import { SIZE_WIDTH_MAP } from './constants'
import FocusTrap from 'focus-trap-react'
import { checkIsExtraActionCheckbox } from './typeguards/checkIsExtraActionCheckbox'
import { checkIsExtraActionButton } from './typeguards/checkIsExtraActionButton'

const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  titleLabel,
  subtitle,
  step,
  onClose,
  testId,
}) => (
  <header className="flex space-x-1 p-4">
    <div className="flex-1 space-y-1">
      {step && (
        <Typography size="base" className="text-subtle">
          {step}
        </Typography>
      )}
      <div className="flex flex-row items-center space-x-2">
        {' '}
        <Typography size="2xl" weight="semibold">
          {title}
        </Typography>
        {titleLabel && titleLabel}
      </div>
      {subtitle && (
        <Typography size="base" className="text-subtle">
          {subtitle}
        </Typography>
      )}
    </div>
    <Button
      size="sm"
      variant="subtle"
      iconLeading={<XClose />}
      onClick={onClose}
      testId={`${testId}-close`}
    />
  </header>
)
const ModalFooter: FC<ModalFooterProps> = ({
  primaryActionProps,
  secondaryActionProps,
  extraActionProps,
}) => {
  if (!primaryActionProps && !secondaryActionProps && !extraActionProps) {
    return null
  }

  return (
    <footer className="flex justify-between gap-x-3 border-t border-default p-4">
      {checkIsExtraActionButton(extraActionProps) && (
        <Button size="md" {...omit(extraActionProps, 'actionType')} />
      )}
      {checkIsExtraActionCheckbox(extraActionProps) && (
        <Checkbox {...omit(extraActionProps, 'actionType')} />
      )}
      <div className="flex flex-1 justify-end gap-x-3">
        {secondaryActionProps && <Button size="md" {...secondaryActionProps} />}
        {primaryActionProps && <Button size="md" {...primaryActionProps} />}
      </div>
    </footer>
  )
}

export const Modal: FC<ModalProps> = ({
  testId = 'modal',
  size = 'md',
  title,
  titleLabel,
  subtitle,
  step,
  onClose,
  children,
  contentId,
  ...footerProps
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  useEffect(() => {
    if (contentId) {
      ref?.current?.scrollTo({ top: 0 })
    }
  }, [contentId])

  return (
    <FocusTrap
      focusTrapOptions={{
        initialFocus: false,
        escapeDeactivates: true,
      }}
    >
      <div role="dialog" aria-modal="true" data-testid={testId}>
        <div
          className="fixed inset-0 z-50 w-screen cursor-pointer bg-new-palette-neutral-700 bg-opacity-50 transition-opacity !max-h-screen"
          onClick={onClose}
          data-testid={`${testId}-overlay`}
        >
          <div className="flex min-h-full !max-h-full items-center justify-center">
            <div
              className={clsx(
                'flex flex-col max-w-full max-h-200 my-8 rounded-xl bg-medium border border-solid border-default cursor-auto',
                SIZE_WIDTH_MAP[size],
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <ModalHeader
                title={title}
                titleLabel={titleLabel}
                subtitle={subtitle}
                step={step}
                onClose={onClose}
                testId={`${testId}-header`}
              />
              {children && (
                <main
                  className="overflow-y-auto overflow-x-hidden border-t border-default p-4"
                  ref={ref}
                >
                  {children}
                </main>
              )}
              <ModalFooter {...footerProps} testId={`${testId}-footer`} />
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}
