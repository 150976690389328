'use client'

import { useMemo } from 'react'
import type { AppData, AppName } from 'types'
import { getAppStates } from './getAppStates'
import type { GetAppStatesOpts } from './types'

export function useAppStates(
  appCapabilities: Array<AppName> = [],
  opts?: GetAppStatesOpts,
): Array<[AppName, AppData]> {
  const appStatesArr = useMemo(() => {
    const appStates = getAppStates(appCapabilities, opts) || []
    return Object.entries(appStates).map<[AppName, AppData]>(
      ([appName, appData]) => [appName as AppName, appData],
    )
  }, [appCapabilities, opts])

  return appStatesArr
}
