import { Tooltip } from '../Tooltip'
import clsx from 'clsx'
import type { FC } from 'react'
import type { HoverTooltipProps } from './types'
import {
  useClickOutsideElement,
  useHookWithRefCallback,
  useTooltipHover,
} from '@sylveraio/react-utils'

export const HoverTooltip: FC<HoverTooltipProps> = ({
  testId = 'hover-tooltip',
  content,
  placement = 'top',
  className,
  offset = [0, 0],
  showArrow,
  children,
}) => {
  const [setReferenceElement, referenceElement, showTooltip, setShowTooltip] =
    useTooltipHover<HTMLSpanElement>()

  const [tooltipElement, setTooltipElement] = useHookWithRefCallback()

  useClickOutsideElement(
    tooltipElement,
    () => setShowTooltip(false),
    showTooltip,
  )

  if (!content) return children

  return (
    <div data-testid={`${testId}-wrapper`} className="inline-flex justify-end">
      <span data-testid={`${testId}-reference`} ref={setReferenceElement}>
        {children}
      </span>
      <Tooltip
        testId={testId}
        referenceElement={referenceElement}
        tooltipRef={setTooltipElement}
        show={showTooltip}
        placement={placement}
        offset={offset}
        withArrow={showArrow}
        className={clsx(
          'relative px-4 py-2 w-fit max-w-[240px] text-default rounded-lg !text-left text-xs drop-shadow-md pointer-events-none z-50',
          className,
        )}
      >
        {content}
      </Tooltip>
    </div>
  )
}

export default HoverTooltip
