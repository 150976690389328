import { FC, useEffect, useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  ChartOptions,
  LegendItem,
  Tooltip,
  PointElement,
  LineElement,
  LineController,
  BarController,
  type ChartDataset as ChartJSDataset,
} from 'chart.js'
import { ChartLegend } from '../ChartLegend'
import { ChartPanning } from '../ChartPanning'
import { MAX_PAN_LIMIT } from '../constants'
import clsx from 'clsx'
import set from 'lodash/set'
import { combineMultiChartDatasets } from './utils/combineMultiChartDatasets'
import { createAnnotationLabels } from './utils/createAnnotationLabels'
import { createAnnotationHighlightRange } from './utils/createAnnotationHighlightRange'
import { createAxis } from './utils/createAxis'
import type { MultiChartProps, ChartType } from './types'
import { EmptyState } from '@sylveraio/design-system'
import BarLineChart from '@sylveraio/untitled-ui-icons-react/build/esm/BarLineChart'
import isEmpty from 'lodash/isEmpty'
import type { ScatterDataPoint } from '../types'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)

if (typeof window !== 'undefined') {
  ;(async () => {
    const { default: zoomPlugin } = await import('chartjs-plugin-zoom')
    ChartJS.register(zoomPlugin)
  })()
}

const COLORS = ['#5d54ff', '#b0eabd', '#fb843f']

export const MultiChart: FC<MultiChartProps> = ({
  testId = 'bar-chart',
  data,
  yAxisOptions,
  y1AxisOptions,
  xAxisOptions,
  hideGridLines = true,
  enableFiltering,
  enablePanning,
  enableHighlighting,
  tooltipOptions,
  sortLegend,
  ignoreMaxWidth = false,
  highlightRange,
  highlightRangeColor,
  variables,
  labelAnnotations,
  labels,
  legendPosition = 'right',
}) => {
  const chartRef = useRef<ChartJS<ChartType, ScatterDataPoint[]> | null>(null)
  const [chartData, setChartData] = useState<{
    labels?: string[]
    datasets: ChartJSDataset<ChartType, ScatterDataPoint[]>[]
  }>({
    datasets: [],
  })
  const [chartOptions, setChartOptions] = useState<ChartOptions<ChartType>>({})
  const [legendItems, setLegendItems] = useState<LegendItem[] | undefined>([])
  const hasData = !isEmpty(chartData.datasets)

  useEffect(() => {
    const datasets = combineMultiChartDatasets(data, COLORS)

    setChartData({
      labels,
      datasets,
    })

    setChartOptions({
      responsive: true,
      backgroundColor: '#FFF',
      plugins: {
        annotation: enableHighlighting
          ? {
              clip: false,
              annotations: {
                ...createAnnotationHighlightRange(
                  highlightRange,
                  highlightRangeColor,
                  !hasData,
                ),
              },
            }
          : {},
        zoom: {
          pan: {
            enabled: enablePanning,
            mode: 'x' as const,
          },
          limits: {
            x: { minRange: 5 },
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          ...tooltipOptions,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        ...createAxis(
          [xAxisOptions, yAxisOptions, y1AxisOptions],
          !hideGridLines,
          enablePanning,
          labels,
        ),
      },
    })
  }, [data])

  useEffect(() => {
    if (
      typeof labelAnnotations !== 'undefined' &&
      (labelAnnotations || []).length > 0 &&
      typeof variables !== 'undefined'
    ) {
      const annotations = createAnnotationLabels(
        chartData?.labels || [],
        labelAnnotations,
        variables,
      )

      setChartOptions((prevOptions) => {
        return set(prevOptions, 'plugins.annotation.annotations', annotations)
      })
    }
  }, [variables, labelAnnotations, chartData])

  useEffect(() => {
    setLegendItems(chartRef.current?.legend?.legendItems)
    if (chartRef.current) {
      chartRef.current.update()
    }
  }, [labels, chartData, chartOptions])

  useEffect(() => {
    // ensures annotations aren't lost after legend added
    if (chartRef.current) {
      chartRef.current.update()
    }
  }, [legendItems])

  if (typeof chartData === 'undefined') return null

  return (
    <div
      data-testid={`${testId}-wrapper`}
      className={clsx('relative flex', {
        'w-full': ignoreMaxWidth,
      })}
    >
      {!hasData && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <EmptyState
            withContainer={false}
            icon={<BarLineChart className="h-20 w-20" />}
            title="No available data"
          />
        </div>
      )}
      <div
        className={clsx('flex justify-center w-full min-h-[400px]', {
          'space-x-10 items-end':
            legendPosition === 'right' || legendPosition === 'left',
          'flex-row': legendPosition === 'right',
          'flex-row-reverse': legendPosition === 'left',
          'space-y-10 items-center':
            legendPosition === 'bottom' || legendPosition === 'top',
          'flex-col': legendPosition === 'bottom',
          'flex-col-reverse': legendPosition === 'top',
        })}
      >
        <div
          className={clsx('w-full relative px-4', {
            'max-w-[1000px]': legendPosition === 'right' && !ignoreMaxWidth,
          })}
        >
          <Chart
            data-testid={testId}
            ref={chartRef}
            options={chartOptions}
            data={chartData}
            // The 'type' prop is required by Chart.js but is arbitrary here
            // because we are implementing a 'mixed' chart, which displays
            // multiple types (e.g., 'bar' and 'line') on the same chart.
            type="bar"
          />
          {enablePanning && labels.length > MAX_PAN_LIMIT && (
            <ChartPanning
              testId={`${testId}-panning`}
              chartInstance={
                chartRef.current as ChartJS<'bar', ScatterDataPoint[]>
              }
            />
          )}
        </div>
        {enableFiltering && (
          <ChartLegend
            testId={`${testId}-legend`}
            type="mixed"
            chartInstance={chartRef?.current?.legend?.chart}
            legendItems={legendItems}
            sortLegend={sortLegend}
            style={
              legendPosition === 'top' || legendPosition === 'bottom'
                ? 'horizontal'
                : 'vertical'
            }
          />
        )}
      </div>
    </div>
  )
}

export default MultiChart
