'use client'

import { useMemo, type FC } from 'react'
import type { SylveraScoresProps } from './types'
import * as Scores from './components'
import clsx from 'clsx'
import {
  DEFAULT_SCORE_SIZE,
  provisionalBadgeSizes,
  provisionalRatingBadgePosition,
} from './constants'
import { ProvisionalBadge } from './components/ProvisionalBadge'

export const SylveraScores: FC<SylveraScoresProps> = ({
  testId = 'sylvera-scores',
  ...props
}) => {
  const scoreComponent = useMemo(() => {
    switch (props.type) {
      case 'sylveraRating': {
        return <Scores.SylveraRating {...props} />
      }
      case 'carbonScore': {
        return <Scores.CarbonScore {...props} />
      }
      case 'capScore': {
        return <Scores.CAPScore {...props} />
      }
      case 'provisional': {
        return <Scores.Provisional {...props} />
      }
      case 'scoreLocked': {
        return <Scores.ScoreLocked {...props} />
      }
      default:
        return null
    }
  }, [props])

  return (
    <div data-testid={testId} className="relative w-fit">
      {!['carbonScore', 'scoreLocked'].includes(props.type) &&
        (props?.isProvisional || props.type === 'provisional') && (
          <ProvisionalBadge
            size={provisionalBadgeSizes[props?.size || DEFAULT_SCORE_SIZE]}
            className={clsx(
              'absolute top-0 right-0',
              provisionalRatingBadgePosition[props?.size || DEFAULT_SCORE_SIZE],
            )}
          />
        )}
      {scoreComponent}
    </div>
  )
}
