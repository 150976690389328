import type { FormField, FormFieldReviewSubmit, FormPage } from '../../types'

export function checkIsSubmit(
  pageType: FormField | FormPage,
): pageType is FormFieldReviewSubmit {
  if ((pageType as FormPage)?.fields) {
    return (pageType as FormPage).fields.some((v) => v.type === 'submit')
  }

  if ((pageType as FormField).type === 'submit') {
    return true
  } else {
    return false
  }
}
