'use client'

import { FC, useState } from 'react'
import { usePopper } from 'react-popper'
import clsx from 'clsx'
import type TooltipProps from './types'

export const Tooltip: FC<TooltipProps> = ({
  show,
  placement = 'bottom',
  referenceElement,
  className,
  arrowSize = 20,
  arrowClassName,
  withArrow,
  children,
  border,
  offset,
  fixed = false,
  preventOverflow = true,
  testId = 'tooltip',
  fitted,
  transparent,
  tooltipRef,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 5,
        },
      },
      {
        name: 'offset',
        options: {
          offset: offset ?? [0, 0],
        },
      },
      {
        name: 'preventOverflow',
        enabled: preventOverflow,
        options: {
          altAxis: true,
          padding: 30,
          rootBoundary: 'document',
        },
      },
      {
        name: 'eventListeners',
        options: {
          scroll: fixed,
        },
      },
      {
        name: 'flip',
        options: {
          rootBoundary: 'viewport',
        },
      },
    ],
  })

  const amendedPosition = (attributes['popper'] ?? {})['data-popper-placement']

  const arrowPositioning = () => {
    switch (amendedPosition || placement) {
      case 'top':
        return '-bottom-1'
      case 'left':
        return '-right-1'
      case 'right':
        return '-left-1'
      case 'bottom':
      default:
        return '-top-1'
    }
  }

  const arrowStyleSize = {
    width: `${arrowSize}px`,
    height: `${arrowSize}px`,
  }

  if (!show) return null
  return (
    <div
      data-testid={testId}
      ref={(ref) => {
        setPopperElement(ref)
        tooltipRef?.(ref)
      }}
      className={clsx(className, {
        '!w-fit': fitted,
        'bg-high': !transparent,
      })}
      style={styles['popper']}
      {...attributes['popper']}
    >
      <div
        className={clsx('relative z-10 flex flex-col', {
          '!w-fit': fitted,
          'bg-high': !transparent,
        })}
      >
        {children}
      </div>
      {withArrow && (
        <div
          data-testid="tooltip-arrow"
          ref={(ref) => setArrowElement(ref)}
          className={clsx(
            'invisible',
            { 'z-20': typeof border !== 'undefined' },
            arrowPositioning(),
          )}
          style={{
            ...styles['arrow'],
            ...arrowStyleSize,
            backgroundColor: 'inherit',
          }}
        >
          <div
            style={{ ...arrowStyleSize, backgroundColor: 'inherit' }}
            className={clsx(
              'absolute visible transform rotate-45',
              border,
              arrowClassName,
            )}
          />
        </div>
      )}
    </div>
  )
}

export default Tooltip
