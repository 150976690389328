export enum Sizes {
  xs = 12,
  sm = 16,
  md = 24,
  lg = 32,
  xl = 42,
}

export enum Direction {
  left = 'left',
  right = 'right',
  up = 'up',
  down = 'down',
}

export type IconSizes = keyof typeof Sizes
export type IconDirection = keyof typeof Direction

export const GenericIcons = [
  'info',
  'location',
  'newTab',
  'download',
  'selected',
  'bulletList',
  'ratings',
  'tour',
  'plus',
  'minus',
  'search',
  'close',
  'loader',
  'plusInRing',
  'supply',
  'cog',
  'request',
  'clock',
  'externalLink',
  'target',
] as const

export const TransitionalIcons = [
  'arrow',
  'chevron',
  'smallChevron',
  'solidArrow',
] as const

export type GenericIconTypes = {
  name: (typeof GenericIcons)[number]
}

export type TransitionalIconTypes = {
  name: (typeof TransitionalIcons)[number]
  direction?: IconDirection
  transitionOn?: boolean
  transitionDirection?: IconDirection
}

export type IconsTypes =
  | (typeof GenericIcons)[number]
  | (typeof TransitionalIcons)[number]

export type IconLibraryProps = {
  testId?: string
  title?: string
  size?: IconSizes
  className?: string
  invert?: boolean
  disabled?: boolean
  wrapperClassName?: string
} & (GenericIconTypes | TransitionalIconTypes)
