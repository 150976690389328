import type { FontSizes } from '../../../Typography/types'
import { SylveraScoreSizesMap } from '../../types'

export const carbonScoreTextVariant: {
  [x in keyof SylveraScoreSizesMap]: FontSizes
} = {
  xs: 'xs',
  sm: 'sm',
  md: 'base',
  lg: 'base',
  xl: 'base',
  '2xl': 'xl',
  '3xl': '3xl',
}

export const carbonScorePadding: Partial<SylveraScoreSizesMap> = {
  sm: 'p-[2px]',
  md: 'p-[2px]',
  lg: 'p-[3px]',
  xl: 'p-[3px]',
  '2xl': 'p-[3px]',
  '3xl': 'p-[8px]',
}
