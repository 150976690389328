import clsx from 'clsx'
import type { FC } from 'react'
import type { AccordionSummaryProps } from './types'
import { Tooltip, Typography } from '@sylveraio/design-system'
import InfoCircle from '@sylveraio/untitled-ui-icons-react/build/esm/InfoCircle'

export const AccordionSummary: FC<AccordionSummaryProps> = ({
  testId = 'accordion-summary',
  isExpanded,
  title,
  secondaryText,
  className,
  infoTooltipLabel,
}) => (
  <div className="flex items-center" data-testid={`${testId}-header`}>
    <Typography
      className={clsx('truncate', className)}
      weight="semibold"
      size="base"
    >
      {title}
    </Typography>
    {infoTooltipLabel && (
      <Tooltip
        content={
          <div className="w-max max-w-64 rounded-lg bg-high p-2 shadow-lg">
            <Typography size="xs">{infoTooltipLabel}</Typography>
          </div>
        }
        remainOpenOnHover
        data-testid={`${testId}-info-tooltip`}
      >
        <InfoCircle className="ml-1 h-4 w-4" />
      </Tooltip>
    )}

    {secondaryText && !isExpanded && (
      <Typography
        testId={`${testId}-secondary-text`}
        className="ml-4 truncate text-subtle"
        size="xs"
      >
        {secondaryText}
      </Typography>
    )}
  </div>
)

export default AccordionSummary
