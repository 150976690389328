import { FC, useRef } from 'react'
import clsx from 'clsx'
import type { SearchInputIconProps, SearchInputProps } from './types'
import SearchMd from '@sylveraio/untitled-ui-icons-react/build/esm/SearchMd'
import XClose from '@sylveraio/untitled-ui-icons-react/build/esm/XClose'

export const SearchInputIcon: FC<SearchInputIconProps> = ({
  testId,
  value,
  handleReset,
}) => {
  const iconClassName = 'h-5 w-5 text-content-action-default'

  return value.length > 0 ? (
    <button
      type="button"
      onClick={(ev) => {
        ev.stopPropagation()
        handleReset()
      }}
      data-testid={`${testId}-close-button`}
      className="rounded-md hover:bg-default"
    >
      <XClose className={iconClassName} />
    </button>
  ) : (
    <div data-testid={`${testId}-search-icon-wrapper`}>
      <SearchMd className={iconClassName} />
    </div>
  )
}

export const SearchInput: FC<SearchInputProps> = ({
  testId = 'search-input',
  value,
  handleOnChange,
  handleReset,
  placeholderText = 'Search all',
  className,
}) => (
  <div
    data-testid={`${testId}-container`}
    className={clsx(
      'flex h-9 min-w-72 items-center space-x-2 rounded-lg border border-default bg-high px-4 ring-content-action-subtle focus-within:border-content-action-subtle focus-within:ring-1 hover:border-default-hover hover:focus-within:border-content-action-subtle',
      className,
    )}
  >
    <input
      data-testid={testId}
      type="text"
      name="search"
      className="grow text-sm outline-none focus:outline-none"
      placeholder={placeholderText}
      value={value}
      onChange={handleOnChange}
    />
    <SearchInputIcon value={value} handleReset={handleReset} testId={testId} />
  </div>
)

export default SearchInput
