import type { FormField, FormFieldInputTypes, FormKeyData } from '../types'
import { checkIsSubmit } from './typeguards/checkIsSubmit'

export function hasOutstandingRequiredFields(
  fields: Array<FormField>,
  formKeys: Map<string, FormKeyData>,
): boolean {
  const requiredFieldKeys = (
    fields.filter((v) =>
      checkIsSubmit(v) ? false : v?.required,
    ) as Array<FormFieldInputTypes>
  )
    .map((v) => v.key)
    .flat()

  const outstandingFields = requiredFieldKeys.filter((v) => {
    const curVal = formKeys.get(v)
    if (curVal) {
      if (curVal.type === 'tuple') {
        return ((curVal.value as Array<string | number>) || []).length < 2
      } else {
        return typeof curVal.value === 'undefined'
      }
    }
    return true
  })

  return outstandingFields.length > 0
}
