import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

export const contentClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env['NEXT_PUBLIC_CONTENTFUL_SPACE']}/environments/${process.env['NEXT_PUBLIC_CONTENTFUL_ENV']}`,
    headers: {
      authorization: `Bearer ${process.env['NEXT_PUBLIC_POLICY_TOKEN']}`,
    },
  }),
})
