import { AppName } from 'types'

type Routes = Record<string, string>
type DefaultRoutes = Partial<Record<AppName, string>>

export const routes: Routes = {
  requestDemo: '/request-demo',
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  newPassword: '/new-password',
  setPassword: '/set-password',
}

export const defaultRoutes: DefaultRoutes = {
  analytics: '/market-overview',
  ratings: '/all-projects',
  'user-management': '/organisations',
  dashboard: '/',
}
