import { FC } from 'react'
import clsx from 'clsx'

import type { ArrowProps } from './types'
import IconsProps from '../types'

const Arrow: FC<ArrowProps & IconsProps> = ({
  direction = 'left',
  size = 16,
  className,
}) => (
  <svg
    width="30"
    height="21"
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx(
      'fill-current',
      {
        'rotate-180': direction === 'right',
        'rotate-90': direction === 'up',
        '-rotate-90': direction === 'down',
      },
      className,
    )}
  >
    <path d="M9.8062 20.6197C9.90114 20.7325 10.0188 20.8245 10.1516 20.8898C10.2844 20.9551 10.4294 20.9923 10.5775 20.9989C10.7256 21.0056 10.8735 20.9815 11.0117 20.9284C11.1499 20.8752 11.2754 20.7941 11.3802 20.6902C11.4851 20.5863 11.5669 20.4618 11.6205 20.3248C11.6742 20.1878 11.6984 20.0412 11.6917 19.8944C11.685 19.7476 11.6475 19.6038 11.5817 19.4721C11.5158 19.3405 11.423 19.2239 11.3091 19.1298L3.64117 11.5193L28.9866 11.5193C29.2602 11.5049 29.5178 11.387 29.7064 11.19C29.8949 10.9929 30 10.7316 30 10.46C30 10.1884 29.8949 9.92711 29.7064 9.73002C29.5178 9.53294 29.2602 9.41506 28.9866 9.40067L3.64117 9.40066L11.3212 1.79916C11.5145 1.59849 11.6223 1.33168 11.6223 1.05421C11.6223 0.776736 11.5145 0.509934 11.3212 0.309256C11.2226 0.211231 11.1055 0.133456 10.9765 0.0803874C10.8476 0.0273192 10.7093 2.12825e-06 10.5697 2.11605e-06C10.4301 2.10384e-06 10.2918 0.0273191 10.1629 0.0803873C10.0339 0.133455 9.91679 0.211231 9.81822 0.309256L0.319692 9.72546C0.218596 9.82193 0.138173 9.93763 0.0832415 10.0656C0.0283098 10.1936 -2.88201e-06 10.3313 -2.89417e-06 10.4704C-2.90633e-06 10.6095 0.0283098 10.7472 0.0832414 10.8752C0.138173 11.0032 0.218596 11.1189 0.319692 11.2154L9.8062 20.6197Z" />
  </svg>
)

export default Arrow
