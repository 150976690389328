import { Breakpoint, useWindowDimensions } from '@sylveraio/react-utils'

export const useSlidesToScroll = () => {
  const { isAboveOrEqualToScreenWidth, isBelowScreenWidth } =
    useWindowDimensions()

  const isDesktopLarge = isAboveOrEqualToScreenWidth(Breakpoint.DesktopLarge)
  const isDesktopMedium = isAboveOrEqualToScreenWidth(Breakpoint.DesktopMedium)
  const isDesktopSmall = isAboveOrEqualToScreenWidth(Breakpoint.DesktopSmall)
  const isTablet = isAboveOrEqualToScreenWidth(Breakpoint.Tablet)

  let slidesToScroll = 1

  switch (true) {
    case isDesktopLarge:
      slidesToScroll = 4
      break
    case isDesktopMedium && isBelowScreenWidth(Breakpoint.DesktopLarge):
      slidesToScroll = 3
      break
    case isDesktopSmall && isBelowScreenWidth(Breakpoint.DesktopMedium):
      slidesToScroll = 2
      break
    case isTablet && isBelowScreenWidth(Breakpoint.DesktopSmall):
      slidesToScroll = 1
      break
    default:
      slidesToScroll = 1
      break
  }

  return slidesToScroll
}
