import fill from 'lodash/fill'

export function getYearRange(
  from: number,
  to?: number,
  asString?: false,
): Array<number>
export function getYearRange(
  from: number,
  to?: number,
  asString?: true,
): Array<string>

export function getYearRange(
  from: number,
  to = new Date().getFullYear(),
  asString?: boolean,
): Array<string | number> {
  const numRecords = to - (from - 1)

  if (numRecords <= 0) return []

  const arr = fill(Array(numRecords), from)
  const range = arr.map((v, i) => v + i)

  if (asString) {
    return range.map((v) => v.toString())
  }

  return range
}
