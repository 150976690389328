import { FC } from 'react'
import type IconProps from '../types'

const NARingWithOuterRing: FC<IconProps> = ({ size = 60 }) => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <g filter="url(#filter0_i_4086_705)">
      <circle cx="38" cy="38" r="38" fill="#F9F9F9" />
    </g>
    <circle cx="38" cy="38" r="31.9627" fill="white" />
    <circle cx="38" cy="38" r="31.9627" fill="url(#paint0_linear_4086_705)" />
    <circle cx="38" cy="38" r="31.9627" stroke="#B8B8B8" strokeWidth="3" />
    <path
      d="M33.7325 29.3105V40.5063L21.5601 28.4767V45.9851H24.3233V34.7893L36.4958 46.8188V29.3105H33.7325ZM42.2176 41.6973H51.0314L50.5073 39.3153H42.7655L42.2176 41.6973ZM46.5768 34.027L49.2448 40.2205L49.2924 40.9113L51.5554 45.9851H54.7474L46.5768 28.3815L38.4301 45.9851H41.5983L43.9089 40.7683L43.9565 40.149L46.5768 34.027Z"
      fill="#424177"
    />
    <defs>
      <filter
        id="filter0_i_4086_705"
        x="0"
        y="0"
        width="78.2687"
        height="78.2687"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2.26866" dy="2.26866" />
        <feGaussianBlur stdDeviation="1.70149" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_4086_705"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4086_705"
        x1="38"
        y1="4.53735"
        x2="38"
        y2="71.4627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default NARingWithOuterRing
