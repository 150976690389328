import React, { FC, memo } from 'react'

interface HeadingRendererProps {
  content: string
  level?: number
}

const renderHeader = (content: string, level: number | undefined) => {
  switch (level) {
    case 1:
      return <h1>{content}</h1>
    case 3:
      return <h3 className="!text-xl">{content}</h3>
    case 4:
      return <h4 className="!text-lg">{content}</h4>
    case 5:
      return <h5 className="!text-md">{content}</h5>
    case 6:
      return <h6 className="!text-md">{content}</h6>
    case 2:
    default:
      return <h2>{content}</h2>
  }
}

const HeadingRenderer: FC<HeadingRendererProps> = memo(({ content, level }) => (
  <>{renderHeader(content, level)}</>
))

export default HeadingRenderer
