import clsx from 'clsx'
import type { ZoomButtonSize } from '../../types'

/**
 * Get the size of the zoom button
 * @param {ZoomButtonSize} size - The size of the zoom button
 * @returns {string} - The class name for the zoom button size
 */
export function getZoomButtonSize(size: ZoomButtonSize) {
  return clsx({
    '!h-6 w-6 px-1': size === 'md',
    '!h-8 w-8 px-2': size === 'lg',
  })
}
