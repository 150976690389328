'use client'

import { useCustomEventListener } from '../../useEventListener'
import { ANALYTICS_EVENT } from '../constants'
import type { AnalyticsEventObject } from '../types'

/**
 * Accepts a function to be called when the custom analytics event is received.
 * This should only be implemented once per app.
 * @param handleAnalyticsEvent
 */
export function useAnalyticsHandler(
  handleAnalyticsEvent: (data: AnalyticsEventObject) => void,
): void {
  const handler = (ev: CustomEvent<AnalyticsEventObject>) => {
    const { detail } = ev
    handleAnalyticsEvent(detail)
  }

  useCustomEventListener(ANALYTICS_EVENT, handler)
}
