import type { FC } from 'react'
import { ICON_DEFAULT_SIZE } from '../../constants'
import { Sizes, type IconLibraryProps } from '../../types'
import clsx from 'clsx'

export const Loader: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'loader',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx(
      'fill-current text-content-action-default animate-spin',
      className,
    )}
  >
    <circle opacity="0.37" cx="12" cy="3" r="1" />
    <circle cx="12" cy="21" r="1" />
    <circle opacity="0.09" cx="3" cy="12" r="1" transform="rotate(-90 3 12)" />
    <circle
      opacity="0.65"
      cx="21"
      cy="12"
      r="1"
      transform="rotate(-90 21 12)"
    />
    <circle
      opacity="0.44"
      cx="15.4442"
      cy="3.68514"
      r="1"
      transform="rotate(-157.5 15.4442 3.68514)"
    />
    <circle
      opacity="0.51"
      cx="18.364"
      cy="5.6362"
      r="1"
      transform="rotate(-135 18.364 5.6362)"
    />
    <circle
      opacity="0.02"
      cx="3.68512"
      cy="15.4444"
      r="1"
      transform="rotate(-112.5 3.68512 15.4444)"
    />
    <circle
      opacity="0.58"
      cx="20.3149"
      cy="8.55611"
      r="1"
      transform="rotate(-112.5 20.3149 8.55611)"
    />
    <circle
      opacity="0.16"
      cx="3.6851"
      cy="8.55597"
      r="1"
      transform="rotate(-67.5 3.6851 8.55597)"
    />
    <circle
      opacity="0.79"
      cx="20.3149"
      cy="15.4443"
      r="1"
      transform="rotate(-67.5 20.3149 15.4443)"
    />
    <circle
      opacity="0.23"
      cx="5.63608"
      cy="5.63623"
      r="1"
      transform="rotate(-45 5.63608 5.63623)"
    />
    <circle
      opacity="0.76"
      cx="18.364"
      cy="18.3641"
      r="1"
      transform="rotate(-45 18.364 18.3641)"
    />
    <circle
      opacity="0.3"
      cx="8.55589"
      cy="3.68524"
      r="1"
      transform="rotate(-22.5 8.55589 3.68524)"
    />
    <circle
      opacity="0.93"
      cx="15.4442"
      cy="20.3151"
      r="1"
      transform="rotate(-22.5 15.4442 20.3151)"
    />
  </svg>
)
