import xorBy from 'lodash/xorBy'

/**
 * Toggles an item in an array of objects by a given key.
 *
 * @template T The type of the array elements.
 * @param {T[]} array The array to toggle the item in.
 * @param {T} item The item to toggle.
 * @param {keyof T} by The key to use for comparison.
 * @returns {T[]} The resulting array.
 */
export function toggleArrayObjects<T>(array: T[], item: T, by: keyof T): T[] {
  return xorBy(array, [item], by)
}
