/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchAuthenticated } from '@sylveraio/auth-utils'
import { deserializeResponse, captureException } from '@sylveraio/react-utils'
import { getDefaultHeaders } from '@sylveraio/constants'
import type { PolicyFull, PolicyTag } from '../helpers/api/types'
import type { RetirementsTrendsTransformed } from './transformers/transformRetirementsTrends/types'
import { transformPrivateRating } from './transformers/transformPrivateRating'
import type { TransformedRatedProjects } from '@sylveraio/data'
import type { FetchAndTransformRssFeedsReturn } from '../utils/fetchAndTransformRssFeeds/types'

/**
 * Fetches the data from the Sylvera API, extracts the JSON, and transforms the response
 * @param endpoint
 * @param transformFn
 * @returns
 */
export async function fetchAndDeserialize<T, D>(
  endpoint: string,
  transformFn?: any,
  deserializeInTransform?: boolean,
  deserializeOptions?: any,
  method?: 'GET' | 'POST',
  bodyObj?: Record<string, unknown>,
): Promise<T | D>
export async function fetchAndDeserialize(
  endpoint: string,
  transformFn?: any,
  deserializeInTransform?: boolean,
  deserializeOptions?: any,
  method?: 'DELETE',
  bodyObj?: Record<string, unknown>,
): Promise<void>
export async function fetchAndDeserialize<T, D>(
  endpoint: string,
  transformFn?: any,
  deserializeInTransform?: boolean,
  deserializeOptions?: any,
  method = 'GET',
  bodyObj?: Record<string, unknown>,
): Promise<T | D | void> {
  const response = await fetchAuthenticated(
    `${process.env['NEXT_PUBLIC_API_URL']}${endpoint}`,
    {
      method,
      headers: {
        ...getDefaultHeaders(),
      },
      body: bodyObj ? JSON.stringify(bodyObj) : undefined,
    },
  )

  if (method === 'DELETE') return Promise.resolve()

  const JSONresponse = await response.json()

  if (deserializeInTransform && typeof transformFn !== 'undefined') {
    return await transformFn(JSONresponse)
  }

  const deserializedResponse = await deserializeResponse<D>(
    JSONresponse,
    deserializeOptions,
  )

  if (!transformFn) return deserializedResponse
  return transformFn(deserializedResponse)
}

export async function getPolicies(params?: string): Promise<Array<PolicyFull>> {
  try {
    const response = await fetch(`/api/policies${params ? '?' : ''}${params}`)
    const result = await response.json()
    const { data } = result
    return data
  } catch (e) {
    captureException(e, 'dashboard - services - getPolicies')
  }
  return []
}

export async function getPolicy(id: string): Promise<PolicyFull | undefined> {
  try {
    const response = await fetch(`/api/policies/${id}`)
    const result = await response.json()
    const { data } = result
    return data
  } catch (e) {
    captureException(e, 'dashboard - services - getPolicy')
  }
}

export async function getPolicyTags(): Promise<Array<PolicyTag>> {
  try {
    const response = await fetch('/api/policies/tags')
    const result = await response.json()
    const { data } = result
    return data
  } catch (e) {
    captureException(e, 'dashboard - services - getPolicyTags')
  }
  return []
}

export async function getRetirementsTrendsData(params = ''): Promise<any> {
  const result: RetirementsTrendsTransformed = {}

  try {
    const response = await fetch(
      `/api/retirementsTrends${params ? '?' : ''}${params}`,
    )
    const result = await response.json()
    const { data } = result
    return data
  } catch (e) {
    captureException(e, 'dashboard - services - getRetirementsTrendsData')
  }
  return result
}

export async function getPublicRatingsData(params = ''): Promise<any> {
  try {
    const response = await fetch(
      `/api/publicRatings${params ? '?' : ''}${params}`,
    )
    const result = await response.json()
    const { data } = result
    return data
  } catch (e) {
    captureException(e, 'dashboard - services - getPublicRatingsData')
  }
  return []
}

export async function getPrivateRatingsData(
  ids: Array<string> = [],
  params = '',
): Promise<any> {
  let response: TransformedRatedProjects = []

  try {
    const promises = ids.map((id) =>
      fetchAndDeserialize(
        `/projects/${id}${params ? '?' : ''}${params}`,
        transformPrivateRating,
        false,
        undefined,
        'GET',
      ),
    )

    response = await Promise.allSettled(promises).then((promises) => {
      // Handle rejected promises
      promises
        .filter((promise) => promise.status === 'rejected')
        .forEach((rejectedPromise) =>
          captureException(
            // @ts-ignore
            rejectedPromise.reason,
            'dashboard - services - getPrivateRatingsData',
          ),
        )

      // Return fulfilled promises
      return (
        promises
          .filter((promise) => promise.status === 'fulfilled')
          // @ts-ignore
          .map((fulfilledPromise) => fulfilledPromise.value)
      )
    })
  } catch (e) {
    captureException(e, 'dashboard - services - getPrivateRatingsData')
  }

  return response
}

export async function getRssFeed(
  params?: string,
): Promise<Array<FetchAndTransformRssFeedsReturn>> {
  try {
    const response = await fetch(`/api/fetchRss${params ? '?' : ''}${params}`)
    const result = await response.json()
    const { data } = result
    return data
  } catch (e) {
    captureException(e, 'dashboard - services - getRssFeed')
  }
  return []
}
