var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_51WnKsbEeh7ex86x0hkF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const environment = process.env.NEXT_PUBLIC_APP_ENV || 'prod'

Sentry.init({
  dsn: 'https://4e880546d0da44dda477c88d3d7bca09@o487051.ingest.sentry.io/4504083766247424',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: environment === 'prod' ? 0.2 : 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment,
  enabled: process.env['NODE_ENV'] === 'production',  
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  beforeSend(event, hint) {
    const error = hint.originalException
    if (typeof error?.response?.status === 'number') {
      if (error.response.status === 401) return null
    }
    if ((error?.message ?? '').match(/Request failed with status code 401/i)) {
      return null
    }

    return event
  },
})
