import { useState, type FC } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import type { ArrowProps, CarouselProps } from './types'
import { ButtonLegacy } from '@sylveraio/design-system'
import { useEnhanceSlickTrack } from './utils/useEnhanceSlickTrack'
import { useSlidesToScroll } from './utils/useSlidesToScroll/useSlidesToScroll'
import ArrowLeft from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowLeft'
import ArrowRight from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowRight'

export const Carousel: FC<CarouselProps> = ({
  content = [],
  enhanceSlickTrack = false,
  isInfinite = true,
  onNextClick,
  onPrevClick,
}) => {
  const [showPrevArrow, setShowPrevArrow] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const slidesToScroll = useSlidesToScroll()

  const handleAfterChange = (currentSlide: number) => {
    setCurrentSlide(currentSlide)
  }

  const showNextArrow = currentSlide + 1 < content.length - slidesToScroll

  function NextArrow(props: ArrowProps) {
    const handleClick = () => {
      props.onClick && props.onClick()
      if (onNextClick) onNextClick()
    }

    return (
      showNextArrow && (
        <ButtonLegacy
          className="absolute right-0 z-10 mt-[23px] h-[38.5px] w-[38.5px] translate-x-[15%] self-start rounded-full border border-default bg-medium shadow-[0_0_5.25px_0_rgba(0,0,0,0.1)] !px-0"
          appearance="none"
          onClick={handleClick}
          testId="btn-next"
        >
          <ArrowRight className="stroke-icon-default w-5 h-5" />
        </ButtonLegacy>
      )
    )
  }

  function PrevArrow(props: ArrowProps) {
    const handleClick = () => {
      props.onClick && props.onClick()
      if (onPrevClick) onPrevClick()
    }

    return (
      showPrevArrow && (
        <ButtonLegacy
          className="absolute left-0 z-10 mt-[23px] h-[38.5px] w-[38.5px] translate-x-[-50%] self-start rounded-full border border-default bg-medium shadow-[0_0_5.25px_0_rgba(0,0,0,0.1)] !px-0"
          appearance="none"
          onClick={handleClick}
          testId="btn-next"
        >
          <ArrowLeft className="stroke-icon-default w-5 h-5" />
        </ButtonLegacy>
      )
    )
  }

  const settings: Settings = {
    arrows: true,
    rows: 1,
    centerMode: false,
    slidesToShow: content.length >= 4 ? 4 : content.length,
    slidesToScroll: useSlidesToScroll(),
    speed: 500,
    infinite: isInfinite,
    nextArrow: <NextArrow onClick={onNextClick} />,
    prevArrow: <PrevArrow onClick={onPrevClick} />,
    className: '!flex items-center',
    variableWidth: true,
    beforeChange: (_, nextSlide) => {
      setShowPrevArrow(nextSlide !== 0)
    },
    afterChange: handleAfterChange,
  }

  useEnhanceSlickTrack(enhanceSlickTrack)

  return <Slider {...settings}>{content}</Slider>
}

export default Carousel
