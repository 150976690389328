import type { LabelAnnotation } from '../../types'

function createLineAnnotation(xValue: number, content: string) {
  return {
    type: 'line',
    xMin: xValue,
    xMax: xValue,
    borderColor: '#98a39a',
    borderWidth: 2,
    borderDash: [3, 3],
    label: {
      content,
      display: true,
      position: 'end',
      backgroundColor: '#f7f5ef',
      color: '#132c18',
      font: {
        weight: 500,
        size: 12,
      },
      borderRadius: 6,
      padding: 6,
    },
  }
}

export function createAnnotationLabels(
  labels: Array<string>,
  labelAnnotations: Array<LabelAnnotation>,
  variables: Record<string, unknown>,
) {
  const annotationsArr: Array<[string, Record<string, unknown>]> = []
  let lineID = 1
  labelAnnotations.forEach((annotation) => {
    const value = (
      variables[annotation.xValueVariableName] as string | number
    ).toString()
    if (value) {
      const labelText = annotation.labelText.replaceAll(
        `{${annotation.xValueVariableName}}`,
        String(value),
      )
      // Using equivalent at the moment
      const idx = labels.findIndex((v) => String(v) === String(value))
      if (idx > -1 && labelText) {
        annotationsArr.push([
          `line${lineID}`,
          createLineAnnotation(idx, labelText),
        ])
        lineID = lineID + 1
      }
    }
  })

  return Object.fromEntries(annotationsArr)
}
