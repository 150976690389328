import { FC } from 'react'
import clsx from 'clsx'

import type CopyProps from './types'

const Copy: FC<CopyProps> = ({ size = 16, className }) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('stroke-current', className)}
  >
    <path
      d="M15 6.59998H8.25C7.42157 6.59998 6.75 7.25662 6.75 8.06664V14.6666C6.75 15.4767 7.42157 16.1333 8.25 16.1333H15C15.8284 16.1333 16.5 15.4767 16.5 14.6666V8.06664C16.5 7.25662 15.8284 6.59998 15 6.59998Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 11H3C2.60218 11 2.22064 10.8455 1.93934 10.5704C1.65804 10.2954 1.5 9.92233 1.5 9.53334V2.93334C1.5 2.54436 1.65804 2.1713 1.93934 1.89625C2.22064 1.6212 2.60218 1.46667 3 1.46667H9.75C10.1478 1.46667 10.5294 1.6212 10.8107 1.89625C11.092 2.1713 11.25 2.54436 11.25 2.93334V3.66667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Copy
