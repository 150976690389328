const config = {
  Auth: {
    region: process.env.NEXT_PUBLIC_AMP_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AMP_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AMP_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'SylveraAPI',
        endpoint: process.env.NEXT_PUBLIC_API_URL,
      },
    ],
  },
}

export default config
