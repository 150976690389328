import {
  calculatePercentage,
  getParamValue,
  parsePercentageScore,
  removeZeroEntries,
} from '@sylveraio/react-utils'
import type {
  EnhancedRetirementsAnalysis,
  TransformedRetirementsAnalysis,
} from '../../../useRetirementsAnalysis/types'
import type {
  RetirementsAnalysisAPIResponse,
  RetirementsAnalysis,
  RetirementsAnalysisSingleGroupedBy,
  RetirementsAnalysisDoubleGroupedBy,
} from 'types'

function convertReducedAnalysisRecords(
  analysis: RetirementsAnalysis,
): EnhancedRetirementsAnalysis {
  const { scores } = analysis || {}

  return {
    scores: {
      ...scores,
      sylveraRatingHistogram: scores?.sylveraRatingHistogram
        ? removeZeroEntries(scores?.sylveraRatingHistogram)
        : {},
    },
  }
}

function convertAnalysisRecords(
  analysis: RetirementsAnalysis,
): EnhancedRetirementsAnalysis {
  const { totals, emissions, scores } = analysis || {}

  const { retirements, projects } = totals || {}

  const { total } = emissions || {}

  const { additionality, coBenefits, permanence, sylveraCarbonScore } =
    scores || {}

  const coveredCredits = projects?.covered?.credits ?? 0
  const nonRatedCredits = projects?.uncovered?.credits ?? 0
  const untraceableCredits =
    retirements?.traceability?.untraceable?.credits ?? 0
  const allCredits = coveredCredits + nonRatedCredits + untraceableCredits

  const coveredProjects = projects?.covered?.number ?? 0
  const nonRatedProjects = projects?.uncovered?.number ?? 0
  const untraceableProjects =
    retirements?.traceability?.untraceable?.number ?? 0
  const allProjects = coveredProjects + nonRatedProjects + untraceableProjects

  const mixedCredits = retirements?.offsetTypes?.mixed?.credits ?? 0
  const reductionCredits = retirements?.offsetTypes?.reduction?.credits ?? 0
  const removalCredits = retirements?.offsetTypes?.removal?.credits ?? 0
  const allOffsetTypeCredits = mixedCredits + reductionCredits + removalCredits

  return {
    ...analysis,
    percentages: {
      coveredCreditsPercentage: calculatePercentage(coveredCredits, allCredits),
      untraceableCreditsPercentage: calculatePercentage(
        untraceableCredits,
        allCredits,
      ),
      nonRatedCreditsPercentage: calculatePercentage(
        nonRatedCredits,
        allCredits,
      ),
      offsetsEmissionsPercentage: calculatePercentage(allCredits, total || 0),
      mixedCreditsPercentage: calculatePercentage(
        mixedCredits,
        allOffsetTypeCredits,
      ),
      reductionCreditsPercentage: calculatePercentage(
        reductionCredits,
        allOffsetTypeCredits,
      ),
      removalCreditsPercentage: calculatePercentage(
        removalCredits,
        allOffsetTypeCredits,
      ),
      rounded: {
        coveredCreditsPercentage: calculatePercentage(
          coveredCredits,
          allCredits,
          {
            round: true,
          },
        ),
        untraceableCreditsPercentage: calculatePercentage(
          untraceableCredits,
          allCredits,
          {
            round: true,
          },
        ),
        nonRatedCreditsPercentage: calculatePercentage(
          nonRatedCredits,
          allCredits,
          {
            round: true,
          },
        ),
        offsetsEmissionsPercentage: calculatePercentage(
          allCredits,
          total || 0,
          {
            round: true,
          },
        ),
        mixedCreditsPercentage: calculatePercentage(
          mixedCredits,
          allOffsetTypeCredits,
          {
            round: true,
          },
        ),
        reductionCreditsPercentage: calculatePercentage(
          reductionCredits,
          allOffsetTypeCredits,
          {
            round: true,
          },
        ),
        removalCreditsPercentage: calculatePercentage(
          removalCredits,
          allOffsetTypeCredits,
          {
            round: true,
          },
        ),
      },
    },
    parsedToString: {
      additionalityStr: additionality?.toString(),
      coBenefitsStr: coBenefits?.toString(),
      permanenceStr: permanence?.toString(),
      carbonScoreStr:
        typeof sylveraCarbonScore !== 'undefined'
          ? `${parsePercentageScore(sylveraCarbonScore)}%`
          : sylveraCarbonScore,
      offsetsEmissionsPercentageStr: calculatePercentage(
        allCredits,
        total || 0,
        {
          format: true,
          precision: 1,
        },
      ),
      coveredCreditsPercentageStr: calculatePercentage(
        coveredCredits,
        allCredits,
        {
          format: true,
          precision: 0,
        },
      ),
    },
    scores: {
      ...scores,
      sylveraRatingHistogram: scores?.sylveraRatingHistogram
        ? removeZeroEntries(scores?.sylveraRatingHistogram)
        : {},
    },
    credits: {
      allCredits,
      coveredCredits,
      nonRatedCredits,
      untraceableCredits,
      mixedCredits,
      reductionCredits,
      removalCredits,
    },
    projects: {
      allProjects,
      coveredProjects,
      nonRatedProjects,
      untraceableProjects,
    },
  }
}

function enhanceAnalysisRecord(
  analysis:
    | RetirementsAnalysis
    | RetirementsAnalysisSingleGroupedBy
    | RetirementsAnalysisDoubleGroupedBy,
  level?: number,
  useLimitedData?: boolean,
):
  | EnhancedRetirementsAnalysis
  | RetirementsAnalysisSingleGroupedBy<EnhancedRetirementsAnalysis>
  | RetirementsAnalysisDoubleGroupedBy<EnhancedRetirementsAnalysis> {
  if (typeof analysis === 'undefined') return analysis

  switch (level) {
    case 2: {
      return Object.entries<Record<string, RetirementsAnalysis>>(
        analysis as RetirementsAnalysisDoubleGroupedBy,
      ).reduceRight((a, [groupedBy, groupedItems]) => {
        const r = Object.entries(groupedItems).map(([groupedItem, record]) => {
          const enhancedRecord = useLimitedData
            ? { ...convertReducedAnalysisRecords(record) }
            : { ...convertAnalysisRecords(record) }
          return [groupedItem, enhancedRecord]
        })

        const amendedRecords = Object.fromEntries(r)

        return {
          ...a,
          [groupedBy]: amendedRecords,
        }
      }, {})
    }
    case 1: {
      return Object.entries<RetirementsAnalysis>(
        analysis as RetirementsAnalysisSingleGroupedBy,
      ).reduceRight((a, [groupedBy, record]) => {
        const enhancedRecord = useLimitedData
          ? convertReducedAnalysisRecords(record)
          : convertAnalysisRecords(record)

        return {
          ...a,
          [groupedBy]: enhancedRecord,
        }
      }, {})
    }
    case 0:
    default: {
      return useLimitedData
        ? convertReducedAnalysisRecords(analysis)
        : convertAnalysisRecords(analysis as RetirementsAnalysis)
    }
  }
}

/**
 * Transforms a RetirementsAnalysisAPIResponse into a TransformedRetirementsAnalysis object.
 *
 * @param {RetirementsAnalysisAPIResponse} response - The response to be transformed.
 * @returns {Promise<TransformedRetirementsAnalysis<EnhancedRetirementsAnalysis | RetirementsAnalysisSingleGroupedBy<EnhancedRetirementsAnalysis> | RetirementsAnalysisDoubleGroupedBy<EnhancedRetirementsAnalysis>>>} A promise that resolves with the transformed response.
 */
export async function transformRetirementsAnalysis(
  response: RetirementsAnalysisAPIResponse,
  params: string,
  useLimitedData?: boolean,
): Promise<
  TransformedRetirementsAnalysis<
    | EnhancedRetirementsAnalysis
    | RetirementsAnalysisSingleGroupedBy<EnhancedRetirementsAnalysis>
    | RetirementsAnalysisDoubleGroupedBy<EnhancedRetirementsAnalysis>
  >
> {
  const param = getParamValue(params, 'groupBy')

  return {
    analysis: enhanceAnalysisRecord(
      response?.data?.attributes?.analysis,
      param?.split(',')?.length || 0,
      useLimitedData,
    ),
  }
}
