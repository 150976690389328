import Link from 'next/link'
import { routes } from '../routes'
import type { AuthenticationErrorMap } from './types'

const authErrorMap: AuthenticationErrorMap = [
  {
    authError: 'Incorrect username or password.',
    context: (
      <p className="text-subtle">
        Incorrect email or password. Please try again or{' '}
        <Link
          href={routes['forgotPassword']}
          className="underline text-content-action-default hover:text-content-action-default-hover"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          reset your password
        </Link>
      </p>
    ),
  },
  {
    authError: 'Password attempts exceeded',
    context: (
      <p className="text-subtle">
        It looks like you’ve tried too many times. Please try again later or{' '}
        <Link
          href={routes['forgotPassword']}
          className="underline text-content-action-default hover:text-content-action-default-hover"
        >
          reset your password
        </Link>
      </p>
    ),
  },
  {
    authError: 'Invalid verification code provided, please try again.',
    context: (
      <p className="text-subtle">
        Invalid verification code provided, please try again.
      </p>
    ),
  },
  {
    authError: 'Attempt limit exceeded, please try after some time.',
    context: (
      <p className="text-subtle">
        It looks like you have attempted too reset your password to many times.
        Please try again later or send us an email at{' '}
        <a href="mailto:contact@sylvera.io">contact@sylvera.io</a>
      </p>
    ),
  },
  {
    authError: 'It looks like something went wrong.',
    context: (
      <p className="text-subtle">
        It looks like something went wrong. Please try again later or send us an
        email at <a href="mailto:contact@sylvera.io">contact@sylvera.io</a>
      </p>
    ),
  },
]

const serverError = {
  authError: 'Server error',
  context: (
    <p>
      There&apos;s been an issue submitting this request. Please try again later
      or send us an email at{' '}
      <a href="mailto:contact@sylvera.io">contact@sylvera.io</a>
    </p>
  ),
}

export function getAuthError(response: Error) {
  return (
    authErrorMap.find((error) => error.authError === response.message) ??
    serverError
  )
}

export default getAuthError
