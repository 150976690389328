import React, { FC, useState } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import Lightbox from 'react-image-lightbox'
import forestSVG from './forest.svg'
import type { ImageRendererProps } from './types'
import 'react-image-lightbox/style.css'
import Expand01 from '@sylveraio/untitled-ui-icons-react/build/esm/Expand01'

// NOTE: Some styles from the react-image-lightbox import are overwritten in the global stylesheet per request of Product

const ImageRenderer: FC<ImageRendererProps> = ({
  alt,
  src,
  width,
  height,
  onError,
  unoptimized = true,
  openInModal = true,
}) => {
  const [hasErrored, setHasErrored] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOnError = () => {
    onError?.()
    setHasErrored(true)
  }

  const handleClick = () => {
    if (openInModal) {
      setIsModalOpen(true)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div
        onClick={handleClick}
        role="none"
        className={clsx({
          'group hover: cursor-pointer hover:drop-shadow-lg flex-1 relative transition duration-150 ease-in-out':
            openInModal,
        })}
      >
        <Image
          src={hasErrored ? forestSVG : src}
          alt={alt}
          width={width}
          height={height}
          onError={handleOnError}
          unoptimized={unoptimized}
          data-testid="rich-commentary-image"
          quality={100}
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />

        {openInModal && (
          <div className="absolute bottom-0 right-0 mb-4 mr-4 transition duration-150 ease-in-out opacity-0 w-7 h-7 group-hover:opacity-100">
            <Expand01 className="w-5 h-5" />
          </div>
        )}
      </div>
      {openInModal && isModalOpen && (
        <Lightbox mainSrc={src} onCloseRequest={handleClose} />
      )}
    </>
  )
}

export default ImageRenderer
