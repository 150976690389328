'use client'

import { FC } from 'react'
import clsx from 'clsx'
import { variantMappings } from './constants'
import type { TypographyLegacyProps } from './types'

/**
 * @deprecated This TypographyLegacy component has been deprecated and will be removed in future releases.
 * Please use Typography instead.
 */
export const TypographyLegacy: FC<TypographyLegacyProps> = ({
  testId = 'typography',
  variant,
  children,
  className,
}) => {
  const { classes, tag: Tag } = variantMappings[variant]

  return (
    <Tag
      data-testid={testId}
      data-variant={variant}
      className={clsx('text-inherit', classes, className)}
    >
      {children}
    </Tag>
  )
}
