import type { HubspotFormData } from '@sylveraio/auth-utils'
import type { RequestDemoFormValues } from '../../types'

export function convertRequestDemoFormDataForHubspot({
  firstName,
  lastName,
  companyName,
  role,
  companyEmail,
}: RequestDemoFormValues) {
  const hubspotFormData: HubspotFormData = [
    {
      name: 'firstname',
      value: firstName,
    },
    {
      name: 'lastname',
      value: lastName,
    },
    {
      name: 'company',
      value: companyName,
    },
    {
      name: 'role',
      value: role,
    },
    {
      name: 'email',
      value: companyEmail,
    },
  ]

  return hubspotFormData
}

export default convertRequestDemoFormDataForHubspot
