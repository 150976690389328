import { type FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Supply: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'supply',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-content-action-default', className)}
  >
    <path
      d="M9.51994 4.59678C9.33994 4.41421 9.12527 4.26929 8.8885 4.1705C8.65173 4.07171 8.39761 4.02103 8.14098 4.02143H5.93466C5.42265 4.02143 4.93161 4.22445 4.56956 4.58584C4.20752 4.94723 4.00412 5.43738 4.00412 5.94846V8.15079C4.00412 8.66187 4.20752 9.15202 4.56956 9.51341C4.93161 9.8748 5.42265 10.0778 5.93466 10.0778H8.92699L13.9326 15.1294V18.0613C13.9326 18.5723 14.136 19.0625 14.498 19.4239C14.8601 19.7853 15.3511 19.9883 15.8631 19.9883H18.0695C18.5815 19.9883 19.0725 19.7853 19.4346 19.4239C19.7966 19.0625 20 18.5723 20 18.0613V15.8589C20 15.3479 19.7966 14.8577 19.4346 14.4963C19.0725 14.1349 18.5815 13.9319 18.0695 13.9319H15.0854L10.0715 8.88031V5.94846C10.0723 5.44314 9.87423 4.95773 9.51994 4.59678ZM8.41677 8.42608H5.93466C5.86151 8.42608 5.79136 8.39708 5.73964 8.34545C5.68792 8.29382 5.65887 8.2238 5.65887 8.15079V5.94846C5.65887 5.87545 5.68792 5.80543 5.73964 5.7538C5.79136 5.70217 5.86151 5.67317 5.93466 5.67317H8.14098C8.21413 5.67317 8.28428 5.70217 8.336 5.7538C8.38772 5.80543 8.41677 5.87545 8.41677 5.94846V8.42608ZM15.5873 15.5836H18.0695C18.1426 15.5836 18.2128 15.6126 18.2645 15.6643C18.3162 15.7159 18.3452 15.7859 18.3452 15.8589V18.0613C18.3452 18.1343 18.3162 18.2043 18.2645 18.2559C18.2128 18.3075 18.1426 18.3366 18.0695 18.3366H15.8631C15.79 18.3366 15.7198 18.3075 15.6681 18.2559C15.6164 18.2043 15.5873 18.1343 15.5873 18.0613V15.5836Z"
      strokeWidth="0.2"
    />
    <path
      d="M19.9999 11.7295V7.04954C19.9999 6.24641 19.6803 5.47618 19.1114 4.90828C18.5425 4.34038 17.7708 4.02134 16.9662 4.02134L12.2778 4.00482V5.65657L16.9855 5.67308C17.3513 5.67308 17.702 5.8181 17.9606 6.07624C18.2192 6.33437 18.3645 6.68448 18.3645 7.04954L18.3452 11.7295H19.9999Z"
      strokeWidth="0.2"
    />
    <path
      d="M7.013 19.9884L11.7263 20.0049V18.3532L7.01851 18.3367C6.65279 18.3367 6.30205 18.1917 6.04345 17.9335C5.78484 17.6754 5.63956 17.3253 5.63956 16.9602L5.65887 12.2803H4.00412V16.9602C4.00409 17.7591 4.3203 18.5256 4.88388 19.0928C5.44745 19.66 6.21272 19.9819 7.013 19.9884Z"
      strokeWidth="0.2"
    />
  </svg>
)
