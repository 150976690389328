import clsx from 'clsx'
import type { ButtonAlignment } from '../../types'

/**
 * Get the alignment of a button
 * @param {ButtonAlignment} position - The position of the button
 * @returns {string} - The class name for the button's alignment
 */
export function getButtonAlignment(position: ButtonAlignment) {
  return clsx({
    'justify-start px-2': position === 'left',
    'justify-center px-4': position === 'middle',
    'justify-end px-2': position === 'right',
    'px-0': position === 'none',
  })
}
