import type { Project } from 'types'
import { isProvisionalRating } from '@sylveraio/react-utils'
import type { TransformedRatedProject } from '@sylveraio/data'

/**
 * Transforms a RatedProject into a TransformedRatedProject
 *
 * @param {Project} ratedProject - The data object to be transformed
 * @returns {Promise<TransformedRatedProject>} The transformed data object
 */
export async function transformPrivateRating(
  ratedProject: Project,
): Promise<TransformedRatedProject> {
  const scores = ratedProject?.analysis?.latest?.scores ?? {}

  return {
    projectId: ratedProject?.id,
    onWatch: ratedProject?.onWatch,
    projectName: ratedProject?.name,
    registryId: ratedProject?.registryId,
    registryName: ratedProject?.registryName,
    projectType: ratedProject?.categories?.[0].shortName,
    projectCountry: ratedProject?.countryName,
    projectStage: ratedProject?.stage,
    sylveraRating: scores?.sylveraRating ?? scores?.provisionalSylveraRating,
    sylveraRatingProvisional: scores?.sylveraRatingProvisionalStatus,
    sylveraRatingIsProvisional: isProvisionalRating(
      scores?.sylveraRating,
      scores?.provisionalSylveraRating,
      scores?.sylveraRatingProvisionalStatus,
    ),
  }
}
