import React, {
  FC,
  BaseSyntheticEvent,
  useState,
  ChangeEvent,
  useEffect,
} from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'

import {
  routes,
  defaultRoutes,
  getAuthError,
  fetchCookies,
  getRedirectUrl,
} from '@sylveraio/auth-utils'
import {
  getInputError,
  getParamFromQuery,
  sendAnalyticsEvent,
  useInterval,
} from '@sylveraio/react-utils'
import { Form, FormHeading, Input, Button } from '@sylveraio/react-ui'
import { useAuthenticateUser } from './utils/useAuthenticateUser'

import type {
  SignInFormProps,
  SignInFormInputNameTypes,
  SignInFormKeys,
} from './types'
import clsx from 'clsx'
import { addBreadcrumb } from '@sentry/nextjs'

export const SignInForm: FC<SignInFormProps> = ({
  testId = 'sign-in-form',
  appName,
}) => {
  const defaultRoute = `${appName ? defaultRoutes[appName] : '/'}`
  const router = useRouter()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [authError, setAuthError] = useState<Error>()
  const { authenticateUser } = useAuthenticateUser()

  const redirect = getRedirectUrl(router.query)
  addBreadcrumb({
    data: {
      redirect,
    },
  })

  useEffect(() => {
    if (!redirect) return
    sendAnalyticsEvent({
      eventName: 'redirect-to-app',
      eventData: {
        redirect,
        appName: getParamFromQuery(router.query['feat']),
        pagee: getParamFromQuery(router.query['page']),
      },
    })
  }, [redirect])

  const navigateToOnSuccess = redirect || defaultRoute

  useInterval(() => {
    if (fetchCookies()?.refresh) {
      router.push(navigateToOnSuccess)
    }
  }, 2000)

  const onSubmit = async (
    data: SignInFormKeys,
    e: BaseSyntheticEvent<object, any, any> | undefined,
  ) => {
    e?.preventDefault()

    setSubmitting(true)

    addBreadcrumb({
      message: 'Signing user in',
    })

    try {
      const response = await authenticateUser(
        {
          email: data['sign-in-email'],
          password: data['sign-in-password'],
        },
        navigateToOnSuccess,
      )

      if (response === 'User Requires New Password') {
        addBreadcrumb({
          message: 'User requires a new password',
        })
        router.push(routes['setPassword'])
        return
      }
    } catch (err) {
      setAuthError(err as Error)
      if (
        getAuthError(err as Error)?.authError === 'Password attempts exceeded'
      )
        setDisabled(true)
    } finally {
      setSubmitting(false)
    }
  }

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback?: () => void,
  ) => {
    switch (e.currentTarget.name) {
      case 'sign-in-email':
        setEmail(e.currentTarget.value)
        break
      case 'sign-in-password':
        setPassword(e.currentTarget.value)
        break
      default:
        break
    }
    setAuthError(undefined)
    callback?.()
  }

  return (
    <Form<SignInFormKeys> testId={testId} onSubmit={onSubmit}>
      {({ register, formState, clearErrors }) => (
        <>
          <FormHeading
            testId={`${testId}-heading`}
            className={clsx('mb-14 !text-default-on-dark !font-medium')}
          >
            Sign In
          </FormHeading>
          <div className="relative flex flex-col w-full gap-y-6">
            <Input
              testId={`${testId}-email-input`}
              type="email"
              label="Email address"
              hideBorder
              error={getInputError<SignInFormKeys, SignInFormInputNameTypes>(
                formState.errors,
                'sign-in-email',
              )}
              {...register('sign-in-email', {
                value: email,
                required: 'Please enter an email address.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
            <Input
              testId={`${testId}-password-input`}
              type="password"
              label="Password"
              hideBorder
              error={
                authError
                  ? getAuthError(authError)?.context
                  : getInputError<SignInFormKeys, SignInFormInputNameTypes>(
                      formState.errors,
                      'sign-in-password',
                    )
              }
              {...register('sign-in-password', {
                value: password,
                required: 'Please enter a password.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
          </div>

          <div className="flex flex-col mt-10 mb-14 gap-y-7">
            <Button
              testId={`${testId}-sign-in-button`}
              type="submit"
              className="!w-[240px] !h-12 mx-auto !text-xl font-medium"
              spinner={submitting}
              disabled={disabled}
              solid
              color="lime"
              shape="pill"
            >
              Sign In
            </Button>

            <Link href={routes['requestDemo']} passHref legacyBehavior>
              <Button
                testId={`${testId}-request-demo-button`}
                type="button"
                className="!w-[240px] !h-12 mx-auto !text-xl font-medium bg-content-discovery-on-default"
                color="forest"
                solid
                onClick={() =>
                  sendAnalyticsEvent({ eventName: 'app-request-access' })
                }
                shape="pill"
              >
                Request demo
              </Button>
            </Link>
          </div>

          <Link href={routes['forgotPassword']} passHref legacyBehavior>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role="link"
              tabIndex={0}
              onClick={() =>
                sendAnalyticsEvent({ eventName: 'app-forgotten-password' })
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendAnalyticsEvent({ eventName: 'app-forgotten-password' })
                }
              }}
              data-testid={`${testId}-forgot-password-link`}
              className={clsx(
                'underline underline-offset-2 text-default-on-dark hover:decoration-2 text-base font-semibold',
              )}
            >
              I forgot my password
            </a>
          </Link>
        </>
      )}
    </Form>
  )
}

export default SignInForm
