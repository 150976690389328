import type { ChangeEvent, FC } from 'react'
import clsx from 'clsx'
import type { CheckboxProps } from './types'

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  secondaryLabel,
  name,
  value,
  checked,
  onChange,
  testId = 'checkbox',
  type = 'checkbox',
  showBox = true,
  disabled = false,
  className,
  labelClassName,
  checkboxClassName,
  checkClassName,
}) => {
  const onChangeNotDisabled = (event: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange?.(event)
    }
  }
  return (
    <label
      className={clsx('flex items-center py-3', className, {
        'hover:cursor-pointer': !disabled,
        'opacity-50': disabled,
      })}
      data-testid={testId}
      role="checkbox"
      aria-checked={checked}
    >
      <input
        type={type}
        id={id}
        aria-describedby={name}
        name={name}
        value={value}
        data-label={label}
        className={clsx('hidden w-5 h-5', {
          'hover:cursor-pointer': !disabled,
        })}
        onChange={onChangeNotDisabled}
        checked={checked}
      />
      {showBox && (
        <div
          className={clsx(
            'flex items-center justify-center flex-shrink-0 w-5 h-5 bg-high border rounded border-content-action-default focus-within:border-content-action-default',
            checkboxClassName,
          )}
        >
          <svg
            className={clsx(
              'hidden w-2 h-2 pointer-events-none fill-current text-content-action-default checked-mark:block',
              checkClassName,
            )}
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(-9 -11)">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </svg>
        </div>
      )}
      <span
        className={clsx(
          'pl-4 text-sm text-default',
          { 'w-4/12': secondaryLabel },
          labelClassName,
        )}
        data-testid="checkbox-label"
      >
        {label}
      </span>
      {secondaryLabel && (
        <span
          className="w-8/12 pl-2 text-xs text-default"
          data-testid="checkbox-secondary-label"
        >
          {secondaryLabel}
        </span>
      )}
    </label>
  )
}

export default Checkbox
