import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const PlusInRing: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current fill-primary-900', className)}
  >
    <circle
      className="fill-white hover:fill-primary-50"
      cx="11"
      cy="11"
      r="11"
    />
    <path d="M15.9558 10.3748H11.6022V6.02108C11.6022 5.67371 11.3243 5.39581 10.9769 5.39581C10.6295 5.39581 10.3516 5.67371 10.3516 6.02108V10.3748H5.99794C5.65058 10.3748 5.37268 10.6527 5.37268 11C5.37268 11.1621 5.44215 11.3242 5.55794 11.44C5.67373 11.5558 5.83584 11.6253 5.99794 11.6253H10.3516V15.979C10.3516 16.1411 10.4211 16.3032 10.5369 16.419C10.6527 16.5348 10.8148 16.6042 10.9769 16.6042C11.3243 16.6042 11.6022 16.3263 11.6022 15.979V11.6253H15.9558C16.3032 11.6253 16.5811 11.3474 16.5811 11C16.5811 10.6527 16.3032 10.3748 15.9558 10.3748Z" />
    <path
      d="M11 0C4.93263 0 0 4.93263 0 11C0 17.0674 4.93263 22 11 22C17.0674 22 22 17.0674 22 11C22 4.93263 17.0674 0 11 0ZM11 20.7726C5.60421 20.7726 1.22737 16.3958 1.22737 11C1.22737 5.60421 5.60421 1.22737 11 1.22737C16.3958 1.22737 20.7726 5.60421 20.7726 11C20.7726 16.3958 16.3958 20.7726 11 20.7726Z"
      shapeRendering="geometricPrecision"
    />
  </svg>
)

export default PlusInRing
