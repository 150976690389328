import { type FC, forwardRef } from 'react'
import Info from '../Icons/Info'
import type InfoButtonProps from './types'

export const InfoButton: FC<InfoButtonProps> = forwardRef(
  (
    {
      testId = 'info-button',
      size = 16,
      wrapperClassName,
      iconClassName,
      onClick,
    },
    ref,
  ) => (
    <button
      ref={ref}
      data-testid={testId}
      type="button"
      onClick={onClick}
      className={wrapperClassName}
    >
      <Info size={size} className={iconClassName} />
    </button>
  ),
)

export default InfoButton
