import type { RatingPillar } from 'types'

export const TITLES: Record<RatingPillar, string> = {
  sylveraRating: 'Rating Score',
  carbonScore: 'Carbon Score',
  additionality: 'Additionality Score',
  permanence: 'Permanence Score',
  coBenefits: 'Co-benefits Score',
}

export const ORDERED_PILLARS: Array<RatingPillar> = [
  'sylveraRating',
  'carbonScore',
  'additionality',
  'permanence',
  'coBenefits',
]
