import clsx from 'clsx'
import { forwardRef } from 'react'
import { LabelProps } from './types'

// This label is currently used in CircularLabel used in CCA.
// A ticket was added to migrate CCA to use the design system label instead.
// This component will then be depracated.
export const Label = forwardRef<HTMLDivElement, LabelProps>(
  (
    {
      testId = 'label',
      label,
      wrapperClassName,
      textClassName,
      maintainCase,
      color = 'default',
    },
    ref,
  ) => (
    <div
      data-testid={testId}
      className={clsx(
        'block px-2 rounded w-fit whitespace-nowrap',
        {
          'bg-primary-sylvera-green': color === 'primaryGreen',
          'bg-green-600': color === 'green',
          'bg-primary-lime': color === 'lightGreen',
          'bg-primary-600': color === 'default',
          'bg-primary-900': color === 'lightPurple',
          'bg-blue-900': color === 'blue',
          'bg-pink-300': color === 'pink',
          'bg-primary-neon-blue': color === 'neonBlue',
          'bg-secondary-mauve': color === 'mauve',
          'bg-data-green': color === 'dataGreen',
          'bg-medium': color === 'offWhite1',
          'bg-secondary-water': color === 'waterBlue',
          'bg-data-coral': color === 'orange',
          'bg-content-discovery-on-default': color === 'forestGreen',
          'bg-low': color === 'offWhite3',
          'bg-secondary-burgundy': color === 'burgundy',
          'bg-secondary-indigo': color === 'indigo',
          'bg-data-dark-green/30': color === 'dataDarkGreen',
          'bg-secondary-lemon/60': color === 'lemon',
          'bg-data-coral/40': color === 'lightOrange',
        },
        wrapperClassName,
      )}
      ref={ref}
    >
      <span
        className={clsx(
          'text-xs font-medium text-default-on-dark',
          {
            uppercase: !maintainCase,
          },
          textClassName,
        )}
      >
        {label}
      </span>
    </div>
  ),
)
