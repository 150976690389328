import clsx from 'clsx'
import { forwardRef } from 'react'
import { defaultPillTestId } from './constants'
import type { PillProps } from './types'
import { ConditionalDivButton } from '../ConditionalDivButton'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'

export const Pill = forwardRef<HTMLButtonElement, PillProps>(
  (
    {
      onClick,
      name,
      color = 'white',
      selectedVariation = 'dark',
      bordered,
      selected,
      children,
      testId = defaultPillTestId,
      showDownArrow,
      className,
      buttonWrapperClassName,
      disabled = false,
      dataLabelName,
      showLabelAbove,
      value,
      size = 'md',
      readOnly,
    },
    ref,
  ) => (
    <div className={buttonWrapperClassName}>
      {dataLabelName && showLabelAbove && (
        <div className="px-2 py-1 text-xs text-default">{dataLabelName}</div>
      )}
      <ConditionalDivButton
        name={name}
        onClick={!readOnly ? onClick : undefined}
        disabled={disabled}
        testId={testId}
        data-label-name={dataLabelName}
        value={value}
        className={clsx(
          'py-1 rounded-full font-medium  flex items-center whitespace-nowrap',
          {
            'bg-high': color === 'white' && !selected,
            'hover:bg-medium':
              color === 'white' && !selected && !disabled && !readOnly,
            'bg-default': color === 'beige' && !selected,
            'hover:bg-low':
              (color === 'beige' || color === 'none') &&
              !selected &&
              !disabled &&
              !readOnly,
            'bg-low': color === 'off-white-3' && !selected,
            'hover:brightness-95':
              color === 'off-white-3' && !selected && !disabled && !readOnly,
            'bg-transparent': color === 'none' && !selected,
            'text-default': !selected && !disabled,
            'text-disabled': !selected && disabled,
            'text-default-on-dark': selected,
            'bg-content-discovery-on-default':
              selected && selectedVariation === 'dark',
            'hover:bg-content-discovery-on-default':
              selected &&
              selectedVariation === 'dark' &&
              !disabled &&
              !readOnly,
            'bg-content-discovery-default hover:bg-content-discovery-default-hover !text-default':
              selected && selectedVariation === 'light',
            'border-solid border border-black-900': bordered && !disabled,
            'border-solid border border-selection-forest/30':
              bordered && disabled,
            'bg-selection-forest/20': color === 'forest-light',
            'text-sm px-4': size === 'md',
            'h-4 text-xs px-2': size === 'sm',
          },
          className,
        )}
        aria-pressed={selected}
        ref={ref}
      >
        {children}
        {showDownArrow && (
          <ChevronDown
            data-testid="chevron-down"
            className="w-5 h-5 stroke-icon-default"
          />
        )}
      </ConditionalDivButton>
    </div>
  ),
)
