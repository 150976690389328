import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Expand: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-["#605CFF"] fill-current', className)}
  >
    <path d="M16.9319 5.87259C17.1172 5.87259 17.295 5.79895 17.4262 5.66777C17.5572 5.53659 17.6309 5.35876 17.6309 5.17345L17.6309 0.699157C17.6309 0.513685 17.5572 0.33585 17.4262 0.20483C17.295 0.0736513 17.1172 1.23529e-05 16.9319 1.23367e-05L12.4576 1.19455e-05C12.2078 1.19237e-05 11.977 0.133304 11.8521 0.349499C11.7273 0.565856 11.7273 0.832299 11.8521 1.04865C11.977 1.265 12.2078 1.3983 12.4576 1.3983L15.2306 1.3983L10.1272 6.52509C9.96495 6.64668 9.86352 6.83263 9.84921 7.03469C9.8349 7.2369 9.90887 7.43538 10.0522 7.57857C10.1954 7.72195 10.3939 7.79607 10.5961 7.7816C10.7983 7.7673 10.9843 7.66587 11.1059 7.5038L16.2326 2.40031L16.2326 5.17348C16.2326 5.35879 16.3063 5.53663 16.4374 5.66781C16.5685 5.79899 16.7463 5.87263 16.9318 5.87263L16.9319 5.87259Z" />
    <path d="M0.000392408 12.5619L0.0702901 17.0129C0.0728908 17.1972 0.148315 17.3731 0.279984 17.5022C0.414093 17.6235 0.588675 17.6899 0.769445 17.6886L5.22043 17.6187C5.47012 17.6145 5.69866 17.4775 5.81992 17.2592C5.94119 17.0407 5.9368 16.7743 5.80838 16.56C5.6798 16.3458 5.44686 16.2164 5.19703 16.2205L2.42386 16.2671L7.48075 11.0937C7.65565 10.9147 7.72165 10.6561 7.65419 10.4152C7.58657 10.1743 7.39557 9.9877 7.15304 9.92573C6.91067 9.8638 6.65368 9.93597 6.47877 10.1149L1.44519 15.3117L1.39854 12.5385L1.3987 12.5386C1.3987 12.3532 1.32491 12.1753 1.19389 12.0441C1.06271 11.9131 0.884871 11.8395 0.699557 11.8395C0.51002 11.8393 0.32861 11.9162 0.196782 12.0524C0.06495 12.1887 -0.00592233 12.3725 0.000415297 12.5619L0.000392408 12.5619Z" />
  </svg>
)

export default Expand
