import { useContentfulQuery } from '@sylveraio/react-utils'
import { Faq, GET_FAQ } from '../queries/GET_FAQ'
import { AccordionItem } from '@sylveraio/design-system'
import { FC, Fragment, useState } from 'react'
import Supply from '@sylveraio/untitled-ui-icons-react/build/esm/Supply'
import CoinsHand from '@sylveraio/untitled-ui-icons-react/build/esm/CoinsHand'
import SwitchHorizontal02 from '@sylveraio/untitled-ui-icons-react/build/esm/SwitchHorizontal02'
import InfoCircle from '@sylveraio/untitled-ui-icons-react/build/esm/InfoCircle'
import HelpCircle from '@sylveraio/untitled-ui-icons-react/build/esm/HelpCircle'
import UserSquare from '@sylveraio/untitled-ui-icons-react/build/esm/UserSquare'
import { ApolloClient } from '@apollo/client'

const ICONS: Record<string, JSX.Element> = {
  'how-work': <Supply width={24} height={24} />,
  'fee-charge': <CoinsHand width={24} height={24} />,
  transaction: <SwitchHorizontal02 width={24} height={24} />,
  'how-use': <InfoCircle width={24} height={24} />,
  'request-type': <HelpCircle width={24} height={24} />,
  suppliers: <UserSquare width={24} height={24} />,
}

function getFaqIdFromEnv() {
  switch (process.env['NEXT_PUBLIC_APP_ENV']) {
    case 'prod':
    case 'staging':
      return 'q6kkuPhM3A82NFidNyJiN'
    default:
      return '3JRqA4JD6WuoRwKnriOcsK'
  }
}

export const FAQ: FC<{ client: ApolloClient<any> }> = ({ client }) => {
  const { data } = useContentfulQuery<Faq>(GET_FAQ, client, {
    variables: {
      faqId: getFaqIdFromEnv(),
    },
    fetchPolicy: 'cache-first',
  })
  const [openItemId, setOpenItemId] = useState('')

  if (!data) return null

  return (
    <div className="space-y-4">
      {(data?.staticJson?.content || []).map(({ id, title, body }, i) => {
        const textWithBreaks = body.split('\n').map((text, index) => (
          <Fragment key={index}>
            <div>{text}</div>
          </Fragment>
        ))

        return (
          <AccordionItem
            key={title}
            title={title}
            body={<div className="flex flex-col gap-y-2">{textWithBreaks}</div>}
            onClick={() =>
              id === openItemId ? setOpenItemId('') : setOpenItemId(id)
            }
            isOpen={id === openItemId}
            icon={ICONS[id]}
          />
        )
      })}
    </div>
  )
}
