'use client'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import { SSRMultipartLink } from '@apollo/experimental-nextjs-app-support'
import { setContext } from '@apollo/client/link/context'
import { fetchCookies } from '@sylveraio/auth-utils'

const sylveraContentfulApi = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SYL_CONTENTFUL_URL,
  headers: {
    'Sylvera-version': '2021-09-01',
  },
})

const contentfulApi = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENV}`,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_POLICY_TOKEN}`,
  },
})

const sylveraApi = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SYL_GRAPHQL_URL,
})

const createAuthLink = () =>
  setContext(async (_, { headers }) => {
    const { access } = fetchCookies()

    return {
      headers: {
        ...headers,
        ...(access?.token ? { authorization: `Bearer ${access.token}` } : {}),
      },
    }
  })

const createLink = (endpoint: HttpLink) => {
  const authLink = createAuthLink()
  return typeof window === 'undefined'
    ? ApolloLink.from([
        new SSRMultipartLink({ stripDefer: true }),
        authLink,
        endpoint,
      ])
    : from([authLink, endpoint])
}

const sylveraApiAuthLink = createLink(sylveraApi)
const sylveraContentfulApiAuthLink = createLink(sylveraContentfulApi)

const linkDynamic = ApolloLink.split(
  (operation) => operation.getContext().apiName === 'sylvera',
  sylveraApiAuthLink,
  ApolloLink.split(
    (operation) => operation.getContext().apiName === 'sylveraContentful',
    sylveraContentfulApiAuthLink,
    contentfulApi,
  ),
)

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: linkDynamic,
})
