/**
 * Returns the sum of all values in the object
 * @returns {number} The sum of all values in the object.
 */

export function getObjectValuesSum(
  dataObject?: Record<string, number>,
): number | undefined {
  if (typeof dataObject === 'undefined') return dataObject

  return Object.values(dataObject).reduce((a, b) => a + b, 0)
}
