import clsx from 'clsx'
import { forwardRef } from 'react'
import { CircularLabelProps } from './types'
import { Label } from '../Label'

export const CircularLabel = forwardRef<HTMLDivElement, CircularLabelProps>(
  (
    {
      testId = 'circular-label',
      color,
      size = 'sm',
      wrapperClassName,
      textClassName,
      maintainCase,
      label,
    },
    ref,
  ) => (
    <Label
      testId={testId}
      ref={ref}
      color={color}
      label={
        <div className="flex items-center gap-x-1">
          <span
            className={clsx(
              'block  leading-none',
              {
                'text-xs': size === 'sm',
                'text-sm': size === 'md',
              },
              textClassName,
            )}
          >
            {label}
          </span>
        </div>
      }
      wrapperClassName={clsx('!rounded-full py-0.5', wrapperClassName)}
      maintainCase={maintainCase}
    />
  ),
)
