import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  useAutoClose,
  useHookWithRefCallback,
  useTooltipHover,
} from '@sylveraio/react-utils'
import { Tooltip } from '../Tooltip'
import { InfoButton } from '../InfoButton'
import { Modal } from '../Modal'

import type InfoTooltipProps from './types'

export const InfoTooltip: FC<InfoTooltipProps> = ({
  buttonSize = 50,
  wrapperClassName,
  iconClassName,
  tooltipWrapperClassName,
  modalWrapperClassName,
  placement = 'bottom',
  children,
  onToggle,
  transparentTooltip = true,
  showOnHover,
  showCloseButton = true,
  withArrow,
  offset,
}) => {
  const [setReferenceElement, referenceElement, isTooltipHovered] =
    useTooltipHover<HTMLButtonElement>()

  const [showTooltip, setShowTooltip] = useState(false)

  const handleShowTooltip = () => {
    setShowTooltip(!showTooltip)
    onToggle?.(!showTooltip === true ? 'open' : 'closed')
  }

  const handleOutsideClick = () => {
    if (showTooltip) {
      setShowTooltip(false)
      onToggle?.('closed')
    }
  }

  useEffect(() => {
    if (!showOnHover) return
    setShowTooltip(isTooltipHovered)
  }, [showOnHover, isTooltipHovered])

  const [tooltipElement, setTooltipElement] = useHookWithRefCallback()

  useAutoClose({
    isOpen: showTooltip,
    setIsOpen: setShowTooltip,
    ref: tooltipElement,
  })

  return (
    <div className="inline-flex" ref={setTooltipElement}>
      <InfoButton
        testId="info-tooltip-button"
        ref={setReferenceElement}
        size={buttonSize}
        onClick={handleShowTooltip}
        wrapperClassName={wrapperClassName}
        iconClassName={iconClassName}
      />
      <Tooltip
        testId="info-tooltip"
        referenceElement={referenceElement}
        show={showTooltip}
        placement={placement}
        className={clsx(
          'rounded-lg shadow-modal z-50',
          tooltipWrapperClassName,
        )}
        offset={offset ?? [0, 10]}
        transparent={transparentTooltip}
        withArrow={withArrow}
      >
        <Modal
          className={clsx(
            '!py-4 !px-4 whitespace-normal',
            { '!pt-7': showCloseButton },
            modalWrapperClassName,
          )}
          showCloseButton={showCloseButton}
          handleClose={handleOutsideClick}
          removeShadow
        >
          {children}
        </Modal>
      </Tooltip>
    </div>
  )
}

export default InfoTooltip
