import React, { createContext, useState } from 'react'

interface UserStateShape {
  user?: Record<string, unknown>
  email?: string
}

interface UserContextProps extends UserStateShape {
  setUserState: (updatedUserState: UserStateShape) => void
}

export const UserContext = createContext<UserContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserState: () => {},
})

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [appState, setUserState] = useState<UserStateShape>({})

  const handleSetUserState = (updatedUserState: UserStateShape): void => {
    setUserState({ ...appState, ...updatedUserState })
  }

  return (
    <UserContext.Provider
      value={{ ...appState, setUserState: handleSetUserState }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
