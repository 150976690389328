import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Ratings: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'ratings',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-content-action-default', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36081 16.6392 3.6 12 3.6C7.36081 3.6 3.6 7.36081 3.6 12C3.6 16.6392 7.36081 20.4 12 20.4ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
    />
    <path d="M9.48853 9.02393L12.0895 14.9999H10.8385L10.2535 13.6769H7.52653L6.94153 14.9999H5.73553L8.33653 9.02393H9.48853ZM8.89453 10.5899L7.99453 12.6239H9.78553L8.89453 10.5899Z" />
    <path d="M15.663 9.02393L18.264 14.9999H17.013L16.428 13.6769H13.701L13.116 14.9999H11.91L14.511 9.02393H15.663ZM15.069 10.5899L14.169 12.6239H15.96L15.069 10.5899Z" />
  </svg>
)
