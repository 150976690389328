import { FC } from 'react'
import clsx from 'clsx'
import type { FormHeadingProps } from './types'

const FormHeading: FC<FormHeadingProps> = ({
  testId = 'form-heading',
  className,
  children,
}) => (
  <h4
    data-testid={testId}
    className={clsx('text-4xl font-semibold text-default-on-dark', className)}
  >
    {children}
  </h4>
)

export default FormHeading
