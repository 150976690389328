'use client'

import { DependencyList, useEffect, useState } from 'react'
import { useTimeout } from '../useTimeout'

/**
 * A custom hook that delays a boolean value for a given amount of time.
 * @param {number} delayTime - The amount of time to delay the boolean value.
 * @param {DependencyList} deps - An array of dependencies to be used in the effect hook.
 * @returns {boolean} - Returns true if the delay is still active, false otherwise.
 */
export function useDelay(
  delayTime: number,
  deps: DependencyList,
  disable?: boolean,
): boolean {
  const [delay, setDelay] = useState<number | null>(null)

  useEffect(() => {
    if (disable) return

    setDelay(delayTime)
  }, deps)

  useTimeout(() => {
    setDelay(null)
  }, delay)

  return Boolean(delay)
}
