import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Shrink: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-["#605CFF"] fill-current', className)}
  >
    <path d="M13.5687 2.83569C13.3434 2.83569 13.1271 2.92523 12.9676 3.08475C12.8083 3.24427 12.7188 3.46052 12.7188 3.68586L12.7188 9.1267C12.7188 9.35224 12.8083 9.56849 12.9676 9.72781C13.1271 9.88733 13.3434 9.97688 13.5687 9.97688H19.0095C19.3133 9.97688 19.594 9.81479 19.7458 9.55189C19.8976 9.2888 19.8976 8.9648 19.7458 8.70171C19.594 8.43863 19.3133 8.27653 19.0095 8.27653H15.6375L21.8434 2.04224C22.0407 1.89438 22.164 1.66825 22.1814 1.42255C22.1988 1.17665 22.1089 0.935301 21.9345 0.761171C21.7604 0.586828 21.519 0.496689 21.2732 0.514284C21.0273 0.531678 20.8011 0.655021 20.6533 0.852099L14.419 7.05805V3.68582C14.419 3.46048 14.3295 3.24423 14.17 3.0847C14.0107 2.92519 13.7944 2.83564 13.5689 2.83564L13.5687 2.83569Z" />
    <path d="M10.0829 18.7898L9.99794 13.3773C9.99477 13.1531 9.90306 12.9393 9.74294 12.7823C9.57986 12.6348 9.36757 12.554 9.14775 12.5556L3.73524 12.6406C3.43162 12.6457 3.15371 12.8123 3.00626 13.0778C2.85879 13.3435 2.86413 13.6674 3.02029 13.928C3.17664 14.1885 3.4599 14.3459 3.7637 14.3409L7.13594 14.2842L0.986653 20.5752C0.773963 20.7928 0.693707 21.1073 0.775739 21.4002C0.857969 21.6932 1.09023 21.9201 1.38515 21.9954C1.67987 22.0708 1.99239 21.983 2.20507 21.7654L8.32602 15.446L8.38275 18.8183C8.38275 19.0438 8.47229 19.2599 8.63162 19.4194C8.79113 19.5787 9.00739 19.6683 9.23273 19.6683C9.46321 19.6685 9.68381 19.575 9.84412 19.4093C10.0044 19.2437 10.0906 19.0201 10.0829 18.7898Z" />
  </svg>
)

export default Shrink
