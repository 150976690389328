'use client'

import { FC } from 'react'
import { TypographyLegacy } from '../TypographyLegacy'
import type { HeaderProps } from './types'

/**
 * Returns a header with the correct TypographyLegacy variatiant
 */
export const Header: FC<HeaderProps> = ({
  testId = 'header',
  variant,
  children,
  className,
}) => (
  <TypographyLegacy testId={testId} variant={variant} className={className}>
    {children}
  </TypographyLegacy>
)
