import { captureException } from '@sylveraio/react-utils'
import {
  submitHubspotForm,
  type HubspotFormCustomOverrideData,
} from '@sylveraio/auth-utils'
import { convertUpsellDataForHubspot } from '../../../../utils/convertUpsellDataforHubspot'

function useSubmitProjectAccessForm() {
  async function submitProjectAccessForm(
    {
      userOrganisation,
      userFirstName,
      userLastName,
      userId,
      userEmail,
      projectName,
      projectId,
    }: HubspotFormCustomOverrideData,
    abandon?: boolean,
  ) {
    try {
      await submitHubspotForm({
        id: abandon
          ? 'fd22c8e3-7273-45bd-8ee1-08ee299fe0b6'
          : '48f43df5-6bd3-4e86-8c51-873e6f64748d',
        data: convertUpsellDataForHubspot({
          userOrganisation,
          userFirstName,
          userLastName,
          userId,
          userEmail,
          projectName,
          projectId,
        }),
      })
    } catch (err) {
      captureException(err, 'useSubmitProjectAccessForm')
      throw err
    }
  }

  return { submitProjectAccessForm }
}

export default useSubmitProjectAccessForm
