/* eslint-disable no-param-reassign */
import { FC, useState } from 'react'
import clsx from 'clsx'
import truncate from 'lodash/truncate'
import { HoverTooltip } from '../../HoverTooltip'
import { getLegendTooltipEntities } from '../constants'
import type { LegendItemProps } from './types'

export const LineGraphLegendItem: FC<LegendItemProps> = ({
  testId = 'line-graph-legend-item',
  item,
  chartInstance,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false)

  function onClickHandler() {
    if (
      !chartInstance ||
      !chartInstance.data ||
      !chartInstance.options ||
      typeof item.datasetIndex === 'undefined'
    )
      return

    chartInstance.options.animation = false

    chartInstance.setDatasetVisibility?.(
      item.datasetIndex,
      !chartInstance.isDatasetVisible?.(item.datasetIndex),
    )

    setDisabled(!disabled)

    chartInstance.update?.()
  }

  return (
    <HoverTooltip
      placement="left"
      offset={[0, 5]}
      content={getLegendTooltipEntities(item, chartInstance)}
    >
      <button
        data-testid={testId}
        type="button"
        className={clsx(
          'flex items-center w-48 gap-4 p-2 border rounded-md bg-black-25 border-subtle',
          {
            'opacity-30': disabled,
          },
        )}
        onClick={onClickHandler}
      >
        <div
          className="w-10 h-1 rounded-full shrink-0"
          style={{
            backgroundColor: `${item.strokeStyle}`,
          }}
        />
        <span className="text-xs font-medium text-left text-default">
          {truncate(item.text, { length: 25 })}
        </span>
      </button>
    </HoverTooltip>
  )
}

export default LineGraphLegendItem
