import clsx from 'clsx'
import type { ButtonSize } from '../../types'

/**
 * Get the size of a button
 * @param {ButtonSize} size - The size of the button
 * @returns {string} - The class name for the button size
 */
export function getButtonSize(size: ButtonSize) {
  return clsx({
    'h-6 text-xs': size === 'md',
    'h-8': size === 'lg',
    'h-10': size === 'xl',
  })
}
