import type { FontSizes } from '../../../Typography/types'
import type { SylveraScoreSizesMap } from '../../types'

export const MAX_SCORE = '5'

export const capScoreTextVariant: {
  [x in 'numerator' | 'denominator']: {
    [x in keyof SylveraScoreSizesMap]: FontSizes
  }
} = {
  numerator: {
    xs: 'xs',
    sm: 'sm',
    md: 'base',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
    '3xl': '4xl',
  },
  denominator: {
    xs: 'xs',
    sm: 'xs',
    md: 'xs',
    lg: 'base',
    xl: 'base',
    '2xl': 'base',
    '3xl': '3xl',
  },
}

export const SegmentPaths = [
  'M70.2,2.5c0-1,0.9-1.9,1.9-1.8c13,0.6,25.6,4.9,36.3,12.4c10.7,7.5,19,18,23.9,30.1c0.4,1-0.1,2.1-1.1,2.4 l-6,2.3c-1,0.4-2.1-0.1-2.5-1.1c-4.2-10.2-11.2-18.9-20.2-25.3c-9-6.4-19.6-10-30.6-10.6c-1-0.1-1.9-0.9-1.9-2L70.2,2.5z',
  'M132.6,49.6c1-0.3,2.1,0.3,2.3,1.3c3.5,12.6,3.3,25.9-0.6,38.4c-3.9,12.5-11.2,23.6-21.2,32 c-0.8,0.7-2,0.5-2.7-0.3l-4-5c-0.7-0.8-0.5-2,0.3-2.7c8.4-7.1,14.5-16.5,17.8-27s3.5-21.8,0.6-32.4c-0.3-1,0.3-2.1,1.3-2.4 L132.6,49.6z',
  'M107.1,123.5c0.6,0.9,0.4,2-0.5,2.6C95.8,133.3,83,137.2,70,137.4c-13.1,0.2-25.9-3.4-37-10.3 c-0.9-0.6-1.1-1.7-0.6-2.6l3.6-5.4c0.6-0.9,1.8-1.1,2.6-0.6c9.4,5.7,20.2,8.7,31.2,8.6c11-0.2,21.8-3.4,31-9.4 c0.9-0.6,2.1-0.4,2.7,0.5L107.1,123.5z',
  'M29,122.1c-0.6,0.8-1.8,1-2.6,0.4c-10.2-8.1-17.9-19-22.1-31.4C0,78.7-0.5,65.3,2.6,52.7 c0.3-1,1.3-1.6,2.3-1.3l6.2,1.7c1,0.3,1.6,1.3,1.4,2.3C9.9,66.1,10.4,77.3,14,87.7s10,19.6,18.6,26.5c0.8,0.7,1,1.8,0.4,2.7 L29,122.1z',
  'M6.1,47.3c-1-0.3-1.5-1.4-1.2-2.4C9.6,32.7,17.6,22.1,28,14.2C38.5,6.4,51,1.7,64,0.8c1-0.1,1.9,0.7,2,1.8 L66.3,9c0,1-0.8,1.9-1.8,2c-11,0.9-21.5,4.8-30.3,11.4C25.3,29,18.6,38,14.7,48.3c-0.4,1-1.5,1.5-2.4,1.2L6.1,47.3z',
]
