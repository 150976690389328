import { useState, type FC } from 'react'
import type { RAGIndicatorProps } from './types'
import clsx from 'clsx'
import { Loader } from '../IconLibrary/Icons'
import { INTENT_TO_ARIA_DESCRIPTION_MAP } from './constant'
import { Typography } from '../Typography'

export const RAGIndicator: FC<RAGIndicatorProps> = ({
  buttonAriaLabel,
  icon,
  intent,
  isLoading = false,
  label,
  labelClassName,
  onClick,
  testId,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className="flex items-center gap-x-1" data-testid={testId}>
      {isLoading && (
        <Loader className="text-default" testId={`${testId}-loader`} />
      )}
      {!isLoading && (
        <div
          className="w-4 h-4 p-0.5 rounded-full"
          aria-description={INTENT_TO_ARIA_DESCRIPTION_MAP[intent]}
        >
          <div
            className={clsx('w-full h-full rounded-full', {
              'bg-new-palette-lime-500': intent === 'green',
              'bg-new-palette-forest-300': intent === 'amber',
              'bg-new-palette-coral-400': intent === 'red',
              'border border-strong': intent === 'neutral',
            })}
          />
        </div>
      )}
      {!onClick && label && (
        <Typography
          size="xs"
          className={clsx(labelClassName)}
          testId={`${testId}-label`}
        >
          {label}
        </Typography>
      )}
      {onClick && label && (
        <button
          aria-label={buttonAriaLabel}
          className="flex items-center gap-x-0.5"
          data-testid={`${testId}-button`}
          onClick={onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography
            size="xs"
            weight="medium"
            className={clsx(
              {
                underline: isHovered,
              },
              labelClassName,
            )}
            testId={`${testId}-label`}
          >
            {label}
          </Typography>
          {!isLoading && icon && icon}
        </button>
      )}
    </div>
  )
}
