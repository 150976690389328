import { ChangeEvent, useState } from 'react'
import isArray from 'lodash/isArray'
import { FormPage, Value } from './types'
import { columnise } from './utils/columnise'
import { createKey } from './utils/createKey'
import {
  Button,
  Checkbox,
  RadioButton,
  Typography,
} from '@sylveraio/design-system'
import clsx from 'clsx'
import { toggleArrayObjects } from '@sylveraio/react-utils'
import { TextRow } from '../../../TextRow'
import ArrowRight from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowRight'
import ArrowLeft from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowLeft'
import Pencil01 from '@sylveraio/untitled-ui-icons-react/build/esm/Pencil01'
import type {
  FormData,
  FormFieldInput,
  FormFieldInputTypes,
  UseForm,
  ValueBase,
} from './types'
import { checkIsCheckboxWithSearch } from './utils/typeguards/checkIsCheckboxWithSearch'
import { evaluateConditions } from './utils/evaluateConditions'
import { FormFieldLabel } from './components/FormFieldLabel'
import { FieldContainer } from './components/FieldContainer'
import { Select } from './components/Select'
import { SelectFieldContainer } from './components/SelectFieldContainer'
import { IconWrapper } from './components/IconWrapper'
import { FormSearch } from './components/FormSearch'
import set from 'lodash/set'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import unset from 'lodash/unset'
import isEqual from 'lodash/isEqual'
import groupBy from 'lodash/groupBy'
import { extractValueBase } from './utils/extractValueBase'
import { checkIsValueBase } from './utils/typeguards/checkIsValueBase'
import { getValue } from './utils/getValue'
import { getFormValues } from './utils/getFormValues'
import { getCurrentPageFields } from './utils/getCurrentPageFields'
import { hasOutstandingRequiredFields } from './utils/hasOutstandingRequiredFields'
import { getFormKeysMap } from './utils/getFormKeysMap'
import { CheckboxRow } from './components/CheckboxRow'
import { prepareFormSubmission } from './utils/prepareFormSubmission'

export function useForm(
  form: Array<FormPage>,
  onSubmit: (form: Record<string, unknown>) => void,
  inPageIdx = 0,
  defaultFormDataValues: FormData = {},
): UseForm {
  const [formData, setFormData] = useState<FormData>(defaultFormDataValues)
  const formDataValuesOnly = getFormValues(formData)
  const [pageIdx, setPageIdx] = useState<number>(inPageIdx)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const clearForm = () => {
    setFormData(defaultFormDataValues)
    setPageIdx(0)
    setErrors({})
  }
  const handleFormDataChange = (
    key: string,
    value: ValueBase | Array<ValueBase>,
  ) => {
    if (has(formData, key) && isEmpty(value)) {
      setFormData((data) => {
        const _amended = { ...data }
        unset(_amended, key)
        return { ..._amended }
      })
    } else {
      setFormData((data) => ({ ...set(data, key, value) }))
    }
  }
  const handleChange = <T extends FormFieldInputTypes>(
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curValue?: Value,
    entry?: T,
  ) => {
    const { name, value, type } = ev.target
    if (curValue) {
      const valueBase = extractValueBase(curValue)
      if (type === 'checkbox') {
        const currentValue: Array<ValueBase> =
          (formData[name] as Array<ValueBase>) || []
        const newValue = toggleArrayObjects<ValueBase>(
          currentValue,
          valueBase,
          'value',
        )
        handleFormDataChange(name, newValue)
      } else {
        handleFormDataChange(name, valueBase)
      }
    } else {
      handleFormDataChange(name, extractValueBase({ value, text: value }))
    }
  }

  const handleSelect = (
    ev: ChangeEvent<HTMLSelectElement>,
    entries: Array<Value>,
  ) => {
    handleFormDataChange(
      ev.target.name,
      extractValueBase(entries[ev.target.selectedIndex]),
    )
  }

  // which pages should we show in the form
  const visibleFormPages = form.filter((entry) =>
    evaluateConditions(formDataValuesOnly, entry?.condition),
  )
  const pageCount = visibleFormPages.length
  const titles = visibleFormPages.map(({ title }) => title)
  const pageFields = getCurrentPageFields(
    visibleFormPages,
    pageIdx,
    formDataValuesOnly,
  )
  const formKeys = getFormKeysMap(
    visibleFormPages,
    formData,
    formDataValuesOnly,
    true,
  )
  const hasOutstandingFields = hasOutstandingRequiredFields(
    pageFields,
    formKeys,
  )

  const defaults = pageFields
    .filter(
      (field: any) =>
        typeof field?.key !== 'undefined' &&
        typeof field?.defaultValue !== 'undefined',
    )
    // @ts-ignore hush now child
    .map((field) => [field.key, field.defaultValue])

  // Set the defaultValue in the formData if no value is present
  if (!isEmpty(defaults)) {
    defaults.forEach(([key, value]) => {
      if (typeof formData[key] === 'undefined') handleFormDataChange(key, value)
    })
  }

  return {
    content: (
      <div className="divide-y divide-subtle">
        {pageFields.map((entry) => {
          switch (entry.type) {
            case 'input': {
              const defaultValue = getValue(formData[entry.key])
              const { withSelect } = entry
              let select: JSX.Element | undefined

              if (typeof withSelect !== 'undefined') {
                const currentFormValue = getValue(formData[withSelect.key])
                if (!currentFormValue && withSelect?.defaultValue) {
                  // Set default value if default value is passed and there's no current
                  handleFormDataChange(withSelect.key, withSelect.defaultValue)
                }
                select = (
                  <Select
                    name={withSelect.key}
                    values={withSelect.values}
                    handleSelect={(ev) => handleSelect(ev, withSelect.values)}
                    currentValue={currentFormValue?.value}
                    align="right"
                  />
                )
              }

              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                <FieldContainer inline>
                  <span
                    className={clsx({
                      'p-3 pr-1 shrink-0': entry.prefix,
                    })}
                  >
                    <Typography
                      size="base"
                      weight="regular"
                      className="text-subtle select-none"
                    >
                      {entry.prefix}
                    </Typography>
                  </span>
                  <input
                    type="input"
                    name={entry.key}
                    defaultValue={defaultValue?.value as string}
                    data-testid={`form-input-${entry.key}`}
                    onChange={(ev) =>
                      handleChange<FormFieldInput>(ev, undefined, entry)
                    }
                    required={entry.required}
                    className={clsx('w-full bg-transparent py-3 grow', {
                      'px-3': !entry.prefix,
                      'pr-0': entry?.withSelect,
                    })}
                  />
                  <span
                    className={clsx({
                      'py-3 pr-3 shrink-0': entry.suffix,
                    })}
                  >
                    <Typography
                      size="base"
                      weight="regular"
                      className="text-subtle select-none"
                    >
                      {entry.suffix}
                    </Typography>
                  </span>
                  {select}
                </FieldContainer>,
              )
            }
            case 'radio': {
              let currentValue: ValueBase | undefined

              if (typeof formData[entry.key] !== 'undefined') {
                const preTest = formData[entry.key]
                if (checkIsValueBase(preTest)) {
                  currentValue = preTest
                }
              }

              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                <ul
                  className={clsx({
                    'grid grid-cols-2 gap-4': entry?.isBoolean,
                    'space-y-4': !entry?.isBoolean,
                  })}
                >
                  {entry.values.map((v) => {
                    const { value, text, icon: Icon, tooltip, description } = v
                    return (
                      <li key={createKey(entry.type, entry.key, value)}>
                        <FieldContainer
                          selected={value === currentValue?.value}
                        >
                          <RadioButton
                            id={createKey(entry.type, entry.key, value)}
                            name={entry.key}
                            value={value as string | number}
                            checked={value === currentValue?.value}
                            onChange={(ev) => handleChange(ev, v, entry)}
                            wrapperClassName="p-3"
                            label={
                              <TextRow
                                text={text}
                                typography={{
                                  size: 'sm',
                                  weight: 'medium',
                                  className: '',
                                }}
                                subtitle={
                                  typeof description === 'string'
                                    ? {
                                        value: description,
                                        position: 'horizontal',
                                      }
                                    : undefined
                                }
                                icon={{
                                  component: (
                                    <IconWrapper Icon={Icon?.component} />
                                  ),
                                }}
                                tooltip={tooltip}
                              />
                            }
                          />
                        </FieldContainer>
                      </li>
                    )
                  })}
                </ul>,
              )
            }
            case 'checkbox': {
              const currentValues: Array<ValueBase> = isArray(
                formData[entry.key],
              )
                ? (formData[entry.key] as Array<ValueBase>)
                : []

              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                <ul className="space-y-4">
                  {entry.values.map((v) => {
                    const { value, text, icon: Icon, tooltip, description } = v
                    const valueBase = extractValueBase(v)
                    const checked = currentValues.some((v) =>
                      isEqual(v, valueBase),
                    )
                    return (
                      <li key={createKey(entry.type, entry.key, value)}>
                        <FieldContainer selected={checked}>
                          <Checkbox
                            id={createKey(entry.type, entry.key, value)}
                            name={entry.key}
                            value={value as string | number}
                            checked={checked}
                            onChange={(ev) =>
                              handleChange(ev, valueBase, entry)
                            }
                            label={
                              <CheckboxRow
                                text={text}
                                description={description}
                                icon={Icon}
                                tooltip={tooltip}
                                value={value}
                              />
                            }
                            wrapperClassName="p-3"
                            labelTypographyProps={{
                              size: 'sm',
                              weight: 'medium',
                            }}
                          />
                        </FieldContainer>
                      </li>
                    )
                  })}
                  {checkIsCheckboxWithSearch(entry) && (
                    <FormSearch
                      currentValues={currentValues}
                      name={entry.key}
                      type={entry.type}
                      onChange={handleFormDataChange}
                      opts={entry.opts}
                      placeholderText={entry.placeholderText}
                      searchFn={entry.searchFn}
                      excludeValues={entry.values.map(extractValueBase)}
                    />
                  )}
                </ul>,
              )
            }
            case 'select': {
              const currentValue = getValue(formData[entry.key])
              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                <SelectFieldContainer
                  key={createKey(entry.type, entry.key)}
                  name={entry.key}
                  values={entry.values}
                  currentValue={currentValue?.value || entry?.defaultValue}
                  handleSelect={(ev) => handleSelect(ev, entry.values)}
                  label={entry.label}
                />,
              )
            }
            case 'tuple': {
              const currentValue = isArray(formData[entry.key])
                ? (formData[entry.key] as Array<ValueBase>)
                : []
              const getUpdatedValue = (valueIdx: number, data: ValueBase) => {
                return set([...currentValue], [valueIdx], data)
              }
              const handleTuple = (
                ev: ChangeEvent<HTMLSelectElement>,
                entries: Array<Value>,
              ) => {
                const valueIdx = Number(
                  ev.target.name.replace(`${entry.key}-`, ''),
                )
                const tupleValue = getUpdatedValue(
                  valueIdx,
                  extractValueBase(entries[ev.target.selectedIndex]),
                )

                if (entry?.validationFn) {
                  const errorMsg = entry.validationFn(tupleValue)
                  if (errorMsg) {
                    setErrors((errors) => ({
                      ...errors,
                      [entry.key]: errorMsg,
                    }))
                  } else
                    setErrors((errors) => {
                      const current = { ...errors }
                      unset(current, entry.key)
                      return current
                    })
                  handleFormDataChange(entry.key, tupleValue)
                } else {
                  handleFormDataChange(entry.key, tupleValue)
                }
              }

              const selects = (
                <div className="grid grid-cols-2 gap-4">
                  {entry.fields.map((tuple, i) => {
                    if (
                      entry?.required &&
                      typeof tuple.defaultValue !== 'undefined' &&
                      typeof currentValue[i]?.value === 'undefined'
                    ) {
                      handleFormDataChange(
                        entry.key,
                        getUpdatedValue(i, tuple.defaultValue),
                      )
                    }
                    const name = `${entry.key}-${i}`
                    return (
                      <SelectFieldContainer
                        key={createKey(entry.type, entry.key, i)}
                        name={name}
                        values={tuple.values}
                        currentValue={
                          currentValue[i]?.value || tuple?.defaultValue
                        }
                        handleSelect={(ev) => handleTuple(ev, tuple.values)}
                        label={tuple.label}
                        hasError={typeof errors[entry.key] === 'string'}
                        errorMsg={i === 0 ? errors[entry.key] : undefined}
                      />
                    )
                  })}
                </div>
              )

              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                selects,
              )
            }
            case 'text-area': {
              const defaultValue = getValue(formData[entry.key])
              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                <>
                  <FieldContainer className="h-max">
                    <textarea
                      name={entry.key}
                      defaultValue={defaultValue?.value as string}
                      onChange={handleChange}
                      data-testid={`form-area-${entry.key}`}
                      required={entry.required}
                      className="w-full h-40 bg-transparent p-3"
                      maxLength={entry?.limit}
                      placeholder="Tell us more"
                    />
                  </FieldContainer>
                  <Typography
                    size="sm"
                    weight="regular"
                    className="text-subtle mt-2"
                    testId={`form-area-${entry.key}-counter`}
                  >
                    {entry?.limit && (
                      <>
                        {entry.limit -
                          ((defaultValue?.value as string)?.length || 0)}{' '}
                        characters left
                      </>
                    )}
                  </Typography>
                </>,
              )
            }
            case 'tags': {
              const currentValues: Array<ValueBase> = isArray(
                formData[entry.key],
              )
                ? (formData[entry.key] as Array<ValueBase>)
                : []

              return columnise(
                createKey(entry.type, entry.key),
                <FormFieldLabel
                  title={entry.title}
                  description={entry?.description}
                  required={entry?.required}
                />,
                <FormSearch
                  currentValues={currentValues}
                  name={entry.key}
                  type={entry.type}
                  onChange={handleFormDataChange}
                  opts={entry.opts}
                  placeholderText={entry.placeholderText}
                  searchFn={entry.searchFn}
                  testId={`form-tags-${entry.key}`}
                  limitSelected={entry?.limitSelected}
                  limitDisplayed={entry?.limitDisplayed}
                  stringifyTagFn={entry?.stringifyTagFn}
                />,
              )
            }
            case 'submit': {
              // The page check removes values added on the submit page from being listed at the top of the page
              const keysWithValues = [...formKeys.values()].filter(
                (v) =>
                  typeof v.value !== 'undefined' && v.page !== titles[pageIdx],
              )

              const pagesWithKeys = groupBy(keysWithValues, ({ page }) => page)

              const display = titles
                .filter((title) => typeof pagesWithKeys[title] !== 'undefined')
                .map((title) => ({
                  title,
                  data: pagesWithKeys[title],
                  pageIdx: titles.indexOf(title),
                }))

              return display.map(({ title, data, pageIdx }) =>
                columnise(
                  createKey('submit', title || ''),
                  <FormFieldLabel title={title || ''} required={true}>
                    <Button
                      onClick={() => setPageIdx(pageIdx)}
                      variant="secondary"
                      label="Edit"
                      iconLeading={<Pencil01 className="h-5 w-5" />}
                    />
                  </FormFieldLabel>,
                  <ul className="space-y-6">
                    {data.map((entry) => (
                      <li
                        key={createKey('submit', title || '', entry.name)}
                        className="flex space-x-3"
                      >
                        {entry.icon && (
                          <IconWrapper Icon={entry?.icon.component} />
                        )}
                        <Typography
                          size="sm"
                          weight="semibold"
                          className="text-default shrink-0"
                        >
                          {entry.label}
                        </Typography>
                        <Typography
                          size="sm"
                          weight="regular"
                          className="text-subtle"
                        >
                          {String(entry)}
                        </Typography>
                      </li>
                    ))}
                  </ul>,
                ),
              )
            }
            default:
              return <div />
          }
        })}
      </div>
    ),
    backButtonProps:
      pageIdx > 0
        ? {
            onClick: () => setPageIdx((i) => i - 1),
            variant: 'subtle',
            label: 'Back',
            iconLeading: <ArrowLeft />,
          }
        : undefined,
    nextButtonProps:
      pageIdx < pageCount - 1
        ? {
            onClick: () => setPageIdx((i) => i + 1),
            size: 'lg',
            disabled: hasOutstandingFields || !isEmpty(errors),
            variant: 'primary',
            testId: 'form-continue-btn',
            label: 'Continue',
            iconTrailing: <ArrowRight color="white" />,
          }
        : pageCount > 0 && pageIdx === pageCount - 1
        ? {
            onClick: () => {
              onSubmit(prepareFormSubmission(formKeys))
            },
            size: 'lg',
            variant: 'primary',
            testId: 'form-submit-btn',
            label: 'Submit request',
            iconTrailing: <ArrowRight color="white" className="w-4 h-4" />,
          }
        : undefined,
    pageIdx,
    page: {
      current: pageIdx + 1,
      total: pageCount,
      title: `${titles[pageIdx]} `,
    },
    clearForm,
  }
}
