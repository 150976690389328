import { useEffect } from 'react'
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk'
import { usePrevious } from '@sylveraio/react-utils'
import { useUser } from '@sylveraio/data'

export function useSetLDUser(isProtected = false) {
  const ldClient = useLDClient()
  const { data } = useUser(isProtected)
  const prevUserId = usePrevious(data?.userId)

  useEffect(() => {
    if (data?.userId && data?.organisationId && data?.userId !== prevUserId) {
      const context: LDContext = {
        kind: 'multi',
        user: {
          key: data.userId,
        },
        organisation: {
          key: data.organisationId,
        },
      }

      ldClient?.identify(context)
    }
  }, [data, prevUserId])
}

export default useSetLDUser
