'use client'

/* eslint-disable consistent-return */
import { Dispatch, RefObject, SetStateAction, useState } from 'react'
import { useEventListener } from '../useEventListener'

export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState<boolean>(false)

  const handleMouseEnter = () => setValue(true)
  const handleMouseLeave = () => setValue(false)

  useEventListener('mouseenter', handleMouseEnter, elementRef)
  useEventListener('mouseleave', handleMouseLeave, elementRef)

  return [value, setValue]
}

export default useHover
