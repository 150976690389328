import React, { FC } from 'react'
import type { LockInRingV2Props } from './types'
import clsx from 'clsx'

export const LockInRingV2: FC<LockInRingV2Props> = ({
  testId = 'lock-in-ring',
  size = 16,
  className,
}) => (
  <svg
    data-testid={testId}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current text-disabled', className)}
  >
    <g clipPath="url(#clip0_2907_4367)">
      <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM3.6 24C3.6 35.2666 12.7334 44.4 24 44.4C35.2666 44.4 44.4 35.2666 44.4 24C44.4 12.7334 35.2666 3.6 24 3.6C12.7334 3.6 3.6 12.7334 3.6 24Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2494 14.55C21.3335 14.55 19.3505 16.8358 19.3505 19.5949V21.2825H19.2444C17.7581 21.2825 16.5736 22.4878 16.55 23.9829V30.7425C16.55 32.2311 17.7445 33.4262 19.2372 33.45H29.2942C30.7805 33.45 31.9649 32.2447 31.9885 30.7496V23.99C31.9885 22.5001 30.7714 21.3025 29.2942 21.3025H29.1881V19.5949C29.1881 16.7877 27.1576 14.55 24.2494 14.55ZM27.1758 21.2825H21.3627V19.5949C21.3627 17.8913 22.505 16.5687 24.2494 16.5687C25.9858 16.5687 27.1758 17.864 27.1758 19.5949V21.2825ZM18.5821 23.99C18.5821 23.6168 18.8796 23.3212 19.2444 23.3212H29.2942C29.659 23.3212 29.9564 23.6168 29.9564 23.99V30.7425C29.9564 31.1157 29.659 31.4113 29.2942 31.4113H19.2444C18.8796 31.4113 18.5821 31.1157 18.5821 30.7425V23.99ZM25.8315 26.2475C25.8315 25.3734 25.1451 24.6542 24.262 24.6788C23.3986 24.6827 22.707 25.3846 22.707 26.2475C22.707 26.7331 22.9196 27.1648 23.2632 27.4564V29.0644C23.2632 29.599 23.6897 30.0737 24.2693 30.0737C24.8074 30.0737 25.2754 29.6408 25.2754 29.0644V27.4564C25.619 27.1648 25.8315 26.7331 25.8315 26.2475Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2907_4367">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
