import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const DocumentFree: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="75"
    height="83"
    viewBox="0 0 75 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.7097 51.2499V22.0004C66.7097 21.4817 66.5033 20.9864 66.1411 20.6198L46.6679 1.12013C46.3018 0.757432 45.8072 0.550735 45.2892 0.550735H16.0795C15.0045 0.550735 14.1321 1.42432 14.1321 2.5007C14.1321 3.57708 15.0045 4.45067 16.0795 4.45067H43.3419V22.0004C43.3419 23.0768 44.2143 23.9503 45.2892 23.9503H62.8151V51.2499C62.8151 52.3262 63.6875 53.1998 64.7624 53.1998C65.8373 53.1998 66.7097 52.3262 66.7097 51.2499ZM47.2366 20.0504V7.20793L60.0616 20.0504H47.2366Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.06959 7.78123L8.29022 15.0117V80.4993C8.29022 81.5757 9.16262 82.4493 10.2375 82.4493H64.7624C65.8373 82.4493 66.7097 81.5757 66.7097 80.4993V73.5107L71.173 77.98C71.9364 78.7405 73.1671 78.7405 73.9304 77.98C74.6899 77.2156 74.6899 75.9833 73.9304 75.2189L3.82699 5.02008C3.06364 4.25959 1.83294 4.25959 1.06959 5.02008C0.310137 5.78447 0.310137 7.01685 1.06959 7.78123ZM12.1849 78.5493V18.9116L62.8151 69.6107V78.5493H12.1849Z"
    />
  </svg>
)

export default DocumentFree
