import { FC } from 'react'
import clsx from 'clsx'

import type LongArrowProps from './types'

const LongArrow: FC<LongArrowProps> = ({ size = 16, className }) => (
  <svg
    width="113"
    height="16"
    viewBox="0 0 113 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M112.707 8.70712C113.098 8.31659 113.098 7.68343 112.707 7.2929L106.343 0.928941C105.953 0.538417 105.319 0.538417 104.929 0.928941C104.538 1.31947 104.538 1.95263 104.929 2.34315L110.586 8.00001L104.929 13.6569C104.538 14.0474 104.538 14.6806 104.929 15.0711C105.319 15.4616 105.953 15.4616 106.343 15.0711L112.707 8.70712ZM-8.74228e-08 9L79.5455 9.00001L79.5455 7.00001L8.74228e-08 7L-8.74228e-08 9ZM79.5455 9.00001L112 9.00001L112 7.00001L79.5455 7.00001L79.5455 9.00001Z"
      fill="#424177"
    />
  </svg>
)

export default LongArrow
