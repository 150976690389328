import { FC } from 'react'
import type AvatarProps from './types'
import { Typography } from '@sylveraio/design-system/server'
import clsx from 'clsx'

const Avatar: FC<AvatarProps> = ({ initials = '' }) => (
  <div className="inline-flex h-10 w-10 rounded-full p-1 group-hover:bg-default-hover">
    <div
      className={clsx(
        {
          'bg-low animate-pulse': initials === '',
          'bg-new-palette-forest-900': initials !== '',
        },
        'flex h-full w-full items-center justify-center rounded-full transition',
      )}
    >
      {initials !== '' && (
        <Typography className="uppercase text-white" size="sm">
          {initials}
        </Typography>
      )}
    </div>
  </div>
)

export default Avatar
