import React, { FC, BaseSyntheticEvent, useState, ChangeEvent } from 'react'
import { routes } from '@sylveraio/auth-utils'
import { getInputError } from '@sylveraio/react-utils'

import {
  Form,
  FormHeading,
  FormSubheading,
  FormBackLink,
  Input,
  Button,
} from '@sylveraio/react-ui'
import useSubmitRequestDemoForm from './utils/useSubmitRequestDemoForm'

import type {
  RequestDemoFormProps,
  RequestDemoFormKeys,
  RequestDemoFormInputNameTypes,
} from './types'
import SignInFormSuccess from '../SignInFormSuccess'
import SignInFormError from '../SignInFormError'

export const RequestDemoForm: FC<RequestDemoFormProps> = ({
  testId = 'request-demo-form',
}) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [companyEmail, setCompanyEmail] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const { submitRequestDemoForm } = useSubmitRequestDemoForm()

  const onSubmit = async (
    data: RequestDemoFormKeys,
    e: BaseSyntheticEvent<object, any, any> | undefined,
  ) => {
    e?.preventDefault()
    setSubmitting(true)

    try {
      await submitRequestDemoForm({
        firstName: data['request-access-first-name'],
        lastName: data['request-access-last-name'],
        companyName: data['request-access-company-name'],
        role: data['request-access-role'],
        companyEmail: data['request-access-company-email'],
      })

      setSuccess(true)
    } catch {
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback?: () => void,
  ) => {
    switch (e.currentTarget.name) {
      case 'request-access-first-name':
        setFirstName(e.currentTarget.value)
        break
      case 'request-access-last-name':
        setLastName(e.currentTarget.value)
        break
      case 'request-access-company-name':
        setCompanyName(e.currentTarget.value)
        break
      case 'request-access-role':
        setRole(e.currentTarget.value)
        break
      case 'request-access-company-email':
        setCompanyEmail(e.currentTarget.value)
        break
      default:
        break
    }

    callback?.()
  }

  if (error) {
    return (
      <SignInFormError
        testId={`${testId}-error`}
        errorMessage={
          <>
            <p className="mb-10">There is an issue with this form.</p>
            <p>
              Please try again later or send us an email at{' '}
              <a href="mailto:contact@sylvera.io">contact@sylvera.io</a>
            </p>
          </>
        }
      />
    )
  }

  if (success) {
    return (
      <SignInFormSuccess
        testId={`${testId}-success`}
        heading="Request submitted!"
        subheading="Thanks for your details. We'll be in touch."
        buttonText="Go to the Sylvera website"
        redirectUrl="https://www.sylvera.com"
      />
    )
  }

  return (
    <Form<RequestDemoFormKeys> testId={testId} onSubmit={onSubmit}>
      {({ register, formState, clearErrors }) => (
        <>
          <FormHeading testId={`${testId}-heading`}>Request Demo</FormHeading>
          <FormSubheading
            testId={`${testId}-subheading`}
            className="mb-5 whitespace-nowrap"
          >
            Enter your details below to get started.
          </FormSubheading>
          <div className="relative flex flex-col w-full gap-y-4">
            <Input
              testId={`${testId}-first-name-input`}
              type="text"
              label="First name *"
              hideBorder
              error={getInputError<
                RequestDemoFormKeys,
                RequestDemoFormInputNameTypes
              >(formState.errors, 'request-access-first-name')}
              {...register('request-access-first-name', {
                value: firstName,
                required: 'Please enter your first name.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
            <Input
              testId={`${testId}-last-name-input`}
              type="text"
              label="Last name *"
              hideBorder
              value={lastName}
              error={getInputError<
                RequestDemoFormKeys,
                RequestDemoFormInputNameTypes
              >(formState.errors, 'request-access-last-name')}
              {...register('request-access-last-name', {
                value: lastName,
                required: 'Please enter your last name.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
            <Input
              testId={`${testId}-company-name-input`}
              type="text"
              label="Company name *"
              hideBorder
              value={companyName}
              error={getInputError<
                RequestDemoFormKeys,
                RequestDemoFormInputNameTypes
              >(formState.errors, 'request-access-company-name')}
              {...register('request-access-company-name', {
                value: companyName,
                required: 'Please enter your company name.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
            <Input
              testId={`${testId}-role-input`}
              type="text"
              label="Role *"
              hideBorder
              value={role}
              error={getInputError<
                RequestDemoFormKeys,
                RequestDemoFormInputNameTypes
              >(formState.errors, 'request-access-role')}
              {...register('request-access-role', {
                value: role,
                required: 'Please enter your role.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
            <Input
              testId={`${testId}-company-email-input`}
              type="email"
              label="Company email address *"
              hideBorder
              value={companyEmail}
              error={getInputError<
                RequestDemoFormKeys,
                RequestDemoFormInputNameTypes
              >(formState.errors, 'request-access-company-email')}
              {...register('request-access-company-email', {
                value: companyEmail,
                required: 'Please enter your company email.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
          </div>

          <div className="flex flex-col mt-4 text-center mb-14 gap-y-4">
            <p className="text-xs text-default-on-dark">
              We don’t share your data. See our{' '}
              <a
                href="https://www.sylvera.com/privacy-policy"
                className="hover:underline text-content-discovery-default hover:text-content-action-default-hover"
              >
                Privacy Policy
              </a>
            </p>
            <Button
              testId={`${testId}-submit-button`}
              type="submit"
              className="!w-[240px] !h-12 mx-auto !text-xl font-medium"
              spinner={submitting}
              solid
              color="lime"
              shape="pill"
            >
              Submit
            </Button>
          </div>

          <FormBackLink href={routes['signIn']} />
        </>
      )}
    </Form>
  )
}

export default RequestDemoForm
