'use client'

import { FC } from 'react'
import { TypographyLegacy } from '../TypographyLegacy'
import type { ParagraphProps } from './types'

/**
 * Returns a paragraph with the correct TypographyLegacy variant
 */
export const Paragraph: FC<ParagraphProps> = ({
  testId = 'paragraph',
  variant,
  children,
  className,
}) => (
  <TypographyLegacy testId={testId} variant={variant} className={className}>
    {children}
  </TypographyLegacy>
)
