import { type FC, useState } from 'react'
import { Modal } from '@sylveraio/react-ui'
import { UpsellModalProps } from './types'
import { CustomHubspotForm } from './components/CustomHubspotForm'

export const UpsellModal: FC<UpsellModalProps> = ({
  show,
  testId = 'upsell',
  onClose,
  appName,
  formCustomOverride,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const handleClose = async () => {
    setSuccess(false)
    setSubmitting(false)
    setError(false)

    onClose()
  }

  if (!appName && !formCustomOverride) return null

  return (
    <Modal
      testId={`${testId}-hubspot-form`}
      isOpen={show}
      handleClose={handleClose}
      showCloseButton={!submitting}
      withOverlay
      className={
        formCustomOverride?.variant === 'tier' && !success ? 'px-12' : ''
      }
    >
      <CustomHubspotForm
        formCustomOverride={formCustomOverride}
        onClose={onClose}
        handleClose={handleClose}
        setSubmitting={setSubmitting}
        setSuccess={setSuccess}
        setError={setError}
        submitting={submitting}
        success={success}
        error={error}
      />
    </Modal>
  )
}
