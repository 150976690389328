import { FC } from 'react'
import { Tooltip } from '@sylveraio/react-ui/server'
import type { AppSwitcherDropdownProps } from './types'

export const AppSwitcherDropdown: FC<AppSwitcherDropdownProps> = ({
  testId = 'app-switcher-dropdown',
  referenceElement,
  showDropdown = false,
  children,
}) => (
  <Tooltip
    testId={testId}
    offset={[10, 10]}
    referenceElement={referenceElement}
    className="z-50 bg-high border rounded shadow-modal border-subtle"
    placement="bottom-end"
    show={showDropdown}
    preventOverflow={false}
    transparent
    arrowSize={10}
    withArrow
  >
    <div className="flex flex-col gap-y-4 !py-6 !px-5">{children}</div>
  </Tooltip>
)
