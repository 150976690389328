import { DEFAULT_ZERO_VALUE_COLOR } from './constants'
import type { ColorScale } from './types'

/**
 * Takes specified parameters and outputs a color mapping
 * @param data data object
 * @param dataParameter field the color mapping will be created for, note the function only assigns steps to numeric values
 * @param numberOfSteps how many steps should the mapping have, it should correspond to number of colors in the array passed as colors parameter
 * @param colors an array of colors to be used for mapping
 * @param zeroValueColor color value assigned to step 0
 * @returns a color mapping
 */

export function getChoroplethColors<
  T extends Record<string, number | string | boolean | undefined>,
>(
  data: T[] = [],
  dataParameter: keyof T,
  numberOfSteps: number,
  colors: Array<string>,
  zeroValueColor: string = DEFAULT_ZERO_VALUE_COLOR,
): Array<ColorScale> {
  if (!data || data.length === 0 || !dataParameter) return [[0, zeroValueColor]]

  // Calculate min and max values, excluding zero
  const relevantValues = data
    .map((item) => item?.[dataParameter])
    .filter(
      (value) => value !== 0 && typeof value === 'number',
    ) as Array<number>

  if (relevantValues.length === 0) return [[0, zeroValueColor]]

  const minValue = Math.min(...relevantValues)
  const maxValue = Math.max(...relevantValues)

  // Generate range for each step for non-zero values
  const rangePerStep = (maxValue - minValue) / (numberOfSteps - 1)

  const getClosestStep = (value: number): number => {
    return Math.min(
      numberOfSteps - 1,
      Math.floor((value - minValue) / rangePerStep),
    )
  }

  // Map each data value to its closest step and then to a color
  const stepToColorIndex = relevantValues.reduce((accumulator, value) => {
    const step = getClosestStep(value)
    return {
      ...accumulator,
      [step]: accumulator[step] || colors[step % colors.length],
    }
  }, {} as Record<number, string>)

  const colorStops = [
    [0, zeroValueColor],
    ...Object.entries(stepToColorIndex).map(([step, color]) => {
      return [minValue + Number(step) * rangePerStep, color]
    }),
  ]

  return colorStops
}
