import { FC } from 'react'
import clsx from 'clsx'
import { Breakpoint, useWindowDimensions } from '@sylveraio/react-utils'
import { Header } from '../Header'
import type { SiteContainerProps } from './types'

const SiteContainer: FC<SiteContainerProps> = ({
  children,
  isSidebarOpen,
  isLoggedInRoute,
  mode = 'light',
  footer,
  headerMenuItems,
  navigationRoutes,
  userNav,
  appName,
  appSwitcherItems,
  responsive,
  activePageId,
  beta,
  tierName,
  className,
}) => {
  const { isBelowScreenWidth } = useWindowDimensions()

  return (
    <div className="flex min-h-screen flex-col">
      <main
        className={clsx(
          `theme-${mode} ${mode} duration-1000 grow flex flex-col`,
          {
            'min-w-[1024px]':
              isBelowScreenWidth(Breakpoint.DesktopSmall) && !responsive,
            'min-w-[1024px] ml-[350px]': isSidebarOpen && !responsive,
            'px-8 bg-default': !className,
          },
          className,
        )}
      >
        <div className="flex h-full w-full grow flex-col text-default">
          <Header
            isLoggedInRoute={isLoggedInRoute}
            navigationRoutes={navigationRoutes}
            userNav={userNav}
            appName={appName}
            appSwitcherItems={appSwitcherItems}
            activePageId={activePageId}
            beta={beta}
            tierName={tierName}
            headerMenuItems={headerMenuItems}
          />
          {children}
        </div>
      </main>
      {isLoggedInRoute && footer && (
        <footer
          className={clsx(
            `theme-${mode} ${mode} duration-1000 h-[102px] shrink-0`,
            {
              'min-w-[1024px]':
                isBelowScreenWidth(Breakpoint.DesktopSmall) && !responsive,
              'min-w-[1024px] ml-[350px]': isSidebarOpen && !responsive,
              'px-8 bg-default': !className,
            },
          )}
        >
          <div className="w-full text-default">{footer}</div>
        </footer>
      )}
    </div>
  )
}

export default SiteContainer
