import type { FC, PropsWithChildren } from 'react'
import { SocialMediaGroup } from '../SocialMediaGroup'
import type { FooterProps } from './types'
import clsx from 'clsx'

export const FooterServer: FC<
  Omit<FooterProps, 'appName'> & PropsWithChildren
> = ({ className, children }) => {
  return (
    <div className="h-max pt-12 px-4">
      <div
        className={clsx(
          'container border-t border-text-default py-4 flex space-x-4 justify-center items-center',
          className,
        )}
      >
        <SocialMediaGroup />
        <a
          href="https://info.sylvera.com/hubfs/Website%20%2B%20Platform%20Disclaimer%20(Live).pdf"
          target="_blank"
          rel="noreferrer"
          className="text-sm font-medium border-b text-default"
          data-testid="footer-ratings-disclaimer-link"
        >
          Sylvera Ratings Disclaimer
        </a>
        {children}
      </div>
    </div>
  )
}

export default FooterServer
