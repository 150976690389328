import { FC } from 'react'
import clsx from 'clsx'
import type { FormSubheadingProps } from './types'

const FormSubheading: FC<FormSubheadingProps> = ({
  testId = 'form-subheading',
  className,
  children,
}) => (
  <h4
    data-testid={testId}
    className={clsx(
      'text-lg font-medium text-center leading-normal text-default-on-dark',
      className,
    )}
  >
    {children}
  </h4>
)

export default FormSubheading
