import { FC } from 'react'
import clsx from 'clsx'
import { ConditionalDivButton } from '../../../ConditionalDivButton'
import type { ProgressRingOuterRingProps } from './types'

export const ProgressRingOuterRing: FC<ProgressRingOuterRingProps> = ({
  testId = 'progress-ring-outer-ring',
  size = 'sm',
  innerLabel,
  onClick,
  removeRatingOuterRing,
  outerRingProvisionalVariant,
}) => (
  <ConditionalDivButton
    testId={testId}
    className={clsx('relative rounded-full bg-black-25 overflow-hidden', {
      '!p-0': removeRatingOuterRing,
      'p-0.5': size === 'xs',
      'p-1': size === 'sm',
      'p-[5px]': size === 'md',
      'p-2': size === 'lg',
      'p-2.5': size === 'xl',
    })}
    onClick={onClick}
  >
    {!removeRatingOuterRing && (
      <div className="absolute top-0 left-0 w-full h-full rounded-full shadow-rating" />
    )}
    <div
      className={clsx('flex items-center justify-center rounded-full', {
        'bg-progress-ring-gradient': !outerRingProvisionalVariant,
        'bg-provisional-rating-progress-ring-gradient':
          outerRingProvisionalVariant,
        'h-[60px] w-[60px] p-0.5': size === 'xs',
        'h-16 w-16 p-0.5': size === 'sm',
        'h-[76px] w-[76px] p-0.5': size === 'md',
        'h-[90px] w-[90px] p-1': size === 'lg',
        'h-[134px] w-[134px] p-1': size === 'xl',
      })}
    >
      <div className="flex items-center justify-center w-full h-full bg-high rounded-full">
        <span
          data-testid="ring-label"
          className={clsx('font-medium', {
            'text-secondary-mauve': outerRingProvisionalVariant,
            'text-content-action-dark': !outerRingProvisionalVariant,
            'text-lg': size === 'xs',
            'text-xl': size === 'md',
            'text-2xl': size === 'lg',
            'text-[40px]': size === 'xl',
          })}
        >
          {innerLabel}
        </span>
      </div>
    </div>
  </ConditionalDivButton>
)

export default ProgressRingOuterRing
