import type { ProvisionalRating } from 'types'

/**
 * Returns a boolean based on whether a given rating is a provisional rating
 * @param rating
 * @returns true if there is no full rating and there is at least one type of provisional rating
 */

export function isProvisionalRating(
  fullRating: string | number | undefined,
  provisionalFullRating: string | number | undefined,
  provisionalRating: ProvisionalRating | undefined,
): boolean {
  return (
    typeof fullRating === 'undefined' &&
    (typeof provisionalFullRating !== 'undefined' ||
      typeof provisionalRating !== 'undefined')
  )
}

export default isProvisionalRating
