'use client'

/* eslint-disable consistent-return */
import { RefObject, useEffect, useState } from 'react'

/**
 * Detects which ref from a provided array of ref elements has been hovered
 * @param {Array<RefObject<T>>} refsArray array of refs elements to observe
 * @returns {number | null} An index of the currently hovered ref
 */

export function useHoverArray<T extends HTMLElement = HTMLElement>(
  refsArray: Array<RefObject<T>>,
): number | null {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null)
  }

  useEffect(() => {
    refsArray.forEach((ref, index) => {
      ref?.current?.addEventListener('mouseenter', () =>
        handleMouseEnter(index),
      )
      ref?.current?.addEventListener('mouseleave', handleMouseLeave)
    })

    return () => {
      refsArray.forEach((ref) => {
        // @ts-ignore
        ref?.current?.removeEventListener('mouseenter', handleMouseEnter)
        ref?.current?.removeEventListener('mouseleave', handleMouseLeave)
      })
    }
  }, [refsArray])

  return hoveredIndex
}

export default useHoverArray
