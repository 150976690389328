import type { FC } from 'react'
import type { MapControlsProps } from './types'
import { ButtonLegacy, Zoom } from '@sylveraio/design-system'
import { RESET_MAP } from './constants'

export const MapControls: FC<MapControlsProps> = ({
  onResetMapClick,
  onFullscreenClick,
  onZoomDecreaseClick,
  onZoomIncreaseClick,
  isFullscreen = false,
  disableZoomIn,
  disableZoomOut,
  disableResetMap,
  testId = 'map-controls',
}) => (
  <div className="flex items-center gap-x-2" data-testid={testId}>
    {onResetMapClick && (
      <ButtonLegacy
        appearance="solid"
        shape="pill"
        onClick={onResetMapClick}
        testId={`${testId}-reset-btn`}
        text={RESET_MAP}
        size="md"
        theme="off-white-1"
        disabled={disableResetMap}
      />
    )}
    {onFullscreenClick && (
      <ButtonLegacy
        appearance="none"
        iconPrefix={{ icon: isFullscreen ? 'arrow' : 'arrow', size: 'md' }}
        onClick={onFullscreenClick}
      />
    )}
    <Zoom
      size="md"
      testId={`${testId}-zoom-btn`}
      onZoomIn={onZoomIncreaseClick}
      onZoomOut={onZoomDecreaseClick}
      disableZoomIn={disableZoomIn}
      disableZoomOut={disableZoomOut}
    />
  </div>
)

export default MapControls
