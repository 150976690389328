import clsx from 'clsx'
import { FC } from 'react'
import { useTooltipHover } from '@sylveraio/react-utils'
import { Tooltip } from '../Tooltip'
import { COLORS } from './constants'
import type { ToggleProps } from './types'

export const Toggle: FC<ToggleProps> = ({
  testId = 'toggle',
  screenReader,
  label,
  tooltip,
  onClick,
  checked = false,
  fitted = false,
  colorScheme = 'forest',
}) => {
  const [setHoverLeftRef, hoverLeftRef, isLeftHover] =
    useTooltipHover<HTMLSpanElement>()
  const [setHoverRightRef, hoverRightRef, isRightHover] =
    useTooltipHover<HTMLSpanElement>()

  const { backgroundColor, labelColor, buttonColor } =
    COLORS[colorScheme.toUpperCase()]

  return (
    <button
      type="button"
      className="flex items-center gap-2"
      data-testid={testId}
      role="switch"
      aria-checked={checked}
      onClick={onClick}
    >
      {tooltip?.left && (
        <Tooltip
          testId={`${testId}-tooltip-left`}
          referenceElement={hoverLeftRef}
          show={isLeftHover}
          placement="top"
          offset={[0, 20]}
          className={clsx(
            'relative z-20 bg-opacity-90 px-4 py-2 w-60 text-content-action-dark',
          )}
          fitted={fitted}
        >
          {tooltip?.left}
        </Tooltip>
      )}
      <span
        className={clsx(`text-xs ${labelColor} select-none font-medium`, {
          'opacity-60': checked,
        })}
        data-testid={`${testId}-label-left`}
        ref={setHoverLeftRef}
      >
        {label.left}
      </span>
      <div
        data-testid={`${testId}-switch`}
        className="relative inline-flex flex-shrink-0 h-[19.71px] w-[45.99px] p-[2px] border border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500"
      >
        {screenReader && <span className="sr-only">{screenReader}</span>}
        <span
          data-testid={`${testId}-switch-track`}
          aria-hidden="true"
          className={`absolute top-0 left-0 w-full h-full rounded-full ${backgroundColor} ${
            !buttonColor ? 'opacity-20' : ''
          }`}
        />
        <span
          data-testid={`${testId}-switch-button`}
          aria-hidden="true"
          className={clsx(
            `pointer-events-none inline-block h-full w-3.5 rounded-full ${
              buttonColor ?? backgroundColor
            } shadow transform transition ease-in-out duration-200`,
            {
              'translate-x-[25px]': checked,
              'translate-x-[1px]': !checked,
            },
          )}
        />
      </div>
      <span
        className={clsx(`text-xs ${labelColor} select-none font-medium`, {
          'opacity-60': !checked,
        })}
        data-testid={`${testId}-label-right`}
        ref={setHoverRightRef}
      >
        {label.right}
      </span>
      {tooltip?.right && (
        <Tooltip
          testId={`${testId}-tooltip-right`}
          referenceElement={hoverRightRef}
          show={isRightHover}
          placement="top"
          offset={[0, 20]}
          className={clsx(
            `relative z-20 bg-opacity-90 px-4 py-2 w-60 ${labelColor}`,
          )}
          fitted={fitted}
        >
          {tooltip?.right}
        </Tooltip>
      )}
    </button>
  )
}

export default Toggle
