import { FC } from 'react'
import { HelperBlockProps } from './types'

export const HelperBlock: FC<HelperBlockProps> = ({
  helperText,
  testId = 'dropdown-helper-block',
}) => (
  <div
    data-testid={testId}
    className="z-20 -mb-2 mt-3 flex h-6 w-full flex-col items-center justify-center bg-default text-xs font-light text-default"
  >
    {helperText}
  </div>
)
