import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTimeout } from '@sylveraio/react-utils'
import FormErrorTooltipArrow from './components/FormErrorTooltipArrow'
import type { FormErrorTooltipProps } from './types'
import AlertTriangle from '@sylveraio/untitled-ui-icons-react/build/esm/AlertTriangle'

const TIMEOUT_MS = 5000
const FormErrorTooltip: FC<FormErrorTooltipProps> = ({
  testId = 'form-error-tooltip',
  removeArrow,
  className,
  children,
}) => {
  const [isHidden, setIsHidden] = useState(false)
  const [timeoutDurationMS, setTimeoutDurationMS] = useState<number | null>(
    TIMEOUT_MS,
  )

  const handleTimeout = useCallback(() => {
    setTimeoutDurationMS(null)
    setIsHidden(true)
  }, [])

  useEffect(() => {
    setTimeoutDurationMS(TIMEOUT_MS)
    setIsHidden(false)
  }, [children])

  useTimeout(handleTimeout, timeoutDurationMS)

  return (
    <div
      data-testid={testId}
      className={clsx(
        {
          invisible: isHidden,
        },
        className,
      )}
    >
      <div className="inline-flex justify-center w-80 relative border border-content-alert-default bg-high rounded-md px-4 pt-6 pb-4">
        {!removeArrow && <FormErrorTooltipArrow testId={`${testId}-arrow`} />}

        <div className="flex items-start">
          <div className="mr-2">
            <AlertTriangle className="w-5 h-5 !stroke-content-alert-default" />
          </div>
          <div data-testid={`${testId}-message`} className="text-md">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormErrorTooltip
