import clsx from 'clsx'
import type { LabelColors } from '../../types'

export function getLabelColors(
  color: LabelColors,
  strong?: boolean,
  outlined?: boolean,
) {
  return clsx({
    'bg-content-action-light text-content-action-dark border-content-action-subtle':
      color === 'action' && !strong && !outlined,

    'bg-content-action-default border-content-action-dark text-content-action-on-default':
      color === 'action' && strong && !outlined,

    'bg-none text-content-action-default border-content-action-subtle':
      color === 'action' && !strong && outlined,

    'bg-none border-content-action-default text-content-action-default':
      color === 'action' && strong && outlined,

    'bg-content-neutral-light text-content-neutral-dark border-content-neutral-subtle':
      color === 'neutral' && !strong,

    'bg-content-neutral-default border-content-neutral-dark text-content-neutral-on-default':
      color === 'neutral' && strong,

    'bg-content-alert-light text-content-alert-dark border-content-alert-subtle':
      color === 'alert' && !strong,

    'bg-content-alert-default border-content-alert-dark text-content-alert-on-default':
      color === 'alert' && strong,

    'bg-content-warning-light text-content-warning-dark border-content-warning-subtle':
      color === 'warning' && !strong,

    'bg-content-warning-default border-content-warning-default-hover text-content-warning-on-default':
      color === 'warning' && strong,

    'bg-content-positive-light text-content-positive-dark border-content-positive-subtle':
      color === 'positive' && !strong,

    'bg-content-positive-default border-content-positive-dark text-content-positive-on-default':
      color === 'positive' && strong,

    'bg-content-discovery-light text-content-discovery-dark border-content-discovery-subtle':
      color === 'discovery' && !strong,

    'bg-content-discovery-default border-content-discovery-default-hover text-content-discovery-on-default':
      color === 'discovery' && strong,
  })
}

export function getSplitLabelBorderColors(
  color: LabelColors,
  strong?: boolean,
) {
  return clsx({
    'border-content-action-subtle': color === 'action' && !strong,

    'border-content-action-dark text-content-action-on-default':
      color === 'action' && strong,

    'border-content-neutral-subtle': color === 'neutral' && !strong,

    'border-content-neutral-dark': color === 'neutral' && strong,

    'border-content-alert-subtle': color === 'alert' && !strong,

    'border-content-alert-dark': color === 'alert' && strong,

    'border-content-warning-subtle': color === 'warning' && !strong,

    'border-content-warning-dark': color === 'warning' && strong,

    'border-content-positive-subtle': color === 'positive' && !strong,

    'border-content-positive-dark': color === 'positive' && strong,

    'border-content-discovery-subtle': color === 'discovery' && !strong,

    'border-content-discovery-dark': color === 'discovery' && strong,
  })
}
