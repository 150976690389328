/* eslint-disable no-param-reassign */
import type { FeatureCollection, Feature, Geometry } from 'geojson'

/**
 * Takes a geoJson object and merges it with a data object
 * @param geoJson a geoJson file
 * @param additionalData a data object that should be merged with geoJson
 * @param matcher field that's used for finding a link between geoJson and additionalData
 * @param defaultData a fallback data object to be merged with geoJson when there's no additionalData for it
 * @returns an enriched geoJson file
 */

export function mergeGeoJsonWithData<T>(
  geoJson: FeatureCollection<Geometry>,
  additionalData: T[],
  defaultData: Record<string, number>,
  matcher: string,
): FeatureCollection<Geometry> {
  const geoJsonData = { ...geoJson }

  if (geoJsonData.features && Array.isArray(geoJsonData.features)) {
    geoJsonData.features.forEach((feature: Feature) => {
      const geoIdentifier = feature?.properties?.['id']

      const matchingData = additionalData.find(
        // @ts-ignore
        (item) => item?.[matcher] === geoIdentifier,
      )

      if (matchingData) {
        feature.properties = { ...feature.properties, ...matchingData }
      } else {
        feature.properties = {
          ...feature.properties,
          ...defaultData,
        }
      }
    })
  }

  return geoJsonData
}
