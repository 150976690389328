import { Typography } from '@sylveraio/design-system'
import { ContentShadow } from '../../ContentShadow'

export function CriteriaTooltip() {
  return (
    <ContentShadow wrapper>
      <Typography size="sm" weight="semibold" className="text-default">
        Example criteria
      </Typography>
      <ul className="list-disc ml-6 marker:text-subtle">
        <li>
          <Typography size="xs" weight="regular" className="text-subtle">
            Project type: ARR
          </Typography>
        </li>
        <li>
          <Typography size="xs" weight="regular" className="text-subtle">
            Location: Brazil
          </Typography>
        </li>
        <li>
          <Typography size="xs" weight="regular" className="text-subtle">
            Accreditation: CORSIA Phase one
          </Typography>
        </li>
        <li>
          <Typography size="xs" weight="regular" className="text-subtle">
            Registry: Verra
          </Typography>
        </li>
        <li>
          <Typography size="xs" weight="regular" className="text-subtle">
            Rating: BBB-AAA
          </Typography>
        </li>
        <li>
          <Typography size="xs" weight="regular" className="text-subtle">
            Screening: -
          </Typography>
        </li>
      </ul>
    </ContentShadow>
  )
}
