import { getAppAccess } from '@sylveraio/auth-utils'
import type { TierType, NextTierType, UserDetails } from 'types'
import type { TransformDataProps } from './types'

export const defaultUserDetailsResponse: UserDetails = {
  userId: '',
  email: '',
  familyName: '',
  givenName: '',
  organisation: '',
  organisationId: '',
  initials: '',
  subscription: {
    projects: [],
  },
  appCapabilities: [],
  apps: {},
  currentTier: 'Business',
  nextTier: 'Enterprise',
}

export function transformUserData(
  user?: TransformDataProps['user'],
  organisation?: TransformDataProps['organisation'],
): UserDetails {
  let name,
    organisationId = ''

  if (!user) return defaultUserDetailsResponse

  const {
    id: userId,
    familyName,
    givenName,
    subscription,
    emailAddress: email,
    access,
  } = user

  if (organisation) {
    name = organisation?.name
    organisationId = organisation?.id
  }

  const initials = `${givenName.charAt(0)}${familyName.charAt(0)}`.toUpperCase()

  return {
    ...defaultUserDetailsResponse,
    userId,
    familyName,
    givenName,
    subscription,
    // @ts-ignore
    organisation: name,
    organisationId,
    email,
    initials,
    appCapabilities: getAppAccess(access),
    featureAccess: access.features,
    apps: access?.apps,
    currentTier: access.currentTier
      ? (`${access.currentTier
          .charAt(0)
          .toUpperCase()}${access.currentTier.slice(1)}` as TierType)
      : undefined,
    nextTier: access.nextTier
      ? (`${access.nextTier.charAt(0).toUpperCase()}${access.nextTier.slice(
          1,
        )}` as NextTierType)
      : undefined,
  }
}

export default transformUserData
