import clsx from 'clsx'
import type { FC } from 'react'
import type { SwitchProps } from './types'
import { Typography } from '../Typography'

export const Switch: FC<SwitchProps> = ({
  testId = 'switch',
  checked = false,
  label,
  onChange,
}) => (
  <label
    data-testid={`${testId}-wrapper`}
    className="flex cursor-pointer items-center justify-between gap-x-2"
  >
    <input
      type="checkbox"
      data-testid="default-switch"
      className="sr-only"
      checked={checked}
      onChange={onChange}
    />

    {label && (
      <Typography size="xs" weight="medium" className="mr-2">
        {label}
      </Typography>
    )}
    <div
      data-testid={testId}
      className={clsx('flex items-center w-8 h-4 rounded-full p-0.5', {
        'bg-content-action-light': !checked,
        'bg-content-action-default': checked,
      })}
    >
      <div
        data-testid={`${testId}-dot`}
        className={clsx(
          'w-3 h-3 rounded-full transition-transform drop-shadow-md',
          {
            'bg-content-action-default': !checked,
            'bg-content-action-on-default translate-x-4': checked,
          },
        )}
      />
    </div>
  </label>
)
