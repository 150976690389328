export const COLORS: Record<
  string,
  { backgroundColor: string; labelColor: string; buttonColor?: string }
> = {
  PURPLE: {
    backgroundColor: 'bg-content-action-default',
    labelColor: 'text-content-action-dark',
  },
  FOREST: {
    backgroundColor: 'bg-content-discovery-on-default',
    labelColor: 'text-default',
  },
  'OFF-WHITE': {
    backgroundColor: 'bg-default',
    buttonColor: 'bg-content-discovery-on-default',
    labelColor: 'text-default',
  },
}
