import { FC } from 'react'
import clsx from 'clsx'

import type APIProps from './types'

const API: FC<APIProps> = ({ size = 16, className }) => (
  <svg
    width="15"
    height="38"
    viewBox="0 0 15 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current stroke-current', className)}
  >
    <path
      d="M7.51282 36.4526L7.51282 31.3613C10.5897 31.0876 13 28.3504 13 24.9562L13 22.5474C13 22.219 12.7949 22 12.4872 22L1.51282 22C1.20513 22 0.999999 22.219 0.999999 22.5474L0.999999 24.9562C1 28.2956 3.41025 31.0328 6.48718 31.3613L6.48718 36.4526C6.48718 36.781 6.69231 37 7 37C7.30769 37 7.51282 36.781 7.51282 36.4526ZM2.02564 24.9562L2.02564 23.0949L11.9744 23.0949L11.9744 24.9562C11.9744 27.8577 9.71795 30.2664 7 30.2664C4.28205 30.2664 2.02564 27.9124 2.02564 24.9562Z"
      strokeWidth="0.5"
    />
    <path
      d="M9.94444 19.4428C9.94444 19.7771 10.1667 20 10.5 20C10.8333 20 11.0556 19.7771 11.0556 19.4428L11.0556 16.2669L13.4444 16.2669C13.7778 16.2669 14 16.044 14 15.7097L14 13.2581C14 9.85924 11.3889 7.07331 8.05556 6.739L8.05556 1.55719C8.05556 1.22287 7.83333 1 7.5 1C7.16667 1 6.94444 1.22287 6.94444 1.55719L6.94444 6.739C3.61111 7.01759 1 9.80352 1 13.2581L1 15.7097C1 16.044 1.22222 16.2669 1.55556 16.2669L3.94444 16.2669L3.94444 19.4428C3.94444 19.7771 4.16667 20 4.5 20C4.83333 20 5.05556 19.7771 5.05556 19.4428L5.05556 16.2669L10 16.2669L10 19.4428L9.94444 19.4428ZM2.05556 15.2082L2.05556 13.3138C2.05556 10.3607 4.5 7.90909 7.44444 7.90909C10.3889 7.90909 12.8333 10.3607 12.8333 13.3138L12.8333 15.2082L2.05556 15.2082Z"
      strokeWidth="0.5"
    />
  </svg>
)

export default API
