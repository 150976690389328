'use client'

import { RefObject, useCallback, useRef, useState } from 'react'

export function useHookWithRefCallback<
  T = HTMLDivElement | HTMLButtonElement,
>(): [RefObject<T>, (node: any) => void, boolean] {
  const ref = useRef<T>(null)
  const [hasRef, setHasRef] = useState(false)
  // @ts-ignore
  const setRef = useCallback((node) => {
    // @ts-ignore
    ref.current = node
    setHasRef(true)
  }, [])

  return [ref, setRef, hasRef]
}

export default useHookWithRefCallback
