import clsx from 'clsx'
import { FC } from 'react'
import IconsProps from '../types'

const Close: FC<IconsProps> = ({ size, className }) => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: 'auto' }}
    className={clsx('fill-current', className)}
  >
    <path d="M5.76263 0L3.5 2.76956L1.23737 0H0.176768L2.98737 3.39348L0 7H1.06061L3.5 4.01739L5.93939 7H7L4.01263 3.39348L6.82323 0H5.76263Z" />
  </svg>
)

export default Close
