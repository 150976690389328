import type { CSSProperties } from 'react'
import type { ViewState } from 'react-map-gl'
import type { ZoomSettings } from './types'

export const INITIAL_VIEW_STATE: ViewState = {
  latitude: 25,
  longitude: 0,
  zoom: 0.6,
  bearing: 0,
  pitch: 0,
  padding: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

export const DEFAULT_ZOOM_SETTINGS: ZoomSettings = {
  minZoom: 0.6,
  maxZoom: 5,
}

export const DEFAULT_ATTRIBUTION =
  '<a href="https://www.sylvera.com" target="_blank">&copy; Sylvera</a>'

export const DEFAULT_ATTRIBUTION_STYLING: CSSProperties = {
  fontSize: 10,
  position: 'absolute',
  bottom: 24,
  right: 0,
  width: 310,
  background: 'none',
}

export const DEFAULT_LOGO_POSITION = 'bottom-right'
