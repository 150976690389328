const noEmailInbox: React.ReactNode = (
  <p className="text-sm text-default-on-dark">
    If you don&apos;t see the email in your inbox, check your spam folder or{' '}
    <a href="mailto:contact@sylvera.io">contact@sylvera.io</a>
  </p>
)

export default {
  noEmailInbox,
}
