import { useEffect } from 'react'

/**
 * A React hook that conditionally applies a `display: flex` style to the first
 * element with the class `.slick-track`. This hook allows for enabling or
 * disabling the enhancement based on the provided `enabled` flag.
 *
 * @param {boolean} enabled A flag that determines whether the enhancement
 * should be applied. When `true`, the hook searches for the first `.slick-track`
 * element and applies `display: flex` to it. When `false`, no action is taken.
 * Any change to this flag will re-trigger the effect.
 *
 * @returns {null} This hook does not return anything. It performs a side effect
 * to manipulate the DOM directly based on the `enabled` state.
 *
 * @example
 * // To enable the slick track enhancement:
 * useEnhanceSlickTrack(true);
 *
 * @example
 * // To disable the slick track enhancement (no effect in this case as the hook
 * // does not revert styles when disabled, but could be used for conditional logic):
 * useEnhanceSlickTrack(false);
 */
export function useEnhanceSlickTrack(enabled: boolean) {
  useEffect(() => {
    if (enabled) {
      const slickTrack = document.querySelector<HTMLDivElement>('.slick-track')

      if (slickTrack) {
        slickTrack.style.display = 'flex'
      }
    }
  }, [enabled])

  return null
}
