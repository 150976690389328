'use client'

import { fetchAuthenticated } from '@sylveraio/auth-utils/server'
import { getDefaultHeaders } from '@sylveraio/constants'
import { deserializeResponse } from '@sylveraio/react-utils/server'
import { addBreadcrumb } from '@sentry/nextjs'

/**
 * Fetches data from an endpoint and deserializes it.
 *
 * @template T The type of the data to be returned.
 * @template D The type of the data to be deserialized.
 * @param {string} endpoint The endpoint to fetch data from.
 * @param {any} [transformFn] A function to transform the data before deserializing.
 * @param {boolean} [deserializeInTransform] Whether to deserialize the data in the transform function.
 * @param {any} [deserializeOptions] Options for deserializing the data.
 * @param {('GET'|'PATCH'|'DELETE')} [method='GET'] The HTTP method to use when fetching the data.
 * @param {Record<string, unknown>} [bodyObj] An object containing the body of the request.
 * @param {boolean} [rawResponse] Whether to return the raw response instead of the deserialized data.
 * @returns {Promise<T|D>|Promise<void>} The deserialized data or void if the method is PATCH or DELETE.
 */
export async function fetchAndDeserialize<T, D>(
  endpoint: string,
  transformFn?: any,
  deserializeInTransform?: boolean,
  deserializeOptions?: any,
  method?: 'GET',
  bodyObj?: Record<string, unknown>,
  rawResponse?: boolean,
  overrideDefaultHeaders?: {
    'Sylvera-Version'?: '2023-08-01'
  },
): Promise<T | D>
export async function fetchAndDeserialize(
  endpoint: string,
  transformFn?: any,
  deserializeInTransform?: boolean,
  deserializeOptions?: any,
  method?: 'PATCH' | 'DELETE',
  bodyObj?: Record<string, unknown>,
  rawResponse?: boolean,
  overrideDefaultHeaders?: {
    'Sylvera-Version'?: '2023-08-01'
  },
): Promise<void>
export async function fetchAndDeserialize<T, D>(
  endpoint: string,
  transformFn?: any,
  deserializeInTransform?: boolean,
  deserializeOptions?: any,
  method = 'GET',
  bodyObj?: Record<string, unknown>,
  rawResponse?: boolean,
  overrideDefaultHeaders?: {
    'Sylvera-Version'?: '2023-08-01'
  },
): Promise<T | D | void> {
  const response = await fetchAuthenticated(
    `${process.env.NEXT_PUBLIC_API_URL}${endpoint}`,
    {
      method,
      headers: {
        ...getDefaultHeaders(),
        ...overrideDefaultHeaders,
      },
      body: bodyObj ? JSON.stringify(bodyObj) : undefined,
    },
  )

  if (method === 'PATCH' || method === 'DELETE') {
    return Promise.resolve()
  }

  const JSONresponse = await response.json()

  if (!response.ok) {
    addBreadcrumb({
      message: 'Response not ok',
      data: {
        status: response.status,
        message: JSONresponse?.message,
        statusText: response.statusText,
      },
    })
    const error = (JSONresponse && JSONresponse.message) || response.status
    return Promise.reject(error)
  }

  if (rawResponse) {
    return JSONresponse
  }

  if (deserializeInTransform && typeof transformFn !== 'undefined') {
    return await transformFn(JSONresponse)
  }

  const deserializedResponse = await deserializeResponse<D>(
    JSONresponse,
    deserializeOptions,
  )

  if (!transformFn) {
    return deserializedResponse
  }

  return transformFn(deserializedResponse)
}
