import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type Countries = {
  countries: {
    nodes: Array<{ id: string; name: string }>
  }
}
export const GET_COUNTRIES: TypedDocumentNode<
  Countries,
  {
    first: number
  }
> = gql`
  query Countries($first: Int!) {
    countries(first: $first, orderBy: NAME_ASC) {
      nodes {
        id
        name
      }
    }
  }
`
