import { addBreadcrumb } from '@sentry/nextjs'
import { getBearerToken } from '../getBearerToken'

export async function checkUserSession(): Promise<boolean> {
  addBreadcrumb({
    message: 'Checking user session',
  })

  const isValidSession = await getBearerToken()

  if (isValidSession) {
    addBreadcrumb({
      message: 'Valid user session found',
    })

    return true
  }

  addBreadcrumb({
    message: 'No valid user session',
  })

  return false
}
