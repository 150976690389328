'use client'
import { FC, memo } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { NavItemProps } from './types'
import { sendAnalyticsEvent } from '@sylveraio/react-utils/server'
import { Label } from '@sylveraio/design-system/server'

const NavItem: FC<NavItemProps> = memo(
  ({ id, tabIndex, href, active, children, count }) => {
    const handleNavItemClick = () => {
      sendAnalyticsEvent({
        eventName: 'navigation-navigation-click',
        eventData: {
          navigation_url: href,
        },
      })
    }

    return (
      <Link href={href} legacyBehavior>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          id={id}
          role="button"
          tabIndex={tabIndex}
          className="font-semibold text-default"
          onClick={() => handleNavItemClick()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNavItemClick()
            }
          }}
        >
          <div className="flex flex-row items-center space-x-1">
            <span
              id={id}
              className={clsx({
                'opacity-60': !active,
              })}
            >
              {children}
            </span>
            {typeof count === 'number' && (
              <Label color="neutral" size="sm" label={count.toString()} />
            )}
          </div>
        </a>
      </Link>
    )
  },
)

export default NavItem
