import type { SylveraScoreSizes, SylveraScoreSizesMap } from './types'
import type { BadgeSize } from '../Badge/types'

export const DEFAULT_SCORE_SIZE: SylveraScoreSizes = 'lg'

export const globalSylveraScoreSize: SylveraScoreSizesMap = {
  xs: 'w-[32px] h-[32px]',
  sm: 'w-[48px] h-[48px]',
  md: 'w-[52px] h-[52px]',
  lg: 'w-[64px] h-[64px]',
  xl: 'w-[72px] h-[72px]',
  '2xl': 'w-[80px] h-[80px]',
  '3xl': 'w-[152px] h-[152px]',
}

export const globalSylveraScorePadding: Partial<SylveraScoreSizesMap> = {
  sm: 'p-[2px]',
  md: 'p-[2px]',
  lg: 'p-[3px]',
  xl: 'p-[3px]',
  '2xl': 'p-[4px]',
  '3xl': 'p-[8px]',
}

export const provisionalRatingBadgePosition: Partial<{
  [x in SylveraScoreSizes]: string
}> = {
  sm: 'mt-px mr-px',
  md: 'mt-0.5 mr-0.5',
  lg: 'mt-1 mr-1',
  xl: 'mt-[6.5px] mr-[6.5px]',
  '2xl': 'mt-1.5 mr-1.5',
  '3xl': 'mt-4 mr-4',
}

export const provisionalBadgeSizes: Partial<{
  [x in SylveraScoreSizes]: BadgeSize
}> = {
  xs: 'xs',
  sm: 'sm',
  md: 'sm',
  lg: 'sm',
  xl: 'sm',
  '2xl': 'md',
  '3xl': 'lg',
}
