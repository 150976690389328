import type { FontSizes } from '../../../Typography/types'
import { SylveraScoreSizesMap } from '../../types'

export const sylveraRatingPadding: {
  outer: SylveraScoreSizesMap
  middle: SylveraScoreSizesMap
} = {
  outer: {
    xs: 'p-[2px]',
    sm: 'p-[2.4px]',
    md: 'p-[3px]',
    lg: 'p-[3px]',
    xl: 'p-[4.52px]',
    '2xl': 'p-[5px]',
    '3xl': 'p-[9px]',
  },
  middle: {
    xs: 'p-[1.14px]',
    sm: 'p-[1.8px]',
    md: 'p-[1.9px]',
    lg: 'p-[2.28px]',
    xl: 'p-[2.5px]',
    '2xl': 'p-[3px]',
    '3xl': 'p-[4px]',
  },
}

export const sylveraRatingTextVariant: {
  [x in keyof SylveraScoreSizesMap]: FontSizes
} = {
  xs: 'xs',
  sm: 'sm',
  md: 'sm',
  lg: 'base',
  xl: 'base',
  '2xl': 'xl',
  '3xl': '2xl',
}
