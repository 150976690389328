import useSWR from 'swr'
import { setUser } from '@sentry/nextjs'
import { UseUser } from './types'
import { useCallback, useEffect } from 'react'
import { AppName } from 'types'
import isEmpty from 'lodash/isEmpty'
import { getUserDetails } from '../services'
import { usePrevious } from '@sylveraio/react-utils'
import isEqual from 'lodash/isEqual'

/**
 * Attempts to fetch the user details
 */
export function useUser(isProtectedRoute = true): UseUser {
  const { data, isValidating, isLoading, error, mutate } = useSWR(
    isProtectedRoute ? '/user' : null,
    getUserDetails,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  )
  const prevData = usePrevious(data)

  useEffect(() => {
    if (!prevData || isEqual(prevData, data) || isValidating) return

    if (data?.userId && !isValidating) {
      setUser({
        id: data.userId,
      })
    }
  }, [isValidating, prevData, data])

  const hasAccess = useCallback(
    (appName: AppName) => {
      if (typeof data?.apps === 'undefined') return false
      return data.apps[appName] || false
    },
    [data?.apps],
  )

  const hasAccessToCurrent: boolean | undefined = data
    ? typeof process.env['NEXT_PUBLIC_APP_NAME'] === 'undefined' ||
      // @ts-ignore
      data.apps[process.env['NEXT_PUBLIC_APP_NAME']]
    : undefined

  return {
    data,
    isValidating,
    isLoading,
    error,
    mutate,
    hasAccess,
    hasAccessToCurrent,
    isFetching: (isLoading || isValidating) && !data,
    hasData: !isEmpty(data?.userId),
  }
}
