import type { AppName } from 'types'

export function getAppAccess(
  userAppAccess: Api.User['access'],
): Array<AppName> {
  const arr: Array<AppName> = []

  if (userAppAccess.apps.analytics) arr.push('analytics')
  if (userAppAccess.apps.ratings) arr.push('ratings')

  return arr
}
