import QueryString from 'qs'
import isEmpty from 'lodash/isEmpty'
import {
  getPolicies,
  getPolicy,
  getPolicyTags,
  getRetirementsTrendsData,
  getPublicRatingsData,
  getPrivateRatingsData,
  getRssFeed,
} from './services'
import { escapeFromRight, wrapObjectValues } from '@sylveraio/react-utils'

export type FetcherKey =
  | '/policy'
  | '/policies'
  | '/policyTags'
  | '/retirementsTrends'
  | '/publicRatings'
  | '/privateRatings'
  | '/rss'

export function fetcher([key, ids, params]: [
  FetcherKey,
  Array<string> | undefined,
  Record<string, unknown> | undefined,
]) {
  let _params
  if (
    (key === '/retirementsTrends' || key === '/rss') &&
    typeof params === 'object'
  ) {
    _params = QueryString.stringify(
      escapeFromRight(wrapObjectValues(params, 'filter')),
      {
        arrayFormat: 'comma',
        encode: false,
        indices: false,
      },
    )
  } else if (!isEmpty(params)) {
    _params = QueryString.stringify(params)
  }

  switch (key) {
    case '/policies':
      return getPolicies(_params)
    case '/policy':
      if (!ids?.length) return {}
      return getPolicy(ids[0])
    case '/policyTags':
      return getPolicyTags()
    case '/retirementsTrends':
      return getRetirementsTrendsData(_params)
    case '/publicRatings':
      return getPublicRatingsData(_params)
    case '/privateRatings':
      return getPrivateRatingsData(ids, _params)
    case '/rss':
      return getRssFeed(_params)
    default:
      return {}
  }
}
