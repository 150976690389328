/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FC, useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
  getPaginationRowModel,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { Pagination } from '../Pagination'
import { SortArrowCellBorder } from './components/SortArrowCellBorder'
import type { ColumnDef, SortingState } from '@tanstack/react-table'
import type { TableDataProps } from './types'
import type { RowCount } from '../Pagination/types'
import { shouldRenderCellContent } from './utils'
import { SortComponent } from './components/SortComponent'
import { RATINGS_ARRAY } from './constants'
import { Spinner } from '../Spinner'
import { sortByLockAndValue } from './sorting/sortByLockAndValue'

export const Table: FC<TableDataProps> = ({
  testId = 'table',
  dataset,
  columns,
  divided,
  headerBreak,
  tableClassName,
  dividedClassName,
  headerClassName,
  rowClassName,
  specificRowClassName,
  loading = false,
  className,
  selectedRow,
  colorScheme,
  customStringify,
  columnClassName,
  sortControls,
  defaultRowCount = 10,
  paginationControls,
  spinnerClassName,
  totalCount,
  totalCountLabel,
  noDataUI,
  reducedStylingLastColumn,
}) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const columnHelper = createColumnHelper<Record<string, any>>()
  const [formattedColumns, setFormattedColumns] = useState<
    Array<ColumnDef<Record<string, any>>>
  >([])
  const [hiddenColumns, setHiddenColumns] = useState<Record<string, boolean>>(
    {},
  )
  const [hoveredHeader, setHoveredHeader] = useState<string | null>(null)
  const [hoveredRow, setHoveredRow] = useState<string | null>(null)

  useEffect(() => {
    setFormattedColumns(
      columns.map((column) => {
        return columnHelper.accessor(column.datasetKey, {
          cell: (info) => {
            if (column.Cell) return column.Cell(info)

            if (customStringify && customStringify[info.column.id]) {
              const fn = customStringify[info.column.id]
              return fn(info.getValue())
            }

            return info.getValue() || '-'
          },
          footer: (info) => info.column.id,
          header: column.title || column.header || '',
          enableSorting: column.sortable ?? false,
          sortDescFirst: column.defaultSortDirection === 'desc',
          // @ts-ignore
          sortingFn: column.sortingFn ?? 'basic',
          size: column.size,
          meta: {
            showCellContentOnHover: column?.showCellContentOnHover ?? false,
          },
        })
      }),
    )

    // So that sorting is active from the initial state this disables the three state sorting
    setSorting(
      columns.reduce<SortingState>((acc, cv) => {
        if (cv.initialSort)
          acc.push({
            id: cv.datasetKey,
            desc: true,
          })
        return acc
      }, []),
    )

    setHiddenColumns(
      columns
        .filter((col) => col?.hideColumn)
        .reduce((obj, key) => ({ ...obj, [key.datasetKey]: false }), {}),
    )
  }, [columns])

  useEffect(() => {
    if (
      typeof sortControls?.onSortChange === 'undefined' ||
      sorting.length === 0
    )
      return
    sortControls.onSortChange?.(sorting)
  }, [sorting])

  const table = useReactTable({
    data: dataset,
    columns: formattedColumns,
    state: {
      sorting,
      // If you set a column called _id, it won't show in the table
      columnVisibility: {
        _id: false,
        ...hiddenColumns,
      },
    },
    manualSorting: sortControls?.manualSorting,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    enableSortingRemoval: false,
    sortingFns: {
      customRatingSorting: (rowA: any, rowB: any, columnId: any): number => {
        return RATINGS_ARRAY.indexOf(rowA.getValue(columnId)) >
          RATINGS_ARRAY.indexOf(rowB.getValue(columnId))
          ? -1
          : RATINGS_ARRAY.indexOf(rowA.getValue(columnId)) <
            RATINGS_ARRAY.indexOf(rowB.getValue(columnId))
          ? 1
          : 0
      },
      customSortingWithLocked: sortByLockAndValue,
    },
  })

  useEffect(() => {
    table.setPageSize(defaultRowCount)
  }, [defaultRowCount])

  const hasColumnClassName = (
    id: string,
    element: 'th' | 'td',
    level: 'outer' | 'inner',
  ) =>
    !!columnClassName &&
    !!columnClassName[id]?.[element] &&
    !!columnClassName[id]?.[element]?.[level]

  return (
    <div className={clsx(className, 'relative')}>
      <table data-testid={testId} className={clsx('w-full', tableClassName)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={clsx(
                {
                  'border-b border-strong': !headerBreak,
                },
                headerClassName,
              )}
            >
              {headerGroup.headers.map((header, idx: number, arr) => {
                const isLast = arr.length - 1 === idx
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.column.columnDef.size }}
                    onMouseEnter={() => setHoveredHeader(header?.id)}
                    onMouseLeave={() => setHoveredHeader(null)}
                    className={clsx(
                      'pb-1 text-sm font-medium text-left first:pl-2 last:pr-2 !min-w-fit align-bottom',
                      {
                        [`${columnClassName?.[header.id]?.th?.outer}`]:
                          hasColumnClassName(header.id, 'th', 'outer'),
                      },
                    )}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          className={clsx(
                            'flex space-x-1 items-center',
                            {
                              'cursor-pointer select-none':
                                header.column.getCanSort(),
                            },
                            {
                              [`${columnClassName?.[header.id]?.th?.inner}`]:
                                hasColumnClassName(header.id, 'th', 'inner'),
                            },
                            {
                              'border-primary-900 border-b-2 mr-1':
                                headerBreak &&
                                sortControls?.sortArrowVariant !==
                                  'borderArrow',
                            },
                            {
                              'border-none': isLast && reducedStylingLastColumn,
                            },
                          )}
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {header.column.getCanSort() && (
                            <SortComponent
                              isHovered={hoveredHeader === header.id}
                              defaultSortDirection={
                                header?.column?.columnDef?.sortDescFirst
                                  ? 'desc'
                                  : 'asc'
                              }
                              sortControls={sortControls}
                              appliedSort={sorting}
                              headerId={header.id}
                            />
                          )}
                        </div>
                        {sortControls?.sortArrowVariant === 'borderArrow' &&
                          typeof sortControls !== undefined &&
                          typeof sortControls?.sortField !== undefined && (
                            <div className="relative mt-4">
                              <SortArrowCellBorder
                                isSorted={
                                  header.column.id === sortControls.sortField
                                }
                                sortDirection={sortControls?.sortDirection}
                              />
                            </div>
                          )}
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className="relative w-full">
          {!loading &&
            table.getRowModel().rows.map((row, idx) => {
              let k
              try {
                if (selectedRow?.key && selectedRow?.value)
                  k = row.getValue(selectedRow.key)
              } catch (e) {
                // blah
              }
              const color = idx % 2 !== 0 && divided ? '#FBFAF7' : undefined
              return (
                <tr
                  key={row.id}
                  style={{ backgroundColor: color }}
                  onMouseEnter={() => setHoveredRow(row?.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  data-row-id={
                    typeof selectedRow?.value !== 'undefined' &&
                    typeof selectedRow?.key !== 'undefined'
                      ? row?.getValue(selectedRow.key)
                      : ''
                  }
                  className={clsx(
                    'text-xs font-medium  last:pr-2',
                    {
                      [`${selectedRow?.className ?? 'bg-high'}`]:
                        typeof k !== 'undefined' && k === selectedRow?.value,
                      [`cursor-pointer ${
                        selectedRow?.hoverClassName ?? 'hover:bg-high-hover'
                      }`]: selectedRow?.onClick !== undefined,
                    },
                    rowClassName,
                    specificRowClassName?.find(({ key }) => row.original[key])
                      ?.className,
                  )}
                  onClick={() => {
                    if (typeof selectedRow?.key === 'undefined') return

                    let value = ''

                    try {
                      value = row.getValue(selectedRow.key)
                    } catch (e) {
                      // ble
                    }

                    if (value) {
                      // eslint-disable-next-line consistent-return
                      return selectedRow?.onClick(value, row.original)
                    }

                    return
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const renderCellContent = shouldRenderCellContent(
                      cell.column.columnDef?.meta?.showCellContentOnHover,
                      row.id,
                      hoveredRow,
                    )
                    return (
                      <td
                        key={cell.id}
                        style={{ width: cell.column.columnDef.size }}
                        className={clsx(
                          'h-10 first:pl-2 last:pr-2',
                          {
                            'border-b border-text-default/20': divided,
                            [`${columnClassName?.[cell.column.id]?.td?.outer}`]:
                              hasColumnClassName(cell.column.id, 'td', 'outer'),
                          },
                          { 'pr-2': headerBreak },
                          {
                            'last:pl-3 last:bg-high last:border-none':
                              reducedStylingLastColumn,
                          },
                          dividedClassName,
                        )}
                      >
                        <div
                          className={clsx({
                            [`${columnClassName?.[cell.column.id]?.td?.inner}`]:
                              hasColumnClassName(cell.column.id, 'td', 'inner'),
                          })}
                        >
                          {renderCellContent &&
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
      {(typeof dataset === 'undefined' || dataset.length === 0) &&
        !loading &&
        noDataUI}
      {loading && (
        <div
          className={clsx(
            'absolute flex items-center justify-center w-full pt-10 min-h-[350px] top-0 bg-high',
            {
              visible: loading,
              invisible: !loading,
            },
            spinnerClassName,
          )}
        >
          <Spinner
            className={clsx({
              'text-content-action-default': colorScheme === 'purple',
              'text-secondary-mauve': colorScheme === 'mauve',
              'text-secondary-water': colorScheme === 'blue',
              'text-secondary-gold': colorScheme === 'lemon',
            })}
            testId="table-loading-spinner"
          />
        </div>
      )}
      {!loading &&
        ((typeof totalCount !== 'undefined' && totalCount > 0) ||
          typeof paginationControls !== 'undefined') && (
          <div className="flex items-center mt-4">
            {typeof totalCount !== 'undefined' && totalCount > 0 && (
              <span className="mx-2 mr-auto text-sm">
                {totalCountLabel ?? <>Total: {totalCount}</>}
              </span>
            )}
            {typeof paginationControls !== 'undefined' && (
              <Pagination
                totalPages={paginationControls['totalPages']}
                rowsPerPage={table.getState().pagination.pageSize as RowCount}
                showPageCount={paginationControls?.['showPageCount']}
                showPrevNextButtons={
                  paginationControls?.['showPrevNextButtons']
                }
                showFirstLastButtons={
                  paginationControls?.['showFirstLastButtons']
                }
                showRowsDropdown={paginationControls?.['showRowsDropdown']}
                onChangeRowCount={
                  typeof paginationControls?.['onChangeRowCount'] !==
                  'undefined'
                    ? paginationControls['onChangeRowCount']
                    : (rowCount) => table.setPageSize(rowCount)
                }
                currentPage={
                  typeof paginationControls?.['currentPage'] !== 'undefined'
                    ? paginationControls['currentPage']
                    : table.getState().pagination.pageIndex + 1
                }
                prevPageDisabled={
                  typeof paginationControls?.['prevPageDisabled'] !==
                  'undefined'
                    ? paginationControls['prevPageDisabled']
                    : !table.getCanPreviousPage()
                }
                nextPageDisabled={
                  typeof paginationControls?.['nextPageDisabled'] !==
                  'undefined'
                    ? paginationControls['nextPageDisabled']
                    : !table.getCanNextPage()
                }
                onNextPage={
                  typeof paginationControls?.['onNextPage'] !== 'undefined'
                    ? paginationControls['onNextPage']
                    : table.nextPage
                }
                onPrevPage={
                  typeof paginationControls?.['onPrevPage'] !== 'undefined'
                    ? paginationControls['onPrevPage']
                    : table.previousPage
                }
                onFirstPage={
                  typeof paginationControls?.['onFirstPage'] !== 'undefined'
                    ? paginationControls['onFirstPage']
                    : () => table.setPageIndex(0)
                }
                onLastPage={
                  typeof paginationControls?.['onLastPage'] !== 'undefined'
                    ? paginationControls['onLastPage']
                    : () => table.setPageIndex(table.getPageCount() - 1)
                }
              />
            )}
          </div>
        )}
    </div>
  )
}
