import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { ConditionalDivButton } from '../../../ConditionalDivButton'
import type { ProgressRingSegmentedProps } from './types'

const ProgressRingSVG: FC<ProgressRingSegmentedProps> = ({
  value,
  trailColor,
  size,
  innerLabel,
}) => {
  const [stepCount, setStepCount] = useState<number>(0)

  useEffect(() => {
    if (typeof value === 'undefined') return
    setStepCount((typeof value === 'string' ? parseInt(value, 10) : value) / 20)
  }, [value])

  const trailColorClass =
    (trailColor ?? '').toLowerCase() === '#ffffff'
      ? 'fill-white'
      : 'fill-black-50'

  return (
    <div className="relative">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 51 51"
      >
        <path
          id="s1"
          className={clsx({
            [trailColorClass]: stepCount < 1,
            'fill-primary-500': stepCount >= 1,
          })}
          d="M49.6,19.4c0.5-0.1,0.9,0.1,1,0.6c1.1,4.7,0.8,9.6-0.7,14.1c-1.6,4.5-4.4,8.5-8.1,11.5
        c-0.4,0.3-0.9,0.2-1.2-0.2l-1.3-1.7c-0.3-0.4-0.2-0.9,0.2-1.2c3.1-2.5,5.5-5.9,6.8-9.7c1.3-3.8,1.6-7.9,0.7-11.9
        c-0.1-0.5,0.2-0.9,0.6-1L49.6,19.4z"
        />
        <path
          id="s2"
          className={clsx({
            [trailColorClass]: stepCount < 2,
            'fill-primary-500': stepCount >= 2,
          })}
          d="M38.5,46c0.3,0.4,0.1,0.9-0.3,1.2c-4.1,2.5-8.8,3.7-13.6,3.6c-4.8-0.1-9.5-1.6-13.5-4.2
        c-0.4-0.3-0.5-0.8-0.2-1.2l1.2-1.7c0.3-0.4,0.8-0.5,1.2-0.2c3.4,2.2,7.3,3.4,11.3,3.5c4,0.1,8-1,11.5-3c0.4-0.2,0.9-0.1,1.2,0.3
        L38.5,46z"
        />
        <path
          id="s3"
          className={clsx({
            [trailColorClass]: stepCount < 3,
            'fill-primary-500': stepCount >= 3,
          })}
          d="M9.6,44.5c-0.3,0.4-0.8,0.4-1.2,0.1c-3.6-3.1-6.3-7.3-7.6-11.9c-1.4-4.6-1.4-9.5-0.1-14.1
        c0.1-0.5,0.6-0.7,1.1-0.6l2,0.6c0.4,0.1,0.7,0.6,0.6,1.1c-1.1,3.9-1,8,0.1,11.9c1.2,3.9,3.4,7.3,6.4,10c0.4,0.3,0.4,0.8,0.1,1.2
        L9.6,44.5z"
        />
        <path
          id="s4"
          className={clsx({
            [trailColorClass]: stepCount < 4,
            'fill-primary-500': stepCount >= 4,
          })}
          d="M2.2,16.8c-0.4-0.2-0.7-0.7-0.5-1.1c1.8-4.4,4.9-8.2,8.8-11C14.4,2,19,0.4,23.8,0.1
        c0.5,0,0.9,0.4,0.9,0.8l0,2.1c0,0.5-0.4,0.9-0.8,0.9c-4,0.2-7.9,1.6-11.2,3.9c-3.3,2.3-5.9,5.5-7.5,9.2c-0.2,0.4-0.7,0.7-1.1,0.5
        L2.2,16.8z"
        />
        <path
          id="s5"
          className={clsx({
            [trailColorClass]: stepCount < 5,
            'fill-primary-500': stepCount >= 5,
          })}
          d="M26.3,1c0-0.5,0.4-0.8,0.9-0.8c4.8,0.4,9.3,2.1,13.2,5c3.8,2.9,6.8,6.8,8.5,11.3
        c0.2,0.4-0.1,0.9-0.5,1.1l-2,0.7c-0.4,0.2-0.9-0.1-1.1-0.5c-1.5-3.8-3.9-7-7.2-9.5C34.8,5.8,31,4.3,27,4c-0.5,0-0.8-0.4-0.8-0.9
        L26.3,1z"
        />
      </svg>
      <div
        className={clsx(
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-content-action-dark progress-ring-rating-text',
          {
            'text-lg': size === 'sm',
            'text-xl': size === 'md',
            'text-[32px]': size === 'lg',
            'text-[40px]': size === 'xl',
          },
        )}
      >
        {innerLabel}
      </div>
    </div>
  )
}

export const ProgressRingSegmented: FC<ProgressRingSegmentedProps> = ({
  testId = 'progress-ring-segmented',
  ...props
}) => {
  return (
    <ConditionalDivButton
      testId={testId}
      onClick={props.onClick}
      className={clsx(
        {
          'h-16 w-16': props.size === 'sm',
          'h-[76px] w-[76px]': props.size === 'md',
          'h-[90px] w-[90px]': props.size === 'lg',
          'h-[134px] w-[134px]': props.size === 'xl',
        },
        'select-none ',
      )}
    >
      <ProgressRingSVG {...props} />
    </ConditionalDivButton>
  )
}

export default ProgressRingSegmented
