import { FC, useEffect, useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { ColoredTableDataProps } from './types'
import { COLORS_SCHEME } from './constants'
import SwitchVertical01 from '@sylveraio/untitled-ui-icons-react/build/esm/SwitchVertical01'

export const ColoredTable: FC<ColoredTableDataProps> = ({
  testId = 'colored-table',
  dataset,
  columns,
  className,
  colorScheme = ['purple', 'mauve'],
  hideTableHead,
}) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const columnHelper = createColumnHelper<Record<string, any>>()
  const [formattedColumns, setFormattedColumns] = useState<
    Array<ColumnDef<Record<string, any>>>
  >([])

  useEffect(() => {
    setFormattedColumns(
      columns.map((column) =>
        columnHelper.accessor(column.datasetKey, {
          cell: (info) => {
            if (column.Cell) return column.Cell(info)

            return info.getValue() || '-'
          },
          footer: (info) => info.column.id,
          header: column.title ?? column.datasetKey,
          enableSorting: column.sortable ?? false,
        }),
      ),
    )
  }, [columns])

  const table = useReactTable({
    data: dataset,
    columns: formattedColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  })

  return (
    <div className={clsx(className)}>
      <table
        data-testid={testId}
        className="w-full table-fixed border-separate border-spacing-x-0 border-spacing-y-2"
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, idx, arr) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={clsx('text-sm font-medium align-bottom ', {
                      'w-2/12 pl-4': idx === 0,
                      hidden: idx !== 0 && hideTableHead,
                    })}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={clsx('flex items-center pb-1', {
                          'cursor-pointer select-none':
                            header.column.getCanSort(),
                          'justify-end border-b-2 border-selection-forest':
                            idx !== 0,
                        })}
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <span className="min-w-0 break-words text-right leading-[14px]">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                        {header.column.getCanSort() && (
                          <SwitchVertical01 className="h-3 w-3 text-subtle" />
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className="w-full">
          {table
            .getRowModel()
            .rows.slice(0, 10)
            .map((row, idx) => {
              return (
                <tr
                  key={row.id}
                  style={{ backgroundColor: COLORS_SCHEME[colorScheme[idx]] }}
                  className="mb-10 text-xs font-medium"
                >
                  {row.getVisibleCells().map((cell, idx, arr) => {
                    return (
                      <td
                        key={cell.id}
                        className={clsx({
                          'text-right': idx !== 0,
                          'pl-4': idx === 0,
                          'pr-2.5': arr.at(-1),
                        })}
                        style={{ lineHeight: '30px' }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
