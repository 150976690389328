import React, { FC, BaseSyntheticEvent, useState, ChangeEvent } from 'react'
import { useRouter } from 'next/router'
import { routes, getAuthError } from '@sylveraio/auth-utils'
import { getInputError, sendAnalyticsEvent } from '@sylveraio/react-utils'

import {
  Form,
  FormBackLink,
  FormHeading,
  FormSubheading,
  Input,
  Button,
} from '@sylveraio/react-ui'
import { SignInFormSuccess } from '../SignInFormSuccess'
import { RequirementsTooltip } from './components/RequirementsTooltip'

import constants from './SetPasswordForm.constants'

import useSetNewUserPassword from './utils/useSetNewUserPassword'
import useResetUserPassword from './utils/useResetUserPassword'

import type {
  SetPasswordFormProps,
  SetPasswordFormInputNameTypes,
  SetPasswordFormKeys,
} from './types'

export const SetPasswordForm: FC<SetPasswordFormProps> = ({
  testId = 'set-password-form',
}) => {
  const { query } = useRouter()
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [authError, setAuthError] = useState<Error>()
  const [success, setSuccess] = useState<boolean>(false)
  const { setNewUserPassword } = useSetNewUserPassword()
  const { resetUserPassword } = useResetUserPassword()

  const onSubmit = async (
    data: SetPasswordFormKeys,
    e: BaseSyntheticEvent<object, any, any> | undefined,
  ) => {
    e?.preventDefault()
    setSubmitting(true)

    if (query['passcode']) {
      try {
        await resetUserPassword({
          passcode: query['passcode'] as string,
          password: data['set-password'],
        })
        setSuccess(true)
        sendAnalyticsEvent({ eventName: 'app-forgotten-password-success' })
      } catch (err) {
        setAuthError(err as Error)
      } finally {
        setSubmitting(false)
      }

      return
    }

    try {
      await setNewUserPassword({ password })
      setSuccess(true)
    } catch (err) {
      setAuthError(err as Error)
    } finally {
      setSubmitting(false)
    }
  }

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback?: () => void,
  ) => {
    switch (e.currentTarget.name) {
      case 'set-password':
        setPassword(e.currentTarget.value)
        break
      case 'set-password-confirm':
        setConfirmPassword(e.currentTarget.value)
        break
      default:
        break
    }
    setAuthError(undefined)
    callback?.()
  }

  const validatePasswordField = (value: string) =>
    !authError?.message && constants.passwordPatternRegex.test(value)

  const validatePasswordConfirm = (value: string) =>
    value !== '' && password !== '' && value === password

  if (success) {
    return (
      <SignInFormSuccess
        testId={`${testId}-success`}
        heading="Your password has been changed"
        buttonText="Go to Sylvera!"
        redirectUrl={routes['signIn']}
      />
    )
  }

  return (
    <Form<SetPasswordFormKeys> testId={testId} onSubmit={onSubmit}>
      {({ register, formState, clearErrors }) => (
        <>
          <FormHeading
            testId={`${testId}-heading`}
            className="mb-4 whitespace-nowrap"
          >
            Choose a new password
          </FormHeading>

          <FormSubheading testId={`${testId}-subheading`}>
            Create a new password that is at least 8 characters long.
          </FormSubheading>

          <RequirementsTooltip content={constants.requirements}>
            Password requirements
          </RequirementsTooltip>

          <div className="relative flex flex-col w-full gap-y-6">
            <Input
              testId={`${testId}-password-input`}
              type="password"
              label="New password"
              hideBorder
              success={validatePasswordField(password)}
              error={
                typeof authError !== 'undefined' ||
                getInputError<
                  SetPasswordFormKeys,
                  SetPasswordFormInputNameTypes
                >(formState.errors, 'set-password')
              }
              {...register('set-password', {
                value: password,
                required: 'Please enter a password',
                pattern: {
                  value: constants.passwordPatternRegex,
                  message: "Your password doesn't meet the requirements",
                },
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />

            <Input
              testId={`${testId}-password-confirm-input`}
              type="password"
              label="Retype new password"
              hideBorder
              success={validatePasswordConfirm(confirmPassword)}
              error={
                authError
                  ? getAuthError(authError)?.context
                  : getInputError<
                      SetPasswordFormKeys,
                      SetPasswordFormInputNameTypes
                    >(formState.errors, 'set-password-confirm')
              }
              {...register('set-password-confirm', {
                value: confirmPassword,
                validate: (value) =>
                  value === password || 'Passwords do not match',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
          </div>

          <div className="flex flex-col mt-10 mb-20 gap-y-7">
            <Button
              testId={`${testId}-submit-button`}
              type="submit"
              className="!w-[240px] !h-12 mx-auto !text-xl font-medium"
              spinner={submitting}
              solid
              color="lime"
              shape="pill"
            >
              Submit
            </Button>
          </div>

          <FormBackLink href={`${routes['forgotPassword']}?verifyPasscode=1`} />
        </>
      )}
    </Form>
  )
}

export default SetPasswordForm
