import clsx from 'clsx'
import { getTrackBackground } from 'react-range'
import type { FC } from 'react'
import type { RangeSliderTrackProps } from './types'

export const RangeSliderTrack: FC<RangeSliderTrackProps> = ({
  testId = 'range-slider-track',
  props,
  children,
  disabled,
  values,
  colors,
  min,
  max,
  rtl,
}) => (
  <div
    data-testid={testId}
    onMouseDown={props.onMouseDown}
    onTouchStart={props.onTouchStart}
    style={{
      ...props.style,
    }}
    className={clsx('flex min-w-[110px] w-full', {
      '!cursor-default': disabled,
    })}
  >
    <div
      style={{
        background: getTrackBackground({
          values,
          colors,
          min,
          max,
          rtl,
        }),
      }}
      ref={props.ref}
      className="w-full self-center h-0.5"
    >
      {children}
    </div>
  </div>
)
