import { FC, useCallback } from 'react'
import throttle from 'lodash/throttle'
import { RangeSlider } from '../RangeSlider'
import { ProgressRing } from '../ProgressRing'
import { SegmentedRangeFilterProps } from './types'
import { RANGE_MAX, RANGE_MIN } from './constants'
import { parseSegmentedScore } from '@sylveraio/react-utils'

export const SegmentedRangeFilter: FC<SegmentedRangeFilterProps> = ({
  onChange,
  filterThrottleMS = 500,
  selectedRange,
  testId = 'segmented-range-filter',
}) => {
  const [minSelected, maxSelected] = selectedRange

  const handleChange = useCallback(
    throttle(onChange, filterThrottleMS, { leading: false }),
    [onChange],
  )

  return (
    <div className="flex items-center justify-start" data-testid={testId}>
      <ProgressRing
        label="Min"
        innerLabel={minSelected}
        stroke={8}
        value={parseSegmentedScore(minSelected)}
        type="segmented"
        wrapperClassName="gap-y-1"
        labelPosition="bottom"
        labelClassName="!text-content-action-subtle"
      />
      <div className="-mt-5">
        <RangeSlider
          rtl={false}
          values={selectedRange}
          onChange={handleChange}
          min={RANGE_MIN}
          max={RANGE_MAX}
          testId={`${testId}-range`}
        />
      </div>
      <ProgressRing
        label="Max"
        innerLabel={maxSelected}
        stroke={8}
        value={parseSegmentedScore(maxSelected)}
        type="segmented"
        wrapperClassName="gap-y-1"
        labelPosition="bottom"
        labelClassName="!text-content-action-subtle"
      />
    </div>
  )
}

export default SegmentedRangeFilter
