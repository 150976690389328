import { FC } from 'react'
import { Badge } from '../../../Badge'
import clsx from 'clsx'
import type { ProvisionalBadgeProps } from './types'

export const ProvisionalBadge: FC<ProvisionalBadgeProps> = ({
  testId = 'provisional-badge',
  size,
  className,
  labelClassName,
}) => (
  <Badge
    testId={testId}
    label="P"
    size={size}
    className={clsx('!bg-new-palette-neon-blue-500 z-20', className)}
    textClassName={labelClassName}
  />
)
