'use client'

import { useMemo, type FC } from 'react'
import { ButtonProps } from './types'
import clsx from 'clsx'
import {
  getButtonAlignment,
  getButtonShape,
  getButtonSize,
  getButtonThemeStyles,
  getButtonTypographyVariant,
} from './utils'
import { IconLibrary } from '../IconLibrary'
import { TypographyLegacy } from '../TypographyLegacy'

/**
 * @deprecated This ButtonLegacy component has been deprecated and will be removed in future releases.
 * Please use Button instead.
 */

export const ButtonLegacy: FC<ButtonProps> = ({
  testId = 'button',
  type = 'button',
  shape = 'rounded',
  appearance = 'solid',
  theme = 'forest',
  size = 'lg',
  align = 'middle',
  children,
  text,
  className,
  disabled = false,
  iconPrefix,
  iconSuffix,
  onClick,
  ...attributes
}) => {
  const classes = useMemo(
    () =>
      clsx(
        'flex text-base items-center gap-x-2 text-nowrap',
        [getButtonSize(size)],
        {
          'cursor-default': disabled,
          [getButtonShape(shape)]: appearance !== 'none',
        },
        getButtonThemeStyles(appearance, theme),
        getButtonAlignment(align),
        className,
      ),
    [theme, appearance, shape, align, className, disabled, size],
  )

  return (
    <button
      data-testid={testId}
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled}
      {...attributes}
    >
      {iconPrefix?.icon && (
        <IconLibrary
          name={iconPrefix?.icon}
          size={iconPrefix?.size}
          className={clsx('text-inherit', iconPrefix?.className)}
          direction={iconPrefix?.direction}
          invert={iconPrefix?.invert}
        />
      )}
      {text && (
        <TypographyLegacy
          variant={getButtonTypographyVariant(size)}
          className="shrink-0 !leading-[0]"
        >
          {text}
        </TypographyLegacy>
      )}
      {children}
      {iconSuffix?.icon && (
        <IconLibrary
          name={iconSuffix?.icon}
          size={iconSuffix?.size}
          className={clsx('text-inherit', iconSuffix?.className)}
          direction={iconSuffix?.direction}
          invert={iconSuffix?.invert}
        />
      )}
    </button>
  )
}
