import type { Variant } from '../../../TypographyLegacy/types'
import type { ButtonSize } from '../../types'

/**
 * Gets the typography variant for a given button size
 * @param {ButtonSize} size - The size of the button
 * @returns {Variant} - The typography variant for the given button size
 */
export function getButtonTypographyVariant(size: ButtonSize): Variant {
  switch (size) {
    case 'xl':
      return 'b2'
    case 'md':
      return 'p5'
    case 'lg':
    default:
      return 'b3'
  }
}
