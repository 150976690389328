import type { FC } from 'react'
import type { ZoomProps } from './types'
import { ZoomButton } from './ZoomButton'
import clsx from 'clsx'
import { getZoomButtonSize } from './utils'
import { Typography } from '../Typography'

export const Zoom: FC<ZoomProps> = ({
  testId = 'zoom',
  onZoomIn,
  onZoomOut,
  disableZoomOut,
  disableZoomIn,
  size = 'md',
}) => (
  <div
    data-testid={testId}
    className="flex items-center justify-center w-fit gap-px text-default"
  >
    <ZoomButton
      testId={`${testId}-out`}
      type="zoomOut"
      size={size}
      onClick={onZoomOut}
      disabled={disableZoomOut}
    />

    <div
      className={clsx(
        'flex items-center justify-center bg-medium !w-auto',
        getZoomButtonSize(size),
      )}
    >
      <Typography size="xs" weight="medium">
        Zoom
      </Typography>
    </div>

    <ZoomButton
      testId={`${testId}-in`}
      type="zoomIn"
      size={size}
      onClick={onZoomIn}
      disabled={disableZoomIn}
    />
  </div>
)
