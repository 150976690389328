/* eslint-disable react/button-has-type */
import React, { FC, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import type { ButtonProps } from './types'
import { Icons } from '../Icons'
import { Spinner } from '@sylveraio/design-system'

function buttonSize(
  shape: ButtonProps['shape'],
  size: ButtonProps['size'],
): Record<'height' | 'width', number> {
  switch (size) {
    case 'sm': {
      return shape === 'square'
        ? {
            height: 6,
            width: 6,
          }
        : {
            height: 6,
            width: 14,
          }
    }
    case 'lg': {
      return shape === 'square'
        ? {
            height: 10,
            width: 10,
          }
        : {
            height: 10,
            width: 48,
          }
    }
    case 'md':
    default: {
      return shape === 'square'
        ? {
            height: 8,
            width: 8,
          }
        : {
            height: 8,
            width: 36,
          }
    }
  }
}

export const Button: FC<ButtonProps> = React.forwardRef(
  (
    {
      children,
      className,
      solid = false,
      bordered = true,
      disabled = false,
      spinner = false,
      type = 'button',
      onClick,
      color = 'dark',
      testId = 'button',
      shape = 'rectangle',
      size = 'md',
      iconName,
      ...rest
    },
    ref,
  ) => {
    const evaluatedSize = buttonSize(shape, size)
    const [evSize, setEvSize] = useState(buttonSize(shape, size))

    const datasetAttr = useMemo(() => {
      return Object.fromEntries(
        Object.entries(rest).filter(
          ([k]) => String(k).toLowerCase().substring(0, 5) === 'data-',
        ),
      )
    }, [rest])

    useEffect(() => {
      setEvSize(buttonSize(shape, size))
    }, [])

    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'text-xs rounded-md flex justify-center items-center transition duration-150 ease-in-out',
          {
            [`px-2 w-fit h-${evaluatedSize.height}`]: size === 'sm',
            [`px-3 w-fit h-${evSize.height}`]: size !== 'sm',
            'h-8': size === 'md',
            'w-36': size === 'md' && shape === 'rectangle',
            'w-8': size === 'md' && shape === 'square',
            '!rounded-full': shape === 'pill',
            'cursor-default': disabled,
          },
          // Transparent
          {
            'bg-high text-content-action-default': !solid && !bordered,
            'hover:text-content-action-default-hover':
              !solid && !bordered && !disabled,
            'text-content-action-subtle': disabled && !solid && !bordered,
          },
          // Outline
          {
            'bg-high text-content-action-default border border-primary-900':
              !solid && bordered && color !== 'forest',
            'hover:border-primary-1000 hover:text-content-action-default-hover':
              !solid && bordered && !disabled && color !== 'forest',
            'border-primary-400 text-content-action-subtle':
              disabled && !solid && bordered && color !== 'forest',
          },
          // Dark solid
          {
            'bg-primary-900 text-default-on-dark':
              color === 'dark' && solid && !disabled,
            'hover:bg-primary-1000': color === 'dark' && solid && !disabled,
            'bg-primary-400 text-default-on-dark':
              color === 'dark' && solid && disabled,
          },
          // Light solid
          {
            'bg-primary-100 text-content-action-default':
              color === 'light' && solid,
            'hover:bg-primary-50': color === 'light' && solid && !disabled,
            'bg-primary-25': color === 'light' && solid && disabled,
          },
          // Secondary solid
          {
            'text-default-on-dark': color === 'secondary',
            'bg-secondary-300': color === 'secondary' && solid,
            'hover:bg-secondary-400':
              color === 'secondary' && solid && !disabled,
            'bg-secondary-50': color === 'secondary' && solid && disabled,
          },
          // Success solid
          {
            'text-default-on-dark': color === 'success',
            'bg-green-300': color === 'success' && solid,
            'hover:bg-green-400': color === 'success' && solid && !disabled,
          },
          // Disabled solid
          {
            'text-disabled': color === 'disabled',
            'bg-black-50': color === 'disabled' && solid,
            'hover:bg-black-100': color === 'disabled' && solid && !disabled,
          },
          // White solid
          {
            'text-content-action-default': color === 'white',
            'bg-high': color === 'white' && solid,
            'hover:bg-black-20': color === 'white' && solid && !disabled,
            'opacity-30': color === 'white' && solid && disabled,
          },
          // Forest
          {
            'bg-content-action-default text-default-on-dark':
              color === 'forest' && solid,
            'hover:brightness-150': color === 'forest' && solid && !disabled,
            'bg-primary-400': color === 'forest' && solid && disabled,
            'bg-high text-default border border-selection-forest':
              color === 'forest' && !solid && bordered,
          },
          // Sylvera Green
          {
            'bg-primary-lime text-default': color === 'lime' && solid,
            'hover:brightness-90': color === 'lime' && solid && !disabled,
          },
          {
            'pointer-events-none': spinner,
          },
          className,
        )}
        data-testid={testId}
        onClick={onClick}
        disabled={disabled}
        {...datasetAttr}
      >
        {spinner ? (
          <Spinner className="h-7 w-7" />
        ) : iconName ? (
          <div className="flex items-center space-x-2 text-sm">
            {/* Not replacing this as it's being deprecated */}
            <Icons name={iconName} size={14} />
            <span>{children}</span>
          </div>
        ) : (
          children
        )}
      </button>
    )
  },
)

export default Button
