import { FC, useState, useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSubmitForgotPasswordForm } from '../../../ForgotPasswordForm/utils/useSubmitForgotPasswordForm'
import { UserContext } from '../../../contexts'

const ResendCode: FC = () => {
  const { email } = useContext(UserContext)
  const [authError, setAuthError] = useState<Error>()
  const [disabled, setDisabled] = useState<boolean>(false)
  const { submitForgotPassword } = useSubmitForgotPasswordForm()

  const resendCode = async () => {
    try {
      if (!email)
        throw { message: 'User is not authorised to use this form.' } as Error

      await submitForgotPassword({ email })
      setDisabled(true)
    } catch (err) {
      setAuthError(err as Error)
    } finally {
      setTimeout(() => setDisabled(false), 20000)
    }
  }

  return (
    <button
      type="button"
      onClick={resendCode}
      disabled={disabled || !isEmpty(authError)}
      className="text-lg font-medium leading-normal text-center text-content-discovery-default disabled:text-default"
    >
      Resend code
    </button>
  )
}

export default ResendCode
