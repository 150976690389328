// Identify internal Sylvera users, with the exception of those who will be testing PostHog functionality and analyzing PostHog data
const INTERNAL_EXCEPTIONS = [
  'rani@sylvera.io',
  'rani+restricted@sylvera.io',
  'jillian.breau@sylvera.io',
  'pat.graczyk@sylvera.io',
  'jerold@sylvera.io',
  'matthewwilson@sylvera.io',
  'larsen@sylvera.io',
  'clement.fleuriot+restricted@sylvera.io',
  'clement.fleuriot+business@sylvera.io',
  'clement.fleuriot@sylvera.io',
  'dilshad.shawki@sylvera.io',
  'shona.cs@sylvera.io',
  'ashleigh@sylvera.io',
  'andreea.ungureanu@sylvera.io',
  'matthew.plumeridge@sylvera.io',
]

/**
 * Determines if a user is an internal user or not
 * @param email
 * @returns
 */
export function isInternalUser(email: string): boolean {
  return (
    email.includes('@sylvera.io') &&
    !INTERNAL_EXCEPTIONS.some(
      (internalUserEmailException) => internalUserEmailException === email,
    )
  )
}
