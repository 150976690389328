import React, { FC, BaseSyntheticEvent, useState, ChangeEvent } from 'react'
import { useRouter } from 'next/router'
import { routes, getAuthError } from '@sylveraio/auth-utils'
import { getInputError } from '@sylveraio/react-utils'

import {
  Form,
  FormBackLink,
  FormHeading,
  FormSubheading,
  Input,
  Button,
} from '@sylveraio/react-ui'

import { useSubmitForgotPasswordForm } from './utils/useSubmitForgotPasswordForm'

import type {
  ForgotPasswordFormProps,
  ForgotPasswordFormInputNameTypes,
  ForgotPasswordFormKeys,
} from './types'

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  testId = 'forgot-password-form',
}) => {
  const router = useRouter()
  const [email, setEmail] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [authError, setAuthError] = useState<Error>()
  const { submitForgotPassword } = useSubmitForgotPasswordForm()

  const onSubmit = async (
    data: ForgotPasswordFormKeys,
    e: BaseSyntheticEvent<object, any, any> | undefined,
  ) => {
    e?.preventDefault()
    setSubmitting(true)
    try {
      await submitForgotPassword({ email: data['forgot-password-email'] })
      router.push({
        pathname: routes['forgotPassword'],
        query: {
          verifyPasscode: 1,
        },
      })
    } catch (err) {
      setAuthError(err as Error)
    } finally {
      setSubmitting(false)
    }
  }

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback?: () => void,
  ) => {
    switch (e.currentTarget.name) {
      case 'forgot-password-email':
        setEmail(e.currentTarget.value)
        break
      default:
        break
    }
    setAuthError(undefined)
    callback?.()
  }

  return (
    <Form<ForgotPasswordFormKeys> testId={testId} onSubmit={onSubmit}>
      {({ register, formState, clearErrors }) => (
        <>
          <FormHeading
            testId={`${testId}-heading`}
            className="mb-4 whitespace-nowrap"
          >
            Forgot your password?
          </FormHeading>

          <FormSubheading
            testId={`${testId}-subheading`}
            className="mb-16 w-52"
          >
            Reset your password in three quick steps.
          </FormSubheading>

          <div className="relative flex flex-col w-full gap-y-6">
            <Input
              testId={`${testId}-email-input`}
              type="email"
              label="Email address"
              hideBorder
              error={
                authError
                  ? getAuthError(authError)?.context
                  : getInputError<
                      ForgotPasswordFormKeys,
                      ForgotPasswordFormInputNameTypes
                    >(formState.errors, 'forgot-password-email')
              }
              {...register('forgot-password-email', {
                value: email,
                required: 'Please enter an email address.',
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
          </div>

          <div className="flex flex-col mt-10 mb-28 gap-y-7">
            <Button
              testId={`${testId}-sign-in-button`}
              type="submit"
              className="!w-[240px] !h-12 mx-auto !text-xl font-medium"
              spinner={submitting}
              solid
              color="lime"
              shape="pill"
            >
              Reset password
            </Button>
          </div>

          <FormBackLink href={routes['signIn']} />
        </>
      )}
    </Form>
  )
}

export default ForgotPasswordForm
