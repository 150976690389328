import clsx from 'clsx'
import type { FC } from 'react'
import type { ToggleProps } from './types'
import { Typography } from '../Typography'

export const Toggle: FC<ToggleProps> = ({
  testId = 'toggle',
  checked = false,
  labels,
  onChange,
}) => (
  <label
    data-testid={`${testId}-wrapper`}
    className="flex items-center cursor-pointer gap-x-2"
  >
    <input
      type="checkbox"
      data-testid="default-toggle"
      className="sr-only"
      checked={checked}
      onChange={onChange}
    />

    {labels?.left ? (
      typeof labels.left === 'string' ? (
        <Typography
          testId={`${testId}-label-left`}
          className={clsx('!leading-[0]', {
            '!text-content-action-default': !checked,
            '!text-content-action-light': checked,
          })}
          size="sm"
          weight="semibold"
        >
          {labels.left}
        </Typography>
      ) : (
        <span
          data-testid={`${testId}-label-left`}
          className={clsx({
            '!text-content-action-default': !checked,
            '!text-content-action-light': checked,
          })}
        >
          {labels.left}
        </span>
      )
    ) : null}

    <div
      data-testid={testId}
      className="flex items-center bg-[#DAD9FF] w-8 h-4 rounded-full p-0.5"
    >
      <div
        data-testid={`${testId}-dot`}
        className={clsx(
          'bg-[#605CFF] w-3 h-3 rounded-full transition-transform drop-shadow-md',
          { 'translate-x-4': checked },
        )}
      />
    </div>

    {labels?.right ? (
      typeof labels.right === 'string' ? (
        <Typography
          testId={`${testId}-label-right`}
          className={clsx('!leading-[0]', {
            '!text-content-action-default': checked,
            '!text-content-action-light': !checked,
          })}
          size="sm"
          weight="semibold"
          children={labels.right}
        />
      ) : (
        <span
          data-testid={`${testId}-label-right`}
          className={clsx({
            '!text-content-action-default': checked,
            '!text-content-action-light': !checked,
          })}
        >
          {labels.right}
        </span>
      )
    ) : null}
  </label>
)
