'use client'

import { createContext, FC, PropsWithChildren, useState } from 'react'
import type { HubspotFormCustomOverride } from '@sylveraio/auth-utils'
import { AppName } from 'types'

type HubspotState = {
  hubspotAppName: AppName | null
  hubspotFormCustomOverride?: HubspotFormCustomOverride
  setHubspotAppName(appName: AppName | null): void
  resetHubspotData(): void
  setHubspotFormCustom(formCustom: HubspotFormCustomOverride | null): void
  setHubspotData(
    appName: AppName,
    formCustom: HubspotFormCustomOverride | null,
  ): void
}

export const HubspotStateContext = createContext<HubspotState>({
  hubspotFormCustomOverride: undefined,
  hubspotAppName: null,
  // Set as default before being overwritten in _app
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHubspotAppName: (app: AppName | null) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetHubspotData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHubspotFormCustom: (formCustom: HubspotFormCustomOverride | null) => {},
  setHubspotData: (
    app: AppName,
    formCustom: HubspotFormCustomOverride | null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
})

export const HubspotStateProvider: FC<PropsWithChildren> = ({ children }) => {
  // @ts-ignore
  const [hubspotContext, setHubspotContext] = useState<HubspotState>({
    hubspotAppName: null,
    setHubspotAppName: (appName: AppName | null) => {
      setHubspotContext({
        ...hubspotContext,
        hubspotAppName: appName,
      })
    },
    resetHubspotData: () => {
      hubspotContext.setHubspotAppName(null)
      hubspotContext.setHubspotFormCustom(null)
    },
    setHubspotFormCustom: (formCustom: HubspotFormCustomOverride) => {
      setHubspotContext({
        ...hubspotContext,
        hubspotFormCustomOverride: formCustom,
      })
    },
    setHubspotData: (
      appName: AppName,
      formCustom: HubspotFormCustomOverride,
    ) => {
      setHubspotContext({
        ...hubspotContext,
        hubspotAppName: appName,
        hubspotFormCustomOverride: formCustom,
      })
    },
  })

  return (
    <HubspotStateContext.Provider value={hubspotContext}>
      {children}
    </HubspotStateContext.Provider>
  )
}
