import type { FC } from 'react'
import type { SearchProps } from './types'
import clsx from 'clsx'
import SearchSm from '@sylveraio/untitled-ui-icons-react/build/esm/SearchSm'
import XClose from '@sylveraio/untitled-ui-icons-react/build/esm/XClose'

export const SearchDefault: FC<Omit<SearchProps, 'theme'>> = ({
  testId = 'search-default',
  value,
  placeholder,
  className,
  wrapperClassName,
  onChange,
  onReset,
}) => (
  <div
    data-testid={testId}
    className={clsx(
      'flex items-center w-full h-8 px-4 py-2 gap-2 bg-high rounded-full focus-within:ring-1 focus-within:ring-[#BCC7C0]',
      wrapperClassName,
    )}
  >
    <input
      data-testid={`${testId}-input`}
      className={clsx(
        'flex-grow tracking-[0.14px] text-sm text-default font-semibold bg-transparent outline-none placeholder:text-placeholder',
        className,
      )}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {value?.length > 0 ? (
      <button data-testid={`${testId}-reset-button`} onClick={onReset}>
        <XClose className="w-5 h-5 stroke-icon-default" />
      </button>
    ) : (
      <SearchSm className="w-5 h-5 stroke-icon-default" />
    )}
  </div>
)
