import type { FormField } from '../types'
import { evaluateConditions } from './evaluateConditions'
import { checkIsSubmit } from './typeguards/checkIsSubmit'

export function getValidFields(
  entry: Array<FormField>,
  currentForm: Record<string, unknown>,
  includeSubmit = false,
): Array<FormField> {
  return entry.filter((v) => {
    if (checkIsSubmit(v)) return includeSubmit
    return evaluateConditions(currentForm, v.condition)
  })
}
