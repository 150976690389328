import { submitHubspotForm } from './hubspot'
import type { HubspotFormData } from './types'
import type { UserDetails } from 'types'
import { EARLY_STAGE_CATALOG_HUBSPOT_FORM_ID } from './constants'

export function submitEarlyStageCatalogForm(user: UserDetails) {
  const formData: HubspotFormData = [
    {
      name: 'firstname',
      value: user.givenName,
    },
    {
      name: 'lastname',
      value: user.familyName,
    },
    {
      name: 'email',
      value: user.email,
    },
    {
      name: 'organisation',
      value: user.organisation,
    },
    {
      name: 'user_id',
      value: user.userId,
    },
  ]

  return submitHubspotForm({
    id: EARLY_STAGE_CATALOG_HUBSPOT_FORM_ID,
    data: formData,
  })
}
