import React from 'react'
import type { TextRowProps } from './types'
import { Tooltip, Typography } from '@sylveraio/design-system'
import InfoCircle from '@sylveraio/untitled-ui-icons-react/build/esm/InfoCircle'
import clsx from 'clsx'

export const TextRow: React.FC<TextRowProps> = ({
  icon,
  text,
  subtitle,
  typography,
  testId,
  tooltip,
  tooltipText,
}) => (
  <div className="flex space-x-2 h-inherit justify-center items-center">
    {icon && icon?.position !== 'suffix' && icon.component}
    {text && (
      <Typography
        testId={`${testId}-label`}
        size="xs"
        weight="medium"
        {...typography}
      >
        {text}
      </Typography>
    )}
    {subtitle && (
      <Typography
        testId={`${testId}-description`}
        size="sm"
        weight="regular"
        {...subtitle?.typography}
        className={clsx('text-subtle', subtitle?.typography?.className)}
      >
        {subtitle.value}
      </Typography>
    )}
    {icon && icon?.position === 'suffix' && icon.component}
    {(tooltipText || tooltip) && (
      <Tooltip
        content={tooltipText || tooltip}
        remainOpenOnHover
        placement="right"
      >
        <InfoCircle className="h-4 w-4 stroke-subtle" />
      </Tooltip>
    )}
  </div>
)
