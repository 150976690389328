import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

export const AppSwitch: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('stroke-selection-forest', className)}
  >
    <path
      d="M9.7,13.6H1.9c-0.6,0-1.1-0.5-1.1-1.1V2c0-0.6,0.5-1.1,1.1-1.1h7.7c0.6,0,1.1,0.5,1.1,1.1v10.6
      C10.8,13.2,10.3,13.6,9.7,13.6z"
      strokeWidth={1.5}
    />
    <path
      d="M9.7,24.1H1.9c-0.6,0-1.1-0.5-1.1-1.1v-4.8c0-0.6,0.5-1.1,1.1-1.1h7.7c0.6,0,1.1,0.5,1.1,1.1V23
      C10.8,23.6,10.3,24.1,9.7,24.1z"
      strokeWidth={1.5}
    />
    <path
      d="M23,7.8h-7.7c-0.6,0-1.1-0.5-1.1-1.1V2c0-0.6,0.5-1.1,1.1-1.1H23c0.6,0,1.1,0.5,1.1,1.1v4.8
      C24.1,7.4,23.6,7.8,23,7.8z"
      strokeWidth={1.5}
    />
    <path
      d="M23,24.1h-7.7c-0.6,0-1.1-0.5-1.1-1.1V12.4c0-0.6,0.5-1.1,1.1-1.1H23c0.6,0,1.1,0.5,1.1,1.1V23
      C24.1,23.6,23.6,24.1,23,24.1z"
      strokeWidth={1.5}
    />
  </svg>
)

export default AppSwitch
