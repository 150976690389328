import { SylveraRatingFilter } from '../../SylveraRatingFilter'
import { getRatingsValues } from '@sylveraio/react-utils'
import { FC } from 'react'
import { DropdownSylveraRatingProps } from './types'
import { DropdownTooltip } from '../DropdownTooltip'

export const DropdownSylveraRating: FC<DropdownSylveraRatingProps> = ({
  referenceElement,
  isVisible = false,
  filteredRatings,
  placement = 'end',
  onChange,
  title,
}) => {
  const sylveraRatings = getRatingsValues()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onAnalyticsEvent = () => {}
  const onFilterChange = () => {
    // Is this necessary?
  }

  return (
    <DropdownTooltip
      referenceElement={referenceElement}
      placement={placement}
      isVisible={isVisible}
      title={title}
    >
      <SylveraRatingFilter
        sylveraRatings={sylveraRatings}
        ratingsFilter={filteredRatings}
        onAnalyticsEvent={onAnalyticsEvent}
        onFilterChange={onFilterChange}
        onSylveraRatingFilterChange={onChange}
      />
    </DropdownTooltip>
  )
}
