import { toTitleCase } from '../toTitleCase'
import type { AppName } from 'types'

/**
 * Returns the title of a given app
 * @return {string} String
 */
export function getAppTitle(appName: AppName): string {
  switch (appName) {
    case 'user-management':
      return 'User Management'
    case 'box':
      return 'Jurisdictional Intel'
    case 'insights':
      return 'Sylvera Insights'
    case 'market-commentary':
      return 'Market Commentary'
    case 'country-profiles':
      return 'Country Profiles'
    case 'ratings':
      return 'Project Catalog'
    default:
      return toTitleCase(appName)
  }
}

export default getAppTitle
