import { Typography } from '@sylveraio/design-system'
import clsx from 'clsx'
import type { PropsWithChildren } from 'react'

export function FieldContainer({
  children,
  selected,
  inline,
  className,
  hasError,
  errorMsg,
}: PropsWithChildren & {
  selected?: boolean
  inline?: boolean
  className?: string
  hasError?: boolean
  errorMsg?: string
}): JSX.Element {
  return (
    <div
      className={clsx(
        'bg-high rounded-lg h-12 w-full',
        {
          'border-2 border-content-action-default': selected,
          'border border-default': !selected,
          flex: inline,
          '!border-content-alert-default': hasError,
        },
        className,
      )}
    >
      {children}
      {hasError && errorMsg && (
        <Typography size="sm" weight="semibold" className="text-default">
          {errorMsg}
        </Typography>
      )}
    </div>
  )
}
