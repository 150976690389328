import { FC } from 'react'
import Link from 'next/link'
import type { FormBackLinkProps } from './types'
import { AnimatedArrowButton } from '../../../AnimatedArrowButton'

const FormBackLink: FC<FormBackLinkProps> = ({
  testId = 'form-back-link',
  href,
}) => (
  <Link href={href} legacyBehavior>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      data-testid={testId}
      className="absolute flex items-center group gap-x-4 bottom-10 left-10 pointer-cursor"
    >
      <AnimatedArrowButton text="Back" darkBackground />
    </a>
  </Link>
)

export default FormBackLink
