'use client'

import { useEffect, useRef } from 'react'

/**
 * A hook that adds or removes a CSS class on an element based on the window scroll position.
 *
 * @param {React.RefObject<HTMLElement>} ref - The ref of the element to modify.
 * @param {string[]} className - The CSS class to toggle.
 * @param {number} threshold - The scroll position (in pixels) at which to toggle the class.
 * @param {boolean} [disabled] - Optional flag to disable the effect.
 */
export const useScrollClass = <T extends HTMLElement>(
  className: string[],
  threshold = 50,
  disabled?: boolean,
) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (disabled) return

      if (ref.current) {
        const currentScrollPosition = window.scrollY
        if (currentScrollPosition >= threshold) {
          ref.current.classList.add(...className)
        } else {
          ref.current.classList.remove(...className)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ref, className, threshold, disabled])
  return {
    ref,
    defaultTransitionClass: 'transition-all duration-250 ease-in-out',
  }
}
