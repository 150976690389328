import { forwardRef, type ForwardedRef } from 'react'
import type { ButtonProps } from './types'
import { Typography } from '../Typography'
import { getButtonSize } from './utils/getButtonSize'
import clsx from 'clsx'
import { getButtonColors } from './utils/getButtonColors'

export const Button = forwardRef(
  (
    {
      testId = 'button',
      type = 'button',
      variant = 'primary',
      size = 'md',
      label,
      iconLeading,
      iconTrailing,
      disabled = false,
      active = false,
      className,
      onClick,
      hide = false,
      children,
      role,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const baseClass = clsx(
      'inline-flex shrink-0 items-center justify-center rounded-lg outline-0 ring-content-action-subtle ring-offset-2 focus-visible:ring-4 focus-visible:border-2',
      {
        'shadow-sm focus:shadow-none': variant !== 'subtle',
      },
    )
    const buttonSizeClass = getButtonSize(size, !label)
    const buttonColorClass = getButtonColors(variant, active, disabled)
    const typographySize = size === 'lg' ? 'base' : 'sm'
    const iconSize = size === 'xs' ? 'h-4 w-4' : 'h-5 w-5'

    if (hide) return null

    return (
      <button
        ref={ref}
        type={type}
        role={role}
        data-testid={testId}
        className={clsx(
          baseClass,
          buttonSizeClass,
          buttonColorClass,
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {typeof iconLeading !== 'undefined' && (
          <span
            data-testid={`${testId}-leading-icon`}
            className={clsx('shrink-0', iconSize)}
          >
            {iconLeading}
          </span>
        )}

        {label && (
          <Typography
            testId={`${testId}-label`}
            size={typographySize}
            weight="semibold"
            className="shrink-0 !text-inherit"
          >
            {label}
          </Typography>
        )}

        {children}

        {label && typeof iconTrailing !== 'undefined' && (
          <span
            data-testid={`${testId}-trailing-icon`}
            className={clsx('shrink-0', iconSize)}
          >
            {iconTrailing}
          </span>
        )}
      </button>
    )
  },
)
