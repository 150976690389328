import type { FontSizes, FontTags, FontWeights } from '../../types'
import type { CreateTypographyBundlesReturn } from './types'

/**
 * Constructs a class string for typography based on the provided font size and weight.
 * Optionally includes an HTML tag if provided that defaults to the 'p' tag. This function utilizes predefined mappings for
 * font sizes and weights to respective tailwind class names.
 *
 * @param {FontSizes} size - The size of the font from a predefined list of size options.
 * Corresponds to utility class names like 'text-xs', 'text-sm', etc.
 * @param {FontWeights} fontWeight - The weight of the font from a predefined list of weight options.
 * Corresponds to utility class names like 'font-regular', 'font-medium', etc.
 * @param {FontTags} [tag] - An optional HTML tag to specify the type of HTML element that should be used,
 * such as 'p', 'h1', 'h2', etc. This allows for additional semantic context in the returned bundle.
 *
 * @returns {CreateTypographyBundlesReturn} An object containing the `classes` string,
 * which is a concatenation of font size and weight classes, and a `tag` element.

 */
export function createTypographyBundles(
  size: FontSizes,
  fontWeight: FontWeights,
  tag: FontTags = 'p',
): CreateTypographyBundlesReturn {
  const weightClasses = {
    regular: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
  }

  const sizeClasses = {
    xs: 'text-xs leading-normal',
    sm: 'text-sm leading-normal',
    base: 'text-base leading-normal',
    lg: 'text-lg leading-normal',
    xl: 'text-xl leading-normal',
    '2xl': 'text-2xl leading-snug',
    '3xl': 'text-3xl leading-tight',
    '4xl': 'text-4xl leading-tight tracking-[-0.72px]',
    '5xl': 'text-5xl leading-tight tracking-[-0.96px]',
  }

  const baseClasses = `${weightClasses[fontWeight]} ${sizeClasses[size]}`

  return {
    classes: baseClasses,
    ...(tag && { tag }),
  }
}
