import { useEffect, useRef, useState } from 'react'
import { captureException } from '@sentry/nextjs'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import clsx from 'clsx'
import { ButtonLegacy, Typography } from '@sylveraio/design-system'
import { MultiChartData, MultiChart, mergeDataLabels } from '../Charts'
import { ContentShadow } from '../ContentShadow'
import { Table } from '@sylveraio/design-system'
import { contentClient } from './contentClient'
import { GET_CONTENTFUL_CHART } from './queries'
import type { ChartWidgetProps, ContentfulChartConfig } from './types'
import { extractTableData } from './utils/extractTableData'
import { extractTableColumns } from './utils/extractTableColumns'
import { extractChartData } from './utils/extractChartData'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import kebabCase from 'lodash/kebabCase'
import uniq from 'lodash/uniq'
import Expand01 from '@sylveraio/untitled-ui-icons-react/build/esm/Expand01'
import Minimize01 from '@sylveraio/untitled-ui-icons-react/build/esm/Minimize01'
import Download02 from '@sylveraio/untitled-ui-icons-react/build/esm/Download02'

async function fetchContentData(id: string) {
  const { data } = await contentClient.query({
    query: GET_CONTENTFUL_CHART,
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  })
  return data?.chart
}

export function ChartWidget({
  id,
  chartDataClient,
  localData = {},
  chartDataQueryVariables = {},
}: ChartWidgetProps) {
  const ref = useRef(null)
  const [dataMode, setDataMode] = useState<'Chart' | 'Table'>('Chart')
  const [mergedLabels, setMergedLabels] = useState<Array<string>>([])
  const [tableData, setTableData] = useState<Array<Record<string, unknown>>>([])
  const [chartData, setChartData] = useState<Array<MultiChartData>>([])
  const [columnDetails, setColumnDetails] = useState<
    Array<{ datasetKey: string; sortable: boolean; title: string }>
  >([])
  const [chartConfig, setChartConfig] = useState<ContentfulChartConfig | null>(
    null,
  )

  useEffect(() => {
    fetchContentData(id)
      .then(async (chartConfig) => {
        setChartConfig(chartConfig)
        const chartData = await extractChartData(
          chartConfig.chartTypeCollection.items,
          chartDataClient,
          chartDataQueryVariables,
          localData,
        )
        setChartData(chartData)
        const xLabels = mergeDataLabels(chartData)
        setMergedLabels(xLabels)
        const tableData = extractTableData(
          xLabels,
          chartData,
          chartConfig?.xAxisOptions?.axisLabel || '-',
        )
        setTableData(tableData)
        setColumnDetails(extractTableColumns(tableData))
      })
      .catch(captureException)
  }, [])

  const fullscreen = useFullScreenHandle()

  const handleFullscreen = () => {
    if (fullscreen.active) {
      fullscreen.exit()

      return
    }

    fullscreen.enter()
  }

  const handleImage = async () => {
    if (!ref?.current) return

    const { title = 'chart' } = chartConfig || {}

    if (dataMode === 'Chart') {
      const canvas = await html2canvas(ref.current)
      const image = canvas.toDataURL('image/jpeg', 1.0)
      saveAs(image, `${kebabCase(title)}.jpg`)
    } else {
      const titles = uniq(tableData.flatMap((v) => Object.keys(v)))
      const data = tableData
        .map((v) => {
          return titles.map((title) => v[title]).join(',')
        })
        .join('\n')
      const csvData = `${titles}\n${data}`
      const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${kebabCase(title)}.csv`)
    }
  }

  if (!chartConfig) return null

  return (
    <>
      <div className="mb-4 flex space-x-4">
        <ButtonLegacy
          role="button"
          key={`btn-chart-type-chart`}
          onClick={() => setDataMode('Chart')}
        >
          Chart
        </ButtonLegacy>
        <ButtonLegacy role="button" onClick={() => setDataMode('Table')}>
          Table
        </ButtonLegacy>
      </div>
      <section className="space-y-4">
        <FullScreen handle={fullscreen} className="h-full w-full">
          <ContentShadow
            testId="chart-view"
            className={clsx('h-full', {
              'flex-col': fullscreen.active,
              'flow-root': !fullscreen.active,
            })}
          >
            <div className="px-6 pb-6" ref={ref}>
              {chartConfig.showTitle && (
                <Typography
                  className="mb-10 w-full"
                  size="lg"
                  weight="semibold"
                >
                  {chartConfig.title}
                  {dataMode === 'Table' ? ' - Data' : ''}
                </Typography>
              )}
              {dataMode === 'Chart' ? (
                <MultiChart
                  data={chartData}
                  labels={mergedLabels}
                  enableFiltering={chartConfig.enableFiltering}
                  hideGridLines={!chartConfig.showGridLines}
                  showNoDataOverlay={chartConfig.showNoDataOverlay}
                  xAxisOptions={{
                    max: chartConfig.xAxisOptions?.maxValue,
                    min: chartConfig.xAxisOptions?.minValue,
                    offset: chartConfig.xAxisOptions?.isOffset,
                    axisLabel: chartConfig.xAxisOptions?.axisLabel,
                  }}
                  yAxisOptions={{
                    max: chartConfig.yAxisOptions?.maxValue,
                    min: chartConfig.yAxisOptions?.minValue,
                    offset: chartConfig.yAxisOptions?.isOffset,
                    axisLabel: chartConfig.yAxisOptions?.axisLabel,
                  }}
                  y1AxisOptions={
                    chartConfig.y1AxisOptions
                      ? {
                          max: chartConfig.y1AxisOptions?.maxValue,
                          min: chartConfig.y1AxisOptions?.minValue,
                          offset: chartConfig.y1AxisOptions?.isOffset,
                          axisLabel: chartConfig.y1AxisOptions?.axisLabel,
                        }
                      : undefined
                  }
                  ignoreMaxWidth={fullscreen.active}
                  variables={chartConfig.staticData}
                  labelAnnotations={
                    chartConfig.labelAnnotationsCollection?.items
                  }
                  legendPosition={chartConfig?.legendPosition}
                />
              ) : (
                <Table
                  columns={columnDetails}
                  dataset={tableData}
                  defaultRowCount={100}
                />
              )}
              <div className="mt-10 flex w-full justify-between text-left">
                <div className="mr-4 w-full bg-[#f3f3f3] px-16 py-4 text-sm">
                  {chartConfig?.description?.json &&
                    documentToReactComponents(chartConfig.description.json)}
                </div>
                <div className="space-y-4" data-html2canvas-ignore>
                  {chartConfig.enableFullscreen && (
                    <ButtonLegacy
                      role="button"
                      theme="off-white-1"
                      onClick={handleFullscreen}
                      data-ignore-outside-click
                      title={`${
                        fullscreen.active ? 'Exit' : 'Enter'
                      } fullscreen mode`}
                    >
                      {fullscreen.active ? (
                        <Minimize01 className="h-5 w-5" />
                      ) : (
                        <Expand01 className="h-5 w-5" />
                      )}
                    </ButtonLegacy>
                  )}
                  {chartConfig.enableDownload && (
                    <ButtonLegacy
                      role="button"
                      theme="off-white-1"
                      onClick={handleImage}
                      title={`Save the ${
                        dataMode === 'Chart' ? 'chart' : 'data'
                      } to ${dataMode === 'Chart' ? 'JPG' : 'CSV'}`}
                    >
                      <Download02 className="h-5 w-5" />
                    </ButtonLegacy>
                  )}
                </div>
              </div>
            </div>
          </ContentShadow>
        </FullScreen>
      </section>
    </>
  )
}
