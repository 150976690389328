import { FC } from 'react'
import Image from 'next/image'
import format from 'date-fns/format'
import type { NewsCardProps } from './types'
import clsx from 'clsx'
import { Typography } from '../Typography'
import { Label } from '../Label'

export const NewsCard: FC<NewsCardProps> = ({
  testId = 'news-card',
  variant = 'stacked',
  date,
  img,
  title,
  summaryHeading,
  summary,
  tags,
  className,
  onClick,
}) => {
  return (
    <article
      data-testid={testId}
      className={clsx(
        'flex overflow-hidden rounded-lg bg-high border border-default shadow-sm group hover:bg-high-hover hover:border-default-hover',
        {
          'cursor-pointer': typeof onClick !== 'undefined',
          'w-full h-full': variant === 'inline',
          'flex-col w-[248px]': variant === 'stacked',
        },
        className,
      )}
      onClick={onClick}
    >
      {img && (
        <div
          className={clsx('w-full overflow-hidden', {
            'h-[240px] min-w-[500px] max-w-[500px]': variant === 'inline',
            'h-[88px]': variant === 'stacked',
          })}
        >
          <Image
            data-testid={`${testId}-thumbnail`}
            src={img.src}
            height={img.height}
            width={img.width}
            alt={img?.alt || title}
            className="object-cover object-bottom h-full transition duration-500 group-hover:scale-110 will-change-transform"
            unoptimized
          />
        </div>
      )}
      <div className="flex flex-col items-center">
        <section
          className={clsx('flex flex-col p-3 gap-y-2 h-[100px]', {
            'self-start justify-center grow': variant === 'inline',
          })}
        >
          <time
            data-testid={`${testId}-date`}
            dateTime={date}
            className="inline-flex text-subtle"
          >
            <Typography
              size="xs"
              weight="semibold"
              className="!leading-none text-subtle"
            >
              {format(new Date(date), 'MMMM dd, yyyy')}
            </Typography>
          </time>

          <Typography
            size="sm"
            weight="semibold"
            className={clsx('group-hover:text-default-hover !leading-none', {
              'line-clamp-1': variant === 'inline',
              'line-clamp-2': variant === 'stacked',
            })}
          >
            {title}
          </Typography>

          {typeof tags !== 'undefined' && (
            <div
              data-testid={`${testId}-tags`}
              className="flex items-center gap-x-1"
            >
              {tags.slice(0, 2).map((tag) => (
                <Label
                  key={tag}
                  testId={`${testId}-tag-${tag}`}
                  size="sm"
                  label={tag}
                  color="neutral"
                />
              ))}
              {tags.length > 2 && (
                <Label
                  testId={`${testId}-tag-more`}
                  size="sm"
                  label={`+${tags.length - 2}`}
                  color="neutral"
                />
              )}
            </div>
          )}
        </section>

        <section className="flex flex-col w-full p-3 border-t gap-y-1 bg-medium border-default group-hover:bg-medium-hover group-hover:border-default-hover">
          <Typography size="xs" weight="semibold">
            {summaryHeading}
          </Typography>

          <Typography size="xs" className="h-8 line-clamp-2 !text-subtle">
            {summary}
          </Typography>
        </section>
      </div>
    </article>
  )
}
