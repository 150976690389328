'use client'

import { useClickOutsideElement } from '../useClickOutsideElement'
import { Dispatch, RefObject, SetStateAction, useState } from 'react'

export const useVisibleDropdown = <D extends string>(
  filterRef: RefObject<HTMLDivElement | HTMLButtonElement | HTMLSpanElement>,
  callback?: () => void,
): [D | 'none', Dispatch<SetStateAction<D | 'none'>>] => {
  const [visibleDropdown, setVisibleDropdown] = useState<D | 'none'>('none')

  useClickOutsideElement(
    filterRef,
    () => {
      setVisibleDropdown('none')
      callback?.()
    },
    visibleDropdown !== 'none',
  )

  return [visibleDropdown, setVisibleDropdown]
}
