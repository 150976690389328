import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const TickInRing: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <circle cx="11" cy="11" r="11" fill="#605CFF" />
    <path
      d="M5.75685 10.6156C5.42777 10.307 4.91059 10.3237 4.60204 10.6528C4.29348 10.9823 4.30982 11.4995 4.63928 11.808L8.99751 15.8938C9.34825 16.2225 9.90608 16.1788 10.2013 15.7991L16.7901 7.50318C17.0671 7.14711 17.0029 6.63374 16.6468 6.35674C16.2908 6.07971 15.7774 6.14394 15.5 6.5L9.46146 14.0884L5.75685 10.6156Z"
      fill="white"
    />
  </svg>
)

export default TickInRing
