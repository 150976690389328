import { captureException } from '@sylveraio/react-utils'
import { submitHubspotForm } from '@sylveraio/auth-utils'
import { convertRequestDemoFormDataForHubspot } from '../convertRequestDemoFormDataForHubspot'
import type { RequestDemoFormValues } from '../../types'

function useSubmitRequestDemoForm() {
  async function submitRequestDemoForm({
    firstName,
    lastName,
    companyName,
    role,
    companyEmail,
  }: RequestDemoFormValues) {
    try {
      await submitHubspotForm({
        id: '2ff6b301-ca40-4669-9fa9-44712129d33f',
        data: convertRequestDemoFormDataForHubspot({
          firstName,
          lastName,
          companyName,
          role,
          companyEmail,
        }),
      })
    } catch (err) {
      captureException(err, 'useSubmitRequestDemo')
      throw err
    }
  }

  return { submitRequestDemoForm }
}

export default useSubmitRequestDemoForm
