'use client'

import { useCallback, useEffect, useState } from 'react'
import posthog from 'posthog-js'
import { isInternalUser } from '../isInternalUser'
import type { AnalyticsEventData, AnalyticsUser } from '../types'

export const useSendAnalyticsEvent = (
  useAnalyticsUserDetails: () => [
    hasValidUserDetails: boolean,
    userDetails: AnalyticsUser,
  ],
) => {
  const [hasValidUserDetails, userDetails] = useAnalyticsUserDetails()
  const [msgBacklog, setMsgBacklog] = useState<
    Pick<AnalyticsEventData, 'eventData' | 'eventName'>[]
  >([])

  const sendAnalyticsEvent = useCallback(
    ({ eventName, eventData }: AnalyticsEventData) => {
      if (!hasValidUserDetails) {
        setMsgBacklog([...msgBacklog, { eventName, eventData }])
      } else if (
        typeof userDetails.userEmail !== 'undefined' &&
        !isInternalUser(userDetails.userEmail)
      ) {
        posthog.capture(eventName, {
          $set: userDetails,
          event: eventName,
          ...userDetails,
          ...eventData,
        })
      }
    },
    [msgBacklog, hasValidUserDetails, userDetails],
  )

  useEffect(() => {
    if (!hasValidUserDetails || msgBacklog.length === 0) return

    // This is to prevent sendAnalyticsEvent writing back to this recursively
    const msgsToDispatch = [...msgBacklog]
    setMsgBacklog([])
    msgsToDispatch.forEach((event) => sendAnalyticsEvent(event))
  }, [hasValidUserDetails, msgBacklog, sendAnalyticsEvent])

  return {
    sendAnalyticsEvent,
  }
}

export default useSendAnalyticsEvent
