import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { defaultPillTestId } from './constants'
import { Pill } from './Pill'
import { PillGroupProps, PillGroupPropsWithoutGroupClick } from './types'

export const PillGroup: FC<PillGroupProps> = ({
  testId = defaultPillTestId,
  pills,
  color,
  selectedVariation,
  bordered,
  wrapperClassName,
  onClick,
  size = 'md',
}) => {
  const [groupPills, setGroupPills] = useState<
    PillGroupPropsWithoutGroupClick['pills']
  >([])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const defaultOnClick = (name: string, label?: React.ReactNode) => {
    if (typeof onClick !== 'undefined') {
      onClick(name, label)
    }
  }

  useEffect(() => {
    setGroupPills(
      pills.map((pill) => ({
        testId,
        color,
        size,
        selectedVariation,
        bordered,
        ...pill,
        onClick:
          typeof pill.onClick !== 'undefined'
            ? pill.onClick
            : () => defaultOnClick(pill.name, pill.children),
      })),
    )
  }, [pills, color, size])

  return (
    <div
      data-testid={`${testId}-group`}
      className={clsx('flex', wrapperClassName, {
        'space-x-3': size === 'md',
        'space-x-1': size === 'sm',
      })}
    >
      {groupPills.map((pillProps) => (
        <Pill key={pillProps.name} {...pillProps} />
      ))}
    </div>
  )
}
