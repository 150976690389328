import clsx from 'clsx'
import { FC } from 'react'
import { InfoHoverTooltip } from '../InfoHoverTooltip'
import { MetricProps } from './types'
import { Spinner } from '@sylveraio/design-system'

export const Metric: FC<MetricProps> = ({
  colorScheme = 'purple',
  value,
  title,
  testId = 'metric',
  valueSize = 'medium',
  titleSize = 'small',
  loading,
  tooltip,
  className,
  onClickViewMore,
}) => (
  <div
    data-testid={testId}
    className={clsx(
      'flex flex-col justify-end items-start space-y-3 min-h-fit',
      className,
    )}
  >
    <div
      className={clsx(
        'font-semibold flex items-center justify-start leading-none',
        {
          'text-content-action-default': colorScheme === 'purple',
          'text-secondary-water': colorScheme === 'blue',
          'text-secondary-mauve': colorScheme === 'mauve',
          'text-secondary-gold': colorScheme === 'lemon',
          'text-default': colorScheme === 'forestAndWhite',
          'text-lg': valueSize === 'small',
          'text-xl': valueSize === 'medium',
          'text-3xl': valueSize === 'large',
        },
      )}
    >
      {loading ? <Spinner className="!h-6 !w-6" /> : value}
    </div>
    {(title || tooltip) && (
      <div
        className={clsx('flex w-full justify-between pt-0.5', {
          'text-sm font-bold text-default': titleSize === 'small',
          'text-xs font-medium text-subtle': titleSize === 'extraSmall',
        })}
      >
        <div>
          {title && <span className="pr-1">{title}</span>}
          {tooltip && (
            <InfoHoverTooltip
              iconClassName="translate-y-0.5"
              wrapperClassName="!max-w-[336px]"
            >
              {tooltip}
            </InfoHoverTooltip>
          )}
        </div>
        {typeof onClickViewMore !== 'undefined' && (
          <button
            className="underline underline-offset-2"
            onClick={onClickViewMore}
          >
            View more
          </button>
        )}
      </div>
    )}
  </div>
)
