'use client'

import Link from 'next/link'
import { FC } from 'react'
import type { LinkWrapperProps } from './types'

export const LinkWrapper: FC<LinkWrapperProps> = ({
  children,
  toClientSidePage,
  testId,
  className,
  onClick,
  ...link
}) => {
  if (link.href) {
    if (toClientSidePage) {
      return (
        <Link
          {...link}
          role="link"
          tabIndex={0}
          data-testid={testId}
          className={className}
          onClick={onClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && onClick) {
              onClick()
            }
          }}
        >
          {children}
        </Link>
      )
    }

    return (
      <a
        tabIndex={0}
        data-testid={testId}
        className={className}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onClick) {
            onClick()
          }
        }}
        href={link.href as string}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <div
      role="none"
      data-testid={testId}
      className={className}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onClick) {
          onClick()
        }
      }}
    >
      {children}
    </div>
  )
}
