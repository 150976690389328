import { ColorScheme, SylveraRating } from 'types'

export const COLORS_SCHEME: Record<ColorScheme, string> = {
  purple: '#605CFF20',
  blue: '#48BCFD20',
  mauve: '#CEA2FA20',
  lemon: '#FCE49140',
}

export const RATINGS_ARRAY: Array<SylveraRating> = [
  'D',
  'C',
  'B',
  'BB',
  'BBB',
  'A',
  'AA',
  'AAA',
]
