'use client'

import { useEffect, useState } from 'react'
import type { RefObject } from 'react'
import type { UseIntersectionObserverArgs } from './types'

/**
 * Detects visibility of a component on the viewport using the IntersectionObserver API
 * @param {RefObject<Element>} elementRef element to observe
 * @param {UseIntersectionObserverArgs} args arguments used to customise when the element should be detectable by the IntersectionObserver
 * @returns {IntersectionObserverEntry} IntersectionObserver's entry object
 */

export function useIntersectionObserver(
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: UseIntersectionObserverArgs,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

  return entry
}
