export default {
  NOTIFICATIONS: 'Notifications',
  SETTINGS: 'Settings',
  COLLPASE: 'Collapse',
  MODE: 'Mode',
  LIGHT: 'Bright',
  DARK: 'Dark',
  API_KEYS: 'API Keys',
  LOGOUT: 'Log out',
}
