import clsx from 'clsx'
import React, { FC } from 'react'
import SortProps from './types'

const Sort: FC<SortProps> = ({ size = 16, className }): JSX.Element => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx(
      {
        'fill-current text-content-action-default': !className,
      },
      className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.96433 1.82755L1.1138 3.67868C0.998503 3.79336 0.812623 3.79336 0.697341 3.67868C0.582657 3.56338 0.582657 3.3775 0.697341 3.26222L3.0503 0.909255C3.1656 0.794571 3.35148 0.794571 3.46677 0.909255L5.81973 3.26222C5.93441 3.37752 5.93441 3.5634 5.81973 3.67868C5.70443 3.79336 5.51855 3.79336 5.40327 3.67868L3.55274 1.82755V12.882C3.55274 13.0444 3.42093 13.1761 3.25865 13.1761C3.09627 13.1761 2.96457 13.0443 2.96457 12.882V1.82755H2.96433ZM7.67015 12.1722V1.11773C7.67015 0.955352 7.80197 0.823647 7.96424 0.823647C8.12662 0.823647 8.25833 0.955459 8.25833 1.11773V12.1722L10.1089 10.3211C10.2242 10.2064 10.41 10.2064 10.5253 10.3211C10.64 10.4364 10.64 10.6223 10.5253 10.7375L8.17235 13.0905C8.05705 13.2052 7.87117 13.2052 7.75589 13.0905L5.40293 10.7375C5.28824 10.6222 5.28824 10.4364 5.40293 10.3211C5.51823 10.2064 5.70411 10.2064 5.81939 10.3211L7.66992 12.1722H7.67015Z"
      strokeWidth="0.4"
    />
  </svg>
)

export default Sort
