import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Target: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'target',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M10.0014 6.68465C8.15972 6.68465 6.66805 8.17632 6.66805 10.018C6.66805 11.8596 8.15972 13.3513 10.0014 13.3513C11.8431 13.3513 13.3347 11.8596 13.3347 10.018C13.3347 8.17632 11.8431 6.68465 10.0014 6.68465ZM17.4514 9.18465C17.2626 7.49475 16.5048 5.91927 15.3025 4.7169C14.1001 3.51453 12.5246 2.75674 10.8347 2.56798V0.851318H9.16805V2.56798C7.47815 2.75674 5.90267 3.51453 4.7003 4.7169C3.49793 5.91927 2.74014 7.49475 2.55138 9.18465H0.834717V10.8513H2.55138C2.74014 12.5412 3.49793 14.1167 4.7003 15.3191C5.90267 16.5214 7.47815 17.2792 9.16805 17.468V19.1846H10.8347V17.468C12.5246 17.2792 14.1001 16.5214 15.3025 15.3191C16.5048 14.1167 17.2626 12.5412 17.4514 10.8513H19.1681V9.18465H17.4514ZM10.0014 15.8513C6.77638 15.8513 4.16805 13.243 4.16805 10.018C4.16805 6.79298 6.77638 4.18465 10.0014 4.18465C13.2264 4.18465 15.8347 6.79298 15.8347 10.018C15.8347 13.243 13.2264 15.8513 10.0014 15.8513Z"
      fill="#01311D"
    />
  </svg>
)
