import { FC } from 'react'
import clsx from 'clsx'
import { Analytics } from './Analytics'
import { AppSwitch } from './AppSwitch'
import Arrow from './Arrow'
import ArrowInRing from './ArrowInRing'
import Bell from './Bell'
import BulletList from './BulletList'
import Chevron from './Chevron'
import Close from './Close'
import Cog from './Cog'
import { CurvedMinus } from './CurvedMinus'
import { CurvedPlus } from './CurvedPlus'
import Proponents from './Proponents'
import ErrorScreen from './ErrorScreen'
import Expand from './Expand'
import LockInRing from './LockInRing'
import { LockInRingV2 } from './LockInRingV2'
import Lock from './Lock'
import LongArrow from './LongArrow'
import Marker from './Marker'
import NARing, { NARingWithOuterRing } from './NARing'
import Rocket from './Rocket'
import Tick from './Tick'
import Warning from './Warning'
import PlusInRing from './PlusInRing'
import { Ratings } from './Ratings'
import TickInRing from './TickInRing'
import Plus from './Plus'
import EmptyInbox from './EmptyInbox'
import ListView from './ListView'
import ScatterGraph from './ScatterGraph'
import Shrink from './Shrink'
import World from './World'
import Twitter from './Twitter'
import LinkedIn from './LinkedIn'
import Filter from './Filter'
import Sort from './Sort'
import Reset from './Reset'
import Search from './Search'
import type { BadgeProps, IconComponentProps } from './types'
import LeftArrow from './LeftArrow'
import Insights from './Insights'
import Home from './Home'
import Menu from './Menu'
import { Chart } from './Chart'
import Copy from './Copy'
import Bin from './Bin'
import Box from './Box'
import DocumentFree from './DocumentFree'
import { LinkExternal } from './LinkExternal'
import ExclamationMark from './ExclamationMark'

const Badge: FC<BadgeProps> = ({ count, size = 'md', opaque = false }) => (
  <div
    className={clsx(
      'bg-green-300 text-default-on-dark flex items-center justify-center rounded-full absolute',
      {
        'h-16 w-16 text-5xl -top-2 -right-2': size === 'xl',
        'h-5 w-5 text-xs -top-1.5 -right-1.5': size === 'md',
        'bg-opacity-70': opaque,
      },
    )}
  >
    {count}
  </div>
)

const Icon: FC<IconComponentProps> = ({
  testId = 'icon',
  name,
  badge,
  adhocProps,
  ...iconProps
}) => {
  const getIcon = () => {
    switch (name) {
      case 'analytics':
        return <Analytics testId={testId} {...iconProps} {...adhocProps} />
      case 'appSwitch':
        return <AppSwitch testId={testId} {...iconProps} {...adhocProps} />
      case 'arrow':
        return <Arrow testId={testId} {...iconProps} {...adhocProps} />
      case 'arrowInRing':
        return <ArrowInRing testId={testId} {...iconProps} {...adhocProps} />
      case 'bin':
        return <Bin testId={testId} {...iconProps} {...adhocProps} />
      case 'box':
        return <Box testId={testId} {...iconProps} {...adhocProps} />
      case 'bell':
        return <Bell testId={testId} {...iconProps} {...adhocProps} />
      case 'bulletList':
        return <BulletList testId={testId} {...iconProps} {...adhocProps} />
      case 'chart':
        return <Chart testId={testId} {...iconProps} {...adhocProps} />
      case 'chevron':
        return <Chevron testId={testId} {...iconProps} {...adhocProps} />
      case 'close':
        return <Close testId={testId} {...iconProps} {...adhocProps} />
      case 'cog':
        return <Cog testId={testId} {...iconProps} {...adhocProps} />
      case 'copy':
        return <Copy testId={testId} {...iconProps} {...adhocProps} />
      case 'curvedMinus':
        return <CurvedMinus testId={testId} {...iconProps} {...adhocProps} />
      case 'curvedPlus':
        return <CurvedPlus testId={testId} {...iconProps} {...adhocProps} />
      case 'documentFree':
        return <DocumentFree testId={testId} {...iconProps} {...adhocProps} />
      case 'emptyInbox':
        return <EmptyInbox testId={testId} {...iconProps} {...adhocProps} />
      case 'errorScreen':
        return <ErrorScreen testId={testId} {...iconProps} {...adhocProps} />
      case 'exclamationMark':
        return (
          <ExclamationMark testId={testId} {...iconProps} {...adhocProps} />
        )
      case 'expand':
        return <Expand testId={testId} {...iconProps} {...adhocProps} />
      case 'filter':
        return <Filter testId={testId} {...iconProps} {...adhocProps} />
      case 'leftArrow':
        return <LeftArrow testId={testId} {...iconProps} {...adhocProps} />
      case 'linkedIn':
        return <LinkedIn testId={testId} {...iconProps} {...adhocProps} />
      case 'listView':
        return <ListView testId={testId} {...iconProps} {...adhocProps} />
      case 'lock':
        return <Lock testId={testId} {...iconProps} {...adhocProps} />
      case 'proponents':
        return <Proponents testId={testId} {...iconProps} {...adhocProps} />
      case 'dashboard':
        return <Home testId={testId} {...iconProps} {...adhocProps} />
      case 'insights':
        return <Insights testId={testId} {...iconProps} {...adhocProps} />
      case 'linkExternal':
        return <LinkExternal testId={testId} {...iconProps} {...adhocProps} />
      case 'lockInRing':
        return <LockInRing testId={testId} {...iconProps} {...adhocProps} />
      case 'lockInRingV2':
        return <LockInRingV2 testId={testId} {...iconProps} {...adhocProps} />
      case 'longArrow':
        return <LongArrow testId={testId} {...iconProps} {...adhocProps} />
      case 'marker':
        return <Marker testId={testId} {...iconProps} {...adhocProps} />
      case 'menu':
        return <Menu testId={testId} {...iconProps} {...adhocProps} />
      case 'naRing':
        return <NARing testId={testId} {...iconProps} {...adhocProps} />
      case 'naRingWithOuterRing':
        return (
          <NARingWithOuterRing testId={testId} {...iconProps} {...adhocProps} />
        )
      case 'plus':
        return <Plus testId={testId} {...iconProps} {...adhocProps} />
      case 'plusInRing':
        return <PlusInRing testId={testId} {...iconProps} {...adhocProps} />
      case 'ratings':
        return <Ratings testId={testId} {...iconProps} {...adhocProps} />
      case 'reset':
        return <Reset testId={testId} {...iconProps} {...adhocProps} />
      case 'rocket':
        return <Rocket testId={testId} {...iconProps} {...adhocProps} />
      case 'scatterGraph':
        return <ScatterGraph testId={testId} {...iconProps} {...adhocProps} />
      case 'search':
        return <Search testId={testId} {...iconProps} {...adhocProps} />
      case 'sort':
        return <Sort testId={testId} {...iconProps} {...adhocProps} />
      case 'shrink':
        return <Shrink testId={testId} {...iconProps} {...adhocProps} />
      case 'tick':
        return <Tick testId={testId} {...iconProps} {...adhocProps} />
      case 'tickInRing':
        return <TickInRing testId={testId} {...iconProps} {...adhocProps} />
      case 'twitter':
        return <Twitter testId={testId} {...iconProps} {...adhocProps} />
      case 'warning':
        return <Warning testId={testId} {...iconProps} {...adhocProps} />
      case 'world':
        return <World testId={testId} {...iconProps} {...adhocProps} />
      default:
        return null
    }
  }

  //  Add more as we go
  return (
    <div
      data-testid={`${testId}-wrapper`}
      className="relative pointer-events-none"
    >
      {getIcon()}
      {typeof badge !== 'undefined' && <Badge {...badge} />}
    </div>
  )
}

export default Icon
