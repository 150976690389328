'use client'
import { FC, useRef, useState } from 'react'
import { useAutoClose } from '@sylveraio/react-utils/server'
import Chevron from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'
import Avatar from '../Avatar'
import UserNavMenu from './UserNavMenu'
import { UserNavProps } from './types'

const UserNav: FC<UserNavProps> = ({
  initials,
  handleOpen,
  signInUrl,
  hasAPIKeysPageAccess,
}) => {
  /* Default behaviour if handleOpen is passed to override - Begin */
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onClose = () => setIsOpen(false)
  const onClick = () => setIsOpen(!isOpen)
  /* Default behaviour if handleOpen is passed to override - End */

  const menuRef = useRef<HTMLDivElement>(null)

  useAutoClose({
    isOpen: handleOpen?.isOpen ?? isOpen,
    setIsOpen: handleOpen?.onClose ?? onClose,
    ref: menuRef,
  })

  return (
    <div className="relative" ref={menuRef}>
      <button
        type="button"
        className="group flex items-center"
        onClick={handleOpen?.onClick ?? onClick}
        aria-expanded={isOpen}
        aria-haspopup="true"
        data-testid="user-nav-button"
      >
        <Avatar initials={initials} />
        <Chevron className="h-4 w-4" />
      </button>
      {isOpen && (
        <UserNavMenu signInUrl={signInUrl} showAPIKeys={hasAPIKeysPageAccess} />
      )}
    </div>
  )
}

export default UserNav
