import type { AppName } from 'types'

/**
 * Returns the subtitle of a given app, if applicable
 * @return {string | undefined} String if applicable, otherwise undefined
 */
export function getAppSubTitle(appName: AppName): string | undefined {
  switch (appName) {
    case 'ratings':
      return '(20k+ projects)'
    default:
      return undefined
  }
}

export default getAppSubTitle
