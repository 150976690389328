import { FC, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useForm } from '../utils/createForm'
import {
  ADDITIONAL_REQUEST_INFORMATION,
  DEFAULT_VALUES,
  REQUEST_DETAILS,
  SET_CRITERIA,
  SUBMIT,
  VINTAGES_AND_VOLUMES,
} from './constants'
import { Modal } from '@sylveraio/design-system'
import ArrowLeft from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowLeft'
import ArrowRight from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowRight'
import {
  GET_PROJECTS_FUZZY,
  type ProjectsFuzzy,
} from '../queries/GET_PROJECTS_FUZZY'
import { useFormSearch } from '../utils/useFormSearch'
import { FAQ } from '../FAQ'
import { useMutation, type ApolloClient } from '@apollo/client'
import { injectVarsToPages } from '../utils/injectVarsToPages'
import { useProjectCategories } from '../utils/useProjectCategories'
import { useAccreditations } from '../utils/useAccreditations'
import { useRegistries } from '../utils/useRegistries'
import { CREATE_SUPPLY_REQUEST } from '../queries/CREATE_SUPPLY_REQUEST'
import { useCountries } from '../utils/useCountries'
import { captureException } from '@sylveraio/react-utils'
import type { ValueBase } from '../utils/createForm/types'
import { formatProjectText } from '../utils/formatProjectText'
import { captureMessage } from '@sentry/nextjs'

export const BuyerForm: FC<{
  userId: string
  client: ApolloClient<any>
  onFAQChange: (value: any) => void
  showFAQ?: boolean
  onClose(): void
  projectId?: ValueBase
}> = ({ userId, client, onFAQChange, showFAQ, onClose, projectId }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const DEFAULT_REQUEST_VARS = {
    userId,
  }
  const [faqAcknowledged, setFaqAcknowledged] = useState<
    'initial' | 'acknowledged' | 'show'
  >('initial')
  const [projectOpts, handleProjectSearch] = useFormSearch<ProjectsFuzzy>(
    GET_PROJECTS_FUZZY,
    client,
    (data) => {
      return (data?.projects?.nodes || []).map(
        ({ publicId, name, projectRegistry }) => {
          const text = formatProjectText(
            name,
            projectRegistry.registry.name,
            projectRegistry.registryProjectId,
          )
          return {
            value: publicId,
            text,
          }
        },
      )
    },
  )
  const { accreditationOpts, handleAccreditationSearch } =
    useAccreditations(client)
  const {
    handleProjectCategorySearch,
    projectCategoryList,
    projectCategoryOpts,
  } = useProjectCategories(client)
  const { handleCountrySearch, countryList, countryOpts } = useCountries(client)
  const { registryOpts, handleRegistrySearch } = useRegistries(client)
  const [submitSupplyRequest] = useMutation(CREATE_SUPPLY_REQUEST, {
    client,
    context: { apiName: 'sylvera' },
    variables: {
      ...DEFAULT_REQUEST_VARS,
      formAnswers: {},
    },
  })

  const handleFAQChange = (value: any) => {
    setFaqAcknowledged('show')
    onFAQChange(value)
  }
  const handleAcknowledgeFaqs = () => setFaqAcknowledged('acknowledged')

  const handleSubmit = async (formAnswers: Record<string, unknown>) => {
    try {
      const { data, errors } = await submitSupplyRequest({
        variables: {
          ...DEFAULT_REQUEST_VARS,
          formAnswers,
        },
      })

      if (
        typeof errors === 'undefined' &&
        data?.createSupplyRequest?.supplyRequest?.createdAt
      ) {
        setShowConfirmation(true)
      } else {
        captureMessage(errors?.toString() || '')
      }
    } catch (e) {
      captureException(e)
    }
  }

  const { content, page, backButtonProps, nextButtonProps, clearForm } =
    useForm(
      [
        injectVarsToPages(REQUEST_DETAILS, [
          {
            key: 'includeProjects',
            entry: { searchFn: handleProjectSearch, opts: projectOpts },
          },
        ]),
        injectVarsToPages(SET_CRITERIA, [
          {
            key: 'includeProjectTypes',
            entry: {
              searchFn: handleProjectCategorySearch,
              opts: projectCategoryOpts,
              values: projectCategoryList,
            },
          },
          {
            key: 'includeAccreditations',
            entry: {
              opts: accreditationOpts,
              searchFn: handleAccreditationSearch,
            },
          },
          {
            key: 'includeRegistries',
            entry: { opts: registryOpts, searchFn: handleRegistrySearch },
          },
          {
            key: 'includeCountries',
            entry: {
              opts: countryOpts,
              searchFn: handleCountrySearch,
              values: countryList,
            },
          },
          {
            key: 'excludeCountries',
            entry: {
              opts: countryOpts,
              searchFn: handleCountrySearch,
              values: countryList,
            },
          },
        ]),
        VINTAGES_AND_VOLUMES,
        ADDITIONAL_REQUEST_INFORMATION,
        SUBMIT,
      ],
      handleSubmit,
      undefined,
      !isEmpty(projectId)
        ? {
            ...DEFAULT_VALUES,
            supplyRequestType: {
              text: 'Project specific',
              value: 'project',
            },
            includeProjects: [projectId],
          }
        : {
            ...DEFAULT_VALUES,
          },
    )

  useEffect(() => {
    setShowConfirmation(false)
    clearForm()
  }, [])

  if (typeof showFAQ === 'undefined') return null

  const showFaq =
    (faqAcknowledged === 'initial' && showFAQ) || faqAcknowledged === 'show'

  const isFirstFormPage = !showFaq && typeof backButtonProps === 'undefined'

  if (showConfirmation) {
    return (
      <Modal
        title="Request submitted"
        onClose={onClose}
        primaryActionProps={{
          active: true,
          onClick: onClose,
          label: 'Okay',
        }}
        size="sm"
        contentId="success"
      >
        We will get back to you within 5 working days with an update.
      </Modal>
    )
  }

  return (
    <Modal
      title={showFaq ? 'Connect to Supply' : page.title}
      onClose={onClose}
      size="lg"
      step={showFaq ? ` ` : `Step ${page.current} of ${page.total}`}
      contentId={showFaq ? 'faq' : page.title}
      subtitle={
        showFaq ? 'Helping you find & procure supply at the best price' : ' '
      }
      primaryActionProps={
        showFaq
          ? {
              active: true,
              onClick: handleAcknowledgeFaqs,
              label: 'Continue',
              iconTrailing: <ArrowRight color="white" />,
            }
          : nextButtonProps
      }
      extraActionProps={
        showFaq
          ? {
              actionType: 'checkbox',
              checked: !showFAQ,
              label: "Don't show me this again",
              name: 'hide-faq',
              id: 'hide-faq',
              onChange: handleFAQChange,
            }
          : isFirstFormPage
          ? {
              actionType: 'button',
              label: 'Back',
              active: true,
              onClick: () => setFaqAcknowledged('show'),
              iconLeading: <ArrowLeft />,
              variant: 'subtle',
            }
          : { actionType: 'button', ...backButtonProps }
      }
    >
      {
        // N.B. I know, I'll sort later. Basically need to grow the modal section to take the rest of the max height
      }
      <div className="min-h-[622px]">
        {showFaq ? <FAQ client={client} /> : content}
      </div>
    </Modal>
  )
}
