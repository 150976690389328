import type { AppName } from 'types'
import { DISABLED_APPS } from '../getAppStates/constants'

export const APP_NAMES: Array<AppName> = [
  'analytics',
  'box',
  'dashboard',
  'insights',
  'ratings',
  'user-management',
]

export function getValidAppName(inbAppName = ''): AppName | undefined {
  const inDisabled = DISABLED_APPS.find(
    (appName) => appName === (inbAppName.toLowerCase() as AppName),
  )

  if (inDisabled) return

  return APP_NAMES.find(
    (appName) => appName === (inbAppName.toLowerCase() as AppName),
  )
}
