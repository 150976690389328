import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'

/**
 * Deduplicates array
 * @param arrToCheck array to be deduplicated
 * @returns
 */

export function dedupeArray<T extends {}>(arrToCheck?: T[]): undefined | T[] {
  if (typeof arrToCheck === 'undefined') return arrToCheck
  return uniqWith(arrToCheck, isEqual)
}

export default dedupeArray
