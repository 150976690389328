import { FC } from 'react'
import { InfoTooltip } from '../InfoTooltip'
import type { InfoHoverTooltipProps } from './types'
import clsx from 'clsx'

export const InfoHoverTooltip: FC<InfoHoverTooltipProps> = ({
  size = 12,
  children,
  iconClassName,
  wrapperClassName,
}) => (
  <InfoTooltip
    buttonSize={size}
    showOnHover
    showCloseButton={false}
    placement="top"
    modalWrapperClassName={clsx(
      'text-xs text-default max-w-[240px] !py-4 !px-3 shadow-sm',
      wrapperClassName,
    )}
    iconClassName={clsx('text-default', iconClassName)}
  >
    {children}
  </InfoTooltip>
)

export default InfoHoverTooltip
