import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Insights: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-selection-forest', className)}
  >
    <path
      d="M0 0.416743V19.5834C0 19.8138 0.189594 20 0.423305 20H19.5765C19.8104 20 19.9998 19.8136 19.9998 19.5834L20 4.33507C20 4.326 19.9952 4.31781 19.9947 4.30891C19.9922 4.27212 19.9851 4.23672 19.9731 4.20184C19.9685 4.18841 19.9647 4.17534 19.9587 4.16243C19.9382 4.11831 19.9121 4.07628 19.876 4.04053L15.8945 0.122069C15.8579 0.0859712 15.8148 0.0601626 15.7694 0.0399348C15.7574 0.034529 15.7455 0.0313896 15.7331 0.0270302C15.6959 0.0141256 15.6578 0.00680145 15.6183 0.00453402C15.6103 0.00401085 15.6032 1.22145e-07 15.5951 1.22145e-07L0.423624 1.22145e-07C0.189913 -0.000174254 0 0.18638 0 0.416743ZM18.5545 3.91846H16.0183V1.42264L18.5545 3.91846ZM0.846882 0.833397H15.1719V4.33525C15.1719 4.56562 15.3615 4.75186 15.5952 4.75186H19.1533V19.1667H0.847018L0.846882 0.833397Z"
      fill="#01311D"
    />
    <path
      d="M10.6313 5C10.4282 5 10.2637 5.18373 10.2637 5.4106V9.29147C10.2637 9.45234 10.3475 9.59791 10.4784 9.66478L13.6358 11.2785C13.6853 11.3038 13.7373 11.3158 13.7886 11.3158C13.9279 11.3158 14.0611 11.2271 14.1228 11.0764C14.356 10.5089 14.4742 9.9085 14.4742 9.29132C14.4742 6.925 12.7504 5.00003 10.6314 5.00003L10.6313 5ZM13.5885 10.3512L10.999 9.02778V5.84523C12.5398 6.04889 13.7387 7.51667 13.7387 9.29125C13.7387 9.65304 13.6884 10.008 13.5885 10.3512H13.5885Z"
      fill="#01311D"
    />
    <path
      d="M13.1755 11.4796L9.81234 10.0038V6.4551C9.81234 6.23234 9.62413 6.05225 9.39213 6.05225C6.97022 6.05225 5 7.94091 5 10.2628C5 12.5846 6.97022 14.4733 9.39213 14.4733C11.1018 14.4733 12.6682 13.5081 13.3829 12.0142C13.4293 11.917 13.4335 11.8061 13.3948 11.7057C13.3556 11.6056 13.2768 11.5241 13.1755 11.4796L13.1755 11.4796ZM9.39199 13.6673C7.43357 13.6673 5.84029 12.1398 5.84029 10.2626C5.84029 8.52153 7.21054 7.08145 8.9716 6.88162V10.2626C8.9716 10.4205 9.06746 10.5633 9.21697 10.6289L12.4204 12.0345C11.7807 13.0378 10.633 13.6673 9.392 13.6673L9.39199 13.6673Z"
      fill="#01311D"
    />
  </svg>
)

export default Insights
