import {
  getAppTitle,
  getAppSubTitle,
  getAppUrl,
} from '@sylveraio/react-utils/server'
import type { AppData, AppName, AppState, AppStateResponse } from 'types'
import {
  APPS,
  DISABLED_APPS,
  RESTRICTED_APPS,
  RESTRICTED_APP_NO_CAPABILITIES,
} from './constants'
import type { GetAppStatesOpts } from './types'
import { HUBSPOT_IDS } from '../constants'

function getAppState(
  appName: AppName,
  appCapabilities: Array<AppName> = [],
  hasJREDDAccess?: boolean,
  isFetching = false,
): AppState {
  if (isFetching) return 'fetching'
  if (DISABLED_APPS.includes(appName)) return 'disabled'
  if (RESTRICTED_APPS.includes(appName) && !appCapabilities.includes(appName))
    return 'locked'
  if (RESTRICTED_APP_NO_CAPABILITIES.includes(appName) && !hasJREDDAccess)
    return 'locked'
  return 'active'
}

export function getAppStates(
  appCapabilities: Array<AppName> = [],
  opts?: GetAppStatesOpts,
): AppStateResponse {
  const appUrlsArr: Array<[AppName, AppData]> = APPS.filter(
    (appName) => !(opts?.excludeApps || []).includes(appName),
  )
    .filter((appName) =>
      opts?.excludeDisabled ? !DISABLED_APPS.includes(appName) : true,
    )
    .map((appName) => {
      const state: AppState = getAppState(
        appName,
        appCapabilities,
        opts?.hasJREDDAccess,
        opts?.isFetching,
      )

      return [
        appName,
        {
          state,
          url: state === 'active' ? getAppUrl(appName) : '',
          clientSide: appName !== 'insights',
          hubspotId: HUBSPOT_IDS[appName],
          title: getAppTitle(appName),
          subTitle: getAppSubTitle(appName),
        },
      ]
    })

  const appUrls: AppStateResponse = Object.fromEntries(appUrlsArr)

  return appUrls
}
