import clsx from 'clsx'
import { type FC } from 'react'
import type { CheckboxProps } from './types'
import { Typography } from '../Typography'

export const Checkbox: FC<CheckboxProps> = ({
  testId = 'checkbox',
  id,
  label,
  name,
  value,
  checked = false,
  disabled = false,
  className,
  labelClassName,
  iconClassName,
  onChange,
  labelTypographyProps,
  wrapperClassName,
}) => (
  <label
    data-testid={`${testId}-wrapper`}
    className={clsx(
      'flex items-center gap-x-3',
      {
        'cursor-pointer': !disabled,
      },
      wrapperClassName,
    )}
    role="checkbox"
    aria-checked={checked}
  >
    <input
      type="checkbox"
      data-testid="default-checkbox"
      data-label={label}
      id={id}
      name={name}
      aria-describedby={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      value={value}
      className="sr-only"
    />

    <div
      data-testid={testId}
      className={clsx(
        'w-5 h-5 border-[1.2px] border-content-action-default rounded flex items-center justify-center m-0.5',
        {
          'bg-content-action-light border-none': disabled,
        },
        className,
      )}
    >
      <svg
        data-testid={`${testId}-mark`}
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          {
            hidden: !checked || (!checked && disabled),
            'stroke-icon-default-on-dark': disabled,
            'stroke-content-action-default': !disabled,
          },
          iconClassName,
        )}
      >
        <path d="M0.5 4.5L5 8L11 1" strokeWidth="1.6" />
      </svg>
    </div>

    {label && (
      <Typography
        testId={`${testId}-label`}
        className={labelClassName}
        size="xs"
        weight="medium"
        {...labelTypographyProps}
      >
        {label}
      </Typography>
    )}
  </label>
)
