'use client'

import { useEffect, useRef, useState } from 'react'

const useGetElementDimensions = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)

  useEffect(() => {
    if (ref && ref?.current) {
      setWidth(ref?.current?.clientWidth)
      setHeight(ref?.current?.clientHeight)
    }
  }, [])

  return {
    ref,
    width,
    height,
  }
}

export default useGetElementDimensions
