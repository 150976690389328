import { type FC, useMemo } from 'react'
import { RatingDistributionHistogramBar } from './components/RatingDistributionHistogramBar'
import type {
  DistributionKey,
  RatingDistribution,
  RatingDistributionHistogramProps,
} from './types'

export const RatingDistributionHistogram: FC<
  RatingDistributionHistogramProps
> = ({
  ratingDistribution,
  projectRating,
  projectRatingPermission,
  totalCount,
  testId = 'ratings-distribution-histogram',
}) => {
  const maxCount = useMemo(() => {
    return Math.max(
      ...(ratingDistribution?.histogram?.map((rating) => rating?.count) || []),
    )
  }, [ratingDistribution])

  const ratingDistributionSimplified: Partial<RatingDistribution> =
    useMemo(() => {
      return {
        ...ratingDistribution,
        histogram: ratingDistribution?.histogram?.map((item) => {
          return {
            ...item,
            ...(ratingDistribution?.type === 'percentage' &&
              typeof item?.range !== 'undefined' && { key: item.range.max }),
          }
        }),
      }
    }, [ratingDistribution])

  if (typeof ratingDistribution === 'undefined') return null

  const formatKey = (key?: DistributionKey): string => {
    switch (ratingDistributionSimplified?.type) {
      case 'percentage':
        return `≤${key}%`
      case 'number':
        return `${key}/5`
      default:
        return `${key}`
    }
  }

  const isProjectKeyWithinKeyRange = (keyIndex: number): boolean => {
    if (typeof projectRating === 'undefined') return false

    switch (ratingDistributionSimplified?.type) {
      case 'percentage':
      case 'number':
        return (
          keyIndex ===
          ratingDistributionSimplified?.histogram?.findIndex(
            ({ key }) =>
              Math.round(
                parseFloat(`${projectRating}`) *
                  (ratingDistributionSimplified?.type === 'percentage'
                    ? 100
                    : 1),
              ) <= parseFloat(`${key}`),
          )
        )
      case 'letter':
        return (
          projectRating ===
          ratingDistributionSimplified?.histogram?.[keyIndex]?.key
        )
      default:
        return false
    }
  }

  return (
    <div
      className="flex flex-col w-full h-[100px] min-h-[100px]"
      data-testid={testId}
    >
      <div
        className="flex justify-around w-full h-full border-b-[1px] border-subtle pt-3"
        data-testid={`${testId}-bars`}
      >
        {ratingDistributionSimplified?.histogram?.map(({ key, count }, i) => {
          return (
            <RatingDistributionHistogramBar
              count={count}
              maxCount={maxCount}
              totalCount={totalCount}
              isCurrent={
                projectRatingPermission && isProjectKeyWithinKeyRange(i)
              }
              testId={`${testId}-bar-${key}`}
              key={`${testId}-bar-${key}`}
            />
          )
        })}
      </div>
      <div
        className="flex justify-around w-full mt-2"
        data-testid={`${testId}-labels`}
      >
        {ratingDistributionSimplified?.histogram?.map(({ key }) => {
          return (
            <div
              className="flex justify-center w-full"
              key={`${testId}-label-${key}`}
              data-testid={`${testId}-label-${key}`}
            >
              <div className="text-center w-5/6 text-xs">
                <span>{formatKey(key)}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
