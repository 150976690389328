import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const ListView: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M4.76801 8.60016C5.34757 8.60016 5.81739 8.15077 5.81739 7.59641C5.81739 7.04205 5.34757 6.59265 4.76801 6.59265C4.18845 6.59265 3.71863 7.04205 3.71863 7.59641C3.71863 8.15077 4.18845 8.60016 4.76801 8.60016Z"
      fill="#605CFF"
    />
    <path
      d="M4.76789 13.6189C5.34744 13.6189 5.81727 13.1695 5.81727 12.6151C5.81727 12.0608 5.34744 11.6114 4.76789 11.6114C4.18833 11.6114 3.71851 12.0608 3.71851 12.6151C3.71851 13.1695 4.18833 13.6189 4.76789 13.6189Z"
      fill="#605CFF"
    />
    <path
      d="M4.76789 18.6371C5.34744 18.6371 5.81727 18.1878 5.81727 17.6334C5.81727 17.079 5.34744 16.6296 4.76789 16.6296C4.18833 16.6296 3.71851 17.079 3.71851 17.6334C3.71851 18.1878 4.18833 18.6371 4.76789 18.6371Z"
      fill="#605CFF"
    />
    <path
      d="M21.6209 11.6114H8.90243C8.35765 11.6114 7.91602 12.0338 7.91602 12.5549V12.6753C7.91602 13.1964 8.35765 13.6189 8.90243 13.6189H21.6209C22.1657 13.6189 22.6073 13.1964 22.6073 12.6753V12.5549C22.6073 12.0338 22.1657 11.6114 21.6209 11.6114Z"
      fill="#605CFF"
    />
    <path
      d="M21.6209 16.6296H8.90243C8.35765 16.6296 7.91602 17.0521 7.91602 17.5732V17.6936C7.91602 18.2147 8.35765 18.6371 8.90243 18.6371H21.6209C22.1657 18.6371 22.6073 18.2147 22.6073 17.6936V17.5732C22.6073 17.0521 22.1657 16.6296 21.6209 16.6296Z"
      fill="#605CFF"
    />
    <path
      d="M21.6209 6.59235H8.90243C8.35765 6.59235 7.91602 7.01478 7.91602 7.53588V7.65633C7.91602 8.17742 8.35765 8.59986 8.90243 8.59986H21.6209C22.1657 8.59986 22.6073 8.17742 22.6073 7.65633V7.53588C22.6073 7.01478 22.1657 6.59235 21.6209 6.59235Z"
      fill="#605CFF"
    />
  </svg>
)

export default ListView
