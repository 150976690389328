import { FC, useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import BarChartLegendItem from './BarChartLegendItem'
import LineGraphLegendItem from './LineGraphLegendItem'
import { sortChartLegendBasedOnLegendID } from '../constants'
import type { ChartLegendProps } from './types'
import type { LegendItem as LegendChartItem } from 'chart.js'
import LegendItem from './LegendItem'
import clsx from 'clsx'

export const ChartLegend: FC<ChartLegendProps> = ({
  testId = 'chart-legend',
  type,
  legendItems,
  chartInstance,
  sortLegend = 'custom-legendId',
  style = 'vertical',
}) => {
  const items = useMemo(() => {
    const _items: LegendChartItem[] = []

    legendItems?.forEach((item) => {
      // @ts-ignore
      const dataset = chartInstance?.data?.datasets?.[item.datasetIndex]

      if (!dataset) {
        return
      }

      if (
        // @ts-ignore
        typeof dataset?.legendId === 'undefined' ||
        !_items.some((i) => i.text === item.text)
      ) {
        _items.push(item)
      }
    })

    if (type === 'line') return _items

    if (sortLegend === 'custom-legendId') {
      return _items?.sort((a, b) =>
        sortChartLegendBasedOnLegendID(a, b, chartInstance),
      )
    }

    return _items?.sort((a, b) => a.text.localeCompare(b.text))
  }, [legendItems])

  return (
    <div
      data-testid={testId}
      className={clsx('flex gap-2 mb-16 shrink-0 overflow-y-auto', {
        'flex-col max-h-[370px]': style === 'vertical',
        'flex-row': style === 'horizontal',
      })}
    >
      {type === 'mixed' &&
        items?.map((item) => {
          if (item === undefined || item.datasetIndex === undefined) return null

          const legendIdGroups = omit(
            groupBy(chartInstance?.data?.datasets, 'legendId'),
            ['undefined'],
          )

          return (
            <LegendItem
              key={item.datasetIndex}
              testId={`${testId}-item`}
              item={item}
              chartInstance={chartInstance}
              legendIdGroup={
                legendIdGroups[
                  //@ts-ignore
                  chartInstance?.data?.datasets[item.datasetIndex].legendId
                ]
              }
            />
          )
        })}
      {type === 'line' &&
        items?.map((item) => (
          <LineGraphLegendItem
            key={item.datasetIndex}
            testId={`${testId}-item`}
            item={item}
            chartInstance={chartInstance}
          />
        ))}

      {type === 'bar' &&
        items?.map((item) => {
          if (item === undefined || item.datasetIndex === undefined) return null

          const legendIdGroups = omit(
            groupBy(chartInstance?.data?.datasets, 'legendId'),
            ['undefined'],
          )

          return (
            <BarChartLegendItem
              key={item.datasetIndex}
              testId={`${testId}-item`}
              item={item}
              chartInstance={chartInstance}
              legendIdGroup={
                legendIdGroups[
                  //@ts-ignore
                  chartInstance?.data?.datasets[item.datasetIndex].legendId
                ]
              }
            />
          )
        })}
    </div>
  )
}

export default ChartLegend
