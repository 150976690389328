import { DropdownTooltip } from '../DropdownTooltip'
import { FC } from 'react'
import { SegmentedRangeFilter } from '../../SegmentedRangeFilter'
import { DropdownSegmentedRangeFilterProps } from './types'

export const DropdownSegmentedRangeFilter: FC<
  DropdownSegmentedRangeFilterProps
> = ({
  testId = 'dropdown-segmented-range',
  onChange,
  selectedRange,
  isVisible = false,
  referenceElement,
  title,
}) => (
  <DropdownTooltip
    referenceElement={referenceElement}
    isVisible={isVisible}
    testId={testId}
    title={title}
  >
    <SegmentedRangeFilter
      onChange={onChange}
      selectedRange={selectedRange}
      testId={`${testId}-filter`}
    />
  </DropdownTooltip>
)
