'use client'

import { addBreadcrumb } from '@sentry/nextjs'
import { getBearerToken } from '../getBearerToken'
import { postUserUnauthorised } from '../userUnauthorised'
import {
  clearCookies,
  doesCookieExist,
  setRevalidationCookie,
} from '../cookies'
import { COMMON_ERRORS } from '@sylveraio/constants'

export async function fetchAuthenticated(
  input: RequestInfo,
  init?: RequestInit,
  retry?: boolean,
): Promise<Response> {
  const bearerToken = await getBearerToken(retry)
  if (!bearerToken) {
    addBreadcrumb({
      message: 'No bearer token for request',
    })
    postUserUnauthorised()
    return Promise.reject(new Error(COMMON_ERRORS.NO_VALID_TOKEN))
  }
  const response = await fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${bearerToken}`,
    },
  })
  // Try once more if this is the first time we've received a 401, as the token might have been out of date when used
  if (!retry && response.status === 401 && !doesCookieExist('revalidation')) {
    addBreadcrumb({
      message: 'API call returned 401',
      data: {
        bearerToken,
      },
    })
    clearCookies('access')
    addBreadcrumb({
      message: 'Setting revalidation cookie',
    })
    setRevalidationCookie()
    return fetchAuthenticated(input, init, true)
  }

  // This is the second attempt and we're still getting 401, let's emit an unauthorised user event so the app can kick us out
  if (retry && response.status === 401) {
    clearCookies()
    postUserUnauthorised()
  }

  return response
}
