import { FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { routes } from '@sylveraio/auth-utils'
import {
  ContentShadow,
  Button,
  FormHeading,
  FormSubheading,
} from '@sylveraio/react-ui'
import type { FormSuccessProps } from './types'

export const SignInFormSuccess: FC<FormSuccessProps> = ({
  testId = 'form-success',
  heading,
  subheading,
  redirectUrl = routes['signIn'],
  buttonText,
}) => (
  <ContentShadow
    testId={testId}
    className="relative flex-col items-center justify-center w-[490px] rounded-[25px] h-[650px]"
    bgColor="bg-high/40"
  >
    <FormHeading
      testId={`${testId}-heading`}
      className={clsx('text-center', {
        'mb-4': subheading,
        'mb-8': !subheading,
      })}
    >
      {heading}
    </FormHeading>
    {subheading && (
      <FormSubheading testId={`${testId}-subheading`} className="mb-8 w-52">
        {subheading}
      </FormSubheading>
    )}
    {buttonText && redirectUrl && (
      <Link href={redirectUrl} passHref legacyBehavior>
        <Button
          testId={`${testId}-submit-button`}
          type="submit"
          className="!w-auto !h-12 mx-auto !text-xl font-medium"
          solid
          shape="pill"
          color="lime"
        >
          {buttonText}
        </Button>
      </Link>
    )}
  </ContentShadow>
)

export default SignInFormSuccess
