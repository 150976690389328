import { FC } from 'react'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { SylveraRatingFilterRangeSlider } from './components/SylveraRatingFilterRangeSlider'
import { SylveraRatingFilterProps } from './types'
import { getRatingFromIdx, getRatingFromString } from '@sylveraio/react-utils'

export const SylveraRatingFilter: FC<SylveraRatingFilterProps> = ({
  rangeSliderDisabled = false,
  sylveraRatings,
  ratingsFilter,
  filterThrottleMS = 500,
  onAnalyticsEvent,
  onFilterChange,
  onSylveraRatingFilterChange,
}) => {
  // Need to remove this so it's more generic at some point
  // These flags from the Sylvera Web App LD project will be undefined here
  const { fullProjectsData, provisionalRatings, provisionalRatingsFiltering } =
    useFlags()
  const [minRating, maxRating] = (ratingsFilter as [number, number]).map(
    getRatingFromIdx,
  )
  const initialValues = [
    getRatingFromString(sylveraRatings[0]),
    getRatingFromString(last(sylveraRatings)),
  ]

  const updateSylveraRating = throttle(
    (payload: number[]) => {
      onAnalyticsEvent({
        column_name: 'Sylvera Rating',
        min_value: sylveraRatings[payload[0]],
        max_value: sylveraRatings[payload[1]],
      })
      if (fullProjectsData) {
        if (isEqual(initialValues, payload)) {
          onFilterChange()
        } else {
          onFilterChange(payload)
        }
      } else {
        onSylveraRatingFilterChange(payload)
      }
    },
    filterThrottleMS,
    { leading: false },
  )

  if (sylveraRatings.length === 0) return null

  return (
    <SylveraRatingFilterRangeSlider
      minRating={minRating}
      maxRating={maxRating}
      values={ratingsFilter}
      max={sylveraRatings.length - 1}
      onChange={updateSylveraRating}
      disabled={
        rangeSliderDisabled && provisionalRatings && provisionalRatingsFiltering
      }
    />
  )
}

export default SylveraRatingFilter
