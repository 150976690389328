import type { Variant, VariantMapping } from './types'

export const Variants: Array<Variant> = [
  'd1',
  'd2',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'p1',
  'p2',
  'h7',
  'h8',
  'p3',
  'b1',
  'b2',
  'b3',
  'h9',
  'p4',
  'p5',
  'l1',
  'l2',
]

export const variantMappings: Record<Variant, VariantMapping> = {
  d1: {
    classes: ' text-5xl font-medium leading-[100%] tracking-[-0.96px]',
    tag: 'h1',
  },
  d2: {
    classes:
      ' text-[32px] font-bold leading-[110%] tracking-[-0.64px] uppercase',
    tag: 'h1',
  },
  h1: {
    classes: ' text-[28px] font-bold leading-[110%] tracking-[-0.56px]',
    tag: 'h1',
  },
  h2: {
    classes: ' text-2xl font-bold leading-[110%] tracking-[-0.48px] uppercase',
    tag: 'h2',
  },
  h3: {
    classes: ' text-2xl font-bold leading-[110%] tracking-[-0.48px]',
    tag: 'h3',
  },
  h4: {
    classes: ' text-base font-bold leading-[110%] tracking-[-0.32px] uppercase',
    tag: 'h4',
  },
  h5: {
    classes: ' text-base font-medium leading-[110%] tracking-[-0.32px]',
    tag: 'h5',
  },
  p1: {
    classes: ' text-lg font-normal leading-[135%] tracking-[0.18px]',
    tag: 'p',
  },
  p2: {
    classes: ' text-base font-normal leading-[140%] tracking-[0.16px]',
    tag: 'p',
  },
  b1: {
    classes: ' text-lg font-semibold leading-[115%] tracking-[0.18px]',
    tag: 'span',
  },
  b2: {
    classes: ' text-base font-semibold leading-[115%] tracking-[0.16px]',
    tag: 'span',
  },
  h8: {
    classes: ' text-sm font-bold leading-[110%] tracking-[-0.28px] uppercase',
    tag: 'span',
  },
  h7: {
    classes: ' text-sm font-medium leading-[110%] tracking-[-0.28px]',
    tag: 'span',
  },
  p3: {
    classes: ' text-sm font-normal leading-[normal] tracking-[0.14px]',
    tag: 'p',
  },
  b3: {
    classes: ' text-sm font-semibold leading-[115%] tracking-[0.14px]',
    tag: 'span',
  },
  h9: {
    classes: ' text-xs font-medium leading-[110%] tracking-[-0.24px] uppercase',
    tag: 'span',
  },
  p4: {
    classes: ' text-xs font-medium leading-[110%] tracking-[-0.24px]',
    tag: 'p',
  },
  p5: { classes: ' text-xs font-medium leading-[normal]', tag: 'p' },
  l1: {
    classes: ' text-[10px] font-bold leading-[110%] tracking-[-0.2px]',
    tag: 'span',
  },
  l2: {
    classes: ' text-[8px] font-bold leading-[110%] tracking-[-0.16px]',
    tag: 'span',
  },
}
