import { useContext } from 'react'
import { captureException } from '@sylveraio/react-utils'
import { Auth } from 'aws-amplify'
import type { ResetPassword } from './types'
import { UserContext } from '../../../contexts'

function useResetUserPassword() {
  const { email } = useContext(UserContext)

  async function resetUserPassword({
    passcode,
    password,
  }: ResetPassword): Promise<void> {
    try {
      if (typeof email === 'undefined')
        throw { message: 'It looks like something went wrong.' } as Error

      await Auth.forgotPasswordSubmit(email, passcode, password)
    } catch (err) {
      captureException(err, 'useResetUserPassword')
      throw err
    }
  }

  return { resetUserPassword }
}

export default useResetUserPassword
