import clsx from 'clsx'
import { FC } from 'react'
import {
  useClickOutsideElement,
  useHookWithRefCallback,
  getOutlookColor,
  useTooltipHover,
} from '@sylveraio/react-utils'
import { InfoTooltip } from '../InfoTooltip'
import { Tooltip } from '../Tooltip'
import { ScoreIndicatorProps } from './types'
import { Spinner } from '@sylveraio/design-system'

export const ScoreIndicator: FC<ScoreIndicatorProps> = ({
  title,
  titleTooltip,
  variant,
  value,
  averageValue,
  outlook = 'neutral',
  label,
  projectType,
  wrapperClassName,
  testId = 'score-indicator',
  loading,
}) => {
  const getScorePercentageAndText = (
    score?: number,
    isAverage?: boolean,
  ): [number, string] => {
    const roundedScore =
      isAverage && variant === 'integer'
        ? Math.round((score || 0) * 10) / 10
        : Math.round(score || 0)

    let scorePercentage = 0
    if (typeof score !== 'undefined' && !isNaN(score)) {
      if (score > 100 || (variant === 'integer' && score > 5)) {
        scorePercentage = 100
      } else {
        scorePercentage =
          variant === 'percentage' ? roundedScore : roundedScore * 20
      }
    }

    const scoreText =
      typeof score === 'undefined' || isNaN(score)
        ? `${isAverage ? '' : 'NA'}`
        : `${roundedScore}${
            variant === 'percentage' ? '%' : `${isAverage ? '' : '/5'}`
          }`

    return [scorePercentage, scoreText]
  }

  const [valuePercentage, valueText] = getScorePercentageAndText(value)
  const [averageValuePercentage, averageValueText] = getScorePercentageAndText(
    averageValue,
    true,
  )

  const [setReferenceElement, referenceElement, showTooltip, setShowTooltip] =
    useTooltipHover<HTMLSpanElement>()
  const [tooltipElement, setTooltipElement] = useHookWithRefCallback()
  useClickOutsideElement(
    tooltipElement,
    () => setShowTooltip(false),
    showTooltip,
  )

  return (
    <div
      data-testid={`${testId}-with-labels`}
      className={clsx(
        'flex flex-col w-full min-w-[256px] gap-y-1',
        wrapperClassName,
      )}
    >
      <div
        data-testid={`${testId}-labels`}
        className="flex w-full items-end justify-between gap-x-2"
      >
        {title && (
          <span className="flex items-center gap-x-1 text-sm font-semibold text-default">
            {title}
            {titleTooltip && (
              <span>
                <InfoTooltip
                  placement="right"
                  modalWrapperClassName="w-fit max-w-[240px] rounded-lg p-2 text-xs font-medium text-default"
                  iconClassName="text-default"
                  buttonSize={12}
                  showCloseButton={false}
                  offset={[0, 10]}
                  showOnHover
                >
                  {titleTooltip}
                </InfoTooltip>
              </span>
            )}
          </span>
        )}
        <span className="text-right text-xs font-medium text-subtle">
          {label}
        </span>
      </div>
      <div
        data-testid={`${testId}-bar-with-value`}
        className="flex w-full items-center justify-between gap-x-2.5"
      >
        <div
          data-testid={`${testId}-value-bar`}
          className="h-1 w-full rounded-full bg-low shadow-inner"
        >
          <div
            data-testid={`${testId}-value-bar-with-average`}
            className="relative h-full"
          >
            <div
              data-testid={`${testId}-filled-value-bar`}
              className={clsx(
                `p-0.5 leading-none rounded-full h-full flex items-center justify-center absolute`,
                getOutlookColor(outlook),
                {
                  '!bg-low': loading || !valuePercentage,
                },
              )}
              style={{ width: `${valuePercentage}%` }}
            />
            {averageValueText && !loading && (
              <>
                <div
                  data-testid={`${testId}-average-marker`}
                  className="absolute -top-1 h-3 w-px bg-selection-forest/60"
                  style={{ left: `${averageValuePercentage}%` }}
                />
                <span
                  data-testid={`${testId}-average-value`}
                  className="absolute left-0 right-0 top-2 ml-auto mr-auto w-20 text-center text-xs font-medium text-subtle"
                  style={{
                    left: `${
                      averageValuePercentage <= 50
                        ? (averageValuePercentage - 50) * 2
                        : 0
                    }%`,
                    right: `${
                      averageValuePercentage > 50
                        ? (50 - averageValuePercentage) * 2
                        : 0
                    }%`,
                  }}
                  ref={setReferenceElement}
                >
                  {`Avg. ${averageValueText}`}
                </span>
                {projectType && (
                  <Tooltip
                    referenceElement={referenceElement}
                    tooltipRef={setTooltipElement}
                    show={showTooltip}
                    offset={[0, 2]}
                    className={clsx(
                      'p-2 w-fit text-default rounded-lg text-xs font-medium drop-shadow-md pointer-events-none z-50',
                    )}
                  >
                    {`Average for ${projectType} projects`}
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
        <div
          data-testid={`${testId}-text`}
          className={clsx(
            'flex h-5 justify-center text-xs font-medium text-default',
          )}
        >
          {loading ? (
            <Spinner className="!h-5 !w-5" />
          ) : (
            <span className="text-nowrap">{valueText}</span>
          )}
        </div>
      </div>
    </div>
  )
}
