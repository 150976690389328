import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { KVPairs } from 'types'

/**
 * returns a unique and sorted list of key / value pairs
 * @param collection
 * @returns
 */
export function sortKVPairsByUniqValue(collection: KVPairs): KVPairs {
  return sortBy(uniqWith(collection, isEqual), 'value')
}
