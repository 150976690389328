import type { ChartDataset } from 'chart.js'
import type { ChartType } from '../../types'

export const DEFAULT_CHART_DATASET_STYLES = {
  bar: {
    maxBarThickness: 32,
    barPercentage: 0.75,
    borderWidth: 0,
  },
  line: {
    borderWidth: 1,
  },
} satisfies Record<ChartType, Partial<ChartDataset>>
