/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Chart, LegendItem, ScatterDataPoint } from 'chart.js'
import { RATINGS_ARRAY } from '@sylveraio/react-utils'
import millify from 'millify'
import truncate from 'lodash/truncate'
import { palette } from '@sylveraio/tailwind'
import type { ColorScheme } from 'types'

export const MIN_PAN_LIMIT = 0
export const MAX_PAN_LIMIT = 5
export const GRID_OPTIONS = {
  borderColor: palette.selection['forest'],
  borderWidth: 3,
  drawTicks: false,
  z: 1,
}
export const AXIS_LABEL_OPTIONS = {
  color: palette.selection['forest'],
  font: {
    size: 18,
    color: palette.selection['forest'],
    weight: '500',
  },
}
export const TICK_OPTIONS = {
  font: {
    size: 12,
    color: palette.selection['forest'],
    weight: '500',
  },
  padding: 15,
}
export const ELEMENT_OPTIONS = {
  point: {
    radius: 5,
    hoverRadius: 6,
  },
}

export function getXAxisTimeOptions(min: string, max: string, options?: any) {
  return {
    type: 'time',
    min,
    max,
    ...options,
  }
}

export function getYAxisRatingsOptions(options?: any, offsetYAxis?: boolean) {
  return {
    yAxisLabel: 'Ratings',
    max: offsetYAxis
      ? [undefined, ...RATINGS_ARRAY].length - 1
      : RATINGS_ARRAY.length - 1,
    ticks: {
      callback: function (value: number) {
        return offsetYAxis
          ? [undefined, ...RATINGS_ARRAY][value]
          : RATINGS_ARRAY[value]
      },
    },
    ...options,
  }
}

export function getYAxisCarbonScoreOptions(options?: any) {
  return {
    min: 0,
    yAxisLabel: 'Carbon Score',
    ticks: {
      stepSize: 0.25,
      callback: function (value: number) {
        return `${value * 100}%`
      },
    },
    ...options,
  }
}

export function getYAxisRetiredCreditsOptions(
  options?: any,
  tickOptions?: any,
) {
  return {
    yAxisLabel: 'Retired Credits',
    ticks: {
      callback: function (value: number) {
        return millify(value)
      },
      ...tickOptions,
    },
    ...options,
  }
}

export function getYAxisVolumePercentageOptions(
  options?: any,
  tickOptions?: any,
) {
  return {
    yAxisLabel: 'Volume',
    min: 0,
    max: 100,
    ticks: {
      stepSize: 25,
      callback: function (value: number) {
        return `${value}%`
      },
      ...tickOptions,
    },
    ...options,
  }
}

export function getYAxisAPCOptions(options?: any) {
  return {
    min: 1,
    max: 5,
    ticks: {
      stepSize: 1,
      callback: function (value: number) {
        if (value === 0) return null

        return value
      },
    },
    ...options,
  }
}

export function getYAxisCreditsOptions(options?: any) {
  return {
    yAxisLabel: 'Credits',
    min: 0,
    ticks: {
      callback: function (value: number) {
        return millify(value, { precision: 0 })
      },
    },
    ...options,
  }
}

export function getHighlightRange(
  range1: Array<string>,
  range2?: Array<string>,
): Array<Array<string> | undefined> {
  let range: Array<Array<string> | undefined> = []

  if (range1?.length > 0) range.push(range1)
  if (typeof range2 !== 'undefined' && range2.length > 0)
    range = [...(range1.length > 0 ? range : [undefined]), range2]

  return range
}

export function sortChartLegendBasedOnLegendID(
  a?: LegendItem,
  b?: LegendItem,
  chartInstance?:
    | Partial<
        Chart<'line' | 'bar', (number | ScatterDataPoint | null)[], unknown>
      >
    | undefined,
): number {
  if (
    typeof (
      // @ts-ignore
      chartInstance?.data?.datasets[a?.datasetIndex]?.legendId
    ) === 'undefined' &&
    typeof (
      // @ts-ignore
      chartInstance?.data?.datasets[b?.datasetIndex]?.legendId
    ) !== 'undefined'
  )
    return -1

  if (
    typeof (
      // @ts-ignore
      chartInstance?.data?.datasets[b?.datasetIndex]?.legendId
    ) === 'undefined' &&
    typeof (
      // @ts-ignore
      chartInstance?.data?.datasets[a?.datasetIndex]?.legendId
    ) !== 'undefined'
  )
    return 1

  // @ts-ignore
  return 0
}

export function getLegendTooltipEntities(
  item?: LegendItem,
  chartInstance?:
    | Partial<
        Chart<'line' | 'bar', (number | ScatterDataPoint | null)[], unknown>
      >
    | undefined,
): React.ReactNode {
  if (typeof item === 'undefined' || typeof item?.datasetIndex === 'undefined')
    return null

  if (item?.text.length >= 25 && !item.text.includes('Custom')) return item.text

  const entities: Array<string> =
    // @ts-ignore
    chartInstance?.data?.datasets?.[item.datasetIndex]?.entities

  if (typeof entities === 'undefined' || !item.text.includes('Custom'))
    return null

  return (
    <div className="flex flex-col">
      <h4 className="mb-2 text-sm">{item.text}</h4>
      <ul className="font-regular list-inside list-disc pl-1.5">
        {entities.map((entity, idx) => (
          <li key={`${entity}-${idx}`}>{truncate(entity, { length: 40 })}</li>
        ))}
      </ul>
    </div>
  )
}

/**
 * COLORS
 */
export const CHART_COLORS_DEFAULT: Array<string> = [
  palette.primary[900],
  palette.secondary.mauve,
  palette.primary.lime,
  palette.data.red,
  palette.data.orange,
  palette.data.green,
]

export const CHART_COLORS_SCHEME: Record<ColorScheme, string> = {
  purple: palette.primary[900],
  blue: palette.secondary.water,
  mauve: palette.secondary.mauve,
  lemon: palette.secondary.lemon,
}

export const MULTI_STACKED_BAR_CHART_COLORS = [
  '#DFDEFF',
  '#BFBEFF',
  palette.primary[600],
  palette.primary[900],
  '#131233',
  palette.selection['off-white-3'],
]

export function getChartColors(
  colorScheme: ColorScheme | Array<ColorScheme>,
  idx: number,
  isGroupedStacked?: boolean,
): string {
  return colorScheme
    ? colorScheme instanceof Array
      ? CHART_COLORS_SCHEME[
          colorScheme[isGroupedStacked ? (idx < 2 ? 0 : 1) : idx]
        ]
      : CHART_COLORS_SCHEME[colorScheme]
    : CHART_COLORS_DEFAULT[idx]
}

export function getHighlightRangeColor(
  colorScheme: Array<ColorScheme> | ColorScheme,
): Array<string> {
  if (typeof colorScheme === 'undefined') return []

  if (colorScheme instanceof Array)
    return [getChartColors(colorScheme, 0), getChartColors(colorScheme, 1)]

  return [getChartColors(colorScheme, 0)]
}

/**
 * MOCKS
 */

export const MOCK_LINE_CHART_DATA_CARBON_SCORE = [
  {
    label: 'Global',
    values: [
      { x: '2017-01-11T00:00:00Z', y: 1.07 },
      { x: '2018-01-11T00:00:00Z', y: 0.97 },
      { x: '2019-01-11T00:00:00Z', y: 0.82 },
      { x: '2020-01-11T00:00:00Z', y: 1.0 },
      { x: '2021-01-11T00:00:00Z', y: 1.02 },
      { x: '2022-01-11T00:00:00Z', y: 1.02 },
    ],
  },
  {
    label: 'Delta Airlines',
    values: [
      { x: '2017-01-11T00:00:00Z', y: 0.42 },
      { x: '2018-01-11T00:00:00Z', y: 0.22 },
      { x: '2019-01-11T00:00:00Z', y: 0.33 },
      { x: '2020-01-11T00:00:00Z', y: 0.56 },
      { x: '2021-01-11T00:00:00Z', y: 0.79 },
      { x: '2022-01-11T00:00:00Z', y: 1 },
    ],
  },
]

export const MOCK_LINE_CHART_DATA_CREDITS = [
  {
    label: 'Global',
    values: [
      { x: '2018-01-11T00:00:00Z', y: 40000 },
      { x: '2019-01-11T00:00:00Z', y: 80000 },
      { x: '2020-01-11T00:00:00Z', y: 80000 },
      { x: '2021-01-11T00:00:00Z', y: 60000 },
      { x: '2022-01-11T00:00:00Z', y: 100000 },
    ],
  },
  {
    label: 'Delta Airlines',
    values: [
      { x: '2018-01-11T00:00:00Z', y: 30000 },
      { x: '2019-01-11T00:00:00Z', y: 60000 },
      { x: '2020-01-11T00:00:00Z', y: 90000 },
      { x: '2021-01-11T00:00:00Z', y: 40000 },
      { x: '2022-01-11T00:00:00Z', y: 120000 },
    ],
  },
]

// APC refers to Additionality, Permanence, Co Benefits
export const MOCK_LINE_CHART_DATA_APC = [
  {
    label: 'Global',
    values: [
      { x: '2018-01-11T00:00:00Z', y: 1 },
      { x: '2019-01-11T00:00:00Z', y: 2 },
      { x: '2020-01-11T00:00:00Z', y: 3 },
      { x: '2021-01-11T00:00:00Z', y: 4 },
      { x: '2022-01-11T00:00:00Z', y: 5 },
    ],
  },
  {
    label: 'Delta Airlines',
    values: [
      { x: '2018-01-11T00:00:00Z', y: 3 },
      { x: '2019-01-11T00:00:00Z', y: 2 },
      { x: '2020-01-11T00:00:00Z', y: 1 },
      { x: '2021-01-11T00:00:00Z', y: 5 },
      { x: '2022-01-11T00:00:00Z', y: 4 },
    ],
  },
  {
    label: 'Shell',
    values: [
      { x: '2018-01-11T00:00:00Z', y: 4 },
      { x: '2019-01-11T00:00:00Z', y: 1 },
      { x: '2020-01-11T00:00:00Z', y: 2 },
      { x: '2021-01-11T00:00:00Z', y: 1 },
      { x: '2022-01-11T00:00:00Z', y: 5 },
    ],
  },
]
