import { type FC } from 'react'
import { COUNTRY_PROFILES_DISCLAIMER_LABEL } from './constants'

export const FooterCountryProfiles: FC = () => (
  <div className="border-l pl-4">
    <a
      href="https://info.sylvera.com/hubfs/Sylvera%20-%20Country%20Profiles%20Disclaimer%20.pdf"
      target="_blank"
      rel="noreferrer"
      className="border-b text-sm font-medium text-default"
      data-testid="footer-country-profiles-disclaimer-link"
    >
      {COUNTRY_PROFILES_DISCLAIMER_LABEL}
    </a>
  </div>
)
