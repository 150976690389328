import type { FC } from 'react'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import useSubmitTierUpgradeForm from './utils/useSubmitTierUpgradeForm'
import tierUpgrade from '../../assets/tierUpgrade.svg'
import { GenericCustomHubspotForm } from '../GenericCustomHubspotForm'
import type { CustomTierUpgradeHubspotFormProps } from './types'
import CONST from './constants'

export const CustomTierUpgradeHubspotForm: FC<
  CustomTierUpgradeHubspotFormProps
> = ({ formCustomOverride, onClose, setSubmitting, setSuccess, setError }) => {
  const { submitTierUpgradeForm } = useSubmitTierUpgradeForm()

  const handleFormSubmitClick = async () => {
    setSubmitting(true)

    sendAnalyticsEvent({
      eventName: 'app-tier-upgrade-requested',
    })

    try {
      await submitTierUpgradeForm({ ...formCustomOverride?.data })
      setSuccess(true)
      setError(false)
    } catch {
      setSuccess(false)
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  const handleAbandonFormSubmitClick = async () => {
    setSubmitting(true)

    sendAnalyticsEvent({
      eventName: 'app-tier-upgrade-aborted',
    })

    onClose()

    setSubmitting(false)
  }

  return (
    <GenericCustomHubspotForm
      image={{
        src: tierUpgrade,
        alt: 'Feature not included, upgrade to view.',
        width: 1059,
        height: 630,
      }}
      submitButtonText={CONST.REQUEST_UPGRADE}
      handleFormSubmitClick={handleFormSubmitClick}
      handleAbandonFormSubmitClick={handleAbandonFormSubmitClick}
    />
  )
}
