import { FeaturedIcon, Typography } from '@sylveraio/design-system'
import type { EarlyStageProject } from '../types'
import millify from 'millify'

type ProjectCardProps = EarlyStageProject

const KeyValueItem: React.FC<{
  label: string
  value: string
}> = ({ label, value }) => (
  <div>
    <Typography className="text-subtle" weight="medium" size="xs">
      {label}
    </Typography>
    <Typography size="sm">{value}</Typography>
  </div>
)

export const ProjectCard: React.FC<ProjectCardProps> = ({
  projectType,
  expectedFirstIssuanceDate,
  estimatedPrice,
  region,
  expectedProjectEndDate,
  paymentTerms,
  estimatedSupplyPerYear,
  stage,
  featuredIconColour,
  featuredIcon,
}: ProjectCardProps) => {
  return (
    <div className="p-6 flex gap-x-8 bg-high rounded-md">
      <FeaturedIcon
        size="xl"
        strong
        color={featuredIconColour}
        icon={featuredIcon}
      />
      <div className="flex-1 grid grid-cols-3 gap-y-4 gap-x-2">
        <KeyValueItem label="Project Type" value={projectType} />
        <KeyValueItem label="Region" value={region} />
        <KeyValueItem
          label="Estimated supply"
          value={`${millify(estimatedSupplyPerYear)}/year`}
        />
        <KeyValueItem
          label="Expected first issuance"
          value={expectedFirstIssuanceDate}
        />
        <KeyValueItem
          label="Expected project end date"
          value={expectedProjectEndDate}
        />
        <KeyValueItem label="Stage" value={stage} />
        <KeyValueItem
          label="Estimated price"
          value={`$${estimatedPrice.min}-${estimatedPrice.max}`}
        />
        <div className="col-span-2">
          <KeyValueItem label="Payment terms" value={paymentTerms} />
        </div>
      </div>
    </div>
  )
}
