import { FC } from 'react'
import type { RoundedDropdownProps } from './types'
import { ButtonLegacy } from '../ButtonLegacy'
import clsx from 'clsx'
import { Typography } from '../Typography'

export const RoundedDropdown: FC<RoundedDropdownProps> = ({
  testId = 'rounded-dropdown',
  title,
  label,
  isOpen,
  children,
  wrapperClassName,
  modalClassName,
  className,
  size = 'lg',
  onOpen,
}) => (
  <div data-testid={testId} className={clsx('flex flex-col', wrapperClassName)}>
    <ButtonLegacy
      testId={`${testId}-button`}
      align="none"
      appearance="none"
      className={clsx(
        'flex rounded-full bg-high items-center w-full',
        {
          '!gap-x-0': size === 'md',
          'gap-x-2': size === 'lg',
        },
        className,
      )}
      iconSuffix={{
        icon: 'smallChevron',
        direction: 'down',
      }}
      onClick={onOpen}
      size={size}
    >
      <div className="flex shrink-0 items-center gap-x-2">
        <Typography size={size === 'lg' ? 'base' : 'xs'} weight="medium">
          {title}
        </Typography>
        {label && (
          <Typography size="xs" weight="medium" className="text-subtle-on-dark">
            {label}
          </Typography>
        )}
      </div>
    </ButtonLegacy>

    {isOpen && (
      <div
        data-testid={`${testId}-modal`}
        className={clsx(
          'flex flex-col w-fit bg-high rounded-lg shadow-[0_0_10px_0px_rgba(0,0,0,0.10)] p-2',
          modalClassName,
        )}
      >
        {children}
      </div>
    )}
  </div>
)
