import type { MultiChartData } from '../MultiChart'
import union from 'lodash/union'

export function mergeDataLabels(data: Array<MultiChartData>): Array<string> {
  const dataOnly = data
    .map((cv) => cv?.datasets || [])
    .flat()
    .map(({ data }) => data)
  const xOnly = dataOnly.map((dataset) => dataset.map(({ x }) => x.toString()))
  return union(...xOnly)
}
