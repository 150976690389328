import { type FC } from 'react'
import type { DropdownListItemProps } from './types'
import { Checkbox } from '../Checkbox'
import { RadioButton } from '../RadioButton'
import clsx from 'clsx'
import { Typography } from '../Typography'

export const DropdownListItem: FC<DropdownListItemProps> = ({
  testId = 'dropdown-list-item',
  note,
  size = 'lg',
  disabled,
  multiSelect = true,
  ...props
}) => {
  const InputComponent = multiSelect ? Checkbox : RadioButton

  return (
    <div
      data-testid={testId}
      className={clsx(
        'flex items-center bg-high gap-x-2 px-2 hover:bg-medium rounded shrink-0',
        {
          'h-8': size === 'lg',
          'h-10': size === 'xl',
        },
      )}
    >
      <InputComponent
        {...props}
        disabled={disabled}
        className={clsx({
          '!bg-transparent !border-solid !border-content-action-subtle':
            disabled && multiSelect,
        })}
        labelClassName={clsx({
          '!text-subtle': disabled,
        })}
        iconClassName={clsx({
          '!text-content-action-subtle': disabled,
        })}
        hideRadio
      />

      {note && (
        <Typography size="xs" className="!text-subtle">
          {note}
        </Typography>
      )}
    </div>
  )
}
