import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const ScatterGraph: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M19.8202 20.1697H2.91604C2.57118 20.1697 2.24043 20.0327 1.99658 19.7889C1.75272 19.545 1.61572 19.2143 1.61572 18.8694V1.96527H2.91604V18.8694H19.8202V20.1697Z"
      fill="#605CFF"
    />
    <path
      d="M6.81668 16.269C7.53482 16.269 8.117 15.6868 8.117 14.9687C8.117 14.2505 7.53482 13.6684 6.81668 13.6684C6.09853 13.6684 5.51636 14.2505 5.51636 14.9687C5.51636 15.6868 6.09853 16.269 6.81668 16.269Z"
      fill="#605CFF"
    />
    <path
      d="M9.41751 11.7177C10.1357 11.7177 10.7178 11.1355 10.7178 10.4174C10.7178 9.69921 10.1357 9.11703 9.41751 9.11703C8.69936 9.11703 8.11719 9.69921 8.11719 10.4174C8.11719 11.1355 8.69936 11.7177 9.41751 11.7177Z"
      fill="#605CFF"
    />
    <path
      d="M14.6189 11.7177C15.3371 11.7177 15.9192 11.1355 15.9192 10.4174C15.9192 9.69921 15.3371 9.11703 14.6189 9.11703C13.9008 9.11703 13.3186 9.69921 13.3186 10.4174C13.3186 11.1355 13.9008 11.7177 14.6189 11.7177Z"
      fill="#605CFF"
    />
    <path
      d="M17.2195 5.86636C17.9377 5.86636 18.5198 5.28418 18.5198 4.56604C18.5198 3.84789 17.9377 3.26572 17.2195 3.26572C16.5014 3.26572 15.9192 3.84789 15.9192 4.56604C15.9192 5.28418 16.5014 5.86636 17.2195 5.86636Z"
      fill="#605CFF"
    />
    <path
      d="M9.41751 7.16683C10.1357 7.16683 10.7178 6.58466 10.7178 5.86651C10.7178 5.14837 10.1357 4.56619 9.41751 4.56619C8.69936 4.56619 8.11719 5.14837 8.11719 5.86651C8.11719 6.58466 8.69936 7.16683 9.41751 7.16683Z"
      fill="#605CFF"
    />
  </svg>
)

export default ScatterGraph
