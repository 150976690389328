import React, { FC } from 'react'
import Link from 'next/link'
import type { SignInLogoProps } from './types'
import { AppLogo } from '@sylveraio/layouts'

export const SignInLogo: FC<SignInLogoProps> = ({
  testId = 'sign-in-logo',
  signInRoute,
}) => (
  <Link href={signInRoute} passHref legacyBehavior>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a data-testid={testId} className="block space-y-5">
      <AppLogo size="xl" inverted hideAppName />
      <p className="text-default-on-dark text-2xl  font-medium">
        Incentivizing investment in real climate action
      </p>
    </a>
  </Link>
)

export default SignInLogo
