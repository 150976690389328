import { FC } from 'react'
import type { ButtonProps } from '../../Button/types'

export const PaginationButton: FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  testId,
}) => {
  const handleOnClick = () => {
    // @ts-ignore
    onClick?.()
  }

  return (
    <button
      type="button"
      onClick={handleOnClick}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </button>
  )
}

export default PaginationButton
