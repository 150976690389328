import { addBreadcrumb } from '@sentry/nextjs'
import { v4 } from 'uuid'

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/vnd.api+json',
  'Sylvera-Version': '2023-08-01',
}

export function getDefaultHeaders() {
  const reqId = v4()
  addBreadcrumb({
    message: 'Request ID',
    data: {
      reqId,
    },
  })
  return {
    ...DEFAULT_HEADERS,
    'x-syl-request-id': reqId,
  }
}
