import { type FC, useMemo } from 'react'
import clsx from 'clsx'
import {
  Sizes,
  type IconLibraryProps,
  TransitionalIconTypes,
} from '../../types'
import { ICON_DEFAULT_SIZE, iconTransistionStyles } from '../../constants'

export const SmallChevron: FC<
  Omit<IconLibraryProps & TransitionalIconTypes, 'name'>
> = ({
  testId = 'small-chevron',
  size = ICON_DEFAULT_SIZE,
  className,
  direction = 'right',
  transitionOn,
  transitionDirection,
}) => {
  const directionalStyles = useMemo(
    () => iconTransistionStyles(direction, transitionOn, transitionDirection),
    [direction, transitionOn, transitionDirection],
  )

  return (
    <svg
      data-testid={testId}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: Sizes[size] }}
      className={clsx(
        'fill-current text-default',
        { ...directionalStyles },
        className,
      )}
    >
      <path d="M5.53003 11.2566L8.58336 8.19659L5.53003 5.13659L6.47003 4.19659L10.47 8.19659L6.47003 12.1966L5.53003 11.2566Z" />
    </svg>
  )
}
