import { FC } from 'react'
import clsx from 'clsx'

import type InfoProps from './types'

const Info: FC<InfoProps> = ({ size = 16, className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current text-content-action-default', className)}
  >
    <path d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7 12.0859C4.1918 12.0859 1.91406 9.8082 1.91406 7C1.91406 4.1918 4.1918 1.91406 7 1.91406C9.8082 1.91406 12.0859 4.1918 12.0859 7C12.0859 9.8082 9.8082 12.0859 7 12.0859Z" />
    <path d="M6.34375 4.59375C6.34375 4.7678 6.41289 4.93472 6.53596 5.05779C6.65903 5.18086 6.82595 5.25 7 5.25C7.17405 5.25 7.34097 5.18086 7.46404 5.05779C7.58711 4.93472 7.65625 4.7678 7.65625 4.59375C7.65625 4.4197 7.58711 4.25278 7.46404 4.12971C7.34097 4.00664 7.17405 3.9375 7 3.9375C6.82595 3.9375 6.65903 4.00664 6.53596 4.12971C6.41289 4.25278 6.34375 4.4197 6.34375 4.59375ZM7.32812 6.125H6.67188C6.61172 6.125 6.5625 6.17422 6.5625 6.23438V9.95312C6.5625 10.0133 6.61172 10.0625 6.67188 10.0625H7.32812C7.38828 10.0625 7.4375 10.0133 7.4375 9.95312V6.23438C7.4375 6.17422 7.38828 6.125 7.32812 6.125Z" />
  </svg>
)

export default Info
