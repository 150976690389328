'use client'
import clsx from 'clsx'
import React, { FC } from 'react'
import type BadgeProps from './types'

const Badge: FC<BadgeProps> = ({
  onClose,
  onClick,
  className,
  children,
  testId,
}) => {
  const handleOnClose = (): void => {
    onClose?.()
  }

  const handleOnClick = (): void => {
    onClick?.()
  }

  return onClick ? (
    <div
      role="button"
      tabIndex={-1}
      className={clsx(
        'inline-flex items-center rounded-xl text-syl-xs bg-content-action-subtle text-default-on-dark py-1 px-2.5',
        className,
      )}
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      data-testid={testId}
    >
      {onClose && (
        <button
          className="leading-none mr-1 -mt-0.5"
          type="button"
          onClick={handleOnClose}
        >
          x
        </button>
      )}
      {children}
    </div>
  ) : (
    <div
      className={clsx(
        'inline-flex items-center rounded-xl text-sm bg-blue-200 text-default-on-dark py-1 px-2.5',
        className,
      )}
      data-testid={testId}
    >
      {children}
    </div>
  )
}

export default Badge
