import { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { SiteContainerWithData } from '../containers/SiteContainerWithData'
import { Amplify } from 'aws-amplify'
import awsConfig from '../../../aws-config'
import './styles.scss'
import { SWRConfig } from 'swr'
import {
  convertMinutesToMS,
  useAnalytics,
  useAnalyticsHandler,
  useSendAnalyticsEvent,
  AnalyticsUser,
  AnalyticsUserField,
} from '@sylveraio/react-utils'
import { HubspotStateProvider, UserProvider } from '@sylveraio/auth'
import { usePosthogIdentify, useUser } from '@sylveraio/data'
import { fetcher } from '../services/swrFetcher'
import { DatadogInit } from '@sylveraio/auth-utils'
import { FontLayer } from '../containers/FontLayer'
import { apolloClient } from '../libs/graphQLClient'
import { LDProvider } from '../containers/LDProvider'

Amplify.configure(awsConfig)

export function useAnalyticsUserDetails(): [boolean, AnalyticsUser] {
  const { data } = useUser()
  const [userDetails, setUserDetails] = useState<AnalyticsUser>({
    uid: data?.userId,
    userEmail: data?.email,
    userOrganisation: data?.organisation,
  })
  const [hasValidUserDetails, setHasValidUserDetails] = useState(false)

  // Set the user details object
  useEffect(() => {
    setUserDetails({
      uid: data?.userId,
      userEmail: data?.email,
      userOrganisation: data?.organisation,
    })
  }, [data])

  // Ensure we have a valid user details object
  useEffect(() => {
    const userDetailFields: AnalyticsUserField[] = [
      'uid',
      'userEmail',
      'userOrganisation',
    ]
    setHasValidUserDetails(
      !userDetailFields.some(
        (field) =>
          typeof userDetails[field] === 'undefined' ||
          userDetails[field] === '',
      ),
    )
  }, [userDetails])

  return [hasValidUserDetails, userDetails]
}

function Dashboard({ Component, pageProps }: AppProps) {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent(useAnalyticsUserDetails)

  useAnalytics(process.env.NEXT_PUBLIC_POSTHOG)
  useAnalyticsHandler(sendAnalyticsEvent)
  const { data } = useUser()

  usePosthogIdentify(data)

  return (
    <ApolloProvider client={apolloClient}>
      <DatadogInit />
      <UserProvider>
        <SWRConfig
          value={{
            refreshInterval: convertMinutesToMS(10),
            fetcher,
            revalidateOnFocus: false,
          }}
        >
          <LDProvider>
            <HubspotStateProvider>
              <FontLayer>
                <SiteContainerWithData>
                  <div className="app flex !h-full grow">
                    <Component {...pageProps} />
                  </div>
                </SiteContainerWithData>
              </FontLayer>
            </HubspotStateProvider>
          </LDProvider>
        </SWRConfig>
      </UserProvider>
    </ApolloProvider>
  )
}

export default Dashboard
