import {
  type FontWeights,
  type TypographyBundlesMapType,
  fontSizes,
  fontWeights,
  TypographyBundlesTagMapType,
} from '../../types'
import { createTypographyBundles } from '../createTypographyBundles/createTypographyBundles'
import type { CreateTypographyBundlesReturn } from '../createTypographyBundles'

const typographyBundlesWithTags: TypographyBundlesTagMapType = {
  base: { semibold: 'h4' },
  lg: { semibold: 'h3' },
  '2xl': { semibold: 'h2' },
  '3xl': { semibold: 'h1' },
}

/**
 * Creates a map of typography bundles based on predefined font sizes and weights, optionally including HTML tags.
 * This function iterates over predefined lists of font sizes and font weights, fetching corresponding tags
 * from a `typographyBundlesWithTags` structure. For each combination of size and weight, it calls the
 * `createTypographyBundles` function to generate CSS class strings and optional tag properties.
 *
 * @returns {TypographyBundlesMapType} A map object where each key is a font size, and each value is a record object
 * mapping font weights to typography bundle objects. Each typography bundle includes classes and an a HTML tag.
 *
 */
export function createTypographyBundlesMap(): TypographyBundlesMapType {
  const map: TypographyBundlesMapType = {} as TypographyBundlesMapType

  for (const size of fontSizes) {
    map[size] = {} as Record<FontWeights, CreateTypographyBundlesReturn>
    for (const weight of fontWeights) {
      const tag = typographyBundlesWithTags[size]?.[weight]
      map[size][weight] = createTypographyBundles(size, weight, tag)
    }
  }

  return map
}
