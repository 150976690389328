import uniq from 'lodash/uniq'
import concat from 'lodash/concat'

/**
 * Returns column details from table data
 * @param tableData
 * @returns
 */
export function extractTableColumns(tableData: Array<Record<string, unknown>>) {
  const _columnDetails = uniq(
    concat([], ...tableData.map((entry) => Object.keys(entry))),
  )

  return _columnDetails.map((column) => ({
    datasetKey: column,
    sortable: true,
    title: column,
  }))
}
