import { FC } from 'react'
import { LegendItemProps } from './types'

export const StackBarChartLegendItem: FC<LegendItemProps> = ({
  testId = 'stacked-bar-chart-legend-item',
  item,
}) => (
  <div data-testid={testId} className="flex items-center gap-4">
    <div
      className="w-6 h-6"
      style={{
        backgroundColor: `${item.fillStyle}`,
      }}
    />
    <span className="text-xs font-medium uppercase text-default">
      {item.text}
    </span>
  </div>
)

export default StackBarChartLegendItem
