import { Typography } from '@sylveraio/design-system'
import { FieldContainer } from './FieldContainer'
import { Select } from './Select'
import type { ChangeEvent } from 'react'
import type { Value } from '../types'

export function SelectFieldContainer({
  name,
  handleSelect,
  values,
  currentValue,
  label,
  hasError,
  errorMsg,
}: {
  name: string
  label?: string
  handleSelect: (ev: ChangeEvent<HTMLSelectElement>) => void
  values: Array<Value>
  currentValue: unknown
  hasError?: boolean
  errorMsg?: string
}): JSX.Element {
  return (
    <label className="space-y">
      <Typography size="sm" weight="medium" className="text-subtle">
        {label}
      </Typography>
      <FieldContainer hasError={hasError} errorMsg={errorMsg}>
        <Select
          name={name}
          handleSelect={handleSelect}
          values={values}
          currentValue={currentValue}
        />
      </FieldContainer>
    </label>
  )
}
