'use client'

import { RefObject, useCallback, useEffect } from 'react'

export const useClickOutsideElement = <
  T extends HTMLDivElement | HTMLButtonElement | HTMLSpanElement,
>(
  ref: RefObject<T>,
  callbackFunction: () => void,
  isOpen = false,
  isBubbling: boolean | undefined = undefined,
) => {
  const escapeKeyListener = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') callbackFunction()
  }

  const outsideClickListener = useCallback((event: MouseEvent): void => {
    if (
      ref?.current &&
      event.target instanceof Node &&
      !ref.current.contains(event.target) &&
      !(event.target as T).matches(
        '[data-ignore-outside-click], [data-ignore-outside-click] *',
      )
    ) {
      callbackFunction()
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', outsideClickListener, isBubbling)
      document.addEventListener('keyup', escapeKeyListener)
    }

    // eslint-disable-next-line consistent-return
    return (): void => {
      if (isOpen) {
        document.removeEventListener('click', outsideClickListener, isBubbling)
        document.removeEventListener('keyup', escapeKeyListener)
      }
    }
  }, [isOpen])
}

export default useClickOutsideElement
