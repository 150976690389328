import type { FC } from 'react'
import { useTooltipContext } from './utils/useTooltipContext'

export const TooltipTrigger: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const context = useTooltipContext()

  return (
    <span
      data-testid="tooltip-reference"
      className="block w-fit"
      ref={context.refs.setReference}
      {...context.getReferenceProps()}
    >
      {children}
    </span>
  )
}
