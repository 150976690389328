'use client'

import { useState, type FC } from 'react'

import type { AccordionProps } from './types'
import { AccordionItem } from '../AccordionItem'

export const Accordion: FC<AccordionProps> = ({
  testId = 'accordion',
  items,
  size,
  multiExpand = false,
}) => {
  const [currentSelectedAccordions, setCurrentSelectedAccordions] = useState<
    string[]
  >([])

  function handleOnClick(event: React.MouseEvent<HTMLButtonElement>) {
    const id = event.currentTarget.id

    if (multiExpand) {
      if (currentSelectedAccordions.includes(id)) {
        setCurrentSelectedAccordions(
          currentSelectedAccordions.filter((accId) => accId !== id),
        )
      } else {
        setCurrentSelectedAccordions([...currentSelectedAccordions, id])
      }
    } else {
      if (currentSelectedAccordions.includes(id)) {
        setCurrentSelectedAccordions([])
      } else {
        setCurrentSelectedAccordions([id])
      }
    }
  }

  return (
    <div data-testid={testId} className="flex w-full flex-col gap-y-4">
      {items.map(({ id, ...props }, idx) => {
        return (
          <AccordionItem
            key={id}
            id={id}
            testId={`${testId}-item-${idx}`}
            onClick={handleOnClick}
            size={size}
            isOpen={id ? currentSelectedAccordions.includes(id) : false}
            {...props}
          />
        )
      })}
    </div>
  )
}
