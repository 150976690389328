import type { ChartDataset, MultiChartData } from '../../../Charts'
import type {
  ContentfulBarChart,
  ChartWidgetProps,
  ContentfulLineChart,
  ContentfulChart,
} from '../../types'
import { gql, type TypedDocumentNode } from '@apollo/client'
import { searchDeepKey } from '../searchDeepKey'
import { DEFAULT_QUERY_LIMIT } from './constants'
import type { ScatterDataPoint } from '../../../Charts/types'

async function fetchChartData(
  chartId: string,
  client: ChartWidgetProps['chartDataClient'],
  query: string,
  queryVariables?: ChartWidgetProps['chartDataQueryVariables'],
): Promise<Array<ChartDataset>> {
  const { data } = await client.query<TypedDocumentNode<any>>({
    query: gql`
      ${query}
    `,
    variables: {
      ...queryVariables,
      first: queryVariables?.['first'] ?? DEFAULT_QUERY_LIMIT,
    },
    fetchPolicy: 'cache-first',
    context: { apiName: 'sylvera' },
  })

  const nodes = searchDeepKey(data, 'nodes')

  if (Array.isArray(nodes)) {
    const chartData: ScatterDataPoint[] = nodes.reduce((acc, node) => {
      const x = searchDeepKey(node, 'x')
      const y = searchDeepKey(node, 'y')

      if (x && y) {
        return [...acc, { x, y }]
      }
      return acc
    }, [])

    return [
      {
        label: chartId,
        data: chartData,
      },
    ]
  }

  return []
}

/**
 * Extracts chart data based on the provided Contentful chart configurations.
 * The data can be sourced from the GraphQL API using an Apollo client, static data
 * from the Contentful chart configurations, or directly from local input.
 *
 * @param charts - An array of Contentful chart configurations, each potentially containing a GraphQL query or static data.
 * @param client - Apollo client instance used for executing GraphQL queries to fetch data.
 * @param queryVariables - Variables to be passed into the GraphQL queries for data retrieval.
 * @param localData - Data passed directly into the ChartWidget component.
 */
export async function extractChartData(
  charts: Array<ContentfulChart>,
  client: ChartWidgetProps['chartDataClient'],
  queryVariables?: ChartWidgetProps['chartDataQueryVariables'],
  localData?: ChartWidgetProps['localData'],
) {
  const validCharts = charts.filter(
    (chart) => typeof chart.__typename !== 'undefined',
  )

  const chartDataPromises = validCharts.map((chart) => {
    if (chart.query) {
      return fetchChartData(chart.chartId, client, chart?.query, queryVariables)
    }

    if (chart.staticData) {
      return Promise.resolve(chart.staticData)
    }

    return Promise.resolve(localData?.[chart.chartId] || [])
  })

  const chartDataResults = await Promise.all(chartDataPromises)

  const chartDataById = Object.fromEntries(
    validCharts.map((chart, index) => [chart.chartId, chartDataResults[index]]),
  )

  return validCharts.map<MultiChartData>((chart) => {
    switch (chart.__typename) {
      case 'LineChart': {
        chart as ContentfulLineChart
        return {
          type: 'line',
          datasets: chartDataById[chart.chartId],
          segmentAfter: chart?.segmentAfter
            ? (ctx: any) =>
                Number(ctx.p0.raw.x) > (chart?.segmentAfter || 0)
                  ? [6, 6]
                  : undefined
            : undefined,
          borderColors: chart?.borderColors || [],
          pointStyle: false,
          yAxisID: chart.isRightYAxis ? 'y1' : 'y',
          enableHighlighting: chart?.enableHighlighting,
        }
      }
      default:
      case 'BarChart': {
        chart as ContentfulBarChart
        return {
          type: 'bar',
          datasets: chartDataById[chart.chartId],
          stack: chart.stack,
          backgroundColors: chart?.backgroundColors || [],
          fill: false,
          enablePanning: chart?.enablePanning,
        }
      }
    }
  })
}
