/* eslint-disable prefer-rest-params */

import type { UserDetails } from 'types'
import crypto from 'crypto'
/* eslint-disable no-var */
export const APP_ID = process.env['NEXT_PUBLIC_INTERCOM_APP_ID']
export const API_BASE = process.env['NEXT_PUBLIC_INTERCOM_API_BASE']

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom

// prettier-ignore
export const load = () => {
  // @ts-ignore
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
}

// Initializes Intercom
export const boot = (
  userDetails: UserDetails,
  userHash: string,
  options = {},
) => {
  window &&
    // @ts-ignore
    window.Intercom &&
    // @ts-ignore
    window.Intercom('boot', {
      app_id: APP_ID,
      api_base: API_BASE,
      user_id: userDetails.userId,
      email: userDetails.email,
      name: `${userDetails.givenName} ${userDetails.familyName}`,
      company: {
        id: userDetails.organisationId,
        name: userDetails.organisation,
      },
      user_hash: userHash,
      ...options,
    })
}

export const update = () => {
  // @ts-ignore
  window && window.Intercom && window.Intercom('update')
}

export const shutdown = () => {
  if (typeof window === 'undefined') return
  // @ts-ignore
  window && window.Intercom && window.Intercom('shutdown')
}

export function getHash(userId?: string): string | undefined {
  if (!userId || typeof process.env['INTERCOM_SECRET_KEY'] === 'undefined') {
    return
  }

  const secretKey = process.env['INTERCOM_SECRET_KEY']
  const hash = crypto
    .createHmac('sha256', secretKey)
    .update(userId)
    .digest('hex')

  return hash
}
