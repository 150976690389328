import type { FC } from 'react'
import clsx from 'clsx'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'
import type { AccordionItemProps } from './types'
import { Typography } from '../Typography'

export const AccordionItem: FC<AccordionItemProps> = ({
  testId = 'accordion-item',
  id,
  isOpen = false,
  size = 'md',
  title,
  icon,
  labelLeading,
  labelTrailing,
  body,
  slot,
  onClick,
}) => {
  const baseClass =
    'flex w-full flex-col items-start rounded-lg border border-default bg-medium'
  const accordionItemSizeClass = clsx({
    'p-3 gap-y-2': size === 'sm',
    'p-4 gap-y-3': size === 'md',
  })
  const accordionIconSizeClass = clsx({
    'h-5 w-5': size === 'sm',
    'h-6 w-6': size === 'md',
  })
  const accordionBodyClass = clsx(
    'flex items-start flex-col gap-x-3 w-full text-left',
    {
      'pl-8': size === 'sm' && typeof icon !== 'undefined',
      'pl-9': size === 'md' && typeof icon !== 'undefined',
      hidden: !isOpen,
    },
  )
  const accordionTextSize = size === 'md' ? 'base' : 'sm'

  return (
    <button
      id={id}
      data-testid={testId}
      data-open={isOpen}
      className={clsx(baseClass, accordionItemSizeClass)}
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between gap-x-3">
        <div className="flex grow items-center gap-x-3">
          {typeof icon !== 'undefined' && (
            <span
              data-testid={`${testId}-icon`}
              className={clsx('shrink-0', accordionIconSizeClass)}
            >
              {icon}
            </span>
          )}

          {typeof labelLeading !== 'undefined' && (
            <span data-testid={`${testId}-leading-label`}>{labelLeading}</span>
          )}

          <Typography
            testId={`${testId}-title`}
            size={accordionTextSize}
            weight="semibold"
            className="text-left"
          >
            {title}
          </Typography>

          {typeof labelTrailing !== 'undefined' && (
            <span data-testid={`${testId}-trailing-label`}>
              {labelTrailing}
            </span>
          )}
        </div>
        <span
          data-testid={`${testId}-arrow`}
          className={clsx('shrink-0', accordionIconSizeClass, {
            'rotate-180': isOpen,
          })}
        >
          <ChevronDown />
        </span>
      </div>
      <div
        data-testid={`${testId}-body`}
        className={accordionBodyClass}
        hidden={!isOpen}
      >
        <Typography
          testId={`${testId}-content`}
          size={accordionTextSize}
          weight="regular"
        >
          {body}
        </Typography>
        {typeof slot !== 'undefined' && (
          <span data-testid={`${testId}-slot`}>{slot}</span>
        )}
      </div>
    </button>
  )
}
