import { FC } from 'react'
import IconsProps from '../types'

export const Analytics: FC<IconsProps> = ({ size = 16 }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <path
      d="M19.2694 15.2808L19.2651 15.2887L19.2611 15.2966C18.8546 16.1096 18.3864 16.7819 17.801 17.375L15.6049 14.1653C15.6343 14.1256 15.6665 14.0814 15.7052 14.0262C15.8038 13.8853 15.9065 13.7201 15.9801 13.5144C16.3082 12.8517 16.5303 12.1802 16.6417 11.4008C16.7554 10.6043 16.6484 9.81846 16.4338 9.14523C16.4163 8.91293 16.3343 8.72781 16.2665 8.59236C16.2503 8.55987 16.2355 8.53108 16.222 8.50501L18.91 5.81691C19.3605 6.48799 19.7311 7.19561 19.9702 7.83057C20.3607 9.00704 20.4629 10.2961 20.3621 11.606C20.263 12.895 19.8652 14.1885 19.2694 15.2808Z"
      stroke="#01311D"
    />
    <path
      d="M4.96475 10.4368L4.96472 10.4368L4.96418 10.4422C4.84934 11.5906 5.08015 12.6277 5.53825 13.544C5.99485 14.4572 6.6835 15.2629 7.60605 15.8394C8.50807 16.4032 9.54145 16.7535 10.5947 16.7535C11.1579 16.7535 11.7161 16.6754 12.2455 16.5896L14.3554 19.7122C13.1202 20.2332 11.8094 20.4507 10.4163 20.3637L10.4145 20.3635C8.70815 20.2632 7.02293 19.7622 5.64756 18.7797L5.63761 18.7726L5.62732 18.766C4.23339 17.8699 3.04728 16.5807 2.35812 15.0055C1.64726 13.3807 1.25795 11.6916 1.45383 10.0266C1.65387 8.32629 2.25302 6.73073 3.2497 5.33537C4.24124 3.94722 5.63422 2.85034 7.23342 2.15069C8.81827 1.45732 10.5072 1.2559 12.2085 1.45545C13.5054 1.62897 14.8099 2.10564 15.9855 2.82275L13.1945 5.61377C12.7181 5.43808 12.1729 5.27229 11.6197 5.18008L11.6062 5.17784L11.5927 5.17634C10.5853 5.06441 9.43406 5.17079 8.48548 5.64508C7.60511 6.08527 6.70041 6.75406 6.11526 7.56457C5.41245 8.39321 5.07617 9.43401 4.96475 10.4368Z"
      stroke="#01311D"
    />
  </svg>
)

export default Analytics
