import type {
  FormAcceptedValues,
  FormFieldType,
  Key,
  ValueBase,
} from '../types'
import { checkIsValueBase } from './typeguards/checkIsValueBase'
import { valueToString } from './valueToString'

export function getFormText(
  type: FormFieldType,
  entry?: ValueBase<FormAcceptedValues> | ValueBase<FormAcceptedValues>[],
  customToString?: Key['toStringFn'],
  opts?: { suffix?: string },
): string | Array<string> {
  if (!entry) return ''
  const suffix = typeof opts?.suffix === 'string' ? ` ${opts.suffix}` : ''
  if (checkIsValueBase(entry)) {
    return valueToString(type, entry.text, customToString) + suffix
  } else {
    return (
      valueToString(
        type,
        entry.map(({ text }) => text).flat(),
        customToString,
      ) + suffix
    )
  }
}
