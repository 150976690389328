'use client'

import { usePathname } from 'next/navigation'
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
} from './intercom'
import { useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { UserDetails } from 'types'
import { captureException } from '../captureException'

export const useIntercom = (userDetails?: UserDetails) => {
  const { showIntercom } = useFlags()
  const pathname = usePathname()
  const intercomActive = useMemo(
    () =>
      showIntercom &&
      typeof process.env['NEXT_PUBLIC_INTERCOM_APP_ID'] !== 'undefined' &&
      process.env['NEXT_PUBLIC_INTERCOM_API_BASE'] !== 'undefined' &&
      typeof pathname !== 'undefined' &&
      pathname !== '/sign-in',
    [showIntercom, pathname],
  )
  const [intercomId, setIntercomId] = useState<string | null>(null)

  if (typeof window !== 'undefined' && intercomActive) {
    loadIntercom()
  }

  useEffect(() => {
    if (
      !intercomActive ||
      !intercomId ||
      typeof userDetails === 'undefined' ||
      !userDetails?.userId
    )
      return

    bootIntercom(userDetails, intercomId)
  }, [intercomActive, intercomId, userDetails])

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        updateIntercom()
      }
    }

    if (intercomActive) {
      handleRouteChange() // Call once initially if active
    }

    return () => {
      // Cleanup if necessary when intercomActive changes
    }
  }, [pathname, intercomActive])

  useEffect(() => {
    if (userDetails?.userId) {
      fetch(`/api/intercom`, {
        method: 'POST',
        body: JSON.stringify({ userId: userDetails.userId }),
      })
        .then((r) => r.json())
        .then((r) => setIntercomId(r.id))
        .catch((e) => captureException(e, 'useIntercom'))
    }
  }, [userDetails?.userId])
}
