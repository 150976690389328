import merge from 'lodash/merge'
import type { MultiChartData } from '../../../Charts'
import concat from 'lodash/concat'
import type { ChartDataset } from '../../../Charts/MultiChart'

/**
 * Converts chart data into table data
 * @param labels
 * @param chartData
 * @param xAxisTitle
 * @returns
 */
export function extractTableData(
  labels: Array<string>,
  chartData: Array<MultiChartData>,
  xAxisTitle: string,
): Array<Record<string, unknown>> {
  return labels.reduce((acc: Array<Record<string, unknown>>, cv) => {
    const allDataAndLabels = chartData
      .map(({ datasets }) => datasets)
      .flat()
      .filter((entry) => typeof entry !== 'undefined') as Array<ChartDataset>
    const xVals = allDataAndLabels
      .map(({ data, label }) => {
        return concat(
          [],
          data
            .filter(({ x }) => x.toString() === cv)
            .map(({ x, y }) => ({ [xAxisTitle]: x.toString(), [label]: y }))
            .flat(),
        )
      })
      .flat()

    return [...acc, merge({}, ...xVals)]
  }, [])
}
