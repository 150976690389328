'use client'

import { Dispatch, MutableRefObject, SetStateAction, useRef } from 'react'
import { useHover } from '../useHover'

export function useTooltipHover<T extends HTMLElement = HTMLElement>(): [
  MutableRefObject<T | null>,
  T | null,
  boolean,
  Dispatch<SetStateAction<boolean>>,
] {
  const referenceElement = useRef<T | null>(null)
  const [isHovered, setIsHovered] = useHover(referenceElement)

  return [referenceElement, referenceElement?.current, isHovered, setIsHovered]
}

export default useTooltipHover
