'use client'

import { FC } from 'react'
import { AppLogoProps } from './types'

export const TierName: FC<Pick<AppLogoProps, 'tierName' | 'testId'>> = ({
  tierName,
  testId,
}) => {
  // Fetch whether to show the tierName or not
  return (
    typeof tierName !== 'undefined' && (
      <span data-testid={`${testId}-tier`}>{tierName}</span>
    )
  )
}
