import clsx from 'clsx'
import type { FeaturedIconSizes } from '../../types'

/**
 * Get the size of the featured icon component
 * @param {FeaturedIconSizes} size - The size of the featured icon component
 * @returns {string} - The class name for the featured icon size
 */
export function getFeaturedIconSize(size: FeaturedIconSizes) {
  return clsx({
    'w-8 h-8 rounded-md p-2': size === 'sm',
    'w-10 h-10 rounded-lg p-2.5': size === 'md',
    'w-12 h-12 rounded-lg p-3': size === 'lg',
    'w-14 h-14 rounded-xl p-3.5': size === 'xl',
  })
}
