import { FC } from 'react'
import type { SignInWrapperProps } from './types'
import { getAssetsUrl, shutdownIntercom } from '@sylveraio/react-utils'

const SignInWrapper: FC<SignInWrapperProps> = ({
  testId = 'sign-in-wrapper',
  children,
}) => {
  shutdownIntercom()

  return (
    <div
      data-testid={testId}
      style={{
        backgroundImage: `url(${getAssetsUrl()}/images/shared/sign-in.png)`,
      }}
      className={`flex absolute -inset-0 items-center h-screen bg-no-repeat bg-center bg-cover justify-center`}
    >
      <div className="container flex flex-col items-center px-16 desktop-sm:flex-row space-y-7 desktop-sm:space-y-0">
        {children}
      </div>
    </div>
  )
}

export default SignInWrapper
