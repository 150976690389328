import { FC } from 'react'
import type { FormErrorTooltipArrowProps } from './types'

const FormErrorTooltipArrow: FC<FormErrorTooltipArrowProps> = ({
  testId = 'form-error-tooltip-arrow',
}) => (
  <div data-testid={testId}>
    <span className="absolute w-0 h-0 border border-solid pointer-events-none border-b-content-alert-default border-content-alert-default/0 border-[9px] ml-[-9px] bottom-full left-1/2" />
    <span className="absolute w-0 h-0 border border-solid pointer-events-none border-b-white border-white/0 border-[8px] ml-[-8px] bottom-full left-1/2" />
  </div>
)

export default FormErrorTooltipArrow
