'use client'

import { useMemo, type FC } from 'react'
import type { SearchProps } from './types'
import { themeComponents } from './constants'

export const Search: FC<SearchProps> = ({ theme = 'default', ...props }) => {
  const ThemeSearchComponent = useMemo(() => themeComponents[theme], [theme])

  if (!ThemeSearchComponent) {
    return null
  }

  return <ThemeSearchComponent {...props} />
}
