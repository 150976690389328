import {
  captureException as _captureException,
  addBreadcrumb,
} from '@sentry/nextjs'
import isError from 'lodash/isError'
import { COMMON_ERRORS } from '@sylveraio/constants'
import { isGraphQLError } from '../isGraphQLError'
import type { GraphQLError } from 'graphql'

export function captureException(
  e: Error | GraphQLError | unknown,
  breadcrumb = 'Not stated',
) {
  if (isError(e) || isGraphQLError(e)) {
    const { message } = e
    if (
      message === COMMON_ERRORS.NO_VALID_TOKEN ||
      message === COMMON_ERRORS.HASURA_GRAPHQL_NO_VALID_TOKEN
    ) {
      return
    }
  }

  addBreadcrumb({
    message: breadcrumb,
  })

  _captureException(e, (scope) => {
    scope.clear()
    scope.setContext('calledFrom', {
      page: breadcrumb,
    })
    scope.setTag('component', breadcrumb)
    return scope
  })
}
