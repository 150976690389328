import clsx from 'clsx'
import { FC } from 'react'
import Sidebar from './Sidebar'
import { SidebarWrapperProps } from './types'

export const SidebarWrapper: FC<SidebarWrapperProps> = ({
  children,
  sidebarContents,
  ...sidebarProps
}) => (
  <>
    <Sidebar {...sidebarProps} className={clsx('w-80', sidebarProps.className)}>
      {sidebarContents}
    </Sidebar>
    <section className="w-screen flex flex-row">
      <div
        className={clsx(
          'origin-left shrink transition-[flex-basis] duration-1000 ease-in-out',
          {
            'basis-80': sidebarProps.isOpen,
            'basis-0': !sidebarProps.isOpen,
          },
        )}
      />
      <div className={clsx('h-screen grow basis-auto')}>{children}</div>
    </section>
  </>
)
