import upperFirst from 'lodash/upperFirst'
import type {
  FormAcceptedValues,
  FormData,
  FormKeyData,
  FormPage,
  Value,
  ValueBase,
  Values,
} from '../types'
import { getValidFields } from './getValidFields'
import { checkIsSubmit } from './typeguards/checkIsSubmit'
import { getFormText } from './getFormText'

/**
 * Maps the field's information to it's key
 * @param pages
 * @param currentForm
 * @param currentFormValues
 * @returns
 */
export function getFormKeysMap(
  pages: Array<FormPage>,
  currentForm: FormData,
  currentFormValues: Record<string, FormAcceptedValues>,
  includeSubmit?: boolean,
): Map<string, FormKeyData> {
  const formKeys = pages.reduce((arr, p) => {
    // Don't include submit page
    if (!includeSubmit && checkIsSubmit(p)) return arr
    const validFields = getValidFields(p.fields, currentFormValues)

    validFields.forEach((field) => {
      if (checkIsSubmit(field)) return

      const currentValue: FormAcceptedValues | undefined =
        currentFormValues[field.key]
      let icon = field?.icon
      if (field.type === 'radio' && currentValue) {
        const currentValueEntry = field.values.find((v) => {
          const { icon, value } = v as unknown as Value
          return (
            value === currentValue && typeof icon?.component !== 'undefined'
          )
        }) as Value | undefined
        if (typeof currentValueEntry?.icon !== 'undefined')
          icon = currentValueEntry.icon
      }

      let suffix: string | undefined
      if (
        field.type === 'input' &&
        field?.withSelect?.key &&
        currentForm[field?.withSelect?.key]
      ) {
        suffix = (currentForm[field.withSelect.key] as ValueBase).text
      }

      const data = {
        label:
          field?.label ||
          upperFirst(
            field.key.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase(),
          ),
        icon,
        required: field?.required || false,
        value: currentValue,
        type: field.type,
        page: p.title,
        name: field.key,
        suffixes:
          field.type === 'tuple' ? field.fields.map((v) => v.suffix || '') : [],
        childKeys:
          field.type === 'input' &&
          field?.withSelect?.key &&
          currentForm[field?.withSelect?.key]
            ? [
                {
                  key: field.withSelect.key,
                  values: currentForm[field.withSelect.key] as Values,
                },
              ]
            : [],
        toString: () =>
          getFormText(field.type, currentForm[field.key], field.toStringFn, {
            suffix,
          }),
        transformValue: field.transformValue,
      }

      arr.set(field.key, data)
    })

    return arr
  }, new Map<string, FormKeyData>())

  return formKeys
}
