'use client'
import React, { FC, useCallback } from 'react'
import { useRouter } from 'next/navigation'
import { LogoutIcon } from '@sylveraio/react-ui/server'
import { signOut } from '@sylveraio/auth-utils/server'
import { sendAnalyticsEvent } from '@sylveraio/react-utils/server'
import { UserNavMenuItem } from '../UserNavMenuItem'
import CONST from '../UserNavMenu.constants'
import { LogoutProps } from './types'
import { addBreadcrumb } from '@sentry/nextjs'

const Logout: FC<LogoutProps> = ({ signInUrl = '/sign-in' }) => {
  const router = useRouter()

  // N.B. Will need to be able to specify different event names
  const logout = useCallback(async () => {
    sendAnalyticsEvent({ eventName: 'pa-logout-attempt' })

    addBreadcrumb({
      message: 'Manual logout',
    })
    await signOut(
      () => {
        sendAnalyticsEvent({ eventName: 'pa-logout-success' })
        router.push(signInUrl)
      },
      () => {
        sendAnalyticsEvent({ eventName: 'pa-logout-failure' })
      },
    )
  }, [])

  return (
    <UserNavMenuItem
      text={CONST.LOGOUT}
      icon={<LogoutIcon size={20} className="!fill-selection-forest" />}
      onClick={logout}
      testId="user-nav-button-logout"
    />
  )
}

export default Logout
