// has a life of 30 minutes
export const ACCESS_COOKIE = `syl-${process.env['NEXT_PUBLIC_AMP_WEB_CLIENT_ID']}-access`
// has a life of one day
export const REFRESH_COOKIE = `syl-${process.env['NEXT_PUBLIC_AMP_WEB_CLIENT_ID']}-refresh`
// has a life of two minutes
export const REVALIDATION_COOKIE = `syl-${process.env['NEXT_PUBLIC_AMP_WEB_CLIENT_ID']}-revalidation`
// access token life
export const ACCESS_EXPIRES = 0.5
// refresh token life
export const EXPIRES_AFTER_DAYS = 1
// revalidation token life
export const REVALIDATE_EXPIRES = 2
