import clsx from 'clsx'
import type { LabelSizes } from '../../types'

/**
 * Get the size of the label component
 * @param {LabelSizes} size - The size of the label component
 * @returns {string} - The class name for the label size
 */
export function getLabelSize(size: LabelSizes) {
  return clsx({
    'h-5 rounded-md px-1.5 py-0.5 gap-x-1': size === 'sm',
    'h-6 rounded-md px-2 py-0.5 gap-x-1': size === 'md',
    'h-7 rounded-lg px-2 py-0.5 gap-x-1.5': size === 'lg',
  })
}
