import type { ReactNode } from 'react'

export const MODAL_HEADER_TEXT = 'Data Disclaimer'
export const MODAL_TEXT_ONE =
  'The data represented is derived from disclosures to CDP and/or registries. We are aware that the data reported to, and maintained by, CDP and the registries is not always accurate and may differ materially from an organisation’s actual carbon credit portfolios and retirements. Specifically, it is not always possible to identify the retiree from a registry’s retirement data, which creates the risk of under or double-counting retirements depending on the assumptions applied. Whilst CDP data is self-reported so the retiree is more easily identifiable, it is not independently verified, meaning there is no guarantee of its accuracy. We have also been required to make certain assumptions concerning the data (for example, if an organisation specifies (i) the projects it has retired from, and (ii) its total retired volume, but (iii) does not specify how that retirement volume is split amongst the listed projects, the volumes retired by projects were pro-rated based on the project’s total issuance). Data from CDP is typically more complete than data from registries, however, given the CDP data is provided with one year lag (i.e., 2021 data only provided at end of 2022), it is not as “live” as the data from the registries. When combining the data sets, we have sought to optimise for data reliability and liveness within these limitations. This may result in periodic changes in volumes represented as registry data is “trued up” against CDP data over time. We will always show the source of the data so that a user can form a view on the data presented.'
export const MODAL_TEXT_TWO =
  'Whilst we expect the quality of disclosures to improve over time given regulatory initiatives, for now, the data in the product is the best efforts representation of potentially incomplete and inaccurate underlying data.'
export const MODAL_SEGMENT: ReactNode = (
  <>
    Lastly, customers are encouraged to email{' '}
    <a href="mailto:product@sylvera.io">product@sylvera.io</a> should they
    notice an error in a company&apos;s data, or certain missing information.
    Once validated, Sylvera will seek to promptly update the company&apos;s
    profile. To ensure the integrity of the data product, Sylvera is only able
    to accept complete, and not selectively presented, missing information
    (i.e., retirements from all missing projects, not just one highly-rated
    project).
  </>
)
export const BUTTON_TEXT = 'Okay'
