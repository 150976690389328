import { DropdownTooltip } from '../DropdownTooltip'
import { FC } from 'react'
import { RangeFilter } from '../../RangeFilter'
import { DropdownRangeFilterProps } from './types'

export const DropdownRangeFilter: FC<DropdownRangeFilterProps> = ({
  testId = 'dropdown-range',
  onChange,
  selectedRange,
  isVisible = false,
  referenceElement,
  title,
  rangeMax,
}) => (
  <DropdownTooltip
    referenceElement={referenceElement}
    isVisible={isVisible}
    testId={testId}
    title={title}
  >
    <RangeFilter
      onChange={onChange}
      selectedRange={selectedRange}
      rangeMax={rangeMax}
      testId={`${testId}-filter`}
    />
  </DropdownTooltip>
)
