import { captureException } from '@sylveraio/react-utils'
import {
  submitHubspotForm,
  type HubspotFormCustomOverrideData,
} from '@sylveraio/auth-utils'
import { convertUpsellDataForHubspot } from '../../../../utils/convertUpsellDataforHubspot'

function useSubmitTierUpgradeForm() {
  async function submitTierUpgradeForm({
    userOrganisation,
    userFirstName,
    userLastName,
    userId,
    userEmail,
  }: HubspotFormCustomOverrideData) {
    try {
      await submitHubspotForm({
        id: 'dbb0a56b-0425-40f1-9cbd-8eda49b99f9d',
        data: convertUpsellDataForHubspot({
          userOrganisation,
          userFirstName,
          userLastName,
          userId,
          userEmail,
        }),
      })
    } catch (err) {
      captureException(err, 'useSubmitTierUpgradeForm')
      throw err
    }
  }

  return { submitTierUpgradeForm }
}

export default useSubmitTierUpgradeForm
