import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Tour: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'tour',
  size = ICON_DEFAULT_SIZE,
  className,
}) => {
  if (size === 'sm') {
    return (
      <svg
        data-testid={testId}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: 'auto', width: Sizes[size] }}
        className={clsx('stroke-current text-default', className)}
      >
        <path
          d="M6.72729 5.08172V1.8999"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.52472 1.8999V5.08172"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.52631 10.7998V13.9816"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.72852 13.9816V10.7998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6.72729H14.1818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1818 9.51514H11"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.18182 9.51514H2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 6.72729H5.18182"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  return (
    <svg
      data-testid={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: Sizes[size] }}
      className={clsx('stroke-current text-default', className)}
    >
      <path
        d="M10 7V2"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.396 2V7"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3984 17V22"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.002 22V17"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 10H21.9999"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9999 14.3809H17"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 14.3809H2"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10H7"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3871 12C22.3871 17.7366 17.7366 22.3871 12 22.3871C6.26337 22.3871 1.61292 17.7366 1.61292 12C1.61292 6.26337 6.26337 1.61292 12 1.61292C17.7366 1.61292 22.3871 6.26337 22.3871 12Z"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3871 12C17.3871 14.9752 14.9752 17.3871 12 17.3871C9.0248 17.3871 6.61292 14.9752 6.61292 12C6.61292 9.0248 9.0248 6.61292 12 6.61292C14.9752 6.61292 17.3871 9.0248 17.3871 12Z"
        strokeWidth="1.22583"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
