import { useContext } from 'react'
import { captureException } from '@sylveraio/react-utils'
import { Auth } from 'aws-amplify'
import type { ForgotPassword } from './types'
import { UserContext } from '../../../contexts'

export function useSubmitForgotPasswordForm() {
  const { setUserState } = useContext(UserContext)

  async function submitForgotPassword({
    email,
  }: ForgotPassword): Promise<void> {
    try {
      await Auth.forgotPassword(email)
      setUserState({ email })
    } catch (err) {
      captureException(err, 'useSubmitForgotPasswordForm')
      throw err
    }
  }

  return { submitForgotPassword }
}

export default useSubmitForgotPasswordForm
