import { FC } from 'react'
import Globe02 from '@sylveraio/untitled-ui-icons-react/build/esm/Globe02'
import Twitter from '@sylveraio/untitled-ui-icons-react/build/esm/Twitter'
import Linkedin from '@sylveraio/untitled-ui-icons-react/build/esm/Linkedin'

export const SocialMediaGroup: FC = () => (
  <div className="flex space-x-2">
    <a
      href="https://www.sylvera.com"
      title="Go to Sylvera"
      target="_blank"
      rel="noreferrer"
      data-testid="social-world"
      className="rounded-full border border-text-default self-center"
    >
      <Globe02 className="w-5 h-5 p-1 stroke-icon-default" />
    </a>
    <a
      href="https://www.linkedin.com/company/sylveracarbon"
      title="Visit Sylvera on LinkedIn"
      target="_blank"
      rel="noreferrer"
      data-testid="social-linkedin"
      className="rounded-full border border-text-default"
    >
      <Linkedin className="w-5 h-5 p-1 fill-current text-default" />
    </a>
    <a
      href="https://twitter.com/SylveraCarbon"
      title="Visit Sylvera on Twitter"
      target="_blank"
      rel="noreferrer"
      data-testid="social-twitter"
      className="rounded-full border border-text-default"
    >
      <Twitter className="w-5 h-5 p-1 fill-current text-default" />
    </a>
  </div>
)

export default SocialMediaGroup
