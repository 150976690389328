import type { ChartDataset as ChartJSChartDataset } from 'chart.js'
import type { ChartType, MultiChartData } from '../../types'
import type { ScatterDataPoint } from '../../../types'
import { DEFAULT_CHART_DATASET_STYLES } from './constants'

/**
 * Returns a palette ID and cycles through the palette if it runs out - could add a modifier on each repeated cycle to modify the color slightly
 * @param palette
 */
export function* getNextPaletteColor(palette: Array<string>) {
  const arrLength = palette.length
  let i = 0

  while (true) {
    const curIdx = i < arrLength ? i : 0
    yield palette[curIdx]
    i = curIdx + 1
  }
}

/**
 * Creates an instance of getNextPaletteColor, then checks whether to return background, border, or the next palette value
 * @param palette
 * @returns
 */

export function getNextColor(palette: Array<string>) {
  const paletteColor = getNextPaletteColor(palette)

  return function (
    idx: number,
    backgroundColors: Array<string> = [],
    borderColors: Array<string> = [],
    skip = false,
  ): string {
    if (skip) return ''
    if (backgroundColors[idx]) return backgroundColors[idx]
    if (borderColors[idx]) return borderColors[idx]
    return paletteColor.next().value || ''
  }
}

export function combineMultiChartDatasets(
  data: Array<MultiChartData>,
  palette: Array<string> = [],
): ChartJSChartDataset<ChartType, ScatterDataPoint[]>[] {
  const paletteColor = getNextColor(palette)

  const datasets: ChartJSChartDataset<ChartType, ScatterDataPoint[]>[] =
    data.reduce<ChartJSChartDataset<ChartType, ScatterDataPoint[]>[]>(
      (combinedDatasets, chartData) => {
        const { datasets, ...rest } = chartData
        const chartDatasets: ChartJSChartDataset<
          ChartType,
          ScatterDataPoint[]
        >[] = chartData.datasets.map((dataset, idx) => {
          const borderColor = paletteColor(
            idx,
            [],
            rest.type === 'line' ? rest.borderColors : [],
            rest.type === 'bar',
          )
          const backgroundColor = paletteColor(
            idx,
            rest.type === 'bar' ? rest.backgroundColors : [],
            [],
            rest.type === 'line',
          )

          return {
            type: rest.type,
            data: dataset.data,
            label: dataset.label,
            maxBarThickness:
              DEFAULT_CHART_DATASET_STYLES['bar'].maxBarThickness,
            barPercentage: DEFAULT_CHART_DATASET_STYLES['bar'].barPercentage,
            borderWidth:
              rest.type === 'bar'
                ? DEFAULT_CHART_DATASET_STYLES['bar'].borderWidth
                : DEFAULT_CHART_DATASET_STYLES['line'].borderWidth,
            backgroundColor,
            borderColor,
            pointBackgroundColor: borderColor,
            segment: {
              borderDash: rest.segmentAfter,
            },
          }
        })

        return [...combinedDatasets, ...chartDatasets]
      },
      [],
    )

  return datasets
}
