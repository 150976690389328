'use client'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

/**
 * Checks if organisation has access to preview JREDD content based on feature flags
 * @param {string} organisationId An organisation ID to check the access for
 * @returns {boolean} Indicating it organisation has access to view JREDD content
 */

export function useCheckJreddAccess(organisationId?: string): boolean {
  const { jreddAccessOrgs = {} } = useFlags()

  const hasAccessToJreddFolder = useMemo(() => {
    if (
      typeof organisationId === 'undefined' ||
      typeof jreddAccessOrgs === 'undefined'
    )
      return false

    return !!jreddAccessOrgs?.[organisationId as string]
  }, [jreddAccessOrgs, organisationId])

  return hasAccessToJreddFolder
}

export default useCheckJreddAccess
