import { useContext } from 'react'
import { addBreadcrumb } from '@sentry/nextjs'
import { captureException } from '@sylveraio/react-utils'
import { Auth } from 'aws-amplify'
import { UserContext } from '../../../contexts'
import type { AuthenticateUser } from './types'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import { setCookies } from '@sylveraio/auth-utils'
import { useRouter } from 'next/router'

function requireNewPassword(user: any): boolean {
  return user.challengeName === 'NEW_PASSWORD_REQUIRED'
}

export function useAuthenticateUser() {
  const { setUserState } = useContext(UserContext)
  const { push, asPath } = useRouter()

  async function authenticateUser(
    { email, password }: AuthenticateUser,
    navigateTo: string,
  ): Promise<void | 'User Requires New Password'> {
    sendAnalyticsEvent({ eventName: 'pa-login-attempt' })

    try {
      addBreadcrumb({
        message: 'Authorising the user',
      })

      const user = await Auth.signIn(email, password)

      if (requireNewPassword(user)) {
        setUserState({ user })
        return 'User Requires New Password'
      }

      const { refreshToken, accessToken } = user?.signInUserSession || {}

      sendAnalyticsEvent({
        eventName: 'login',
        eventData: {
          login_method: 'email',
        },
      })

      if (refreshToken && accessToken) {
        addBreadcrumb({
          message: 'Refresh and access token values created',
        })

        setCookies(
          {
            token: accessToken?.jwtToken,
            exp: accessToken?.payload.exp - accessToken?.payload.iat,
          },
          {
            token: refreshToken?.token,
            iss: accessToken?.payload?.iss,
            uid: user.username,
          },
        )

        addBreadcrumb({
          message: 'Cookies set, redirect to page',
          data: {
            navigateTo,
            asPath,
          },
        })

        // We've set cookies, now redirect to the page if we're on the sign in page
        if (asPath === '/sign-in') {
          push(navigateTo)
        }
      }

      return undefined as void
    } catch (err) {
      sendAnalyticsEvent({ eventName: 'pa-login-failure' })
      addBreadcrumb({
        category: 'auth',
        message: (err as Error).message,
        level: 'log',
        data: {
          name: (err as Error).name,
          raw: err as Error,
        },
      })
      captureException(err, 'useAuthenticateUser')
      throw err
    }
  }

  return { authenticateUser }
}

export default useAuthenticateUser
