import type { Row } from '../../types'

export const sortByLockAndValue = (
  rowA: Row,
  rowB: Row,
  columnId: string,
): number => {
  if (rowA.original._locked && !rowB.original._locked) return 1
  if (!rowA.original._locked && rowB.original._locked) return -1

  const a = rowA.original[columnId]
  const b = rowB.original[columnId]

  return a > b ? 1 : a < b ? -1 : 0
}

export default sortByLockAndValue
