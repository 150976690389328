import { FC } from 'react'
import type IconProps from '../types'

const NARing: FC<IconProps> = ({ size = 60 }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <path
      d="M28.246 25.2H29.87V35.49L22.716 28.42V35H21.092V24.71L28.246 31.78V25.2ZM38.7072 35L37.5872 32.48H33.9752L32.8552 35H30.9932L35.7812 24.654L40.5692 35H38.7072ZM35.7812 27.972L34.4512 31.08H37.1112L35.7812 27.972Z"
      fill="#4F4F51"
    />
    <path
      d="M60.1973 30C60.1973 46.5685 46.7658 60 30.1973 60C13.6287 60 0.197266 46.5685 0.197266 30C0.197266 13.4315 13.6287 0 30.1973 0C46.7658 0 60.1973 13.4315 60.1973 30ZM1.69727 30C1.69727 45.7401 14.4572 58.5 30.1973 58.5C45.9374 58.5 58.6973 45.7401 58.6973 30C58.6973 14.2599 45.9374 1.5 30.1973 1.5C14.4572 1.5 1.69727 14.2599 1.69727 30Z"
      fill="#B8B8B8"
    />
  </svg>
)

export default NARing
