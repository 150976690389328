import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Download: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'download',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-default', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09998 9.99998C2.09998 9.77906 2.27906 9.59998 2.49998 9.59998H9.49998C9.72089 9.59998 9.89998 9.77906 9.89998 9.99998C9.89998 10.2209 9.72089 10.4 9.49998 10.4H2.49998C2.27906 10.4 2.09998 10.2209 2.09998 9.99998Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99998 1.09998C6.22089 1.09998 6.39998 1.27906 6.39998 1.49998V7.99998C6.39998 8.22089 6.22089 8.39998 5.99998 8.39998C5.77906 8.39998 5.59998 8.22089 5.59998 7.99998V1.49998C5.59998 1.27906 5.77906 1.09998 5.99998 1.09998Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21713 5.21713C3.37334 5.06092 3.62661 5.06092 3.78282 5.21713L5.99998 7.43429L8.21713 5.21713C8.37334 5.06092 8.62661 5.06092 8.78282 5.21713C8.93903 5.37334 8.93903 5.62661 8.78282 5.78282L6.28282 8.28282C6.12661 8.43903 5.87334 8.43903 5.71713 8.28282L3.21713 5.78282C3.06092 5.62661 3.06092 5.37334 3.21713 5.21713Z"
    />
  </svg>
)
