import { captureException } from '@sylveraio/react-utils'
import axios from 'axios'
import type { SubmitHubspotFormResponse, SubmitHubspotForm } from './types'

/**
 * Jankily moved this across for the moment - MCW
 */
export const hubspotInstance = axios.create({
  baseURL: `${process.env['NEXT_PUBLIC_HUBSPOT_URL']}/${process.env['NEXT_PUBLIC_HUBSPOT_PORTAL_ID']}`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const submitHubspotForm = async ({
  id,
  data,
}: SubmitHubspotForm): Promise<SubmitHubspotFormResponse> => {
  try {
    const response = await hubspotInstance.post(`/${id}`, {
      fields: data,
    })

    return response.data
  } catch (err) {
    captureException(err, 'hubspot')
    throw err
  }
}

export default submitHubspotForm
