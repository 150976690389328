import { FC, useCallback, useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import type { BreakdownDoughnutProps } from './types'
import {
  COLORS,
  DEAFULT_LABEL_STYLING,
  DEFAULT_LAYOUT_STYLING,
  LOADING_DATASET,
  LOADING_TIME_MS,
} from './constants'
import { useTimeout, formatChartLabel } from '@sylveraio/react-utils'
import { InfoHoverTooltip } from '../InfoHoverTooltip'
import clsx from 'clsx'
import { EmptyState } from '@sylveraio/design-system'
import { BarLineChart } from '@sylveraio/untitled-ui-icons-react'

ChartJS.register(ArcElement, Tooltip, Legend)

export const BreakdownDoughnut: FC<BreakdownDoughnutProps> = ({
  data,
  title,
  colorScheme = 'purple',
  testId = 'breakdown-donut',
  loading = false,
  wrapperClassName,
  layoutStyling,
  labelStyling,
  chartAnnotation,
}) => {
  const [datasetValues, setDatasetValues] = useState<Array<number>>([])
  const [labels, setLabels] = useState<Array<string>>([])
  const { backgroundColor, label, labelBackgroundColor } =
    COLORS[colorScheme.toUpperCase()]
  const [loadingDataset, setLoadingDataset] =
    useState<Array<number>>(LOADING_DATASET)
  const [loadingTimer, setLoadingTimer] = useState<number | null>(
    loading ? LOADING_TIME_MS : null,
  )

  const handleLoadingTimer = useCallback(() => {
    if (!loading) return

    if (loadingDataset.length === 1) {
      setLoadingTimer(LOADING_TIME_MS)
      setLoadingDataset(LOADING_DATASET)
    } else {
      setLoadingTimer(250)
      setLoadingDataset([0])
    }
  }, [loadingDataset, loading])

  useEffect(() => {
    if (loadingTimer === null && loading) {
      setLoadingTimer(LOADING_TIME_MS)
    } else {
      if (loadingTimer !== null && !loading) setLoadingTimer(null)
    }
  }, [loadingTimer, loading])

  useTimeout(handleLoadingTimer, loadingTimer)

  const hasData = !loading && datasetValues.length > 0

  const donutData = {
    labels,
    datasets: [
      {
        data: loading
          ? loadingDataset
          : datasetValues.length === 0
          ? [100]
          : datasetValues,
        backgroundColor,
        hoverOffset: 4,
        borderWidth: 0,
        cutout: '80%',
      },
    ],
  }

  useEffect(() => {
    setLabels(data.map(({ label }) => label))
    setDatasetValues(data.map(({ value }) => value))
  }, [data])

  return (
    <div
      className={clsx(
        { 'w-[450px] h-[450px]': !wrapperClassName },
        'flex flex-col justify-center items-center',
        wrapperClassName,
      )}
      data-testid={testId}
    >
      <div className="relative flex h-full w-full items-center justify-center">
        {
          <Doughnut
            data={donutData}
            style={{ opacity: hasData ? 1 : 0.4, transition: 'opacity .5s' }}
            unselectable={hasData ? 'off' : 'on'}
            options={{
              plugins: {
                // @ts-ignore
                datalabels: {
                  opacity: hasData ? 1 : 0,
                  backgroundColor: function (context) {
                    return labelBackgroundColor
                  },
                  align: 'end',
                  anchor: 'end',
                  ...(!labelStyling ? DEAFULT_LABEL_STYLING : labelStyling),
                  display: function (context) {
                    const active = context?.active
                    return active ? true : 'auto'
                  },
                  formatter: function (value, context) {
                    const i = context.dataIndex
                    return formatChartLabel(value, labels[i])
                  },
                  textAlign: 'center',
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              layout: {
                ...(!layoutStyling ? DEFAULT_LAYOUT_STYLING : layoutStyling),
              },
            }}
            // @ts-ignore
            plugins={[ChartDataLabels]}
            className="absolute z-10"
            data-testid={`${testId}-chart`}
          />
        }
        <div className="absolute z-[11] flex w-max flex-col items-center justify-center">
          <span
            className={clsx(`text-5xl font-bold ${label}`, {
              invisible: loading,
              visible: !loading,
            })}
            data-testid={`${testId}-title`}
          >
            {hasData && title}
          </span>
          {hasData && title && (
            <span
              className="text-base font-normal text-default"
              data-testid={`${testId}-subheading`}
            >
              Average Rating{' '}
              <InfoHoverTooltip>
                Average portfolio rating is based on rated projects.
              </InfoHoverTooltip>
            </span>
          )}
          {!hasData && !loading && (
            <EmptyState withContainer={false} title="No available data" />
          )}
        </div>
      </div>
      {chartAnnotation && !loading && hasData && (
        <div className="text-center text-sm text-default">
          {chartAnnotation}
        </div>
      )}
    </div>
  )
}
