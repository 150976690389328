import { FC } from 'react'
import type { LogoProps } from './types'

const Logo: FC<LogoProps> = ({ size = 150, className, inverted }) => {
  const textColor = inverted ? 'fill-white' : 'fill-selection-forest'
  return (
    <svg
      width="136"
      height="45"
      viewBox="0 0 5008 1287"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: size }}
      className={className}
    >
      <path
        d="M1378.45 829.836C1465.7 901.46 1549.98 937.272 1631.27 937.272C1674.56 937.272 1708.8 927.984 1734.19 909.294C1746.34 900.761 1756.18 889.337 1762.82 876.053C1769.46 862.768 1772.69 848.039 1772.22 833.193C1772.74 813.741 1766.41 794.726 1754.33 779.475C1734.94 759.123 1711.76 742.774 1686.09 731.353L1549.61 666.443C1489.2 638.091 1446.87 607.502 1422.64 574.674C1397.96 540.556 1385.18 499.265 1386.28 457.166C1385.41 423.833 1392.22 390.747 1406.19 360.472C1420.16 330.197 1440.9 303.545 1466.82 282.581C1492.09 261.863 1521.16 246.277 1552.4 236.697C1585.56 226.517 1620.08 221.423 1654.76 221.588C1675.4 221.654 1695.98 223.526 1716.29 227.184C1736.69 230.778 1756.72 236.209 1776.14 243.412C1794.8 250.308 1812.77 258.925 1829.84 269.151C1845.77 278.917 1860.75 290.158 1874.58 302.725V459.404C1842.59 426.749 1805.34 399.701 1764.39 379.386C1728.49 361.149 1688.88 351.387 1648.61 350.848C1617.72 349.379 1587.21 358.238 1561.91 376.029C1551.23 383.953 1542.65 394.362 1536.89 406.355C1531.14 418.348 1528.4 431.563 1528.91 444.855C1528.64 462.717 1534.57 480.119 1545.69 494.097C1562.46 512.263 1583.11 526.416 1606.1 535.505L1733.63 594.818C1763.15 608.248 1791.58 623.964 1818.65 641.822C1839.75 655.66 1858.61 672.643 1874.58 692.183C1888.43 709.457 1898.87 729.205 1905.35 750.378C1911.94 772.907 1915.14 796.292 1914.86 819.764C1915.7 855.193 1908.19 890.323 1892.93 922.308C1877.68 954.292 1855.1 982.231 1827.04 1003.86C1799.59 1024.87 1768.49 1040.61 1735.31 1050.3C1700.28 1060.78 1663.91 1066.06 1627.35 1065.97C1580.94 1066.05 1534.83 1058.49 1490.87 1043.59C1450.11 1030.39 1411.9 1010.33 1377.89 984.276L1378.45 829.836Z"
        className={textColor}
      />
      <path
        d="M1908.7 478.987H2069.24L2233.68 816.965L2398.13 478.987H2554.75L2139.71 1287H1986.45L2156.49 968.048L1908.7 478.987Z"
        className={textColor}
      />
      <path
        d="M2598.15 1051.98V201.444H2745.82V1051.98H2598.15Z"
        className={textColor}
      />
      <path
        d="M2786.76 478.987H2950.09L3112.3 815.846L3275.63 478.987H3431.13L3108.95 1082.2L2786.76 478.987Z"
        className={textColor}
      />
      <path
        d="M3693.91 465.558C3784.07 465.558 3853.8 496.521 3903.1 558.446C3952.32 621.863 3973.21 703.56 3965.75 803.536H3538.41C3541.46 823.988 3548.69 843.59 3559.67 861.114C3570.64 878.639 3585.11 893.707 3602.17 905.377C3637.15 929.997 3681.53 942.308 3735.3 942.308C3770.14 942.187 3804.72 936.327 3837.66 924.961C3869.07 914.725 3898.43 899 3924.36 878.517V1008.9C3895.56 1027.97 3863.81 1042.15 3830.39 1050.86C3793.58 1060.96 3755.57 1066.05 3717.4 1065.97C3675.45 1066.17 3633.79 1058.97 3594.34 1044.71C3556.97 1031.38 3522.58 1010.84 3493.1 984.275C3464.09 957.791 3440.86 925.596 3424.86 889.709C3407.74 851.153 3399.15 809.35 3399.69 767.164C3399.53 727.206 3407.4 687.624 3422.85 650.774C3437.36 615.264 3458.42 582.803 3484.94 555.089C3511.5 527.514 3543.24 505.455 3578.35 490.179C3614.7 473.924 3654.08 465.534 3693.91 465.558V465.558ZM3542.88 705.052H3828.15C3825.55 671.616 3810.36 640.414 3785.64 617.76C3759.79 594.906 3726.14 582.883 3691.67 584.186C3657.76 583.523 3624.69 594.814 3598.26 616.081C3570.7 638.992 3551.27 670.2 3542.88 705.052Z"
        className={textColor}
      />
      <path
        d="M4040.7 478.988H4188.37V567.399C4206.14 534.666 4233.45 508.122 4266.67 491.298C4299.92 474.115 4337.36 466.741 4374.63 470.035V617.76C4316.46 610.299 4270.96 619.998 4238.15 646.857C4205.33 673.716 4188.93 715.497 4188.93 772.2V1051.98H4041.26L4040.7 478.988Z"
        className={textColor}
      />
      <path
        d="M4671.53 465.558C4710.4 465.099 4748.8 474.126 4783.4 491.857C4814.6 507.023 4841.06 530.437 4859.91 559.565V478.987H5007.58V1051.98H4859.91V971.405C4841.06 1000.53 4814.6 1023.95 4783.4 1039.11C4748.78 1056.79 4710.39 1065.82 4671.53 1065.41C4632.18 1065.9 4593.22 1057.69 4557.42 1041.35C4523.92 1025.92 4494.04 1003.61 4469.72 975.881C4445.12 947.764 4425.97 915.317 4413.22 880.196C4386.23 806.106 4386.23 724.864 4413.22 650.774C4425.97 615.652 4445.12 583.206 4469.72 555.089C4494.04 527.355 4523.92 505.049 4557.42 489.619C4593.22 473.284 4632.18 465.067 4671.53 465.558V465.558ZM4700.61 938.95C4722.77 939.165 4744.71 934.583 4764.94 925.521C4784.34 916.883 4801.86 904.526 4816.51 889.149C4831.17 873.236 4842.57 854.607 4850.07 834.311C4858.44 812.341 4862.61 788.995 4862.38 765.485C4862.64 742.154 4858.47 718.986 4850.07 697.218C4842.41 677 4831.03 658.4 4816.51 642.381C4802.14 626.833 4784.69 614.446 4765.27 606.009C4744.96 597.288 4723.05 592.905 4700.95 593.139C4679.25 592.981 4657.76 597.365 4637.85 606.009C4618.34 614.434 4600.77 626.819 4586.28 642.381C4571.57 658.357 4560 676.959 4552.16 697.218C4543.86 719.008 4539.72 742.166 4539.97 765.485C4539.75 788.984 4543.89 812.32 4552.16 834.311C4559.84 854.65 4571.43 873.281 4586.28 889.149C4600.96 904.5 4618.47 916.853 4637.85 925.521C4657.57 934.453 4678.97 939.034 4700.61 938.95V938.95Z"
        className={textColor}
      />
      <path
        d="M183.8 551.62C183.689 582.594 187.447 613.459 194.987 643.501C215.384 722.502 261.457 792.478 325.958 842.418C390.459 892.358 469.727 919.427 551.289 919.366V1103.24H537.082C409.355 1100.01 286.719 1052.48 190.156 968.777C93.5921 885.077 29.1021 770.411 7.71864 644.396C2.53448 614.045 -0.0479212 583.306 -0.000330668 552.515C-0.0150234 480.081 14.2337 408.353 41.932 341.428C69.6303 274.503 110.236 213.692 161.429 162.468C212.622 111.244 273.401 70.6104 340.294 42.8876C407.187 15.1647 478.883 0.89575 551.289 0.895752V184.769H537.082C442.255 188.495 352.54 228.752 286.698 297.123C220.856 365.494 183.987 456.683 183.8 551.62V551.62Z"
        className={textColor}
      />
      <path
        d="M1102.69 735.381C1102.69 807.82 1088.43 879.551 1060.72 946.476C1033.01 1013.4 992.391 1074.21 941.189 1125.43C889.986 1176.66 829.2 1217.29 762.301 1245.01C695.402 1272.73 623.7 1287 551.289 1287V1103.13H565.496C660.459 1099.42 750.301 1059.09 816.184 990.568C882.067 922.051 918.875 830.676 918.89 735.604C919.002 704.631 915.243 673.765 907.703 643.724C887.278 564.789 841.224 494.877 776.774 444.966C712.324 395.055 633.129 367.972 551.625 367.97V183.873H565.832C693.653 187.124 816.368 234.731 912.961 318.541C1009.55 402.351 1074.02 517.149 1095.31 643.276C1100.39 673.711 1102.86 704.525 1102.69 735.381Z"
        fill="#3ACB5A"
      />
    </svg>
  )
}

export default Logo
