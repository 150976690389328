import { FC } from 'react'
import clsx from 'clsx'
import type { ChevronProps } from './types'

export const Chevron: FC<ChevronProps> = ({
  testId = 'chevron',
  size,
  direction = 'right',
  transitionOn,
  transitionDirection,
  className,
}) => (
  <svg
    data-testid={testId}
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: size, width: 'auto' }}
    className={clsx(
      'transition duration-150 ease-in-out transform fill-current',
      {
        'text-content-action-default': !className,
        '-rotate-90': direction === 'up',
        'rotate-90': direction === 'down',
        'rotate-180': direction === 'left',
        '!-rotate-90': transitionOn && transitionDirection === 'up',
        '!rotate-90': transitionOn && transitionDirection === 'down',
        '!rotate-[90]': transitionOn && transitionDirection === 'right',
        '!rotate-180': transitionOn && transitionDirection === 'left',
      },
      className,
    )}
  >
    <path d="M0.337523 11.472C0.625523 11.76 1.10552 11.76 1.39352 11.472L6.43952 6.42599C6.49515 6.37048 6.53927 6.30455 6.56938 6.23196C6.59949 6.15938 6.61499 6.08157 6.61499 6.00299C6.61499 5.92441 6.59949 5.8466 6.56938 5.77401C6.53927 5.70143 6.49515 5.6355 6.43952 5.57999L1.39352 0.533988C1.09952 0.239987 0.625524 0.239987 0.331523 0.533987C0.0375237 0.827987 0.0375237 1.30199 0.331523 1.59599L4.74152 5.99999L0.331523 10.41C0.0435229 10.704 0.0435229 11.178 0.337523 11.472Z" />
  </svg>
)

export default Chevron
