/**
 * Get the domain value based on the current environment
 * @returns domain
 */
export function getDomain(wildcard = false, localhost = false): string {
  if (localhost && process.env['NODE_ENV'] !== 'production') return 'localhost'
  const env = process.env['NEXT_PUBLIC_APP_ENV']
  if (env && env !== 'prod') {
    return `${wildcard ? '.' : ''}${env}.sylvera.com`
  } else {
    return `${wildcard ? '.' : ''}sylvera.com`
  }
}
