import type { FC } from 'react'
import type { SearchProps } from './types'
import clsx from 'clsx'
import SearchSm from '@sylveraio/untitled-ui-icons-react/build/esm/SearchSm'
import XClose from '@sylveraio/untitled-ui-icons-react/build/esm/XClose'

export const SearchLight: FC<Omit<SearchProps, 'theme'>> = ({
  testId = 'search-light',
  value,
  placeholder,
  className,
  wrapperClassName,
  onChange,
  onReset,
}) => (
  <div
    data-testid={testId}
    className={clsx(
      'flex items-center w-full h-8 py-2 gap-2 border-b-[0.5px] border-default focus-within:border-strong',
      wrapperClassName,
    )}
  >
    {value?.length > 0 ? (
      <button data-testid={`${testId}-reset-button`} onClick={onReset}>
        <XClose className="w-5 h-5 stroke-icon-default" />
      </button>
    ) : (
      <SearchSm className="w-5 h-5 stroke-icon-default" />
    )}
    <input
      data-testid={`${testId}-input`}
      className={clsx(
        'flex-grow tracking-[0.14px] text-sm text-default font-semibold bg-transparent outline-none placeholder:text-placeholder',
        className,
      )}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
)
