import clsx from 'clsx'
import type { ZoomButtonType } from '../../types'

/**
 * Get the radius of the zoom button
 * @param {ZoomButtonType} type - The type of the zoom button
 * @returns {string} - The class name for the zoom button radius
 */
export function getZoomButtonRadius(type: ZoomButtonType) {
  return clsx({
    'rounded-r-3xl': type === 'zoomIn',
    'rounded-l-3xl': type === 'zoomOut',
  })
}
