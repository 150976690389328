'use client'

import { FC, useRef, useState } from 'react'
import { useClickOutsideElement } from '@sylveraio/react-utils/server'
import { AppSwitcherDropdown } from './AppSwitcherDropdown'
import { AppSwitcherDropdownItem } from './AppSwitcherDropdownItem'
import type { AppSwitcherProps } from './types'
import type { AppName } from 'types'
import AppSwitcherIcon from '@sylveraio/untitled-ui-icons-react/build/esm/AppSwitcher'
import { Button } from '@sylveraio/design-system'

export const AppSwitcher: FC<AppSwitcherProps> = ({
  testId = 'app-switcher',
  appName,
  items,
}) => {
  const appSwitchRef = useRef<HTMLDivElement>(null)
  const appSwitchBtnRef = useRef<HTMLButtonElement>(null)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  useClickOutsideElement(
    appSwitchRef,
    () => {
      setShowDropdown(false)
    },
    showDropdown,
  )

  const appItems = Object.entries(items || {})

  if (!items) return null

  return (
    <div data-testid={testId} className="flex" ref={appSwitchRef}>
      <Button
        variant="secondary"
        testId={`${testId}-button`}
        iconLeading={<AppSwitcherIcon />}
        onClick={() => setShowDropdown(!showDropdown)}
        ref={appSwitchBtnRef}
      />

      <AppSwitcherDropdown
        testId={`${testId}-dropdown`}
        referenceElement={appSwitchBtnRef.current}
        showDropdown={showDropdown}
      >
        {appItems.map(([_appName, data]) => (
          <AppSwitcherDropdownItem
            key={`${testId}-${_appName}-dropdown-item`}
            testId={`${testId}-${_appName}-dropdown-item`}
            name={_appName as AppName}
            appUrl={data.url}
            appClientSide={data.clientSide}
            title={data.title}
            // Is this item the same app as the current app we're in or is it disabled
            disabled={_appName === appName || data.state === 'disabled'}
            appLocked={data.state === 'locked'}
            onClick={() => {
              setShowDropdown(false)
            }}
          />
        ))}
      </AppSwitcherDropdown>
    </div>
  )
}

export default AppSwitcher
