import clsx from 'clsx'
import React, { FC } from 'react'
import type { LockProps } from './types'

const Lock: FC<LockProps> = ({ testId = 'lock', size = 20, className }) => (
  <svg
    data-testid={testId}
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M15.0262 8.25305H14.3465V5.61598C14.3465 2.46615 12.0889 0 8.86034 0C5.63178 0 3.42276 2.51498 3.42276 5.61598V8.22863C3.42276 8.25305 3.42276 8.25305 3.42276 8.22863H2.74306C1.23802 8.22863 0.0242749 9.4495 0 10.9878V19.2408C0 20.7547 1.21375 21.9756 2.74306 22H15.0262C16.5312 22 17.745 20.7791 17.7692 19.2408V10.9878C17.7692 9.47392 16.5312 8.25305 15.0262 8.25305ZM4.78216 8.25305V5.61598C4.78216 3.27192 6.3843 1.36737 8.86034 1.36737C11.3121 1.36737 12.9871 3.22309 12.9871 5.61598V8.22863C12.9871 8.25305 12.9871 8.25305 12.9871 8.22863H4.78216C4.78216 8.25305 4.78216 8.25305 4.78216 8.25305ZM16.3856 19.2408C16.3856 19.9978 15.7787 20.6082 15.0262 20.6082H2.74306C1.99054 20.6082 1.38367 19.9978 1.38367 19.2408V10.9878C1.38367 10.2309 1.99054 9.62042 2.74306 9.62042H15.0262C15.7787 9.62042 16.3856 10.2309 16.3856 10.9878V19.2408ZM10.244 13.7469C10.244 14.2597 9.97699 14.6992 9.56431 14.9434H9.54004H9.56431V17.1898C9.56431 17.5805 9.24874 17.8735 8.88461 17.8735C8.49622 17.8735 8.20492 17.556 8.20492 17.1898V14.9434H8.22919H8.20492C7.79225 14.6992 7.52522 14.2597 7.52522 13.7469C7.52522 12.99 8.13209 12.3796 8.88461 12.3796C9.63714 12.3552 10.244 12.9656 10.244 13.7469Z"
      fill="#BAB8FE"
    />
  </svg>
)

export default Lock
