import { FC } from 'react'
import clsx from 'clsx'
import { Tooltip } from '../Tooltip'
import { DropdownTooltipProps } from './types'

export const DropdownTooltip: FC<DropdownTooltipProps> = ({
  testId = 'dropdown',
  isVisible = false,
  referenceElement,
  title,
  children,
  placement = 'end',
  offset,
  className,
}) => (
  <Tooltip
    referenceElement={referenceElement}
    placement={`bottom-${placement}`}
    show={isVisible}
    testId={testId}
    className={clsx('!z-10 p-5 rounded-lg shadow mt-2', className)}
    offset={offset}
  >
    {title && <h2 className="mb-4">{title}</h2>}
    {children}
  </Tooltip>
)
