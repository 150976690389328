import { FC } from 'react'
import Image from 'next/image'
import format from 'date-fns/format'
import type { NewsListItemProps } from './types'
import clsx from 'clsx'
import LinkExternal02 from '@sylveraio/untitled-ui-icons-react/build/esm/LinkExternal02'
import { Typography } from '../Typography'
import { Label } from '../Label'

export const NewsListItem: FC<NewsListItemProps> = ({
  testId = 'news-list-item',
  variant = 'on-background-default',
  date,
  img,
  title,
  summaryHeading,
  summary,
  tags,
  hasExternalLink,
  hideBorder,
  showAdditionalSummary,
  onClick,
}) => {
  const summaryComponent = (
    <Typography
      size="xs"
      weight="medium"
      className={clsx('line-clamp-1 text-subtle', {
        'line-clamp-2': showAdditionalSummary,
      })}
    >
      {summary}
    </Typography>
  )

  return (
    <article
      data-testid={testId}
      className={clsx('flex items-center px-2 py-4 gap-x-4 hover:rounded', {
        'border-default border-t': !hideBorder,
        'hover:!bg-default-hover hover:border-subtle-hover':
          variant === 'on-background-default',
        'hover:bg-high-hover hover:border-default-hover':
          variant === 'on-background-high',
        'cursor-pointer': typeof onClick !== 'undefined',
      })}
      onClick={onClick}
    >
      {img && (
        <div className="w-[112px] h-[112px] overflow-hidden shrink-0 rounded">
          <Image
            data-testid={`${testId}-thumbnail`}
            src={img.src}
            height={img.height}
            width={img.width}
            alt={img?.alt || title}
            className="object-cover object-center h-full"
            unoptimized
          />
        </div>
      )}
      <div className="flex flex-col grow gap-y-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-1">
            <time
              data-testid={`${testId}-date`}
              dateTime={date}
              className="inline-flex text-subtle shrink-0 min-h-5 items-center"
            >
              <Typography
                size="xs"
                weight="semibold"
                className="!leading-none text-subtle"
              >
                {format(new Date(date), 'MMMM dd, yyyy')}
              </Typography>
            </time>

            {typeof tags !== 'undefined' && (
              <div
                data-testid={`${testId}-tags`}
                className="flex items-center gap-x-1"
              >
                {tags.slice(0, 2).map((tag) => (
                  <Label
                    key={tag}
                    testId={`${testId}-tag-${tag}`}
                    size="sm"
                    label={tag}
                    color="neutral"
                  />
                ))}
                {tags.length > 2 && (
                  <Label
                    testId={`${testId}-tag-more`}
                    size="sm"
                    label={`+${tags.length - 2}`}
                    color="neutral"
                  />
                )}
              </div>
            )}
          </div>

          {hasExternalLink && (
            <LinkExternal02 className="w-3 h-3 stroke-icon-subtle" />
          )}
        </div>

        <Typography
          size="sm"
          weight="semibold"
          className="line-clamp-2 min-h-11"
        >
          {title}
        </Typography>

        {summaryHeading && (
          <Typography size="xs" weight="semibold" className="pt-1 text-default">
            {summaryHeading}
          </Typography>
        )}

        {summaryComponent}
      </div>
    </article>
  )
}
