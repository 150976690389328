import { CreateTypographyBundlesReturn } from './utils/createTypographyBundles/types'

export const fontSizes = [
  'xs',
  'sm',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
] as const

export const fontWeights = ['regular', 'medium', 'semibold'] as const

export type FontSizes = (typeof fontSizes)[number]
export type FontWeights = (typeof fontWeights)[number]
export type FontTags = 'h1' | 'h2' | 'h3' | 'h4' | 'p'

export type TypographyProps = {
  testId?: string
  size: FontSizes
  children: React.ReactNode
  weight?: FontWeights
  className?: string
}

export type TypographyBundlesMapType = Record<
  FontSizes,
  Record<FontWeights, CreateTypographyBundlesReturn>
>

export type TypographyBundlesTagMapType = Partial<
  Record<FontSizes, Partial<Record<FontWeights, FontTags | undefined>>>
>
