import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Search: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'search',
  size = ICON_DEFAULT_SIZE,
  className,
}) => {
  return (
    <svg
      data-testid={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: Sizes[size] }}
      className={clsx('fill-current text-content-action-default', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.4C14.5346 17.4 17.4 14.5346 17.4 11C17.4 7.46538 14.5346 4.6 11 4.6C7.46538 4.6 4.6 7.46538 4.6 11C4.6 14.5346 7.46538 17.4 11 17.4ZM11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9343 15.9343C16.2467 15.6218 16.7533 15.6218 17.0657 15.9343L21.5657 20.4343C21.8781 20.7467 21.8781 21.2532 21.5657 21.5656C21.2533 21.8781 20.7467 21.8781 20.4343 21.5656L15.9343 17.0656C15.6219 16.7532 15.6219 16.2467 15.9343 15.9343Z"
      />
    </svg>
  )
}
