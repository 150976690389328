import { TextRow } from '../../../../TextRow'
import { Value } from '../types'
import { IconWrapper } from './IconWrapper'

export function CheckboxRow({ text, description, icon, tooltip }: Value) {
  return (
    <TextRow
      text={text}
      typography={{
        size: 'sm',
        weight: 'medium',
        className: 'text-left',
      }}
      subtitle={
        typeof description === 'string'
          ? {
              value: description,
              position: 'horizontal',
              typography: {
                className: 'text-left',
                size: 'sm',
                weight: 'regular',
              },
            }
          : undefined
      }
      icon={{
        component: <IconWrapper Icon={icon?.component} />,
      }}
      tooltip={tooltip}
    />
  )
}
