import { type FC, useMemo } from 'react'
import clsx from 'clsx'
import {
  TransitionalIconTypes,
  type IconLibraryProps,
  Sizes,
} from '../../types'
import { ICON_DEFAULT_SIZE, iconTransistionStyles } from '../../constants'

export const SolidArrow: FC<
  Omit<IconLibraryProps & TransitionalIconTypes, 'name'>
> = ({
  testId = 'solid-arrow',
  size = ICON_DEFAULT_SIZE,
  className,
  direction = 'right',
  transitionOn,
  transitionDirection,
  invert,
}) => {
  const directionalStyles = useMemo(
    () => iconTransistionStyles(direction, transitionOn, transitionDirection),
    [direction, transitionOn, transitionDirection],
  )

  return (
    <svg
      data-testid={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: Sizes[size] }}
      className={clsx(
        'fill-current',
        {
          'text-default hover:text-default-hover': invert,
          'text-default-on-dark hover:text-subtle-hover': !invert,
        },
        { ...directionalStyles },
        className,
      )}
    >
      <rect
        x="23"
        y="23"
        width="22"
        height="22"
        rx="11"
        transform="rotate(-180 23 23)"
        className={clsx({
          'fill-white': invert,
          'fill-new-palette-forest-900': !invert,
        })}
      />
      <path
        d="M12.0001 15.4736L12.6123 14.8614L10.1894 12.4342L15.4738 12.4342L15.4738 11.5657L10.1894 11.5657L12.6123 9.1385L12.0001 8.52626L8.52639 11.9999L12.0001 15.4736Z"
        transform="rotate(180, 12, 12)"
        className={clsx({
          'fill-new-palette-forest-900 hover:text-default-hover': invert,
          'fill-white hover:text-subtle-hover': !invert,
        })}
      />
    </svg>
  )
}
