import { FC } from 'react'
import clsx from 'clsx'

import type PlusProps from './types'

const Plus: FC<PlusProps> = ({ size = 16, className }) => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <path
      d="M2.76667 6.65584H3.7105V3.63929H6.65304V2.79725H3.7105V0.178589H2.76667V2.79725H0.175781V3.63929H2.76667V6.65584Z"
      className={clsx('fill-current', className)}
    />
  </svg>
)

export default Plus
