export function columnise(
  key: string,
  left: JSX.Element,
  right: JSX.Element,
): JSX.Element {
  return (
    <section
      key={key}
      className="grid grid-cols-12 gap-4 py-6 first:pt-0 last:pb-0"
    >
      <span className="col-span-4">{left}</span>
      <span className="col-span-8">{right}</span>
    </section>
  )
}
