import findKey from 'lodash/findKey'
import set from 'lodash/set'
import type { FormPage } from '../createForm/types'

export function injectVarsToPages(
  page: FormPage,
  fns: Array<{ key: string; entry: Record<string, unknown> }> = [],
): FormPage {
  let PAGE = { ...page }
  fns.forEach(({ key, entry }) => {
    const fieldIndex = findKey(PAGE.fields, { key })
    if (fieldIndex) {
      const fieldIndexNumberic = Number.parseInt(fieldIndex)
      const obj = PAGE.fields[fieldIndexNumberic]
      if (Number.isInteger(fieldIndexNumberic)) {
        PAGE = set(PAGE, ['fields', fieldIndexNumberic], { ...obj, ...entry })
      }
    }
  })
  return PAGE
}
