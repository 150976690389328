'use client'
import React, { FC } from 'react'
import { useRouter } from 'next/navigation'
import { ApiIcon } from '@sylveraio/react-ui/server'
import { UserNavMenuItem } from '../UserNavMenuItem'
import CONST from '../UserNavMenu.constants'
import type { APIKeysProps } from './types'
import { sendAnalyticsEvent } from '@sylveraio/react-utils/server'

export const APIKeys: FC<APIKeysProps> = ({ testId = 'api-keys' }) => {
  const router = useRouter()

  return (
    <UserNavMenuItem
      testId={testId}
      text={CONST.API_KEYS}
      icon={
        <ApiIcon
          className="!fill-selection-forest !stroke-selection-forest"
          size={14}
        />
      }
      onClick={() => {
        sendAnalyticsEvent({
          eventName: 'app-user-nav-item-click',
          eventData: { path: '/keys' },
        })
        router.push('/keys')
      }}
    />
  )
}

export default APIKeys
