import { useState } from 'react'
import difference from 'lodash/difference'
import type { FieldSearchFn, Values } from '../createForm/types'
import { useSylveraQuery } from '@sylveraio/react-utils'
import type { ApolloClient } from '@apollo/client'
import { type Countries, GET_COUNTRIES } from '../../queries/GET_COUNTRIES'

export function useCountries(client: ApolloClient<any>) {
  const [allCountries, setAllCountries] = useState<Values>([])
  const [countryList, setCountryList] = useState<Values>([])
  const [countryOpts, setCountryOpts] = useState<Values>([])
  const handleCountrySearch: FieldSearchFn = (
    value,
    key,
    currentValues = [],
  ) => {
    if (value === '') {
      setCountryOpts([])
    } else {
      const optsMinusSelected: Values = difference(allCountries, currentValues)
      setCountryOpts(
        optsMinusSelected.filter(
          ({ text, description }) =>
            text.toLowerCase().match(value.toLowerCase()) ||
            (typeof description !== 'undefined' &&
              description.toLowerCase().match(value.toLowerCase())),
        ),
      )
    }
  }

  useSylveraQuery<Countries>(GET_COUNTRIES, client, {
    variables: {
      first: 100,
    },
    onCompleted: (data: Countries) => {
      if (!data) {
        setAllCountries([])
        setCountryList([])
        return
      }

      const countryValues = data.countries.nodes.map(({ id, name }) => {
        return {
          text: name,
          value: id,
        }
      })

      setCountryList(countryValues)
      setAllCountries(countryValues)
    },
  })

  return {
    countryList,
    countryOpts,
    handleCountrySearch,
  }
}
