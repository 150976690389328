import type { FormFieldTags, Value } from '../types'
import { Typography } from '@sylveraio/design-system'
import X from '@sylveraio/untitled-ui-icons-react/build/esm/X'

export function Tag({
  value,
  onRemove,
  testId = 'tag',
  stringifyTagFn,
}: {
  value: Value
  onRemove: (v: Value) => void
  testId?: string
  stringifyTagFn?: FormFieldTags['stringifyTagFn']
}) {
  const visibleText =
    typeof stringifyTagFn !== 'undefined' ? stringifyTagFn(value) : value.text
  const handleRemove = () => onRemove(value)
  return (
    <div
      className="rounded flex space-x-1 p-2 border border-subtle-hover bg-low h-6 items-center w-max"
      data-testid={testId}
    >
      <Typography size="xs" className="max-w-36 truncate">
        <span title={typeof value.text === 'string' ? value.text : ''}>
          {visibleText}
        </span>
      </Typography>
      <button
        onClick={handleRemove}
        data-testid={`${testId}-remove`}
        className="cursor-pointer"
      >
        <X className="h-4 w-4" />
      </button>
    </div>
  )
}
