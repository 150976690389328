import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const BulletList: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'bullet-list',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx(className, 'fill-current text-content-action-default')}
  >
    <path d="M4.00012 7.00018C4.55241 7.00018 5.00012 6.55247 5.00012 6.00018C5.00012 5.4479 4.55241 5.00018 4.00012 5.00018C3.44784 5.00018 3.00012 5.4479 3.00012 6.00018C3.00012 6.55247 3.44784 7.00018 4.00012 7.00018Z" />
    <path d="M3.99994 13.0002C4.55222 13.0002 4.99994 12.5525 4.99994 12.0002C4.99994 11.4479 4.55222 11.0002 3.99994 11.0002C3.44765 11.0002 2.99994 11.4479 2.99994 12.0002C2.99994 12.5525 3.44765 13.0002 3.99994 13.0002Z" />
    <path d="M3.99994 19.0002C4.55222 19.0002 4.99994 18.5525 4.99994 18.0002C4.99994 17.4479 4.55222 17.0002 3.99994 17.0002C3.44765 17.0002 2.99994 17.4479 2.99994 18.0002C2.99994 18.5525 3.44765 19.0002 3.99994 19.0002Z" />
    <path d="M20.06 11.2H7.94C7.42085 11.2 7 11.5367 7 11.952V12.048C7 12.4633 7.42085 12.8 7.94 12.8H20.06C20.5791 12.8 21 12.4633 21 12.048V11.952C21 11.5367 20.5791 11.2 20.06 11.2Z" />
    <path d="M20.06 17.2H7.94C7.42085 17.2 7 17.5367 7 17.952V18.048C7 18.4633 7.42085 18.8 7.94 18.8H20.06C20.5791 18.8 21 18.4633 21 18.048V17.952C21 17.5367 20.5791 17.2 20.06 17.2Z" />
    <path d="M20.06 5.20001H7.94C7.42085 5.20001 7 5.53669 7 5.95201V6.04801C7 6.46333 7.42085 6.80001 7.94 6.80001H20.06C20.5791 6.80001 21 6.46333 21 6.04801V5.95201C21 5.53669 20.5791 5.20001 20.06 5.20001Z" />
  </svg>
)
