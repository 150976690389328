import { FC } from 'react'
import { EmptyStateProps } from './types'
import clsx from 'clsx'
import { ButtonLegacy } from '../ButtonLegacy'
import { Typography } from '../Typography'

export const EmptyState: FC<EmptyStateProps> = ({
  testId = 'empty-state',
  className,
  icon,
  functionalActionName,
  functionalActionOnClick,
  primaryActionName,
  primaryActionOnClick,
  functionalActionDisabled,
  title,
  subtitle,
  withContainer = true,
}) => {
  return (
    <div
      data-testid={testId}
      className={clsx(
        'flex flex-col min-w-[200px] items-center p-6 justify-center',
        className,
        {
          'border border-default rounded-lg bg-low': withContainer,
        },
      )}
    >
      <div className="flex flex-col items-center">
        {typeof icon !== 'undefined' && <div data-testid="icon">{icon}</div>}
        <div
          className={clsx(
            'flex flex-col items-center max-w-[400px] text-center',
            { 'pt-4': icon },
          )}
        >
          <Typography size="base" weight="semibold">
            {title}
          </Typography>
          {subtitle && (
            <Typography size="sm" className="text-subtle">
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      {((functionalActionName && functionalActionOnClick) ||
        (primaryActionName && primaryActionOnClick)) && (
        <div
          data-testid="buttons-container"
          className="flex flex-row flex-wrap justify-center gap-3 pt-6"
        >
          {functionalActionName && functionalActionOnClick && (
            <ButtonLegacy
              text={functionalActionName}
              theme="off-white-1"
              onClick={functionalActionOnClick}
              disabled={functionalActionDisabled}
            />
          )}
          {primaryActionName && primaryActionOnClick && (
            <ButtonLegacy
              text={primaryActionName}
              theme="neon-blue"
              onClick={primaryActionOnClick}
            />
          )}
        </div>
      )}
    </div>
  )
}
