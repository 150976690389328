import type { ButtonAppearance, ButtonTheme, ButtonThemeStyles } from './types'

export const BUTTON_THEMES: {
  [k in Exclude<ButtonAppearance, 'none'>]: {
    [j in ButtonTheme]: ButtonThemeStyles
  }
} = {
  solid: {
    lime: {
      default: {
        bg: 'bg-[#BFF102]',
        text: 'text-default',
      },
      hover: {
        bg: 'hover:bg-[#ACD902]',
      },
      disabled: {
        bg: 'disabled:bg-[#E5F99A]',
        text: 'disabled:text-subtle-on-dark',
      },
    },
    'neon-blue': {
      default: {
        bg: 'bg-[#605CFF]',
        text: 'text-default-on-dark',
      },
      hover: {
        bg: 'hover:bg-[#4C48FF]',
      },
      disabled: {
        bg: 'disabled:bg-[#DAD9FF]',
        text: 'disabled:text-default-on-dark',
      },
    },
    white: {
      default: {
        bg: 'bg-high',
        text: 'text-default',
      },
      hover: {
        bg: 'hover:bg-medium',
      },
      disabled: {
        bg: 'disabled:bg-high',
        text: 'disabled:text-subtle-on-dark',
      },
    },
    'off-white-1': {
      default: {
        bg: 'bg-medium',
        text: 'text-default',
      },
      hover: {
        bg: 'hover:bg-default',
      },
      disabled: {
        bg: 'disabled:bg-medium',
        text: 'disabled:text-subtle-on-dark',
      },
    },
    'off-white-2': {
      default: {
        bg: 'bg-default',
        text: 'text-default',
      },
      hover: {
        bg: 'hover:bg-low',
      },
      disabled: {
        bg: 'disabled:bg-default',
        text: 'disabled:text-subtle-on-dark',
      },
    },
    forest: {
      default: {
        bg: 'bg-[#01311D]',
        text: 'text-default-on-dark',
      },
      hover: {
        bg: 'hover:bg-[#012717]',
      },
      disabled: {
        bg: 'disabled:bg-[#D1D8D2]',
        text: 'disabled:text-default-on-dark',
      },
    },
  },
}
