import type {
  HubspotFormData,
  HubspotFormCustomOverrideData,
} from '@sylveraio/auth-utils'

export function convertUpsellDataForHubspot({
  userOrganisation,
  userFirstName,
  userLastName,
  userId,
  userEmail,
  projectName,
  projectId,
}: HubspotFormCustomOverrideData): HubspotFormData {
  const hubspotFormData: HubspotFormData = [
    {
      name: 'organisation',
      value: userOrganisation || '',
    },
    {
      name: 'firstname',
      value: userFirstName || '',
    },
    {
      name: 'lastname',
      value: userLastName || '',
    },
    {
      name: 'user_id',
      value: userId || '',
    },
    {
      name: 'email',
      value: userEmail || '',
    },
    ...(projectName
      ? [
          {
            name: 'project_name',
            value: projectName,
          },
        ]
      : []),
    ...(projectId
      ? [
          {
            name: 'project_id',
            value: projectId,
          },
        ]
      : []),
  ]

  return hubspotFormData
}

export default convertUpsellDataForHubspot
