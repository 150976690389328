import clsx from 'clsx'
import React, { FC } from 'react'
import FilterProps from './types'

const Filter: FC<FilterProps> = ({ size = 14, className }): JSX.Element => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx(
      {
        'fill-current text-content-action-default': !className,
      },
      className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.31905 2.76701L9.46329 2.76701C9.66209 2.76701 9.82324 2.60586 9.82324 2.40707C9.82324 2.20827 9.66209 2.04712 9.46329 2.04712L8.28658 2.04712L8.31905 2.76701ZM5.84034 2.04712L0.536593 2.04712C0.337799 2.04712 0.176645 2.20827 0.176645 2.40707C0.176645 2.60586 0.337799 2.76701 0.536593 2.76701L5.85018 2.76701"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.09225 4.05623C7.98442 4.05623 8.70752 3.33242 8.70752 2.43973C8.70752 1.54705 7.98442 0.823242 7.09225 0.823242C6.20009 0.823242 5.47699 1.54705 5.47699 2.43973C5.47699 3.33242 6.20009 4.05623 7.09225 4.05623ZM7.09225 1.54314C7.58667 1.54314 7.98762 1.94448 7.98762 2.43973C7.98762 2.93499 7.58667 3.33633 7.09225 3.33633C6.59783 3.33633 6.19688 2.93499 6.19688 2.43973C6.19688 1.94448 6.59783 1.54314 7.09225 1.54314Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.31905 9.53386L9.46329 9.53386C9.66209 9.53386 9.82324 9.37271 9.82324 9.17391C9.82324 8.97512 9.66209 8.81396 9.46329 8.81396L8.28658 8.81396L8.31905 9.53386ZM5.84034 8.81396L0.536593 8.81396C0.337799 8.81396 0.176645 8.97512 0.176645 9.17391C0.176645 9.37271 0.337799 9.53386 0.536593 9.53386L5.85018 9.53386"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.09225 10.8233C7.98442 10.8233 8.70752 10.0995 8.70752 9.20682C8.70752 8.31414 7.98442 7.59033 7.09225 7.59033C6.20009 7.59033 5.47699 8.31414 5.47699 9.20682C5.47699 10.0995 6.20009 10.8233 7.09225 10.8233ZM7.09225 8.31023C7.58667 8.31023 7.98762 8.71157 7.98762 9.20682C7.98762 9.70208 7.58667 10.1034 7.09225 10.1034C6.59783 10.1034 6.19688 9.70208 6.19688 9.20682C6.19688 8.71157 6.59783 8.31023 7.09225 8.31023Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.68095 5.50252L0.536705 5.50252C0.337912 5.50252 0.176758 5.66367 0.176758 5.86246C0.176758 6.06126 0.337912 6.22241 0.536705 6.22241L1.71342 6.22241L1.68095 5.50252ZM4.15966 6.22241L9.46341 6.22241C9.6622 6.22241 9.82335 6.06126 9.82335 5.86246C9.82335 5.66367 9.6622 5.50252 9.46341 5.50252L4.14982 5.50252"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.90775 4.21331C2.01558 4.21331 1.29248 4.93711 1.29248 5.8298C1.29248 6.72248 2.01558 7.44629 2.90775 7.44629C3.79991 7.44629 4.52301 6.72248 4.52301 5.8298C4.52301 4.93711 3.79991 4.21331 2.90775 4.21331ZM2.90775 6.72639C2.41333 6.72639 2.01238 6.32505 2.01238 5.8298C2.01238 5.33454 2.41333 4.9332 2.90775 4.9332C3.40217 4.9332 3.80312 5.33454 3.80312 5.8298C3.80312 6.32505 3.40217 6.72639 2.90775 6.72639Z"
    />
  </svg>
)

export default Filter
