import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { Range } from 'react-range'
import { RangeSliderTrack } from './RangeSliderTrack'
import { RangeSliderThumb } from './RangeSliderThumb'
import { getRangeSliderTheme } from './utils/getRangeSliderTheme'
import { RangeSliderProps } from './types'
import { Typography } from '../Typography'

export const RangeSlider: FC<RangeSliderProps> = ({
  testId = 'range-slider',
  min,
  max,
  values,
  step = 1,
  rtl,
  theme = 'neon-blue',
  labels,
  disabled = false,
  onChange,
}) => {
  const handleOnChange = (newValues: number[]): void => {
    if (disabled) return

    onChange?.(newValues)
  }

  const sliderTheme = useMemo(() => getRangeSliderTheme(theme), [theme])

  const renderLabel = (label: React.ReactNode, position: 'left' | 'right') =>
    typeof label === 'string' ? (
      <Typography
        testId={`${testId}-label-${position}`}
        size="xs"
        weight="medium"
        className="text-subtle"
      >
        {label}
      </Typography>
    ) : (
      <span data-testid={`${testId}-label-${position}`}>{label}</span>
    )

  return (
    <div
      data-testid={testId}
      className={clsx('flex items-center gap-x-3', {
        'opacity-20': disabled,
      })}
    >
      {labels?.left && renderLabel(labels.left, 'left')}

      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={handleOnChange}
        renderTrack={({ props, children }) => (
          <RangeSliderTrack
            props={props}
            min={min}
            max={max}
            values={values}
            colors={sliderTheme}
            disabled={disabled}
            rtl={rtl}
          >
            {children}
          </RangeSliderTrack>
        )}
        renderThumb={({ props }) => (
          <RangeSliderThumb key={props.key} props={props} disabled={disabled} />
        )}
      />

      {labels?.right && renderLabel(labels.right, 'right')}
    </div>
  )
}
