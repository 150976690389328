import React, { FC, memo } from 'react'
// import PrimarySDGGrid from 'src/components/PrimarySDGGrid'
// import SecondarySDGGrid from 'src/components/SecondarySDGGrid'
import { SDGRendererProps } from './types'

// const SDGRenderer: FC<SDGRendererProps> = memo(
//   ({ testId = 'sdg-renderer', primary, secondary }) => (
//     <>
//       <PrimarySDGGrid testId={`${testId}-primary-grid`} primary={primary} />
//       <SecondarySDGGrid
//         testId={`${testId}-secondary-grid`}
//         secondary={secondary}
//       />
//     </>
//   ),
// )

const SDGRenderer: FC<SDGRendererProps> = () => <div>SDG</div>

export default SDGRenderer
