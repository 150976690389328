import { Typography } from '@sylveraio/design-system'
import { ContentShadow } from '../../ContentShadow'

export function OfftakeTooltip() {
  return (
    <ContentShadow wrapper className="max-w-80">
      <Typography size="xs" weight="semibold" className="text-default">
        Offtake
      </Typography>
      <Typography size="xs" weight="regular" className="text-subtle">
        An offtake agreement is a contract where a buyer commits to purchasing a
        specified quantity of carbon credits at a predetermined price from a
        project developer
      </Typography>
    </ContentShadow>
  )
}

export function EquityTooltip() {
  return (
    <ContentShadow wrapper className="max-w-80">
      <Typography size="xs" weight="semibold" className="text-default">
        Equity
      </Typography>
      <Typography size="xs" weight="regular" className="text-subtle">
        Equity financing is the process by which a project developer raises
        capital by selling shares of ownership in their company or project to
        investors.
      </Typography>
    </ContentShadow>
  )
}

export function DebtTooltip() {
  return (
    <ContentShadow wrapper className="max-w-80">
      <Typography size="xs" weight="semibold" className="text-default">
        Debt
      </Typography>
      <Typography size="xs" weight="regular" className="text-subtle">
        Debt financing involves a project developer raising capital by selling
        debt instruments to investors.
      </Typography>
    </ContentShadow>
  )
}
