'use client'
import { v4 } from 'uuid'
import { ANALYTICS_EVENT } from '../constants'
import type { AnalyticsEventData } from '../types'

/**
 * Posts a custom analytics event
 * @param data
 * @returns
 */
export function sendAnalyticsEvent(data: AnalyticsEventData) {
  const isServerSide = typeof window === 'undefined'

  if (isServerSide) return

  const event = new CustomEvent(ANALYTICS_EVENT, {
    detail: {
      ...data,
      eventId: v4(),
    },
  })

  window.dispatchEvent(event)
}
