import { useState } from 'react'
import difference from 'lodash/difference'
import type { FieldSearchFn, Values } from '../createForm/types'
import { useSylveraQuery } from '@sylveraio/react-utils'
import type { ApolloClient } from '@apollo/client'
import {
  GET_PROJECT_ACCREDITATIONS,
  type Accreditations,
} from '../../queries/GET_ACCREDITATIONS'

export function useAccreditations(client: ApolloClient<any>) {
  const [allAccreditations, setAllAccreditations] = useState<Values>([])
  const [accreditationOpts, setAccreditationOpts] = useState<Values>([])
  const handleAccreditationSearch: FieldSearchFn = (
    value,
    key,
    currentValues = [],
  ) => {
    if (value === '') {
      setAccreditationOpts([])
    } else {
      const optsMinusSelected = difference(allAccreditations, currentValues)
      setAccreditationOpts(
        optsMinusSelected.filter(({ text }) =>
          text.toLowerCase().match(value.toLowerCase()),
        ),
      )
    }
  }

  useSylveraQuery<Accreditations>(GET_PROJECT_ACCREDITATIONS, client, {
    variables: {
      first: 50,
    },
    onCompleted: (data: Accreditations) => {
      if (!data) {
        setAllAccreditations([])
        return
      }

      const { nodes } = data.accreditations
      setAllAccreditations(
        nodes.reduce((accreditations, accreditation) => {
          const distinctions = accreditation.distinctions.nodes.map(
            (distinction) => ({
              value: distinction.id,
              text: distinction.name,
              description: accreditation.abbreviation,
            }),
          )

          return [
            ...accreditations,
            {
              value: accreditation.id,
              text: accreditation.abbreviation,
              description: accreditation.name,
            },
            ...distinctions,
          ]
        }, [] as Values),
      )
    },
  })

  return {
    accreditationOpts,
    handleAccreditationSearch,
  }
}
