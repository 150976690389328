/**
 * Formats and splits a chart label into multiple lines based on the specified rules.
 * @param {number} val- The value to be shown on the label.
 * @param {string} description - The description to be shown on the label.
 * @returns {string} - The formatted label with line breaks.
 */
export const formatChartLabel = (val: number, description: string): string => {
  const value = val === 0 ? '< 1' : `${val}%`

  if (typeof description === 'undefined') return value

  // Check for comma and split if present
  if (description.includes(',')) {
    const parts = description.split(',')
    return `${value}\n${parts[0]},\n${parts[1].trim()}`
  }

  // Split label description by spaces
  const words = description.split(' ')

  if (words?.length === 2) {
    // If there are 2 words, split them into two lines
    return `${value}\n${words[0]}\n${words[1]}`
  } else if (words?.length === 3) {
    // If there are 3 words, first two words on the first line, third word on the second line
    return `${value}\n${words[0]} ${words[1]}\n${words[2]}`
  } else if (words?.length === 4) {
    // If there are 4 words, first two words on the first line, next two words on the second line
    return `${value}\n${words[0]} ${words[1]}\n${words[2]} ${words[3]}`
  }

  // Default case: return the label as is
  return `${value}\n${description}`
}

export default formatChartLabel
