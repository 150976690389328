import type { FormAcceptedValues, FormField, FormPage } from '../types'
import { getValidFields } from './getValidFields'

/**
 * Gets all visible form fields for the current page
 * @param pages
 * @param pageIdx
 * @param currentFormValues
 * @returns
 */
export function getCurrentPageFields(
  pages: Array<FormPage>,
  pageIdx: number,
  currentFormValues: Record<string, FormAcceptedValues>,
): Array<FormField> {
  const allFieldsForPage = pages.find((entry, i) => i === pageIdx)?.fields || []
  return getValidFields(allFieldsForPage, currentFormValues, true)
}
