import { FC } from 'react'
import type { PagerProps } from './types'

export const Pager: FC<PagerProps> = ({ currentPage, totalPages }) => {
  if (!totalPages) return null

  return (
    <div className="mx-2 text-default">
      <span className="mr-2" data-testid="pager-label">
        Page
      </span>
      <span data-testid="pager-range">
        {currentPage} of {totalPages}
      </span>
    </div>
  )
}

export default Pager
