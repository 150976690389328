import { forwardRef } from 'react'
import { useTooltipContext } from './utils/useTooltipContext'
import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { TooltipContentProps } from './types'

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  ({ rootNodeId, rootElement, style, ...props }, propRef) => {
    const context = useTooltipContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    if (!context.isOpen) return null

    return (
      <FloatingPortal id={rootNodeId} root={rootElement}>
        <div
          data-testid="tooltip"
          ref={ref}
          className="z-[1000]"
          style={{
            ...context.floatingStyles,
            ...style,
          }}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    )
  },
)
