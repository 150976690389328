import { useMemo, type FC } from 'react'
import type { DropdownProps } from './types'
import { variantComponents } from './constants'

export const Dropdown: FC<DropdownProps> = ({
  variant = 'default',
  ...props
}) => {
  const VariantDropdownComponent = useMemo(
    () => variantComponents[variant],
    [variant],
  )

  if (!VariantDropdownComponent) {
    return null
  }

  return <VariantDropdownComponent {...props} />
}
