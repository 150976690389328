import { DropdownTooltip } from '../DropdownTooltip'
import type { FC } from 'react'
import { FilterableList } from '../../FilterableList'
import type { DropdownFilterableListProps } from './types'
import { HelperBlock } from '../../HelperBlock'
import { SubmitResetButtons } from '../../SubmitResetButtons'
import { NO_RESULTS_TEXT } from './constants'
import { Spinner } from '@sylveraio/design-system'

export const DropdownFilterableList: FC<DropdownFilterableListProps> = ({
  testId = 'dropdown-filterable-list',
  onChange,
  entries,
  selectedEntries,
  disabledEntries,
  isVisible = false,
  referenceElement,
  name = 'filterable-list',
  type = 'checkbox',
  placeholderText,
  showHelperBlock,
  helperBlockText,
  listWrapperClassName,
  hideSearchSortSection,
  hideSearchSection,
  hideSortSection,
  placement,
  offset,
  onReset,
  loading,
  onSort,
  onTextSearch,
  onSortAnalyticsHandler,
  showSaveCancel,
  handleSave,
  handleCancel,
  disableSaveButton = false,
  disableCancelButton = false,
}) => (
  <DropdownTooltip
    referenceElement={referenceElement}
    isVisible={isVisible}
    testId={testId}
    offset={offset}
    placement={placement}
  >
    <FilterableList
      name={name}
      onChange={onChange}
      entries={entries}
      selectedEntries={selectedEntries}
      isVisible={isVisible}
      type={type}
      placeholderText={placeholderText}
      testId={`${testId}-filterable-list`}
      onReset={onReset}
      onSort={onSort}
      onTextSearch={onTextSearch}
      onSortAnalyticsHandler={onSortAnalyticsHandler}
      listWrapperClassName={listWrapperClassName}
      hideSearchSortSection={hideSearchSortSection}
      disabledEntries={disabledEntries}
      hideSearchSection={hideSearchSection}
      hideSortSection={hideSortSection}
    />
    {entries?.length === 0 && !loading && (
      <div
        data-testid={`${testId}-no-results`}
        className="absolute flex h-full w-full items-center justify-center text-xs text-default"
      >
        {NO_RESULTS_TEXT}
      </div>
    )}
    {showHelperBlock && helperBlockText && (
      <HelperBlock helperText={helperBlockText} />
    )}
    {showSaveCancel && (
      <SubmitResetButtons
        handleSubmit={handleSave}
        handleReset={handleCancel}
        disableSubmitButton={disableSaveButton}
        disableResetButton={disableCancelButton}
        submitButtonText="Save"
      />
    )}
    {loading && (
      <div
        data-testid={`${testId}-loader`}
        className="absolute z-10 flex h-full w-full items-center justify-center bg-high"
      >
        <Spinner className="!h-10 !w-10" />
      </div>
    )}
  </DropdownTooltip>
)
