import { gql } from '@apollo/client'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type SupplyRequest = {
  createSupplyRequest: {
    supplyRequest: {
      userId: string
      supplyRequestStatus: {
        name: string
      }
      formAnswers: Record<string, any>
      createdAt: string
    }
  }
}

export const CREATE_SUPPLY_REQUEST: TypedDocumentNode<
  SupplyRequest,
  {
    formAnswers: Record<string, any>
    userId: string
  }
> = gql`
  mutation CreateSupply($userId: UUID!, $formAnswers: FormAnswers!) {
    createSupplyRequest(
      input: { supplyRequest: { userId: $userId, formAnswers: $formAnswers } }
    ) {
      supplyRequest {
        userId
        supplyRequestStatus {
          name
        }
        formAnswers
        createdAt
      }
    }
  }
`
