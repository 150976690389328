'use client'

import { useEffect, useState } from 'react'

import { type WindowDimensions, type Breakpoint } from './types'

const useWindowDimensions = (
  setInitial?: boolean,
): {
  windowDimensions: WindowDimensions
  isBelowScreenWidth: (width: Breakpoint) => boolean
  isBelowOrEqualToScreenWidth: (width: Breakpoint) => boolean
  isAboveOrEqualToScreenWidth: (width: Breakpoint) => boolean
} => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: setInitial ? window.innerWidth : undefined,
    height: setInitial ? window.innerHeight : undefined,
  })

  useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isBelowScreenWidth = (width: Breakpoint): boolean => {
    const { width: currentWidth } = windowDimensions

    if (currentWidth && currentWidth < width) return true

    return false
  }

  const isBelowOrEqualToScreenWidth = (width: Breakpoint): boolean => {
    const { width: currentWidth } = windowDimensions

    if (currentWidth && currentWidth <= width) return true

    return false
  }

  const isAboveOrEqualToScreenWidth = (width: Breakpoint): boolean => {
    const { width: currentWidth } = windowDimensions

    if (currentWidth && currentWidth >= width) return true

    return false
  }

  return {
    windowDimensions,
    isBelowScreenWidth,
    isBelowOrEqualToScreenWidth,
    isAboveOrEqualToScreenWidth,
  }
}

export default useWindowDimensions
