import { SylveraRating } from 'types'

export const RATINGS_ARRAY: Array<SylveraRating> = [
  'D',
  'C',
  'B',
  'BB',
  'BBB',
  'A',
  'AA',
  'AAA',
]
