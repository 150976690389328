import clsx from 'clsx'
import { type FC } from 'react'
import type { LabelProps } from './types'
import {
  getLabelColors,
  getSplitLabelBorderColors,
} from './utils/getLabelColors'
import { getLabelSize } from './utils/getLabelSize'
import { getIconSize } from './utils/getIconSize'
import { Typography } from '../Typography'

export const Label: FC<LabelProps> = ({
  testId = 'label',
  label,
  size = 'md',
  color = 'action',
  strong = false,
  outlined = false,
  iconLeading,
  iconTrailing,
  splitLabel,
  ping = false,
  onClick,
}) => {
  const labelBaseClass =
    'z-10 flex w-fit items-center justify-center border font-medium'
  const pingBaseClass = 'absolute z-0 w-full animate-ping'
  const sizeClass = getLabelSize(size)
  const colorClass = getLabelColors(color, strong, outlined)
  const splitLabelPaddingLeftClass = size === 'sm' ? 'pl-1' : 'pl-1.5'
  const textSize = size === 'lg' ? 'sm' : 'xs'

  return (
    <div
      data-testid={testId}
      className={clsx('relative inline-flex', { 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      {ping && (
        <div
          data-testid={`${testId}-ping`}
          className={clsx(pingBaseClass, sizeClass, colorClass)}
        />
      )}
      <div
        data-testid={`${testId}-label`}
        className={clsx(labelBaseClass, sizeClass, colorClass)}
      >
        {iconLeading && (
          <span
            data-testid={`${testId}-leading-icon`}
            className={getIconSize(size)}
          >
            {iconLeading}
          </span>
        )}
        <Typography
          testId={`${testId}-title`}
          size={textSize}
          weight="medium"
          className="!text-inherit"
        >
          {label}
        </Typography>
        {iconTrailing && (
          <span
            data-testid={`${testId}-trailing-icon`}
            className={getIconSize(size)}
          >
            {iconTrailing}
          </span>
        )}
        {splitLabel && (
          <div
            data-testid={`${testId}-split`}
            className={clsx(
              'border-l',
              splitLabelPaddingLeftClass,
              getSplitLabelBorderColors(color, strong),
            )}
          >
            <Typography
              testId={`${testId}-split-title`}
              size={textSize}
              weight="medium"
              className="!text-inherit"
            >
              {splitLabel}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
