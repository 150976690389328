import type { FC } from 'react'
import Image from 'next/image'
import { ButtonLegacy, Typography } from '@sylveraio/design-system'
import type { GenericCustomHubspotFormProps } from './types'
import CONST from './constants'

export const GenericCustomHubspotForm: FC<GenericCustomHubspotFormProps> = ({
  image,
  text,
  submitButtonText,
  handleFormSubmitClick,
  handleAbandonFormSubmitClick,
}) => {
  // '259px' value is used to align the button wrapper with the svg image
  // columns. This is a temporary solution until the upsell tier is implented in
  // code.
  const buttonWrapperClassName = 'w-[259px] px-4 py-2'

  return (
    <>
      {image && (
        <div>
          <Image
            src={image.src}
            alt={image.alt}
            width={image.width}
            height={image.height}
            layout="fixed"
          />
        </div>
      )}
      {text && (
        <Typography size="lg" className="py-4">
          {text}
        </Typography>
      )}
      <div className="flex gap-2 self-end">
        <div className={buttonWrapperClassName}>
          <ButtonLegacy
            size="xl"
            className="w-full"
            theme="off-white-1"
            testId="upsell-modal-cancel-button"
            onClick={handleAbandonFormSubmitClick}
          >
            {CONST.CANCEL}
          </ButtonLegacy>
        </div>
        <div className={buttonWrapperClassName}>
          <ButtonLegacy
            size="xl"
            className="w-full"
            theme="neon-blue"
            testId="upsell-modal-request-button"
            onClick={handleFormSubmitClick}
          >
            {submitButtonText ?? CONST.REQUEST_ACCESS}
          </ButtonLegacy>
        </div>
      </div>
    </>
  )
}
