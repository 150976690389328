import { type FC, useRef } from 'react'
import { Button } from '../Button'
import { useHover } from '@sylveraio/react-utils'
import clsx from 'clsx'
import type { SidebarSortButtonProps } from './types'
import ArrowNarrowUp from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowNarrowUp'
import ArrowNarrowDown from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowNarrowDown'
import InfoCircle from '@sylveraio/untitled-ui-icons-react/build/esm/InfoCircle'
import { Tooltip, Typography } from '@sylveraio/design-system'

export const SidebarSortButton: FC<SidebarSortButtonProps> = ({
  testId = 'sort-button',
  isSelected,
  sortButtonText,
  className,
  direction,
  handleButtonClick,
  infoTooltipLabel,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isHovered] = useHover(buttonRef)
  const arrowsClassName = clsx('w-4 h-4 mr-1 ', {
    'text-content-action-default': isSelected,
    'stroke-icon-default': !isSelected && isHovered,
    invisible: !isSelected && !isHovered,
  })

  return (
    <Button
      testId={`${testId}-sidebar-sort-button`}
      className={clsx(
        'flex !w-full !min-w-[90px] !h-10 text-default hover:text-default font-semibold !text-base border-0 !pl-0 !justify-start',
        className,
      )}
      onClick={handleButtonClick}
      ref={buttonRef}
    >
      {direction === 'desc' ? (
        <ArrowNarrowDown className={arrowsClassName} />
      ) : (
        <ArrowNarrowUp className={arrowsClassName} />
      )}
      {sortButtonText}
      {infoTooltipLabel && (
        <Tooltip
          content={
            <div className="w-max max-w-64 rounded-lg bg-high p-2 shadow-lg">
              <Typography size="xs">{infoTooltipLabel}</Typography>
            </div>
          }
          remainOpenOnHover
        >
          <InfoCircle className="ml-1 h-4 w-4" />
        </Tooltip>
      )}
    </Button>
  )
}
