'use client'

import { FC, useState } from 'react'
import { DataDisclaimerModal } from '../DataDisclaimerModal'

export const FooterAnalytics: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const handleOpenModal = () => setShowModal(!showModal)

  return (
    <>
      <div className="pl-4 border-l">
        <button
          type="button"
          className="text-sm font-medium border-b"
          data-testid="footer-cca-disclaimer-button"
          onClick={handleOpenModal}
        >
          CCA Disclaimer
        </button>
      </div>
      <DataDisclaimerModal show={showModal} onClick={handleOpenModal} />
    </>
  )
}
