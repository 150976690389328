import { Typography } from '@sylveraio/design-system'
import type { FormFieldBase } from '../types'
import type { PropsWithChildren } from 'react'

export function FormFieldLabel({
  title,
  description,
  required,
  children,
}: Pick<FormFieldBase, 'title' | 'description' | 'required'> &
  PropsWithChildren): JSX.Element {
  return (
    <>
      <Typography size="sm" weight="semibold">
        {title}
      </Typography>
      {!required && (
        <Typography size="sm" weight="regular" className="text-subtle">
          Optional
        </Typography>
      )}
      {description && (
        <Typography size="sm" weight="regular" className="text-subtle">
          {description}
        </Typography>
      )}
      {typeof children !== 'undefined' && (
        <div className="mt-3">{children}</div>
      )}
    </>
  )
}
