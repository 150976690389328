'use client'

import Fuse from 'fuse.js'
import { useMemo } from 'react'

export function useFullTextSearch<T extends object, K extends string>(
  dataset: Array<T> = [],
  keys: Array<K> = [],
  search?: string,
  options?: Partial<Fuse.IFuseOptions<T>>,
): Array<T> {
  const fuse = useMemo(
    () =>
      new Fuse(dataset, {
        findAllMatches: true,
        ignoreFieldNorm: true,
        ignoreLocation: true,
        threshold: 0,
        distance: 1000,
        ...options,
        keys,
      }),
    [dataset, keys, options],
  )

  const results = useMemo(() => {
    if (!search) return dataset

    return fuse
      .search(search)
      .map((r) => r.item)
      .flat() as Array<T>
  }, [search, dataset])

  return results
}
