/* eslint-disable no-useless-escape */
'use client'

import { datadogRum } from '@datadog/browser-rum'

if (
  typeof process.env.NEXT_PUBLIC_DATADOG_APP_ID !== 'undefined' &&
  typeof process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN !== 'undefined' &&
  typeof process.env.NEXT_PUBLIC_DATADOG_SERVICE !== 'undefined'
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID || '',
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.eu',
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    env: process.env['NEXT_PUBLIC_APP_ENV'],
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Specify URLs to propagate trace headers for connection between RUM and backend trace
    allowedTracingUrls: [
      {
        match: '^https://dashboard.(dev.|staging.)?sylvera.com/.*$',
        propagatorTypes: ['tracecontext'],
      },
    ],
  })
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
