import clsx from 'clsx'
import type { FC } from 'react'
import type { ContentShadowProps, ContentShadowTabsProps } from './types'

const ContentShadowTab: FC<ContentShadowTabsProps> = ({
  testId = 'content-shadow-tab',
  tabs,
  activeTab,
}) => {
  return (
    <ul
      data-testid={testId}
      className="flex w-[800px] overflow-hidden text-center"
    >
      {tabs?.map((tab, idx, arr) => {
        return (
          <li
            key={tab.id}
            className={clsx('flex-1 relative', {
              'z-20': activeTab === tab.id,
              'z-[5]': idx === 0 && activeTab !== tab.id,
              'z-[4]': idx === 1 && activeTab !== tab.id,
              'z-[3]': idx === 2 && activeTab !== tab.id,
              'z-[2]': idx === 3 && activeTab !== tab.id,
              'z-[1]': idx === 4 && activeTab !== tab.id,
              '-translate-x-2': idx === 1,
              '-translate-x-4': idx === 2,
              '-translate-x-6': idx === 3,
              '-translate-x-8': idx === 4,
            })}
            data-testid={`${testId}-${tab.id}`}
          >
            <button
              data-testid={`${testId}-${tab.id}-button`}
              data-tab-id={tab.id}
              className={clsx(
                'block w-full py-2.5 text-lg font-medium text-default shadow-[0px_4px_6px_1px_rgb(0_0_0/0.1)]',
                {
                  'bg-high rounded-t-lg': activeTab === tab.id,
                  'rounded-tl-lg': idx === 0,
                  'bg-medium rounded-tr-lg': activeTab !== tab.id,
                },
              )}
              onClick={tab.onClick}
            >
              {tab.title}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export const ContentShadow: FC<ContentShadowProps> = ({
  testId = 'content-shadow',
  children,
  className,
  wrapperClassName,
  align = 'center',
  wrapper,
  shadow = true,
  bgColor,
  withTabs,
  tabs,
  activeTab,
}) => (
  <>
    {withTabs && tabs?.length && (
      <ContentShadowTab tabs={tabs} activeTab={activeTab} />
    )}
    <div
      data-testid={testId}
      className={clsx(
        'flex items-center text-md p-4',
        {
          'shadow-md': shadow,
          'justify-center': align === 'center',
          'justify-start': align === 'left',
          'bg-high': typeof bgColor === 'undefined',
          'relative rounded-b-lg rounded-tr-lg z-10 !shadow-[0px_2px_6px_1px_rgb(0_0_0/0.1)]':
            withTabs && tabs?.length,
          'rounded-lg': !withTabs,
        },
        className,
        bgColor,
      )}
    >
      {wrapper ? (
        <section className={clsx('w-full', wrapperClassName)}>
          {children}
        </section>
      ) : (
        children
      )}
    </div>
  </>
)
