import { type FC } from 'react'
import type { DefaultDropdownProps } from './types'
import { ButtonLegacy } from '../ButtonLegacy'
import clsx from 'clsx'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'
import ChevronUp from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronUp'
import { Typography } from '../Typography'

export const DefaultDropdown: FC<DefaultDropdownProps> = ({
  testId = 'default-dropdown',
  title,
  label,
  isOpen,
  children,
  wrapperClassName,
  modalClassName,
  className,
  onOpen,
}) => {
  const chevronIcon = isOpen ? (
    <ChevronUp className="w-5 h-5" />
  ) : (
    <ChevronDown className="w-5 h-5" />
  )
  return (
    <div
      data-testid={testId}
      className={clsx(
        'flex flex-col bg-high p-6 pb-8 w-full rounded',
        wrapperClassName,
      )}
    >
      <ButtonLegacy
        testId={`${testId}-button`}
        align="none"
        appearance="none"
        className={clsx(
          'flex items-center justify-between w-full h-[50px] pt-4 pb-2',
          className,
        )}
        onClick={onOpen}
      >
        <div className="flex shrink-0 items-center gap-x-4">
          <Typography size="base" weight="semibold">
            {title}
          </Typography>
          <Typography size="xs" weight="medium" className="text-subtle-on-dark">
            {label}
          </Typography>
        </div>
        {chevronIcon}
      </ButtonLegacy>
      <div
        data-testid={`${testId}-modal`}
        className={clsx(
          'flex flex-col w-full border-b border-default',
          {
            'pt-2': isOpen,
          },
          modalClassName,
        )}
      >
        {isOpen && children}
      </div>
    </div>
  )
}
