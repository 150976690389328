/* eslint-disable no-param-reassign */
import { FC, useState } from 'react'
import clsx from 'clsx'
import truncate from 'lodash/truncate'
import { HoverTooltip } from '../../HoverTooltip'
import { getLegendTooltipEntities } from '../constants'
import type { LegendItemProps } from './types'

export const LegendItem: FC<LegendItemProps> = ({
  testId = 'bar-chart-legend-item',
  item,
  chartInstance,
  legendIdGroup,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false)

  function onClickHandler() {
    if (
      !chartInstance ||
      !chartInstance.data ||
      !chartInstance.options ||
      typeof item.datasetIndex === 'undefined'
    )
      return

    const dataset = chartInstance.data.datasets[item.datasetIndex]

    chartInstance.options.animation = false

    if (legendIdGroup?.includes(dataset)) {
      legendIdGroup?.forEach((ds) => {
        const datasetIdx: number =
          // @ts-ignore
          ds?.data?._chartjs?.listeners[0]?.$context?.datasetIndex

        chartInstance.setDatasetVisibility?.(
          datasetIdx,
          !chartInstance.isDatasetVisible?.(datasetIdx),
        )

        setDisabled(!disabled)
      })

      chartInstance.update?.()

      return
    }

    chartInstance.setDatasetVisibility?.(
      item.datasetIndex,
      !chartInstance.isDatasetVisible?.(item.datasetIndex),
    )

    setDisabled(!disabled)

    chartInstance.update?.()
  }

  return (
    <HoverTooltip
      placement="left"
      offset={[0, 5]}
      content={getLegendTooltipEntities(item, chartInstance)}
    >
      <button
        data-testid={testId}
        type="button"
        className={clsx(
          'flex items-center w-48 gap-4 p-2 border rounded-full',
          {
            'border-subtle': item.strokeStyle,
            'opacity-30': disabled,
          },
        )}
        onClick={onClickHandler}
      >
        <div
          className="w-4 h-4 rounded-full shrink-0"
          style={{
            backgroundColor: `${
              item.strokeStyle ? item.strokeStyle : item.fillStyle
            }`,
          }}
        />
        <span className="text-xs font-medium text-left text-selection-forest">
          {truncate(item.text, { length: 25 })}
        </span>
      </button>
    </HoverTooltip>
  )
}

export default LegendItem
