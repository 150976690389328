import { FC } from 'react'
import clsx from 'clsx'
import kebabCase from 'lodash/kebabCase'
import type { GenericListProps } from './types'

export const GenericList: FC<GenericListProps> = ({
  testId = 'generic-list',
  entries = [],
  selectedEntry,
  onClick,
}) => {
  return (
    <div
      data-testid={testId}
      className={clsx(
        'overflow-none px-3 py-1 rounded-lg bg-high inline-flex flex-col shadow min-w-[222px] ',
      )}
    >
      <ul className="h-full max-h-full shrink divide-y divide-solid divide-primary-300 overflow-y-scroll focus:outline-none active:outline-none">
        {(entries ?? []).map((entry: string) => (
          <li
            data-testid={`${testId}-item-${kebabCase(entry)}`}
            key={`list-item-${kebabCase(entry)}`}
          >
            <button
              data-testid={`${testId}-item-${kebabCase(entry)}-button`}
              type="button"
              className="w-full py-3 text-left text-sm text-default disabled:text-disabled"
              disabled={selectedEntry === entry}
              value={entry}
              onClick={onClick}
            >
              {entry}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
