import React, { FC, memo } from 'react'
import { TextRendererProps } from './types'

const TextRenderer: FC<TextRendererProps> = memo(({ content, className }) => {
  const paragraphs = content.split('\n')

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <p key={`rendered-text-${index}`} className={className}>
          {paragraph}
        </p>
      ))}
    </>
  )
})

export default TextRenderer
