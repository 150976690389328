import { type FC } from 'react'
import type { FeaturedIconProps } from './types'
import { getFeaturedIconSize } from './utils/getFeaturedIconSize'
import clsx from 'clsx'
import { getFeaturedIconColors } from './utils/getFeaturedIconColors'

export const FeaturedIcon: FC<FeaturedIconProps> = ({
  testId = 'featured-icon',
  icon,
  size = 'md',
  color = 'action',
  strong = false,
}) => {
  const baseClass = 'flex items-center justify-center'
  const sizeClass = getFeaturedIconSize(size)
  const colorClass = getFeaturedIconColors(color, strong)

  return (
    <div
      data-testid={testId}
      className={clsx(baseClass, sizeClass, colorClass)}
    >
      {icon}
    </div>
  )
}
