import SearchSm from '@sylveraio/untitled-ui-icons-react/build/esm/SearchSm'
import { FieldContainer } from './FieldContainer'
import clsx from 'clsx'
import { createKey } from '../utils/createKey'
import { Tag } from './Tag'
import type {
  FieldSearch,
  FormFieldTags,
  FormFieldType,
  Value,
  ValueBase,
} from '../types'
import { useRef, type ChangeEvent } from 'react'
import isEqual from 'lodash/isEqual'
import { Checkbox } from '@sylveraio/design-system'
import { CheckboxRow } from './CheckboxRow'

export function FormSearch({
  searchFn,
  opts = [],
  currentValues,
  type,
  name,
  onChange,
  placeholderText,
  testId = 'form-search',
  limitSelected,
  limitDisplayed = 100,
  stringifyTagFn,
  excludeValues = [],
}: FieldSearch & {
  currentValues: Array<ValueBase>
  type: FormFieldType
  name: string
  onChange: (name: string, value: Array<ValueBase>) => void
  testId?: string
  limitSelected?: number
  limitDisplayed?: number
  stringifyTagFn?: FormFieldTags['stringifyTagFn']
  excludeValues?: Array<ValueBase>
}): JSX.Element {
  const excludeValuesIds = excludeValues.map(({ value }) => value)
  const selectedSearchValues = currentValues.filter(
    ({ value }) => !excludeValuesIds.includes(value),
  )
  const ref = useRef<null | HTMLInputElement>(null)
  const handleAdd = (value: Value) => {
    if (
      typeof limitSelected === 'number' &&
      currentValues.length === limitSelected
    ) {
      return
    }
    const newValues = [...currentValues, value]
    onChange(name, newValues)
    if (ref.current) {
      ref.current.value = ''
      searchFn('', name, currentValues)
    }
  }
  const handleRemove = (value: Value) => {
    const newValues = currentValues.filter((v) => !isEqual(v, value))
    onChange(name, newValues)
  }
  const handleSearch = async (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    searchFn(value, name, currentValues)
  }
  const hasOptions = opts.length > 0
  return (
    <div className="group relative">
      <FieldContainer inline className="min-h-12 h-auto">
        <SearchSm
          className="m-3 h-6 w-6 !stroke-icon-subtle shrink-0"
          color="inherit"
        />
        <div className="flex flex-wrap w-full py-3">
          <div className="mr-3 flex gap-x-3 gap-y-2 flex-wrap grow">
            {selectedSearchValues.map((entry) => (
              <Tag
                key={createKey(type, name, entry.value, 'selected')}
                value={entry}
                onRemove={handleRemove}
                testId={`${testId}-tag-${entry.value}`}
                stringifyTagFn={stringifyTagFn}
              />
            ))}
            <input
              type="search"
              className="bg-transparent pr-4 focus:outline-none grow shrink min-w-24"
              name={name}
              data-testid={testId}
              onChange={handleSearch}
              placeholder={placeholderText}
              ref={ref}
            />
          </div>
        </div>
      </FieldContainer>
      <ul
        className={clsx(
          'px-4 bg-high rounded-lg border border-default grid grid-cols-1 absolute w-full z-10 invisible max-h-64 overflow-y-auto mt-1 shadow-md',
          {
            'group-focus-within:visible': hasOptions,
          },
        )}
        data-testid={`${testId}-items`}
      >
        {hasOptions &&
          opts
            .filter((entry, i) => i < limitDisplayed)
            .map((entry, idx) => (
              <li key={createKey(type, name, entry.value)} className="py-3">
                <button
                  type="button"
                  role="listitem"
                  className="w-full h-full"
                  onClick={(event) => event.stopPropagation()}
                >
                  <Checkbox
                    id={createKey(type, name, entry.value, 'check')}
                    label={
                      <CheckboxRow
                        text={entry.text}
                        description={entry.description}
                        icon={entry.icon}
                        tooltip={entry.tooltip}
                        value={entry.value}
                      />
                    }
                    name={createKey(type, name, entry.value)}
                    onChange={() =>
                      currentValues.includes(entry)
                        ? handleRemove(entry)
                        : handleAdd(entry)
                    }
                    checked={currentValues.includes(entry)}
                    testId={`${testId}-item-${entry.value}`}
                    className="shrink-0"
                  />
                </button>
              </li>
            ))}
      </ul>
    </div>
  )
}
