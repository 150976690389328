import {
  type DocumentNode,
  type OperationVariables,
  type QueryHookOptions,
  type TypedDocumentNode,
  useQuery,
  ApolloClient,
} from '@apollo/client'

export function useSylveraQuery<T>(
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  client: ApolloClient<any>,
  opts?: QueryHookOptions,
) {
  return useQuery<T>(query, {
    context: { apiName: 'sylvera' },
    ...opts,
    client,
  })
}
