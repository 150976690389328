import { FC } from 'react'
import clsx from 'clsx'
import { ConditionalDivButton } from '../../../ConditionalDivButton'
import type { ProgressRingNotApplicableProps } from './types'

export const ProgressRingNotApplicable: FC<ProgressRingNotApplicableProps> = ({
  testId = 'progress-ring-outer-ring',
  size = 'sm',
}) => (
  <ConditionalDivButton
    testId={testId}
    className={clsx('relative rounded-full bg-low overflow-hidden', {
      'p-1': size === 'sm',
      'p-[5px]': size === 'md',
      'p-2': size === 'lg',
      'p-2.5': size === 'xl',
    })}
  >
    <div
      className={clsx('flex items-center justify-center rounded-full bg-high', {
        'h-16 w-16 p-0.5': size === 'sm',
        'h-[76px] w-[76px] p-0.5': size === 'md',
        'h-[90px] w-[90px] p-1': size === 'lg',
        'h-[134px] w-[134px] p-1': size === 'xl',
      })}
    >
      <div className="flex items-center justify-center w-full h-full bg-high rounded-full">
        <span
          className={clsx('text-content-action-dark', {
            'text-lg': size === 'sm',
            'text-xl': size === 'md',
            'text-2xl': size === 'lg',
            'text-[40px]': size === 'xl',
          })}
        >
          NA
        </span>
      </div>
    </div>
  </ConditionalDivButton>
)

export default ProgressRingNotApplicable
