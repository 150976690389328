import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type ProjectsFuzzy = {
  projects: {
    nodes: Array<{
      name: string
      id: string
      publicId: string
      projectRegistry: {
        registryProjectId: string
        registry: {
          name: string
        }
      }
    }>
  }
}
export const GET_PROJECTS_FUZZY: TypedDocumentNode<
  ProjectsFuzzy,
  {
    search: string
  }
> = gql`
  query GetProjectsFuzzy($search: String) {
    projects(first: 20, condition: { fuzzySearch: { contains: $search } }) {
      nodes {
        name
        id
        publicId
        projectRegistry {
          registryProjectId
          registry {
            name
          }
        }
      }
    }
  }
`
