import { FC } from 'react'
import clsx from 'clsx'

import IconsProps from '../types'

const Tick: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6267 0.94099L5.11513 9.08267L0 4.81956L0.965118 3.66142L4.89797 6.93829L10.4504 0L11.6267 0.94099Z"
    />
  </svg>
)

export default Tick
