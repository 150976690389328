import clsx from 'clsx'
import { FC } from 'react'
import { CAPScoreProps } from './types'

import {
  globalSylveraScorePadding,
  globalSylveraScoreSize,
} from '../../constants'
import { MAX_SCORE, SegmentPaths, capScoreTextVariant } from './constants'
import type { SylveraScoreTheme } from '../../types'
import { Typography } from '../../../Typography'

const Segment: FC<{
  idx: number
  step: number
  path: string
  theme: SylveraScoreTheme
}> = ({ idx, step, path, theme }) => {
  return (
    <path
      className={clsx({
        'fill-new-palette-off-white-3':
          (!step || step < idx) && theme === 'dark',
        'fill-new-palette-white': (!step || step < idx) && theme === 'light',
        'fill-new-palette-neon-blue-300': step >= idx,
      })}
      d={path}
    />
  )
}

export const CAPScore: FC<Omit<CAPScoreProps, 'type'>> = ({
  testId = 'cap-score',
  size = '2xl',
  score = 0,
  hideDenominator,
  theme = 'dark',
}) => (
  <div
    data-testid={testId}
    className={clsx(
      'flex items-center justify-center relative',
      globalSylveraScoreSize[size],
      globalSylveraScorePadding[size],
    )}
  >
    <div
      data-testid={`${testId}-label`}
      className="absolute flex items-center justify-center text-content-action-dark"
    >
      <Typography
        testId={`${testId}-label-numerator`}
        size={capScoreTextVariant['numerator'][size]}
        weight="semibold"
        className={clsx('!leading-none !text-content-action-dark', {
          'mb-1.5': size === '3xl' && !hideDenominator,
          'mb-1': !['3xl', 'xs'].includes(size) && !hideDenominator,
        })}
      >
        {score}
      </Typography>
      {!hideDenominator && size !== 'xs' && (
        <Typography
          testId={`${testId}-label-denominator`}
          size={capScoreTextVariant['denominator'][size]}
          weight="medium"
          className={clsx('!leading-none !text-content-action-dark', {
            '-mb-1.5': size === '3xl',
            '-mb-0.5': size !== '3xl',
          })}
        >
          /{MAX_SCORE}
        </Typography>
      )}
    </div>
    <svg
      viewBox="0 0 138 138"
      width="100%"
      height="100%"
      className="flex shrink-0"
    >
      {SegmentPaths.map((d, idx) => (
        <Segment idx={idx + 1} step={score} path={d} theme={theme} />
      ))}
    </svg>
  </div>
)
