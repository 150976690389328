import type { TagThemes, TagSize, TagShape } from './types'

export const tagThemes: { [x in TagThemes]: string } = {
  'primary-burgundy': 'bg-new-palette-burgundy-900 text-default-on-dark',
  'primary-coral': 'bg-new-palette-coral-400 text-default',
  'primary-forest': 'bg-new-palette-forest-100 text-default',
  'primary-green': 'bg-new-palette-green-500 text-default-on-dark',
  'primary-indigo': 'bg-new-palette-indigo-900 text-default-on-dark',
  'primary-lemon': 'bg-new-palette-lemon-500 text-default',
  'primary-lime': 'bg-new-palette-lime-500 text-default',
  'primary-mauve': 'bg-new-palette-mauve-500 text-default-on-dark',
  'primary-neon-blue': 'bg-new-palette-neon-blue-500 text-default-on-dark',
  'primary-neon-blue-light': 'bg-new-palette-neon-blue-100 text-default',
  'secondary-burgundy':
    'bg-new-palette-burgundy-200 text-new-palette-burgundy-800',
  'secondary-coral': 'bg-new-palette-coral-200 text-content-warning-dark',
  'secondary-forest': 'bg-new-palette-green-100 text-placeholder',
  'secondary-green': 'bg-new-palette-green-100 text-new-palette-green-1000',
  'secondary-indigo': 'bg-new-palette-indigo-200 text-new-palette-indigo-800',
  'secondary-lemon': 'bg-new-palette-lemon-400 text-new-palette-lemon-900',
  'secondary-lime': 'bg-new-palette-lime-200 text-placeholder',
  'secondary-mauve': 'bg-new-palette-mauve-200 text-new-palette-mauve-800',
  'secondary-neon-blue':
    'bg-new-palette-neon-blue-100 text-content-action-dark',
  'secondary-water': 'bg-new-palette-water-200 text-new-palette-water-800',
  'secondary-red': 'bg-new-palette-red-200 text-content-alert-default',
  'secondary-bordered': 'border border-forest-700 text-placeholder',
}

export const tagSize: { [x in TagShape]: { [x in TagSize]: string } } = {
  rounded: {
    sm: 'h-3 py-1 px-1',
    md: 'h-4 py-1 px-1',
    lg: 'h-6 py-1 px-2',
  },
  pill: {
    sm: 'h-3 py-1 px-1',
    md: 'h-4 py-1 px-1',
    lg: 'h-6 py-1 px-2',
  },
}

export const tagTextSize: { [x in TagSize]: string } = {
  sm: '!text-[8px]',
  md: '!text-xs',
  lg: '!text-xs',
}

export const tagShape: { [x in TagShape]: { [x in TagSize]: string } } = {
  rounded: {
    sm: 'rounded-sm',
    md: 'rounded-sm',
    lg: 'rounded',
  },
  pill: {
    sm: 'rounded-full',
    md: 'rounded-full',
    lg: 'rounded-full',
  },
}
