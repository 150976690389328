'use client'

import type { FC } from 'react'
import { Sizes, type IconLibraryProps } from './types'
import clsx from 'clsx'
import { Icons } from './constants'

export const IconLibrary: FC<IconLibraryProps> = ({
  testId = 'icon-library',
  title,
  name,
  size = 'sm',
  className,
  wrapperClassName,
  ...props
}) => (
  <div
    data-testid={testId}
    title={title}
    style={{
      width: `${[Sizes[size]]}px`,
      height: `${[Sizes[size]]}px`,
    }}
    className={clsx(
      'flex items-center justify-center',
      {
        'p-1': size === 'lg',
      },
      wrapperClassName,
    )}
  >
    {Icons[name]({
      testId: `${testId}-${name}`,
      size,
      className,
      ...props,
    })}
  </div>
)
