import type { DeepPartial } from 'types'
import { TransformedRatedProjects } from '../useRatings'

const MOCK_RATINGS_DATA: TransformedRatedProjects = [
  {
    projectId: '9964401d-c22b-4c43-b26f-83ab0053b9e7',
    onWatch: false,
    projectName: 'Katingan',
    registryId: '12',
    registryName: 'Verra',
    projectType: 'REDD+',
    projectCountry: 'Brazil',
    projectCountryCode: 'br',
    permittedCredits: 123456,
    retiredCredits: 12,
    projectStage: 'Inactive',
    sylveraRating: 'AAA',
    sylveraRatingProvisional: 'positive',
    sylveraRatingIsProvisional: false,
    estimatedSupply: 1234567,
  },
]

export function mockUseRatingsReturnValue(
  data?: DeepPartial<TransformedRatedProjects>,
  clearData = false,
) {
  const mockData = clearData
    ? undefined
    : {
        ...MOCK_RATINGS_DATA,
        ...data,
      }

  jest.mock('@sylveraio/data', () => ({
    useRatings: jest.fn().mockReturnValue({
      data: mockData,
      isValidating: false,
      hasAccess: () => true,
      mutate: () => Promise.resolve(undefined),
      isFetching: false,
      hasData: !!mockData,
    }),
  }))
}
