import { EarlyStageProjectsModalStep, type EarlyStageProject } from './types'
import Globe05 from '@sylveraio/untitled-ui-icons-react/build/esm/Globe05'
import Globe06 from '@sylveraio/untitled-ui-icons-react/build/esm/Globe06'

export const CAROUSEL_EARLY_STAGE_PROJECTS: EarlyStageProject[] = [
  {
    projectType: 'ARR',
    expectedFirstIssuanceDate: '2028',
    estimatedPrice: {
      min: 25,
      max: 50,
    },
    region: 'Africa',
    expectedProjectEndDate: '2053',
    paymentTerms: 'Offtake agreement',
    estimatedSupplyPerYear: 62000,
    stage: 'Feasibility study',
    featuredIconColour: 'discovery',
    featuredIcon: <Globe05 />,
  },
  {
    projectType: 'BioChar',
    expectedFirstIssuanceDate: 'Nov 2024',
    estimatedPrice: {
      min: 125,
      max: 150,
    },
    region: 'Asia',
    expectedProjectEndDate: '2034',
    paymentTerms: 'Offtake agreement, payment at delivery',
    estimatedSupplyPerYear: 4000,
    stage: 'Draft project docs',
    featuredIconColour: 'neutral',
    featuredIcon: <Globe06 />,
  },
  {
    projectType: 'Rice emissions reductions',
    expectedFirstIssuanceDate: '2024',
    estimatedPrice: {
      min: 0,
      max: 25,
    },
    region: 'India',
    expectedProjectEndDate: '2039',
    paymentTerms: 'Offtake agreement',
    estimatedSupplyPerYear: 200000,
    stage: 'Draft project docs',
    featuredIconColour: 'positive',
    featuredIcon: <Globe05 />,
  },
]

export const UI_STRINGS = {
  [EarlyStageProjectsModalStep.Form]: {
    title: (hasAccess: boolean) =>
      hasAccess ? 'Want an update?' : 'Discover early-stage projects',
    copy: (hasAccess: boolean) =>
      hasAccess
        ? [
            'Our catalog of early-stage projects, not yet listed on any registry, is constantly growing. Access the latest version to discover new projects added that you might want to invest or secure credits in.',
            `What's in the latest catalog? Discover ${EARLY_STAGE_PROJECTS_DISPLAY} projects spanning ARR, mangroves to biochar, and a combined supply of over 10M+ credits.`,
            "Interested? Let us know and we’ll get back to you with the latest version (it's available as an offline spreadsheet today and will be accessible in the Sylvera web app later this year).",
          ]
        : [
            'Leveraging our extensive network of developers, we are creating a unique catalog of early-stage projects not yet listed on any registry. The pre-issuance market is fragmented and opaque. With this catalog, you can discover and explore projects in the earliest phase of development, making sourcing and origination easier.',
            `What can you find in the catalog? Get access to ${EARLY_STAGE_PROJECTS_DISPLAY} projects spanning ARR, mangroves to biochar, and a combined supply of over 10M+ credits.`,
            'Check out the example snippets above. The full dataset is available as an offline spreadsheet today and will be accessible in the Sylvera web app later this year.',
          ],
    buttonLabels: {
      primary: (isSubmitting: boolean, hasAccess: boolean) => {
        if (isSubmitting) {
          return 'Submitting...'
        }
        return hasAccess ? 'Update me' : "I'm interested"
      },
      secondary: 'Close',
    },
  },
  [EarlyStageProjectsModalStep.Submitted]: {
    title: 'Thanks for your interest',
    copy: 'A member of our customer success team will be in touch with you shortly.',
    buttonLabel: 'Okay',
  },
}

export const EARLY_STAGE_PROJECTS_DISPLAY = '50+'
