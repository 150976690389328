import JSONAPISerializer from 'jsonapi-serializer'

export function deserializeResponse<T, A = Record<string, unknown>>(
  apiResponse: A,
  options: JSONAPISerializer.DeserializerOptions = {},
): Promise<T> {
  return new JSONAPISerializer.Deserializer({
    keyForAttribute: 'camelCase',
    ...options,
  }).deserialize(apiResponse)
}
