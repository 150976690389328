import clsx from 'clsx'
import type { FC } from 'react'
import { Label } from '../Label'
import type { OnWatchLabelProps } from './types'

export const OnWatchLabel: FC<OnWatchLabelProps> = ({
  testId = 'on-watch-label',
  show,
  tooltip,
  withTooltip,
  wrapperClassName,
  textClassName,
  size = 'sm',
}) =>
  show ? (
    <Label
      testId={testId}
      color="orange"
      label={
        <div className="flex items-center gap-x-1">
          <span
            className={clsx(
              'block font-bold text-default leading-none',
              {
                'text-[8px]': size === 'sm',
                'text-xs': size === 'md',
                'text-sm': size === 'lg',
              },
              textClassName,
            )}
          >
            On Watch
          </span>
          {withTooltip && tooltip && tooltip}
        </div>
      }
      wrapperClassName={clsx('rounded-full h-fit py-0.5', wrapperClassName)}
    />
  ) : null

export default OnWatchLabel
