import type { BadgeSize } from './types'

export const badgeSize: { [x in BadgeSize]: string } = {
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-6 w-6',
  lg: 'h-7 w-7',
}

export const badgeDotSize: { [x in BadgeSize]: string } = {
  xs: 'h-1 w-1',
  sm: 'h-1.5 w-1.5',
  md: 'h-2 w-2',
  lg: 'h-2.5 w-2.5',
}

export const badgeTextSize: Partial<{ [x in BadgeSize]: string }> = {
  xs: '!text-[8px] !font-bold',
  sm: '!text-[10px] !font-bold',
}
