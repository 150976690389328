import { FC } from 'react'
import { routes } from '@sylveraio/auth-utils'
import {
  ContentShadow,
  FormSubheading,
  FormBackLink,
} from '@sylveraio/react-ui'
import type { FormErrorProps } from './types'

export const SignInFormError: FC<FormErrorProps> = ({
  testId = 'form-error',
  errorMessage,
}) => (
  <ContentShadow
    testId={testId}
    className="relative flex-col items-center justify-center w-[490px] rounded-[25px] h-[650px]"
    bgColor="bg-high/40"
  >
    <FormSubheading testId={`${testId}-message`}>{errorMessage}</FormSubheading>
    <FormBackLink testId={`${testId}-back-link`} href={routes['signIn']} />
  </ContentShadow>
)

export default SignInFormError
