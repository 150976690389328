import type { FC } from 'react'
import type { ProgressRingLockButtonProps } from './types'
import Lock01 from '@sylveraio/untitled-ui-icons-react/build/esm/Lock01'
import clsx from 'clsx'

export const ProgressRingLockButton: FC<ProgressRingLockButtonProps> = ({
  testId = 'progress-ring-lock-button',
  disableClick,
  onClick,
}) => {
  return (
    <div
      data-testid={testId}
      tabIndex={0}
      role="button"
      onClick={onClick}
      className={clsx(
        'w-12 h-12 border-4 border-default rounded-full flex justify-center items-center',
        { 'cursor-default ': disableClick },
      )}
    >
      <Lock01 className="w-9 h-9 p-[5px] stroke-icon-disabled " />
    </div>
  )
}

export default ProgressRingLockButton
