import { useInterval } from '@sylveraio/react-utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { BarChartData } from '../BarChart'
import type { LineGraphData } from '../LineGraph'

export const useShowNoDataOverlay = (
  data: BarChartData[] | LineGraphData[],
  showNoDataOverlay?: boolean,
): boolean => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [runCallbackFunction, setRunCallbackFunction] = useState(true)

  const hasNoChartData = useMemo(
    () =>
      // @ts-ignore
      data.every((dataset) => {
        return dataset && dataset.values.length === 0
      }),
    [data],
  )

  useEffect(() => {
    setRunCallbackFunction(true)
  }, [hasNoChartData])

  const handleLoadingNoDataOverlay = useCallback(() => {
    if (!showNoDataOverlay) return

    setShowOverlay(hasNoChartData)

    setRunCallbackFunction(false)
  }, [hasNoChartData])

  useInterval(handleLoadingNoDataOverlay, runCallbackFunction ? 100 : null)

  return showOverlay
}
