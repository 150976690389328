import forInRight from 'lodash/forInRight'
import isArray from 'lodash/isArray'

/**
 * Stringifies arrays and converts special characters to their URI equivalents
 * @param object
 * @param key
 * @returns
 */
export function escapeFromRight(
  object: Record<string, unknown>,
  key: Array<string> = [],
): Record<string, unknown> {
  const newObject = { ...object }

  forInRight(object, (v, k) => {
    if (typeof v === 'string') {
      newObject[k] = encodeURIComponent(v)
    }
    if (typeof v === 'object') {
      if (isArray(v)) {
        newObject[k] = encodeURIComponent(v.join(','))
      } else {
        // @ts-ignore
        newObject[k] = escapeFromRight(v, [...key, k])
      }
    }
    return v
  })

  return newObject
}
