import { type FC, useState, useMemo, useEffect, useRef } from 'react'
import clsx from 'clsx'
import pluralize from 'pluralize'
import sum from 'lodash/sum'
import isEmpty from 'lodash/isEmpty'
import { useClickOutsideElement } from '@sylveraio/react-utils'
import { DropdownList } from '../DropdownTooltip/DropdownList'
import { RatingDistributionHistogram } from '../RatingDistributionHistogram'
import { TITLES, ORDERED_PILLARS } from './constants'
import type { RatingPillar } from 'types'
import type {
  RatingsDistributionsTileProps,
  RatingsDistributions,
} from './types'
import { Spinner } from '@sylveraio/design-system'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'

export const RatingsDistributionsTile: FC<RatingsDistributionsTileProps> = ({
  projectType,
  ratingsDistributions,
  projectRatings,
  projectRatingsPermissions,
  onUpdatePillar,
  loading,
  testId = 'ratings-distribution-tile',
}) => {
  const [currentPillar, setCurrentPillar] =
    useState<RatingPillar>('sylveraRating')
  const [pillarDropdownVisible, setPillarDropdownVisible] =
    useState<boolean>(false)

  const updateCurrentPillar = (pillar: RatingPillar) => {
    setCurrentPillar(pillar)
    setPillarDropdownVisible(false)
    onUpdatePillar?.(pillar)
  }

  const pillarEntries = useMemo(() => {
    return ORDERED_PILLARS.filter(
      (pillar) =>
        typeof ratingsDistributions?.[pillar as keyof RatingsDistributions] !==
        'undefined',
    ).map((pillar) => ({
      value: TITLES[pillar],
      id: pillar,
    }))
  }, [ratingsDistributions])

  useEffect(() => {
    const firstPillar = pillarEntries?.[0]?.id
    if (firstPillar) updateCurrentPillar(firstPillar)
  }, [])

  const [projectsCountTotal, projectsCountFullCarbon] = useMemo(() => {
    return [
      sum(
        ratingsDistributions?.sylveraRating?.histogram.map(
          ({ count }) => count,
        ),
      ),
      sum(
        ratingsDistributions?.carbonScore?.histogram.map(({ count }) => count),
      ),
    ]
  }, [ratingsDistributions])

  const pillarDropdownRef = useRef<HTMLButtonElement>(null)
  useClickOutsideElement(
    pillarDropdownRef,
    () => setPillarDropdownVisible(false),
    pillarDropdownVisible,
  )

  const getDisclaimer = () => {
    if (!projectType) return null

    return `Based on ${pluralize(
      `${projectType} project`,
      currentPillar === 'carbonScore'
        ? projectsCountFullCarbon
        : projectsCountTotal,
      true,
    )}${currentPillar === 'carbonScore' ? ' with a complete carbon score' : ''}`
  }

  return (
    <div
      className="flex h-[194px] min-h-[194px] w-full min-w-[290px] flex-col justify-between space-y-4 rounded-lg bg-selection-white p-4"
      data-testid={testId}
    >
      <button
        className={clsx('flex space-x-2 items-center', {
          'cursor-default':
            isEmpty(ratingsDistributions) ||
            !(Object.keys(ratingsDistributions).length > 1),
        })}
        ref={pillarDropdownRef}
        onClick={() => {
          if (
            !isEmpty(ratingsDistributions) &&
            Object.keys(ratingsDistributions).length > 1
          ) {
            setPillarDropdownVisible(!pillarDropdownVisible)
          }
        }}
        data-testid={`${testId}-dropdown-button`}
      >
        <span
          className={clsx('text-sm font-semibold', {
            'text-default': !pillarDropdownVisible,
            'text-subtle': pillarDropdownVisible,
          })}
        >
          {`${TITLES[currentPillar]} Distribution`}
        </span>
        {!isEmpty(ratingsDistributions) &&
          Object.keys(ratingsDistributions).length > 1 && (
            <ChevronDown
              className={clsx('w-3 h-3', {
                'stroke-icon-default': !pillarDropdownVisible,
                'stroke-icon-subtle': pillarDropdownVisible,
              })}
            />
          )}
      </button>
      <DropdownList
        referenceElement={pillarDropdownRef.current}
        isVisible={pillarDropdownVisible}
        // @ts-ignore
        onClick={updateCurrentPillar}
        entries={pillarEntries}
        placement="start"
        testId={`${testId}-dropdown-list`}
        offset={[0, -10]}
      />
      <div className="flex h-full w-full flex-col justify-end space-y-2">
        {loading ? (
          <div className="flex h-8 w-full items-center justify-center">
            <Spinner className="!h-8 !w-8" />
          </div>
        ) : (
          <div className="h-full w-full">
            {!(
              isEmpty(ratingsDistributions) ||
              typeof ratingsDistributions[currentPillar] === 'undefined' ||
              typeof ratingsDistributions[currentPillar]?.type ===
                'undefined' ||
              isEmpty(ratingsDistributions[currentPillar]?.histogram)
            ) && (
              <RatingDistributionHistogram
                ratingDistribution={ratingsDistributions[currentPillar]}
                projectRating={projectRatings?.[currentPillar]}
                projectRatingPermission={
                  projectRatingsPermissions?.[currentPillar]
                }
                totalCount={
                  currentPillar === 'carbonScore'
                    ? projectsCountFullCarbon
                    : projectsCountTotal
                }
                testId={`${testId}-${currentPillar}-histogram`}
              />
            )}
          </div>
        )}
        <div className="text-xs text-subtle">{getDisclaimer()}</div>
      </div>
    </div>
  )
}
