'use client'

import { useEffect } from 'react'
import posthog from 'posthog-js'
import { useRouter } from 'next/router'

/**
 * manually initialises Posthog
 */
export function useAnalytics(analyticsKey?: string): void {
  const router = useRouter()

  useEffect(() => {
    if (!analyticsKey) return

    // Init PostHog
    posthog.init(analyticsKey, {
      api_host: 'https://app.posthog.com',
    })

    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}
