import clsx from 'clsx'
import type { FC } from 'react'
import type { BadgeProps } from './types'
import { badgeSize, badgeDotSize, badgeTextSize } from './constants'
import { Typography } from '../Typography'

export const Badge: FC<BadgeProps> = ({
  testId = 'badge',
  size = 'lg',
  label,
  children,
  className,
  textClassName,
  showDot = false,
}) => (
  <div
    data-testid={testId}
    className={clsx(
      'flex items-center justify-center bg-new-palette-green-500 rounded-full shrink-0',
      badgeSize[size],
      className,
    )}
  >
    {showDot ? (
      <div
        data-testid={`${testId}-dot`}
        className={clsx('bg-high rounded-full shrink-0', badgeDotSize[size])}
      />
    ) : (
      <>
        <Typography
          size={size === 'lg' ? size : 'base'}
          testId={`${testId}-label`}
          className={clsx(
            'text-default-on-dark',
            badgeTextSize?.[size],
            textClassName,
          )}
        >
          {label}
        </Typography>
        {children}
      </>
    )}
  </div>
)
