import { FC } from 'react'
import clsx from 'clsx'
import { InfoHoverTooltip } from '../InfoHoverTooltip'
import { MetricGroup } from '../Metric'
import { MetricGroupTileProps } from './types'

export const MetricGroupTile: FC<MetricGroupTileProps> = ({
  title,
  tooltipText,
  metrics,
  colorScheme = 'purple',
  testId = 'metric-group-tile',
}) => (
  <div
    className={clsx(
      'flex flex-col justify-between p-3 w-full min-w-fit rounded-lg min-h-[90px]',
      {
        'bg-medium border border-subtle': colorScheme !== 'forestAndWhite',
        'bg-high': colorScheme === 'forestAndWhite',
      },
    )}
    data-testid={testId}
  >
    <div className="flex space-x-1">
      <span className="text-sm font-semibold text-default">{title}</span>
      {tooltipText && (
        <InfoHoverTooltip
          iconClassName={clsx({
            '!text-content-action-default': colorScheme === 'purple',
            '!text-secondary-water': colorScheme === 'blue',
            '!text-secondary-mauve': colorScheme === 'mauve',
            '!text-secondary-gold': colorScheme === 'lemon',
            '!text-default': colorScheme === 'forestAndWhite',
          })}
          wrapperClassName="!max-w-[336px]"
        >
          {tooltipText}
        </InfoHoverTooltip>
      )}
    </div>
    <MetricGroup
      metrics={metrics}
      valueSize="small"
      titleSize="extraSmall"
      expanded
      metricClassName="!space-y-0"
      colorScheme={colorScheme}
    />
  </div>
)
