import { Value, ValueBase } from '../types'

// Extracts text and value so they're consistently organised
export function extractValueBase(fullValue: Value): ValueBase {
  const { text, value } = fullValue
  return {
    text,
    value,
  }
}
