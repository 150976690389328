import { usePathname } from 'next/navigation'
import { useMemo } from 'react'
import type { NavProps, NavRoute } from '../../Nav'

function getCurrentPageId(
  path: string,
  routes: NavProps['navigationRoutes'] = [],
): string {
  const interpolativeURLS = routes.filter(({ href, includesChildHrefs }) => {
    return (
      href.includes('*') ||
      (includesChildHrefs || []).some((href) => href.includes('*'))
    )
  })

  if (interpolativeURLS) {
    const pattern = '.+/?'
    const { id } =
      interpolativeURLS.find(({ href }) => {
        const rgx = new RegExp(href.replace('*', pattern))
        const matches = path.match(rgx)
        return matches !== null
      }) || {}

    if (id) return id
  }

  const { id } = routes.find(({ href }) => href === path) || {}

  return id as string
}

function getRouteById(
  routes: NavProps['navigationRoutes'] = [],
  id?: string,
): NavRoute {
  return routes.find(
    (r) => typeof id !== 'undefined' && r.id === id,
  ) as NavRoute
}

/**
 * A hook which returns information about the navigation routes and current page
 * @returns [ isProtected, currentPageId ]
 */
export function useNavigationRoutes(
  navigationRoutes: NavProps['navigationRoutes'],
): [boolean, string | undefined] {
  const pathname = usePathname()
  const currentPageId = useMemo(
    () => getCurrentPageId(pathname || '', navigationRoutes),
    [pathname, navigationRoutes],
  )
  const isProtected = useMemo(
    () => getRouteById(navigationRoutes, currentPageId)?.isProtected || false,
    [navigationRoutes, currentPageId],
  )

  return [isProtected, currentPageId]
}
