import { FC } from 'react'
import clsx from 'clsx'
import type BulletListProps from './types'

const BulletList: FC<BulletListProps> = ({ size = 16, className }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M13.6822 0.423065H3.99901C3.60285 0.423065 3.28174 0.734148 3.28174 1.11792C3.28174 1.5017 3.60285 1.81278 3.99901 1.81278H13.6822C14.0783 1.81278 14.3995 1.5017 14.3995 1.11792C14.3995 0.734148 14.0783 0.423065 13.6822 0.423065Z"
      fill="white"
    />
    <path
      d="M13.6822 6.67651H3.99901C3.60285 6.67651 3.28174 6.9876 3.28174 7.37137C3.28174 7.75515 3.60285 8.06623 3.99901 8.06623H13.6822C14.0783 8.06623 14.3995 7.75515 14.3995 7.37137C14.3995 6.9876 14.0783 6.67651 13.6822 6.67651Z"
      fill="white"
    />
    <path
      d="M13.6822 12.9308H3.99901C3.60285 12.9308 3.28174 13.2419 3.28174 13.6257C3.28174 14.0095 3.60285 14.3206 3.99901 14.3206H13.6822C14.0783 14.3206 14.3995 14.0095 14.3995 13.6257C14.3995 13.2419 14.0783 12.9308 13.6822 12.9308Z"
      fill="white"
    />
    <path
      d="M0.707668 1.60994C0.836217 1.73934 1.01688 1.81278 1.19754 1.81278C1.38168 1.81278 1.55887 1.73934 1.68742 1.60994C1.81944 1.47705 1.8924 1.29869 1.8924 1.11333C1.8924 0.92798 1.81944 0.749621 1.68742 0.616727C1.43032 0.357931 0.964765 0.357932 0.704194 0.620224C0.575645 0.749621 0.502686 0.931477 0.502686 1.11333C0.502686 1.29519 0.575646 1.47705 0.707668 1.60994Z"
      fill="white"
    />
    <path
      d="M0.707668 7.86339C0.836217 7.99279 1.01688 8.06623 1.19754 8.06623C1.38168 8.06623 1.55887 7.99279 1.68742 7.86339C1.81944 7.73049 1.8924 7.55213 1.8924 7.36678C1.8924 7.18143 1.81944 7.00307 1.68742 6.87018C1.43032 6.61138 0.964765 6.61138 0.704194 6.87367C0.575645 7.00307 0.502686 7.18493 0.502686 7.36678C0.502686 7.54864 0.575646 7.73049 0.707668 7.86339Z"
      fill="white"
    />
    <path
      d="M0.707668 14.1177C0.836217 14.2471 1.01688 14.3206 1.19754 14.3206C1.38168 14.3206 1.55887 14.2471 1.68742 14.1177C1.81944 13.9848 1.8924 13.8065 1.8924 13.6211C1.8924 13.4358 1.81944 13.2574 1.68742 13.1245C1.43032 12.8657 0.964765 12.8657 0.704194 13.128C0.575645 13.2574 0.502686 13.4393 0.502686 13.6211C0.502686 13.803 0.575646 13.9848 0.707668 14.1177Z"
      fill="white"
    />
  </svg>
)

export default BulletList
