'use client'

import { useEventListener } from '@sylveraio/react-utils/server'
import { UNAUTHORISED_EVENT } from './constant'
import { addBreadcrumb } from '@sentry/nextjs'
import { signOut } from '../signOut'

export function postUserUnauthorised() {
  addBreadcrumb({
    message: 'User is unauthorised',
  })
  window.postMessage(
    {
      event: UNAUTHORISED_EVENT,
    },
    '*',
  )
}

export function useUserUnauthorised() {
  useEventListener(UNAUTHORISED_EVENT, async () => {
    addBreadcrumb({
      message: 'User is unauthorised, logging out and pushing to sign-in',
    })
    await signOut()
    if (window?.reload) window.reload()
  })
}

// withAuth pass whether access or not to component
