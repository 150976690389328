import { FC } from 'react'
import clsx from 'clsx'
import { Metric } from './Metric'
import { MetricGroupProps } from './types'

export const MetricGroup: FC<MetricGroupProps> = ({
  metrics,
  colorScheme = 'purple',
  testId = 'metric-group',
  valueSize = 'medium',
  titleSize = 'small',
  className,
  expanded,
  metricClassName,
}) => (
  <div
    data-testid={testId}
    className={clsx('min-w-max', className, {
      'flex justify-start gap-x-10': !expanded,
      'grid gap-x-4': expanded,
    })}
    style={{
      ...(expanded && {
        gridTemplateColumns: `repeat(${metrics?.length}, minmax(0, 1fr))`,
      }),
    }}
  >
    {metrics?.map((metric, i) => (
      <Metric
        key={`metric-group-${i}-value-${metric.value}`}
        {...metric}
        colorScheme={colorScheme}
        valueSize={valueSize}
        titleSize={titleSize}
        className={clsx(metricClassName, {
          'border-r border-text-default/20':
            expanded &&
            colorScheme === 'forestAndWhite' &&
            i < metrics.length - 1,
        })}
      />
    ))}
  </div>
)
