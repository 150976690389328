import React, { ForwardedRef, forwardRef } from 'react'
import { ConditionalDivButtonProps } from './types'

// Could extend this to onClick = button, href = anchor, neither = div - MCW

export const ConditionalDivButton = forwardRef<
  HTMLDivElement | HTMLButtonElement,
  ConditionalDivButtonProps
>(
  (
    {
      children,
      onClick,
      className,
      testId,
      disabled,
      value,
      name,
      type = 'button',
      ...rest
    },
    ref,
  ) => {
    if (onClick) {
      return (
        <button
          type={type}
          onClick={onClick}
          className={className}
          data-testid={testId}
          ref={ref as ForwardedRef<HTMLButtonElement>}
          name={name}
          value={value}
          disabled={disabled}
          {...rest}
        >
          {children}
        </button>
      )
    }

    return (
      <div
        className={className}
        data-testid={testId}
        ref={ref as ForwardedRef<HTMLDivElement>}
        {...rest}
      >
        {children}
      </div>
    )
  },
)

export default ConditionalDivButton
