import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Home: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-selection-forest', className)}
  >
    <path
      d="M16.5832 6.43874L16.5836 6.43903C16.6165 6.46569 16.6455 6.49578 16.6704 6.52863L16.5737 6.45006L9.7535 0.909374C9.75348 0.909355 9.75345 0.909335 9.75343 0.909316C9.10679 0.383847 8.16867 0.381912 7.52091 0.909593C7.52083 0.909661 7.52074 0.909729 7.52066 0.909797L0.701008 6.45006L0.600846 6.53143C0.626403 6.49712 0.656536 6.46564 0.690916 6.43773L0.691101 6.43758L7.51004 0.896955C7.51009 0.896916 7.51013 0.896878 7.51018 0.896839C8.162 0.367722 9.11116 0.36772 9.76298 0.896833C9.76303 0.896874 9.76308 0.896914 9.76313 0.896955L16.5832 6.43874ZM16.7584 6.83814L16.7584 6.7124C16.7686 6.75315 16.774 6.79561 16.7742 6.8391C16.7742 6.83929 16.7742 6.83947 16.7742 6.83965L16.7742 17.2654C16.7742 17.3094 16.7686 17.3522 16.7582 17.393L16.7582 17.2708L16.7584 6.83814ZM16.2582 17.7708H16.3815C16.3418 17.7808 16.3004 17.7862 16.2579 17.7862H11.6854C11.643 17.7862 11.6016 17.7808 11.5619 17.7708H11.6847H16.2572H16.2582ZM11.1847 17.2708V17.3913C11.1746 17.351 11.1692 17.3088 11.1692 17.2654V10.6658V10.5364C11.1793 10.5764 11.1847 10.6182 11.1847 10.6611V17.2708ZM10.6685 10.1504C10.7123 10.1504 10.7548 10.1557 10.7954 10.1658H10.6692H6.60499H6.47735C6.51789 10.1557 6.56039 10.1504 6.60426 10.1504H10.6685ZM6.08801 10.6611C6.08801 10.6162 6.09391 10.5725 6.10499 10.5308V10.6658V17.2654C6.10499 17.3109 6.09908 17.355 6.08801 17.3971V17.2708V10.6611ZM5.58801 17.7708H5.71232C5.67261 17.7808 5.6312 17.7862 5.58875 17.7862H1.01624C0.973806 17.7862 0.932413 17.7808 0.892715 17.7708H1.01628H5.58801ZM0.516281 17.2708V17.3944C0.505663 17.3531 0.5 17.3099 0.5 17.2654V6.83818C0.5 6.79367 0.50554 6.75013 0.516281 6.70833V6.83814V17.2708Z"
      fill="#01311D"
      stroke="#01311D"
    />
  </svg>
)

export default Home
