'use client'

import { useRouter, useSearchParams } from 'next/navigation'
import {
  useState,
  ReactElement,
  ComponentType,
  FC,
  useEffect,
  useMemo,
} from 'react'
import { captureException } from '@sylveraio/react-utils'
import isEmpty from 'lodash/isEmpty'
import { signInRoute } from '../constants'
import { useAuth } from '../hooks/useAuth'
import { useUserUnauthorised } from '../userUnauthorised'

export function withAuth<P>(
  Component: ComponentType<P>,
  handleSignInRoute?: () => string | undefined,
): FC<P> {
  return (props: P): ReactElement => {
    // If a 401 is emitted, logout and push back to sign-in
    useUserUnauthorised()
    const { checkUserSession } = useAuth()
    const [authValid, setAuthValid] = useState(false)
    const router = useRouter()
    const searchParams = useSearchParams()

    const route = handleSignInRoute ? handleSignInRoute() : signInRoute

    const feat = searchParams?.get('feat')
    const page = searchParams?.get('page')

    let params: Record<string, string> = {}

    if (typeof feat === 'string') {
      params = {
        ...params,
        feat,
      }

      // Only allow page if feat is set so we know which feature/app the page belongs on
      if (typeof page === 'string') {
        params = {
          ...params,
          page,
        }
      }
    }

    const routeWithParams = useMemo(
      () =>
        typeof route !== 'undefined'
          ? !isEmpty(params)
            ? `${route}?${Object.entries(params)
                .join('&')
                .replaceAll(',', '=')}`
            : route
          : undefined,
      [route],
    )

    useEffect(() => {
      checkUserSession()
        .then((isValid) => {
          if (isValid) setAuthValid(true)
          else if (routeWithParams) {
            router.push(routeWithParams)
          }
        })
        .catch((e) => {
          captureException(e, 'withAuth')
          if (routeWithParams) router.push(routeWithParams)
        })
    }, [routeWithParams])

    if (!authValid) return <div />
    // @ts-ignore
    return <Component {...props} />
  }
}

export default withAuth
