import { FC } from 'react'
import { DefaultDropdown } from './DefaultDropdown'
import { RoundedDropdown } from './RoundedDropdown'
import { DropdownProps, DropdownVariants } from './types'

export const variantComponents: { [x in DropdownVariants]: FC<DropdownProps> } =
  {
    default: DefaultDropdown,
    rounded: RoundedDropdown,
  }
