'use client'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC } from 'react'
import { Inter } from 'next/font/google'
import clsx from 'clsx'

const inter = Inter({ subsets: ['latin'] })

export const FontLayer: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { enableFontInter } = useFlags()

  return (
    <div
      className={clsx({
        [`${inter.className}`]: enableFontInter,
        'font-maison': !enableFontInter,
      })}
    >
      {children}
    </div>
  )
}
