import type { FC } from 'react'
import type { RequestMoreInfoButtonProps } from './types'
import { REQUEST_MORE_INFO } from './constants'
import { Button } from '../Button'

export const RequestMoreInfoButton: FC<RequestMoreInfoButtonProps> = ({
  testId = 'request-more-info-button',
  onClick,
}) => (
  <Button
    testId={testId}
    label={REQUEST_MORE_INFO}
    variant="secondary"
    onClick={onClick}
    size="sm"
  />
)
