import clsx from 'clsx'
import type { FeaturedIconColors } from '../../types'

export function getFeaturedIconColors(
  color: FeaturedIconColors,
  strong?: boolean,
) {
  return clsx({
    'bg-content-action-light text-content-action-dark':
      color === 'action' && !strong,

    'bg-content-action-default border border-content-action-dark text-content-action-on-default':
      color === 'action' && strong,

    'bg-content-neutral-light text-content-neutral-dark':
      color === 'neutral' && !strong,

    'bg-content-neutral-default border border-content-neutral-dark text-content-neutral-on-default':
      color === 'neutral' && strong,

    'bg-content-alert-light text-content-alert-dark':
      color === 'alert' && !strong,

    'bg-content-alert-default border border-content-alert-dark text-content-alert-on-default':
      color === 'alert' && strong,

    'bg-content-warning-light text-content-warning-dark':
      color === 'warning' && !strong,

    'bg-content-warning-default border border-content-warning-default-hover text-content-warning-on-default':
      color === 'warning' && strong,

    'bg-content-positive-light text-content-positive-dark':
      color === 'positive' && !strong,

    'bg-content-positive-default border border-content-positive-dark text-content-positive-on-default':
      color === 'positive' && strong,

    'bg-content-discovery-light text-content-discovery-dark':
      color === 'discovery' && !strong,

    'bg-content-discovery-default border border-content-discovery-default-hover text-content-discovery-on-default':
      color === 'discovery' && strong,
  })
}
