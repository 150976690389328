import type { FormKeyData } from '../types'
import { getValue } from './getValue'

export function prepareFormSubmission(formData: Map<string, FormKeyData>) {
  // Extract the key + value pairs from the data
  const formArray = [...formData.values()]
    .filter((v) => typeof v.value !== 'undefined')
    .map((entry) => {
      if (entry.type === 'tuple' && entry.suffixes.length > 0) {
        return (entry.value as Array<unknown>).map((v, i) => [
          entry.name + entry.suffixes[i],
          v,
        ])
      }
      const child =
        entry.childKeys.length > 0
          ? entry.childKeys.map(({ key, values }) => [
              key,
              getValue(values)?.value,
            ])
          : []
      return [[entry.name, entry.value], ...child]
    })
    .flat()

  // Apply any transformations to the data
  const transformedFormArray = formArray.map(([key, value]) => {
    const { transformValue } = formData.get(key as string) || {}
    // @ts-ignore
    if (transformValue) return [key, transformValue(value)]
    return [key, value]
  })

  // Transform into an object and return
  return Object.fromEntries(transformedFormArray)
}
