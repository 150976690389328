import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const NewTab: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'new-tab',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-default', className)}
  >
    <path d="M3.08333 2.25C2.62789 2.25 2.25 2.62789 2.25 3.08333V8.91667C2.25 9.37211 2.62789 9.75 3.08333 9.75H8.91667C9.37211 9.75 9.75 9.37211 9.75 8.91667V6H8.91667V8.91667H3.08333V3.08333H6V2.25H3.08333ZM6.83333 2.25V3.08333H8.32747L4.4554 6.9554L5.0446 7.5446L8.91667 3.67253V5.16667H9.75V2.25H6.83333Z" />
  </svg>
)
