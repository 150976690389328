import { FC } from 'react'
import IconsProps from '../types'

export const Ratings: FC<IconsProps> = ({ size = 16 }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <circle cx="10" cy="10" r="9.5" stroke="#01311D" />
    <path
      d="M5.69917 11.1279H8.34717L8.87517 12.3279H9.81117L7.49117 7.01588H6.58717L4.26717 12.3279H5.17117L5.69917 11.1279ZM7.99517 10.3199H6.05117L7.02717 8.10388L7.99517 10.3199ZM11.246 11.1279H13.894L14.422 12.3279H15.358L13.038 7.01588H12.134L9.81405 12.3279H10.718L11.246 11.1279ZM13.542 10.3199H11.598L12.574 8.10388L13.542 10.3199Z"
      fill="#01311D"
    />
  </svg>
)

export default Ratings
