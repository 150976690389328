import { gql } from '@apollo/client'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type Faq = {
  staticJson: {
    content: Array<{ id: string; title: string; body: string }>
  }
}
export const GET_FAQ: TypedDocumentNode<
  Faq,
  {
    faqId: string
  }
> = gql`
  query getFAQ($faqId: String!) {
    staticJson(id: $faqId) {
      content
    }
  }
`
