import type { RAGIndicatorCommonProps } from './types'

export const INTENT_TO_ARIA_DESCRIPTION_MAP: Record<
  RAGIndicatorCommonProps['intent'],
  string
> = {
  green: 'Green status indicator',
  amber: 'Amber status indicator',
  red: 'Red status indicator',
  neutral: 'Neutral status indicator',
}
