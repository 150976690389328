import React, { FC, memo, useState } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import ButtonText from './ButtonText'
import type { AnimatedArrowButtonProps } from './types'
import ArrowLeft from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowLeft'

const renderContent = (
  text: string | undefined,
  small: boolean,
  textClassName: string | undefined,
  animationActive: boolean,
) => (
  <>
    <div
      className={clsx(
        'flex justify-center items-center overflow-hidden bg-content-discovery-default rounded-full w-9 min-w-9 h-9 min-h-9 !text-default',
        {
          '!w-8 !min-w-8 !h-8 !min-h-8': small,
        },
      )}
    >
      <div
        className={clsx('relative', {
          'animate-exitAndEnter': animationActive,
        })}
      >
        <ArrowLeft className="w-4 h-4" />
      </div>
    </div>
    {text && (
      <ButtonText
        content={text}
        level={small ? 2 : 1}
        className={clsx('text-right', textClassName)}
      />
    )}
  </>
)

export const AnimatedArrowButton: FC<AnimatedArrowButtonProps> = memo(
  ({
    text,
    darkBackground = false,
    small = false,
    textClassName,
    href,
    toClientSidePage = true,
    onClick,
  }) => {
    const [animationActive, setAnimationActive] = useState(false)
    const buttonClass = clsx(
      'flex flex-row items-center space-x-3 text-default',
      {
        '!text-default-on-dark': darkBackground,
      },
    )
    const handleOnClick = (): void => {
      onClick?.()
    }

    if (href) {
      return (
        <button
          type="button"
          onMouseEnter={() => setAnimationActive(true)}
          onMouseLeave={() => setAnimationActive(false)}
        >
          {toClientSidePage ? (
            <Link href={href} legacyBehavior>
              <a className={buttonClass} data-testid="internal-page-link">
                {renderContent(text, small, textClassName, animationActive)}
              </a>
            </Link>
          ) : (
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className={buttonClass}
              data-testid="external-page-link"
            >
              {renderContent(text, small, textClassName, animationActive)}
            </a>
          )}
        </button>
      )
    } else {
      return (
        <button
          type="button"
          onMouseEnter={() => setAnimationActive(true)}
          onMouseLeave={() => setAnimationActive(false)}
          onClick={handleOnClick}
          className={buttonClass}
          data-testid="function-button"
        >
          {renderContent(text, small, textClassName, animationActive)}
        </button>
      )
    }
  },
)

export default AnimatedArrowButton
