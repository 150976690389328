import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import clsx from 'clsx'
import throttle from 'lodash/throttle'
import orderBy from 'lodash/orderBy'
import deburr from 'lodash/deburr'
import { FilterableListProps } from './types'
import { Checkbox } from '../Checkbox'
import { SearchInput } from '../SearchInput'
import { Button } from '../Button'
import { useUniqueObject } from '@sylveraio/react-utils'

const SortButton: FC<{
  selected?: boolean
  children?: string
  onClick(ev: MouseEvent<HTMLButtonElement>): void
  testId?: string
}> = ({ selected, children, onClick, testId = 'sort-btn' }) => (
  <button
    type="button"
    data-testid={testId}
    onClick={onClick}
    className={clsx('shrink-0 p-1 text-sm font-normal', {
      'text-content-action-default underline underline-offset-4 decoration-primary-neon-blue':
        !selected,
      'text-subtle-on-dark cursor-default': selected,
    })}
  >
    {children}
  </button>
)

export const FilterableList: FC<FilterableListProps> = ({
  onChange,
  name,
  entries = [],
  selectedEntries = [],
  disabledEntries = [],
  testId = 'filterable-list',
  placeholderText = 'Search all',
  isVisible = false,
  type = 'radio',
  listWrapperClassName,
  hideSearchSortSection,
  hideSortSection,
  hideSearchSection,
  onReset,
  onTextSearch,
  onSort,
  onSortAnalyticsHandler,
}) => {
  const [search, setSearch] = useState<string>('')
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc')
  const [filteredEntrySet, setFilteredEntrySet] = useState<
    FilterableListProps['entries']
  >([])
  const uniqueEntries = useUniqueObject(entries)
  const uniqueSelectedEntries = useUniqueObject(selectedEntries)
  const uniqueDisabledEntries = useUniqueObject(disabledEntries)

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearch(target.value)
  }

  const handleSearchReset = () => setSearch('')

  // Callback prevents multiple instances being created, thereby ensuring throttle works as expected
  const handleThrottledTextSearch = useCallback(
    throttle((value) => {
      if (onTextSearch) onTextSearch(value)
    }, 500),
    [],
  )

  useEffect(() => {
    handleThrottledTextSearch(search)
  }, [search])

  useEffect(() => {
    const fSet =
      typeof onTextSearch !== 'undefined'
        ? uniqueEntries
        : uniqueEntries.filter(({ value }) =>
            (value || '').toLowerCase().includes((search || '').toLowerCase()),
          )

    if (sortDir && typeof onSort === 'undefined') {
      setFilteredEntrySet(
        orderBy(
          fSet,
          [(entry) => deburr(entry.value.toLowerCase())],
          [sortDir],
        ),
      )
      return
    }
    setFilteredEntrySet(fSet)
  }, [
    sortDir,
    uniqueEntries,
    uniqueSelectedEntries,
    onTextSearch,
    onSort,
    search,
  ])

  return (
    <div
      className={clsx(
        'h-96 w-96 overflow-none flex flex-col',
        listWrapperClassName,
        {
          invisible: !isVisible,
        },
      )}
      data-testid={testId}
    >
      {(!hideSearchSortSection || onReset) && (
        <div className={'relative z-20 flex items-center justify-end gap-3'}>
          {!hideSearchSortSection && (
            <>
              {!hideSearchSection && (
                <SearchInput
                  testId={`${testId}-search`}
                  handleOnChange={handleSearch}
                  handleReset={handleSearchReset}
                  value={search}
                  className="w-full rounded-none border-b-2 border-subtle font-normal !min-w-48"
                  placeholderText={placeholderText}
                />
              )}
              {!hideSortSection && (
                <>
                  <SortButton
                    testId={`${testId}-sort-asc`}
                    selected={sortDir === 'asc'}
                    onClick={(ev) => {
                      ev.preventDefault()
                      if (onSort) onSort('asc')
                      setSortDir('asc')
                      onSortAnalyticsHandler?.('asc')
                    }}
                  >
                    A-Z
                  </SortButton>
                  <SortButton
                    testId={`${testId}-sort-desc`}
                    selected={sortDir === 'desc'}
                    onClick={(ev) => {
                      ev.preventDefault()
                      if (onSort) onSort('desc')
                      setSortDir('desc')
                      onSortAnalyticsHandler?.('desc')
                    }}
                  >
                    Z-A
                  </SortButton>
                </>
              )}
            </>
          )}
          {onReset && (
            <Button
              size="md"
              bordered={false}
              className={clsx('!w-max !bg-default !text-default', {
                'mb-1': hideSearchSortSection,
              })}
              onClick={() => {
                onReset()
                handleSearchReset()
              }}
              solid
              iconName="reset"
              testId={`${testId}-btn-reset`}
            >
              Reset
            </Button>
          )}
        </div>
      )}
      <ul
        data-testid={`${testId}-list`}
        className="h-full max-h-full shrink divide-y divide-solid divide-primary-300 overflow-y-scroll pb-5 pt-2 focus:outline-none active:outline-none"
      >
        {(filteredEntrySet ?? []).map(({ id, value, secondaryValue }, i) => (
          <li key={`list-item-${id}`} className="mr-10 bg-high">
            <Checkbox
              key={id}
              id={id}
              testId={`${testId}-${type}-${i}`}
              label={value}
              secondaryLabel={secondaryValue}
              name={name}
              value={id}
              checked={uniqueSelectedEntries.includes(id)}
              onChange={onChange}
              type={type}
              showBox={type === 'checkbox'}
              disabled={uniqueDisabledEntries.includes(id)}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
