import { addBreadcrumb } from '@sentry/nextjs'
import { captureException } from '@sylveraio/react-utils/server'
import { clearCookies, fetchCookies, setCookies } from '../cookies'
import { AMZN_HEADERS } from './constants'

/**
 * Fetches the bearer token using the set cookies
 * @returns
 */
export async function getBearerToken(
  retry?: boolean,
): Promise<string | undefined> {
  const { access, refresh } = fetchCookies()

  if (!refresh) {
    addBreadcrumb({
      message: 'No valid refresh cookie',
      data: {
        access,
      },
    })

    return undefined
  }

  if (retry) {
    addBreadcrumb({
      message: 'Retrying API call due to bad access token',
    })
    clearCookies('access')
  }

  if (!access) {
    addBreadcrumb({
      message: 'No valid access token found',
    })

    try {
      const response = await fetch(refresh.iss, {
        method: 'POST',
        body: JSON.stringify({
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: refresh?.token,
          },
          ClientId: process.env['NEXT_PUBLIC_AMP_WEB_CLIENT_ID'],
          ClientMetadata: {},
        }),
        headers: AMZN_HEADERS,
      })

      if (response.ok) {
        addBreadcrumb({
          message: 'Refresh token request ok',
        })

        const jsonResponse = await response.json()
        const { AccessToken, ExpiresIn } =
          jsonResponse?.AuthenticationResult || {}

        setCookies({
          token: AccessToken,
          exp: ExpiresIn,
        })

        return AccessToken
      } else {
        addBreadcrumb({
          message: 'Refresh token request failed',
        })
      }

      return
    } catch (e) {
      captureException(e, 'getBearerToken')
    }
  }

  return access?.token
}
