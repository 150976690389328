import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Close: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'close',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-content-action-default', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28025 3.86361C4.63172 3.51214 5.20157 3.51214 5.55304 3.86361L12.2083 10.5189L18.8636 3.86361C19.2151 3.51214 19.7849 3.51214 20.1364 3.86361C20.4878 4.21508 20.4878 4.78493 20.1364 5.1364L13.4811 11.7917L20.1364 18.4469C20.4878 18.7984 20.4878 19.3683 20.1364 19.7197C19.7849 20.0712 19.2151 20.0712 18.8636 19.7197L12.2083 13.0645L5.13637 20.1364C4.7849 20.4879 4.21505 20.4879 3.86358 20.1364C3.51211 19.7849 3.51211 19.2151 3.86358 18.8636L10.9355 11.7917L4.28025 5.1364C3.92877 4.78493 3.92877 4.21508 4.28025 3.86361Z"
    />
  </svg>
)
