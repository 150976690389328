'use client'

import type { FC, PropsWithChildren } from 'react'
import { useMemo } from 'react'
import { Footer, SiteContainer, useNavigationRoutes } from '@sylveraio/layouts'
import { getAppStates } from '@sylveraio/auth-utils'
import { useUser } from '@sylveraio/data'
import { usePathname } from 'next/navigation'
import type { AppName } from 'types'
import { useCheckJreddAccess } from '@sylveraio/react-utils'
import { useSetLDUser } from '../../utils/hooks/useSetLDUser'
import { useIntercom } from '@sylveraio/react-utils'

export const SiteContainerWithData: FC<PropsWithChildren> = ({ children }) => {
  const navigationRoutes = [
    {
      id: 'home',
      href: '/',
      title: '',
      active: true,
      isProtected: true,
    },
    {
      id: 'jurisdictional-intel',
      href: '/jurisdictional-intel',
      title: 'Jurisdictional Intel',
      active: false,
      isProtected: true,
    },
    {
      id: 'market-commentary',
      href: '/market-commentary',
      title: 'Market Commentary',
      active: false,
      isProtected: true,
    },
    {
      id: 'profile',
      href: '/profile/*',
      title: '',
      active: false,
      isProtected: true,
    },
    {
      id: 'supply-requests',
      href: '/supply-requests',
      title: 'Supply Requests',
      active: false,
      isProtected: true,
    },
    {
      id: 'supply-request',
      href: '/supply-requests/*',
      title: 'Supply Request',
      active: false,
      isProtected: true,
    },
  ]
  const [isProtected] = useNavigationRoutes(navigationRoutes)
  const { data } = useUser(isProtected)
  useIntercom(data)

  useSetLDUser(isProtected)
  const canAccessJredd = useCheckJreddAccess(data?.organisationId)
  const pathname = usePathname()
  const excludeApps: Array<AppName> = useMemo(() => {
    const excludeApps = ['user-management', 'dashboard'] as Array<AppName>

    if (pathname?.includes('jurisdictional-intel')) {
      const apps = excludeApps.filter((item) => item !== 'dashboard')
      return [...apps, 'box']
    }

    if (pathname?.includes('market-commentary')) {
      const apps = excludeApps.filter((item) => item !== 'dashboard')
      return [...apps, 'market-commentary']
    }

    if (pathname?.includes('profile')) {
      const apps = excludeApps.filter((item) => item !== 'dashboard')
      return [...apps]
    }
    return excludeApps
  }, [pathname])

  const appSwitcherItems = getAppStates(data?.appCapabilities, {
    excludeApps,
    excludeDisabled: true,
    hasJREDDAccess: canAccessJredd,
  })

  let appName: AppName

  switch (true) {
    case pathname?.includes('profile/country'):
      appName = 'country-profiles'
      break
    case pathname?.includes('market-commentary'):
      appName = 'market-commentary'
      break
    case pathname?.includes('jurisdictional-intel'):
      appName = 'box'
      break
    default:
      appName = 'dashboard'
  }

  return (
    <SiteContainer
      isLoggedInRoute={isProtected}
      navigationRoutes={navigationRoutes}
      userNav={{ initials: data?.initials }}
      appName={appName as AppName}
      appSwitcherItems={appSwitcherItems}
      responsive
      footer={
        <Footer
          appName={
            pathname?.includes('/profile/country')
              ? 'country-profiles'
              : 'dashboard'
          }
        />
      }
    >
      {children}
    </SiteContainer>
  )
}
