import clsx from 'clsx'
import { FC } from 'react'
import { FractionProps } from './types'
import { Spinner } from '@sylveraio/design-system'

export const Fraction: FC<FractionProps> = ({
  numerator,
  denominator,
  className,
  testId = 'fraction',
  grow,
  colorScheme,
  loading,
}) => (
  <span
    data-testid={testId}
    className={clsx(
      'inline-block',
      {
        'w-full grow': grow,
        'text-content-action-default': colorScheme === 'purple',
        'text-secondary-mauve': colorScheme === 'mauve',
        'text-secondary-water': colorScheme === 'blue',
        'text-secondary-gold': colorScheme === 'lemon',
      },
      className,
    )}
  >
    {loading ? (
      <Spinner className="!h-4 !w-4" />
    ) : !numerator || numerator === '-' ? (
      <span>-</span>
    ) : (
      <>
        <span>{numerator}</span>
        <span className="text-sm">&#47;{denominator}</span>
      </>
    )}
  </span>
)
