import { FC } from 'react'
import SignInWrapper from '../SignInWrapper'
import { SignInLogo } from '../SignInLogo'
import type { SignInLayoutProps } from './types'
import { signInRoute } from '@sylveraio/auth-utils'

export const SignInLayout: FC<SignInLayoutProps> = ({
  testId = 'sign-in',
  children,
}) => (
  <SignInWrapper testId={`${testId}-wrapper`}>
    <section className="relative flex items-center grow">
      <SignInLogo testId={`${testId}-logo`} signInRoute={signInRoute} />
    </section>
    <section>{children}</section>
  </SignInWrapper>
)

export default SignInLayout
