import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Minus: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'minus',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('stroke-current text-content-action-default', className)}
  >
    <path
      d="M3.13889 7.86111L12.8612 7.86111"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
)
