import { FC } from 'react'
import clsx from 'clsx'

import type BinProps from './types'

const Bin: FC<BinProps> = ({ size = 16, className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path d="M6.20326 0.00628662C5.43263 0.00628662 4.79465 0.638192 4.79465 1.40148V2.21615H1.29655C0.988496 2.21615 0.73877 2.4635 0.73877 2.76862C0.73877 3.07374 0.988496 3.32108 1.29655 3.32108H1.80748C2.17117 6.83357 2.50208 10.3513 2.8446 13.8708C2.92631 14.7104 3.59666 15.4753 4.51576 15.4753H11.2091C12.1277 15.4753 12.7953 14.7105 12.8792 13.873C13.2377 10.3511 13.5629 6.83086 13.9435 3.32108H14.5525C14.8606 3.32108 15.1103 3.07374 15.1103 2.76862C15.1103 2.4635 14.8606 2.21615 14.5525 2.21615H10.9302V1.40148C10.9302 0.638192 10.2922 0.00628662 9.5216 0.00628662H6.20326ZM6.20326 1.11122H9.5216C9.69415 1.11122 9.81465 1.23058 9.81465 1.40148V2.21615H5.9102V1.40148C5.9102 1.23058 6.03071 1.11122 6.20326 1.11122ZM2.9274 3.32108H12.8236C12.4319 6.9487 12.082 10.6976 11.773 13.7602C11.7353 14.1405 11.526 14.3704 11.2091 14.3704H4.51576C4.19882 14.3704 3.99269 14.1441 3.9558 13.7651C3.61722 10.286 3.28717 6.80284 2.9274 3.32108Z" />
    <path d="M6.25065 6.08344C6.10272 6.08344 5.96084 6.14164 5.85624 6.24525C5.75164 6.34886 5.69287 6.48938 5.69287 6.6359V11.0556C5.69287 11.2022 5.75164 11.3427 5.85624 11.4463C5.96084 11.5499 6.10272 11.6081 6.25065 11.6081C6.39858 11.6081 6.54045 11.5499 6.64506 11.4463C6.74966 11.3427 6.80843 11.2022 6.80843 11.0556V6.6359C6.80843 6.48938 6.74966 6.34886 6.64506 6.24525C6.54045 6.14164 6.39858 6.08344 6.25065 6.08344Z" />
    <path d="M9.59733 6.08344C9.4494 6.08344 9.30752 6.14164 9.20292 6.24525C9.09832 6.34886 9.03955 6.48938 9.03955 6.6359V11.0556C9.03955 11.2022 9.09832 11.3427 9.20292 11.4463C9.30752 11.5499 9.4494 11.6081 9.59733 11.6081C9.74526 11.6081 9.88713 11.5499 9.99174 11.4463C10.0963 11.3427 10.1551 11.2022 10.1551 11.0556V6.6359C10.1551 6.48938 10.0963 6.34886 9.99174 6.24525C9.88713 6.14164 9.74526 6.08344 9.59733 6.08344Z" />
  </svg>
)

export default Bin
