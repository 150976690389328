import { useState } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  safePolygon,
} from '@floating-ui/react'
import type { TooltipOptions } from './types'

export function useTooltip({
  placement = 'top',
  remainOpenOnHover = false,
  tooltipOffset = 5,
}: TooltipOptions = {}) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(tooltipOffset),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  })

  const hover = useHover(context, {
    move: false,
    handleClose: remainOpenOnHover ? safePolygon({ buffer: 10 }) : null,
  })

  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  return {
    isOpen,
    refs,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
  }
}
