import { captureException } from '@sylveraio/react-utils/server'

export const getServerAccessToken = async (): Promise<string | undefined> => {
  try {
    const tokenResponse = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/tokens`,
      {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Sylvera-Version': '2023-08-01',
        },
        method: 'POST',
        body: JSON.stringify({
          data: {
            type: 'apiTokens',
            attributes: {
              apiKey: `${process.env.NEXT_PUBLIC_FULL_ACCESS_API_KEY}`,
            },
          },
        }),
      },
    )
    const tokenData = await tokenResponse.json()

    const {
      data: { attributes },
    } = tokenData
    const { accessToken } = attributes

    return accessToken
  } catch (e) {
    captureException(e, 'getServerAccessToken')
  }
}
