/* eslint-disable jsx-a11y/click-events-have-key-events */
import { type FC, forwardRef } from 'react'
import FocusTrap from 'focus-trap-react'
import clsx from 'clsx'

import type ModalProps from './types'
import { CloseButton } from '../CloseButton'

/**
 * @deprecated - Use `Modal` from `@sylveraio/design-system` instead
 */
export const Modal: FC<ModalProps> = forwardRef(
  (
    {
      testId = 'modal',
      removeShadow,
      showCloseButton,
      handleClose,
      closeButtonClass,
      className,
      children,
      isOpen = true,
      withOverlay,
      headerElement,
    },
    ref,
  ) => {
    if (!isOpen) return null

    const isHeader = typeof headerElement !== 'undefined' || showCloseButton

    const getModalContent = () => {
      return (
        <div
          ref={ref}
          role="none"
          data-testid={testId}
          className={clsx(
            'relative p-6 bg-high inline-flex flex-col',
            { 'shadow-modal': !removeShadow },
            className,
          )}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {isHeader && (
            <div className="flex justify-end items-center gap-x-2.5 mb-2">
              {typeof headerElement !== 'undefined' && headerElement}
              {showCloseButton && typeof handleClose !== 'undefined' && (
                <CloseButton
                  testId={`${testId}-close-button`}
                  onClick={handleClose}
                  className={closeButtonClass}
                />
              )}
            </div>
          )}
          <div className="relative flex flex-col items-center justify-center w-full h-full">
            {children}
          </div>
        </div>
      )
    }

    if (withOverlay) {
      return (
        <FocusTrap
          active={isOpen}
          focusTrapOptions={{
            initialFocus: false,
          }}
        >
          <div
            role="none"
            data-testid={`${testId}-backdrop`}
            onClick={handleClose}
            className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-opacity-25 bg-black-900"
          >
            {getModalContent()}
          </div>
        </FocusTrap>
      )
    }

    return <>{getModalContent()}</>
  },
)

export default Modal
