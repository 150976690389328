import { FC } from 'react'
import clsx from 'clsx'
import { AppSwitcherDropdownItemButton } from '../AppSwitcherDropdownItemButton'
import type { AppSwitcherDropdownItemProps } from '../types'
import { sendAnalyticsEvent } from '@sylveraio/react-utils/server'
import { Label } from '@sylveraio/design-system'
import LockUnlocked01 from '@sylveraio/untitled-ui-icons-react/build/esm/LockUnlocked01'

export const AppSwitcherDropdownItem: FC<AppSwitcherDropdownItemProps> = ({
  testId = 'app-switcher-dropdown-item',
  name,
  title,
  disabled,
  appLocked,
  appUrl,
  appClientSide,
  onClick,
}) => {
  const handleOnClick = () => {
    if (!disabled && !appLocked) {
      sendAnalyticsEvent({
        eventName: 'app-switcher-go-to-app',
        eventData: {
          appName: name,
        },
      })
    }

    onClick()
  }

  return (
    <div
      data-testid={testId}
      className={clsx(
        'relative w-[290px] border rounded border-subtle bg-medium hover:bg-default transition flex space-x-1 items-center pr-3 cursor-default',
        {
          'opacity-20': disabled,
        },
      )}
    >
      <AppSwitcherDropdownItemButton
        testId={`${testId}-button`}
        name={name}
        title={title}
        appUrl={appUrl}
        appClientSide={appClientSide}
        disabled={disabled || appLocked}
        onClick={handleOnClick}
      />
      {appLocked && (
        <Label
          testId={`${testId}-locked-label`}
          color="neutral"
          size="sm"
          label="Locked"
          iconLeading={<LockUnlocked01 />}
        />
      )}
    </div>
  )
}
