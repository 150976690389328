import { FC } from 'react'
import clsx from 'clsx'
import type IconsProps from '../types'

export const CurvedMinus: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="8"
    height="2"
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <rect
      width="8"
      height="2"
      rx="1"
      className={clsx('fill-current', className)}
    />
  </svg>
)

export default CurvedMinus
