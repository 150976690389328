'use client'

import { FC, useState } from 'react'
import Image from 'next/image'
import type { NewsListCTAProps } from './types'
import { Chevron } from '../IconLibrary/Icons'
import clsx from 'clsx'
import { Typography } from '../Typography'

export const NewsListCTA: FC<NewsListCTAProps> = ({
  testId = 'news-list-cta',
  img,
  title,
  callToAction,
  onClick,
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      data-testid={testId}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(
        'border border-default rounded-lg bg-default hover:border-default-hover hover:bg-default-hover  px-2 py-4',
        {
          'cursor-pointer': typeof onClick !== 'undefined',
        },
        className,
      )}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-x-4">
        <div>
          {img && (
            <Image
              data-testid={`${testId}-thumbnail`}
              src={img.src}
              height={img.height}
              width={img.width}
              alt={img?.alt || title}
              className="h-[72px] w-[92px] rounded"
              unoptimized
            />
          )}
        </div>
        <div className="flex flex-col items-start justify-between gap-y-2">
          <Typography size="sm" weight="semibold" className="line-clamp-2">
            {title}
          </Typography>
          {onClick && (
            <button
              className="flex items-center"
              data-testid={`${testId}-button`}
              onClick={onClick}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Typography
                size="xs"
                weight="medium"
                className={clsx('flex flex-row gap-x-1', {
                  underline: isHovered,
                })}
              >
                {callToAction}
                <Chevron size="xs" className="stroke-icon-default" />
              </Typography>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
