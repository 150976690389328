import { FC } from 'react'
import clsx from 'clsx'
import { Rows } from './Rows'
import { Pager } from './Pager'
import { PaginationButton } from './PaginationButton'
import ChevronLeftDouble from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronLeftDouble'
import ChevronRightDouble from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronRightDouble'
import ChevronLeft from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronLeft'
import ChevronRight from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronRight'
import type { PaginationProps } from './types'

export const Pagination: FC<PaginationProps> = ({
  showRowsDropdown,
  showPageCount,
  showPrevNextButtons,
  showFirstLastButtons,
  rowsPerPage,
  onChangeRowCount,
  onFirstPage,
  onNextPage,
  onPrevPage,
  onLastPage,
  nextPageDisabled,
  prevPageDisabled,
  currentPage,
  totalPages,
  wrapperClassName,
  customRowCount,
  rowCountLabel,
}) => {
  return (
    <div className={clsx('flex mx-2 ml-auto text-sm', wrapperClassName)}>
      {showRowsDropdown && (
        <Rows
          rowsPerPage={rowsPerPage}
          onChangeRowCount={onChangeRowCount}
          customRowCount={customRowCount}
          rowCountLabel={rowCountLabel}
        />
      )}
      <div className="ml-auto flex items-center">
        {showFirstLastButtons && (
          <PaginationButton
            onClick={onFirstPage}
            disabled={prevPageDisabled}
            testId="pagination-first-button"
            variant="primary"
          >
            <ChevronLeftDouble
              className={clsx('w-4 h-4', {
                'text-subtle-on-dark': prevPageDisabled,
                'text-default': !prevPageDisabled,
              })}
            />
          </PaginationButton>
        )}

        {showPrevNextButtons && (
          <PaginationButton
            onClick={onPrevPage}
            disabled={prevPageDisabled}
            testId="pagination-prev-button"
            variant="primary"
          >
            <ChevronLeft
              className={clsx('w-4 h-4', {
                'text-subtle-on-dark': prevPageDisabled,
                'text-default': !prevPageDisabled,
              })}
            />
          </PaginationButton>
        )}

        {showPageCount && (
          <Pager currentPage={currentPage} totalPages={totalPages} />
        )}

        {showPrevNextButtons && (
          <PaginationButton
            onClick={onNextPage}
            disabled={nextPageDisabled}
            testId="pagination-next-button"
            variant="primary"
          >
            <ChevronRight
              className={clsx('w-4 h-4', {
                'text-subtle-on-dark': nextPageDisabled,
                'text-default': !nextPageDisabled,
              })}
            />
          </PaginationButton>
        )}

        {showFirstLastButtons && (
          <PaginationButton
            onClick={onLastPage}
            disabled={nextPageDisabled}
            testId="pagination-last-button"
            variant="primary"
          >
            <ChevronRightDouble
              className={clsx('w-4 h-4', {
                'text-subtle-on-dark': nextPageDisabled,
                'text-default': !nextPageDisabled,
              })}
            />
          </PaginationButton>
        )}
      </div>
    </div>
  )
}

export default Pagination
