import Cookie from 'js-cookie'
import { addSeconds, subMinutes, addMinutes } from 'date-fns'
import { v4 as uuidV4 } from 'uuid'
import { getDomain } from '@sylveraio/react-utils/server'
import {
  EXPIRES_AFTER_DAYS,
  REFRESH_COOKIE,
  ACCESS_COOKIE,
  ACCESS_EXPIRES,
  REVALIDATION_COOKIE,
  REVALIDATE_EXPIRES,
} from './constants'
import type { AccessToken, CookieType, RefreshToken } from './types'
import { addBreadcrumb } from '@sentry/nextjs'
import { captureException } from '@sylveraio/react-utils/server'

const domain = getDomain(true, true)

/**
 * Fetch the access and refresh cookies if they exist
 * @returns
 */
export function fetchCookies(): {
  access: AccessToken | undefined
  refresh: RefreshToken | undefined
} {
  const refresh = Cookie.get(REFRESH_COOKIE)
  const access = Cookie.get(ACCESS_COOKIE)

  addBreadcrumb({
    message: 'Fetching cookies',
    data: {
      access,
      refresh,
    },
  })

  return {
    access: access ? JSON.parse(access) : access,
    refresh: refresh ? JSON.parse(refresh) : refresh,
  }
}

export function getAccessCookieData(access: AccessToken): {
  name: string
  value: string
  expires: Date
  domain: string
  secure: boolean
} {
  const d = new Date()
  const expires = addSeconds(d, access?.exp || ACCESS_EXPIRES)

  addBreadcrumb({
    message: 'Adjusting datetime',
    data: {
      currentDstamp: d,
      accessExpiryS: access?.exp,
      accessExpires: expires,
    },
  })

  return {
    name: ACCESS_COOKIE,
    value: JSON.stringify(access),
    expires,
    domain,
    secure: process.env['NODE_ENV'] !== 'development',
  }
}

export function getRefreshCookieData(refresh: RefreshToken): {
  name: string
  value: string
  expires: number
  domain: string
} {
  addBreadcrumb({
    message: 'Setting refresh cookie',
    data: {
      refreshExpiryD: refresh?.exp || EXPIRES_AFTER_DAYS,
    },
  })

  return {
    name: REFRESH_COOKIE,
    value: JSON.stringify(refresh),
    expires: refresh?.exp || EXPIRES_AFTER_DAYS,
    domain,
  }
}

/**
 * Sets the access and refresh cookies
 */
export function setCookies(access: AccessToken, refresh?: RefreshToken): void {
  addBreadcrumb({
    message: 'About to set cookies',
    data: {
      domain,
    },
  })

  const { name, value, expires } = getAccessCookieData(access)

  Cookie.set(name, value, {
    expires,
    domain,
    secure: true,
  })

  if (refresh) {
    const { name, value, expires } = getRefreshCookieData(refresh)
    Cookie.set(name, value, {
      expires,
      domain,
      secure: true,
    })
  }
}

/**
 * Clears the current cookies
 */
export function clearCookies(cookieType?: CookieType): void {
  addBreadcrumb({
    message: 'Clearing cookies',
    data: {
      cookieType,
    },
  })

  if (cookieType === 'access' || typeof cookieType === 'undefined') {
    try {
      Cookie.remove(ACCESS_COOKIE, {
        domain,
      })
      Cookie.remove(ACCESS_COOKIE)
    } catch (e) {
      captureException(e, 'cookies - access')
    }
  }
  if (cookieType === 'refresh' || typeof cookieType === 'undefined') {
    try {
      Cookie.remove(REFRESH_COOKIE, {
        domain,
      })
      Cookie.remove(REFRESH_COOKIE)
    } catch (e) {
      captureException(e, 'cookies - refresh')
    }
  }
  if (cookieType === 'revalidation' || typeof cookieType === 'undefined') {
    try {
      Cookie.remove(REVALIDATION_COOKIE, {
        domain,
      })
      Cookie.remove(REVALIDATION_COOKIE)
    } catch (e) {
      captureException(e, 'cookies - revalidation')
    }
  }
}

export function setRevalidationCookie() {
  addBreadcrumb({
    message: 'About to set revalidation cookie',
    data: {
      domain,
    },
  })

  const d = new Date()
  d.setTime(d.getTime() + REVALIDATE_EXPIRES * 60 * 1000)
  const a = new Date()
  const offset = a.getTimezoneOffset()
  const dateTzAdjusted = subMinutes(new Date(), offset)
  const expires = addMinutes(dateTzAdjusted, REVALIDATE_EXPIRES)
  const validationToken = uuidV4()
  console.log(d.toUTCString(), expires.toUTCString())

  addBreadcrumb({
    message: 'Adjusting revalidation datetime',
    data: {
      currentDstamp: d,
      offset,
      adjustedDstamp: dateTzAdjusted,
      accessExpires: expires,
      maxCookieTimeM: REVALIDATE_EXPIRES,
      validationToken,
    },
  })

  Cookie.set(REVALIDATION_COOKIE, validationToken, {
    expires: d,
    domain,
    secure: process.env['NODE_ENV'] !== 'development',
  })
}

export const doesCookieExist = (cookieType: CookieType): boolean => {
  switch (cookieType) {
    case 'access':
      return !!Cookie.get(ACCESS_COOKIE)
    case 'refresh':
      return !!Cookie.get(REFRESH_COOKIE)
    case 'revalidation':
      return !!Cookie.get(REVALIDATION_COOKIE)
    default:
      return false
  }
}
