import { FC, useRef } from 'react'
import clsx from 'clsx'
import { useClickOutsideElement } from '@sylveraio/react-utils'
import type { SectionHeaderProps } from './types'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'

export const SectionHeader: FC<SectionHeaderProps> = ({
  testId = 'section-header',
  title,
  titleClassName,
  className,
  subheader,
  subheaderClassName,
  descriptionClassName,
  removeSubheaderPrefix,
  description,
  filterElement,
  tooltip,
  dropdownElement,
  dropdownElementClassName,
  toggleDropdown,
  showDropdown,
  labelsGroup,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useClickOutsideElement(
    dropdownRef,
    () => {
      toggleDropdown?.(false)
    },
    showDropdown,
  )

  return (
    <div className="flex items-end">
      <div
        data-testid={testId}
        className={clsx('flex flex-col w-full px-2 mb-4', className)}
      >
        <div className="text-default grow">
          <div
            data-testid={`${testId}-headers`}
            className={clsx(
              'flex items-baseline text-2xl font-medium ',
              titleClassName,
            )}
          >
            <div data-testid={`${testId}-headers-title`}>{title}</div>
            {subheader && (
              <div
                data-testid={`${testId}-headers-subheader`}
                className={clsx('ml-2 font-normal', subheaderClassName)}
              >
                {!removeSubheaderPrefix && <>-&nbsp;</>}
                {subheader}
              </div>
            )}
            {dropdownElement && (
              <div
                ref={dropdownRef}
                data-testid={`${testId}-dropdown-container`}
                className={clsx('relative ml-6', dropdownElementClassName)}
              >
                <button
                  data-testid={`${testId}-dropdown-arrow-button`}
                  onClick={() => {
                    toggleDropdown?.()
                  }}
                >
                  <ChevronDown className="w-5 h-5" />
                </button>

                {showDropdown && (
                  <span
                    data-testid={`${testId}-dropdown-element`}
                    className="absolute left-0 top-full"
                  >
                    {dropdownElement}
                  </span>
                )}
              </div>
            )}
            {tooltip && (
              <span
                data-testid={`${testId}-tooltip-container`}
                className="ml-2"
              >
                {tooltip}
              </span>
            )}
          </div>
        </div>
        {description && (
          <div
            className={clsx(
              'flex items-end',
              { 'text-default': !descriptionClassName },
              descriptionClassName,
            )}
          >
            <p
              data-testid={`${testId}-description`}
              className="text-sm font-normal "
            >
              {description}
            </p>
          </div>
        )}
        {labelsGroup && (
          <div className="flex items-end">
            <div data-testid={`${testId}-labels-group`}>{labelsGroup}</div>
          </div>
        )}
      </div>
      {filterElement && (
        <div
          data-testid={`${testId}-filter-element`}
          className="ml-auto shrink-0 px-2 mb-4"
        >
          {filterElement}
        </div>
      )}
    </div>
  )
}

export default SectionHeader
