import type { UserDetails, DeepPartial } from 'types'

const MOCK_USER_DETAILS: UserDetails = {
  givenName: 'Joe',
  familyName: 'Bloggs',
  email: 'joe.bloggs@test.com',
  userId: '0000000-29ba-4b30-92a2-07bdf39262',
  initials: 'JB',
  organisationId: '7a50e7c4-c091-4849-8018-83fca972f86e',
  organisation: 'Test',
  featureAccess: {
    canManageServerApiKeys: true,
    canManageMapApiKeys: true,
    canManageApiKeys: true,
    canViewPreIssuanceProjects: true,
    canViewCapScores: true,
    canViewPricing: true,
    canViewAccreditations: true,
    canViewProjectDevelopers: true,
    canViewQualityPricingGraph: true,
    canViewSylveraRatingFullCommentary: true,
    canViewCapFullCommentary: true,
    canViewCoBenefitsFullCommentary: true,
    canViewFullMapTab: true,
    canDownloadFactsheet: true,
    canViewProponentsTab: true,
    canViewEnhancedUnsubscribed: true,
  },
  subscription: {
    projects: [
      '9964401d-c22b-4c43-b26f-83ab0053b9e7',
      'c4e490db-9100-4286-9058-5c392634973c',
      'e0953dff-0274-4dd0-be58-af607c862e33',
      '97adbe37-2c33-4def-a00a-2d1a20a1724e',
      '9e4120cb-03a6-4ccc-aaf4-a1034edab5b2',
      'ad3d1552-c2f1-42c1-8f21-f72ee0138938',
      '2c82f79a-633d-4e77-a34d-4d52ed2d0ebb',
      '42b23a30-b9af-49e1-9263-996bf535994f',
      'a3f42d90-b325-46e6-bebc-cc7da70ea8c8',
      '0005d954-dea9-4823-b110-f134fa537ddc',
      '63b4b687-723b-4cbe-9059-4405fa481e5d',
      'de996aeb-5ef2-41eb-82d9-93b150f73b8f',
      'd4ccdf73-739e-4a0e-96de-68c4568bf9ad',
      'e067d9d5-a59e-42b1-82b7-488adaca3c4e',
      'fd459472-0277-4c15-ab78-cefe4c6b64ee',
      '3fbefc59-161b-49aa-9802-6e0f3c0718bb',
      'ba408f26-4f80-47e1-9543-4c4527a9ef7a',
      '242d7df2-6ed8-4de5-8342-dc82c50ff106',
      '6618f50a-7246-4f67-8a00-26e8275d71d1',
      '742196cb-9b0e-4bf9-a5c1-93b9b7e6df2e',
      '4cff145f-1d3d-4941-afd4-b96782a1f510',
      'a38c2591-4bab-421f-9b24-5fdda64d176f',
      '7697ffe9-c68e-401a-bd23-4fd1c11692aa',
      'e9ed78bf-76ea-4c25-8257-828deefd3c7d',
      '820613a3-3b11-498d-8b6e-8bbf1d6aebaf',
      '53f3d124-b75b-4c8c-9f4f-ef7852faadf4',
      'f2667918-e908-4c50-af8b-d4b65f972568',
      '8e64d7ed-c8fb-425b-bb88-375309bbabf2',
      '04b90dd1-e56c-43d7-b864-8ff6b6b45216',
      '63032eb0-25be-45af-91e5-e15dd6b575a7',
      'b4b2488a-8899-4751-8668-32d903a556be',
      'bb18ef6c-d63e-4dc6-8378-8e2be3a391ca',
      'ca27561b-7c9f-4336-a052-f0419f1dfe31',
      '15db0a8c-8455-46bc-86f3-539110106d96',
      'c2659135-3c1b-45af-9915-5fff9013e6d0',
      '217fcb29-26d0-49ed-aebb-24966b3611e1',
      'ac779866-eb09-42fc-b88a-309f4ec5476b',
      '1e48c707-2d8b-49b2-a587-37075575acaf',
      '38719b79-096d-4541-baa5-7c39bdd970b7',
      'fac3c35c-e138-4628-8704-22fda5e51680',
      '1701f409-4580-487a-80a0-6223dd9b30fa',
      '8a288579-5297-4cc6-ba7c-8543d6ab4f16',
      'be35fbd2-9aa2-4ecb-905c-f1e4e3bdd5a4',
      '721f23ff-4599-481b-9954-37050bbc26d0',
      '3238ad32-b50a-42c6-a443-5147403d5728',
      'fd0c8ba7-58ae-4e75-9a7d-a0cd07c6bf3c',
      '878fb57a-6b28-43cc-bcc8-ec589e13531f',
      '7b1afcc2-cace-42a8-8812-70c17d8b7aaa',
      'afe572ff-7b39-4e31-8d50-32c3fd632893',
      '1de2a444-e92b-403c-a24b-4ec2d2ccf3b8',
      'c195e656-075f-4264-88b0-9f4cb4c9c107',
      '6366c28a-1a6e-4233-a56a-bb902060d642',
      'f06bfa71-b8af-4423-90d6-0b79025300fe',
      '3cadaa51-f22d-419b-a9d8-f706a8abd128',
      '518d9458-e55a-46c7-9609-7d5c866b36ce',
      'be7ce520-aea1-404b-857e-36141e8d46aa',
    ],
  },
  appCapabilities: ['analytics', 'ratings'],
  apps: {
    ratings: true,
    analytics: true,
  },
  currentTier: 'Enterprise',
  nextTier: undefined,
}

export function mockUseUserReturnValue(
  data?: DeepPartial<UserDetails>,
  clearData = false,
) {
  const mockData = clearData
    ? undefined
    : {
        ...MOCK_USER_DETAILS,
        ...data,
      }

  jest.mock('@sylveraio/data', () => ({
    useUser: jest.fn().mockReturnValue({
      data: mockData,
      isValidating: false,
      hasAccess: () => true,
      mutate: () => Promise.resolve(undefined),
      isFetching: false,
      hasData: !!mockData,
    }),
  }))
}
