import {
  AXIS_LABEL_OPTIONS,
  GRID_OPTIONS,
  MAX_PAN_LIMIT,
  MIN_PAN_LIMIT,
  TICK_OPTIONS,
} from '../../../constants'
import type { AxisOptions } from '../../types'

export function createAxis(
  axisOptions: [
    AxisOptions | undefined,
    AxisOptions | undefined,
    AxisOptions | undefined,
  ],
  showGridLines = true,
  enablePanning = false,
  xAxisLabels: Array<string> = [],
) {
  const [xAxis, yAxis, y1Axis] = axisOptions

  let x: Record<string, unknown> | undefined
  let y: Record<string, unknown> | undefined
  let y1: Record<string, unknown> | undefined

  if (xAxis) {
    const { axisLabel, type, max, min, offset, ticks } = xAxis
    x = {
      type: enablePanning ? 'category' : (type as any) ?? 'category',
      title: {
        display: !!axisLabel,
        text: axisLabel,
        ...AXIS_LABEL_OPTIONS,
      },
      grid: {
        ...GRID_OPTIONS,
        display: showGridLines,
      },
      ticks: {
        ...TICK_OPTIONS,
        ...ticks,
      },
      ...(type === 'time'
        ? {
            time: {
              unit: 'year',
            },
          }
        : {}),
      min: enablePanning ? xAxisLabels[MIN_PAN_LIMIT] : min,
      max: enablePanning ? xAxisLabels[MAX_PAN_LIMIT] : max,
      suggestedMax: xAxisLabels.at(-1),
      offset: offset ?? true,
    }
  }

  if (yAxis) {
    const { axisLabel, type, max, min, offset, ticks } = yAxis
    y = {
      type,
      title: {
        display: !!axisLabel,
        text: axisLabel,
        ...AXIS_LABEL_OPTIONS,
      },
      grid: {
        ...GRID_OPTIONS,
        display: showGridLines,
      },
      ticks: {
        ...TICK_OPTIONS,
        ...ticks,
      },
      min,
      max,
      offset,
    }
  }

  if (y1Axis) {
    const { axisLabel, type, max, min, offset, ticks } = y1Axis
    y1 = {
      type,
      display: true,
      title: {
        display: !!axisLabel,
        text: axisLabel,
        ...AXIS_LABEL_OPTIONS,
      },
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        ...TICK_OPTIONS,
        ...ticks,
      },
      min,
      max,
      offset,
      position: 'right',
    }
  }

  return {
    x,
    y,
    y1,
  }
}
