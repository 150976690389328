import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Bell: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="27"
    height="33"
    viewBox="0 0 27 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      d="M13.6645 6.98145C13.2117 6.98145 12.8413 7.35187 12.8413 7.80462C12.8413 8.25736 13.2117 8.62779 13.6645 8.62779C16.2986 8.62779 18.4389 10.768 18.4389 13.4022C18.4389 13.8549 18.8093 14.2253 19.262 14.2253C19.7148 14.2253 20.0852 13.8549 20.0852 13.4022C20.0852 9.86254 17.2041 6.98145 13.6645 6.98145Z"
      fill="#605CFF"
    />
    <path
      d="M24.0777 23.0333L22.8018 13.4434C22.7607 9.40983 20.1677 5.95252 16.5457 4.6766C16.7104 4.26502 16.8338 3.81228 16.8338 3.35953C16.8338 2.41289 16.4223 1.5074 15.7226 0.848861C15.0229 0.231483 14.0351 -0.0977854 13.0884 0.0256902C11.6067 0.190324 10.4131 1.34276 10.2073 2.78331C10.0838 3.44185 10.1662 4.05923 10.4131 4.6766C6.79116 5.95252 4.19817 9.36868 4.15701 13.4434L2.8811 23.0333C1.27591 23.1568 0 24.515 0 26.1614C0 27.89 1.39939 29.2894 3.12805 29.2894H9.26067C9.54878 31.3473 11.3186 32.9525 13.5 32.9525C15.6814 32.9525 17.4512 31.3473 17.7393 29.2894H23.872C25.6006 29.2894 27 27.89 27 26.1614C26.9177 24.515 25.6829 23.1568 24.0777 23.0333ZM11.7713 3.07142C11.8948 2.33057 12.5122 1.75435 13.253 1.67203C13.747 1.58971 14.2409 1.75435 14.6113 2.08362C14.9817 2.41288 15.1875 2.86563 15.1875 3.35953C15.1875 3.6888 15.1052 3.97691 14.9405 4.26502C14.4878 4.1827 13.9939 4.14154 13.5 4.14154C13.0061 4.14154 12.5122 4.1827 12.0595 4.26502C11.8125 3.93575 11.689 3.52417 11.7713 3.07142ZM5.76219 13.608V13.4845C5.76219 9.2452 9.21951 5.78788 13.4588 5.78788C17.6982 5.78788 21.1555 9.2452 21.1555 13.4845L22.4314 22.9922H4.52744L5.76219 13.608ZM13.4588 31.3062C12.2241 31.3062 11.154 30.4418 10.907 29.2894H16.0107C15.7637 30.4418 14.6936 31.3062 13.4588 31.3062ZM23.7896 27.6431H3.12805C2.30488 27.6431 1.64634 26.9845 1.64634 26.1614C1.64634 25.3382 2.30488 24.6796 3.12805 24.6796H23.8308C24.654 24.6796 25.3125 25.3382 25.3125 26.1614C25.3125 26.9845 24.6128 27.6431 23.7896 27.6431Z"
      fill="#605CFF"
    />
  </svg>
)

export default Bell
