import posthog from 'posthog-js'
import { useEffect } from 'react'
import { UserDetails } from 'types'

/**
 * Custom React hook that identifies a user to PostHog analytics using their email and other details.
 * This identification is performed only if the PostHog environment variable is set and the user's email is present.
 * The identification data includes the user's email, user ID, organisation, and full name.
 *
 * Note: This hook is intended to be used at the top level within `_app.tsx` to ensure it's called only once
 * during the application lifecycle, suitable for initial user identification in analytics.
 *
 * @param {UserDetails} data - The user's details object
 *
 * @example
 * usePosthogIdentify({
 *   email: 'jane.doe@example.com',
 *   userId: 'user123',
 *   organisation: 'Example Corp',
 *   givenName: 'Jane',
 *   familyName: 'Doe'
 * });
 */
export function usePosthogIdentify(data?: UserDetails) {
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_POSTHOG &&
      typeof data !== 'undefined' &&
      typeof data?.email !== 'undefined'
    ) {
      posthog.identify(data.email, {
        userEmail: data.email,
        uid: data.userId,
        userOrganisation: data.organisation,
        userName: `${data.givenName} ${data.familyName}`,
      })
    }
  }, [data])
}
