import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const EmptyInbox: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0002 58.1958L33.0768 42.7508C33.667 42.146 34.4766 41.8048 35.3217 41.8048H64.6783C65.5234 41.8048 66.3329 42.146 66.9232 42.7508L81.9998 58.1958V73.9809C81.9998 76.753 79.7524 79.0004 76.9803 79.0004H23.0195C20.2473 79.0004 18 76.7529 18 73.9809L18.0002 58.1958ZM22.7059 56.9351L34.9409 44.3268H65.0584L77.2934 56.9351H61.6068V59.4836C61.6068 62.6024 59.0784 65.1308 55.9599 65.1308H44.0383C40.9198 65.1308 38.3914 62.6024 38.3914 59.4836V56.9351H22.7059ZM20.5002 59.5002H36.0002C36.0002 63.8631 39.5373 67.4003 43.9003 67.4003H56.1003C60.4632 67.4003 64.0003 63.8632 64.0003 59.5002H79.5003V73.5002C79.5003 75.1571 78.1573 76.5002 76.5003 76.5002H23.5003C21.8434 76.5002 20.5003 75.1571 20.5003 73.5002L20.5002 59.5002ZM51.2552 35.5002C51.2552 36.1965 50.6933 36.7609 50.0003 36.7609C49.3072 36.7609 48.7453 36.1965 48.7453 35.5002V22.261C48.7453 21.5647 49.3072 21.0002 50.0003 21.0002C50.6933 21.0002 51.2552 21.5647 51.2552 22.261V35.5002ZM59.3698 36.6366C59.1901 37.3091 58.5023 37.7085 57.8327 37.5282C57.1634 37.3478 56.7659 36.6564 56.9453 35.9839L60.3558 23.1956C60.5352 22.5231 61.2233 22.124 61.8926 22.304C62.5622 22.4843 62.9593 23.1757 62.7799 23.8482L59.3698 36.6366ZM43.3289 35.9839C43.5083 36.6564 43.1108 37.3478 42.4416 37.5282C41.772 37.7085 41.0838 37.3091 40.9045 36.6366L37.4943 23.8482C37.315 23.1757 37.7121 22.4843 38.3817 22.304C39.0509 22.124 39.7391 22.5231 39.9184 23.1956L43.3289 35.9839Z"
      fill="#605CFF"
    />
  </svg>
)

export default EmptyInbox
