'use client'
// import { Auth } from 'aws-amplify'
import { setUser } from '@sentry/nextjs'
import { captureException } from '@sylveraio/react-utils/server'
import posthog from 'posthog-js'
import { clearCookies } from '../cookies'

/**
 * Clears cookies, resets Sentry and Posthog states, then calls success or failure functions
 */
export async function signOut(
  onSuccess?: () => void,
  onFailure?: () => void,
): Promise<void> {
  try {
    clearCookies()
    // await Auth.signOut({
    //   global: true,
    // })
    setUser(null)
    if (onSuccess) onSuccess()
    if (process.env.NEXT_PUBLIC_POSTHOG) posthog.reset()
  } catch (e) {
    captureException(e, 'signOut')
    if (onFailure) onFailure()
  }
}
