import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import type { PaginationVariables, UseCursorPagination } from './types'
import { usePrevious } from '../usePrevious'

const DEFAULT_PAGE_NUM = 1

function getRemainingPageCount(recordCount = 0, rowsPerPage = 0): number {
  return recordCount % rowsPerPage
}

export function useCursorPagination(
  rowsPerPage: number,
  recordCount: number,
  variables: Record<string, unknown> | string,
  cursors?: { start: string | undefined; end: string | undefined },
): UseCursorPagination {
  const DEFAULT_PAGINATION_VARS = {
    cursorValue: null,
    firstLimit: rowsPerPage,
    lastLimit: null,
  }
  const pageCount = Math.ceil(recordCount / rowsPerPage)
  const hasMultiplePages = pageCount > DEFAULT_PAGE_NUM
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUM)
  const hasPrevPage = hasMultiplePages && currentPage > DEFAULT_PAGE_NUM
  const hasNextPage = hasMultiplePages && currentPage < pageCount
  const [paginationVars, setPaginationVars] = useState<PaginationVariables>(
    DEFAULT_PAGINATION_VARS,
  )
  const prevVariables = usePrevious(variables)

  useEffect(() => {
    // Check for deep-equality
    if (prevVariables && !isEqual(prevVariables, variables)) {
      setPaginationVars(DEFAULT_PAGINATION_VARS)
      setCurrentPage(DEFAULT_PAGE_NUM)
      return
    }
  }, [variables, prevVariables])

  useEffect(() => {
    setCurrentPage(DEFAULT_PAGE_NUM)
    setPaginationVars({
      cursorValue: null,
      firstLimit: rowsPerPage,
      lastLimit: null,
    })
  }, [rowsPerPage])

  const handlePrevPage = (first = false) => {
    if (first) {
      setCurrentPage(DEFAULT_PAGE_NUM)
      setPaginationVars(DEFAULT_PAGINATION_VARS)
    } else {
      if (hasPrevPage) {
        setCurrentPage((p) => p - 1)
        setPaginationVars({
          cursorValue: cursors?.start || null,
          firstLimit: null,
          lastLimit: rowsPerPage,
        })
      }
    }
  }

  const handleNextPage = (last = false) => {
    if (last) {
      setCurrentPage(pageCount)
      setPaginationVars({
        cursorValue: null,
        firstLimit: null,
        lastLimit: getRemainingPageCount(recordCount, rowsPerPage),
      })
    } else {
      if (hasNextPage) {
        setCurrentPage((p) => p + 1)
        setPaginationVars({
          cursorValue: cursors?.end || null,
          firstLimit: rowsPerPage,
          lastLimit: null,
        })
      }
    }
  }

  return {
    pageCount,
    currentPage,
    hasPrevPage,
    hasNextPage,
    onPrevPage: hasPrevPage ? handlePrevPage : undefined,
    onNextPage: hasNextPage ? handleNextPage : undefined,
    paginationVars: paginationVars,
  }
}
