import { useState } from 'react'
import type { FC } from 'react'
import type { ClipboardCopyProps } from './types'
import { captureException } from '@sylveraio/react-utils'
import Copy04 from '@sylveraio/untitled-ui-icons-react/build/esm/Copy04'

export const ClipboardCopy: FC<ClipboardCopyProps> = ({
  testId = 'clipboard-copy',
  copyText,
  onCopy,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      const res = await navigator.clipboard.writeText(text)
      return res
    }
  }

  const handleCopyClick = async () => {
    try {
      await copyTextToClipboard(copyText)
      onCopy?.()
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    } catch (err) {
      captureException(err, 'ClipboardCopy')
    }
  }

  return (
    <button
      data-testid={testId}
      className="flex items-center px-3.5 py-3 text-xl bg-high border border-solid rounded border-content-action-default"
      type="button"
      onClick={handleCopyClick}
    >
      <input
        data-testid={`${testId}-value`}
        className="mr-10 bg-transparent w-72"
        type="text"
        value={copyText}
        readOnly
      />
      <span
        data-testid={`${testId}-icon`}
        className="text-sm font-bold text-content-action-default"
      >
        {isCopied ? (
          'Copied!'
        ) : (
          <Copy04 className="w-4 h-4 stroke-content-action-default" />
        )}
      </span>
    </button>
  )
}

export default ClipboardCopy
