import { FC } from 'react'
import type MarkerProps from './types'

const Marker: FC<MarkerProps> = ({ size = 16 }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <circle
      cx="9.88394"
      cy="9.91311"
      r="9.32534"
      fill="#605CFF"
      fillOpacity="0.5"
    />
    <circle
      cx="9.88361"
      cy="9.91303"
      r="6.66096"
      fill="#605CFF"
      fillOpacity="0.5"
    />
    <circle cx="9.88384" cy="9.78362" r="3.10845" fill="white" />
  </svg>
)

export default Marker
