import { FC } from 'react'
import clsx from 'clsx'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { ConditionalDivButton } from '../../../ConditionalDivButton'
import { palette } from '@sylveraio/tailwind'
import type { ProgressRingDefaultProps } from './types'

export const ProgressRingDefault: FC<ProgressRingDefaultProps> = ({
  testId = 'progress-ring-default',
  onClick,
  size = 'sm',
  value,
  stroke = 8,
  trailColor,
  innerLabel,
}) => (
  <ConditionalDivButton
    testId={testId}
    onClick={onClick}
    className={clsx(
      {
        'h-16 w-16': size === 'sm',
        'h-[76px] w-[76px]': size === 'md',
        'h-[90px] w-[90px]': size === 'lg',
        'h-[134px] w-[134px]': size === 'xl',
      },
      'select-none',
    )}
  >
    <CircularProgressbarWithChildren
      value={value as number}
      styles={{
        path: {
          stroke: 'url(#progressBarGradient)',
          strokeLinecap: 'round',
          transform: 'rotate(0.25turn)',
          transformOrigin: 'center center',
        },
        trail: {
          stroke: trailColor ?? palette.black[50],
        },
      }}
      strokeWidth={stroke}
    >
      <div
        className={clsx('text-content-action-dark progress-ring-rating-text', {
          'text-sm': size === 'sm',
          'text-lg': size === 'md',
          'text-syl-lg': size === 'lg',
          'text-syl-2xl': size === 'xl',
        })}
      >
        {innerLabel}
      </div>
    </CircularProgressbarWithChildren>
  </ConditionalDivButton>
)

export default ProgressRingDefault
