import { getCurrentSortDirection, isColumnSorted } from '../../utils'
import ArrowUp from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowUp'
import ArrowDown from '@sylveraio/untitled-ui-icons-react/build/esm/ArrowDown'
import SwitchVertical01 from '@sylveraio/untitled-ui-icons-react/build/esm/SwitchVertical01'
import { type FC } from 'react'
import clsx from 'clsx'
import type { SortComponentProps, TableHeaderSortIconProps } from './types'

const TableHeaderSortIcon: FC<TableHeaderSortIconProps> = ({
  isHovered,
  isSorted,
  sortDirection,
}) => {
  if (!isSorted && !isHovered) return null

  const iconStyle = clsx('w-3 h-3 ', {
    'text-subtle': isHovered,
    'text-content-action-default': !isHovered,
  })
  return (
    <>
      {sortDirection === 'asc' && <ArrowUp className={iconStyle} />}
      {sortDirection === 'desc' && <ArrowDown className={iconStyle} />}
    </>
  )
}

export const SortComponent: FC<SortComponentProps> = ({
  isHovered,
  defaultSortDirection,
  sortControls,
  appliedSort,
  headerId,
}) => {
  if (sortControls?.sortArrowVariant === 'doubleArrow') {
    return <SwitchVertical01 className="h-3 w-3 text-subtle" />
  }

  const isSorted = isColumnSorted(
    appliedSort,
    headerId,
    sortControls?.sortField,
  )
  const currentSortDirection = getCurrentSortDirection(
    appliedSort,
    sortControls?.sortDirection,
  )

  if (sortControls?.sortArrowVariant === 'singleArrow') {
    return (
      <div className="relative w-2">
        <TableHeaderSortIcon
          isHovered={isHovered}
          isSorted={isSorted}
          sortDirection={isSorted ? currentSortDirection : defaultSortDirection}
        />
      </div>
    )
  }

  return null
}
