import clsx from 'clsx'
import { FC } from 'react'
import { SortArrowCellBorderProps, SortArrowProps } from './types'

export const SortArrow: FC<SortArrowProps> = ({
  testId = 'sort-arrow',
  sortDirection,
}) => (
  <div
    data-testid={`${testId}-${sortDirection}`}
    className={clsx('w-0 h-0 border-x-[14px] border-transparent relative', {
      'border-t-[6px] border-t-content-action-default mt-[8px]':
        sortDirection === 'desc',
      'border-b-[6px] border-b-content-action-default mb-[8px]':
        sortDirection === 'asc',
    })}
  >
    <div
      className={clsx(
        'border-transparent border-x-[14px] w-0 h-0 absolute translate-x-[-14px]',
        {
          'border-t-subtle border-t-[6px] translate-y-[-8px]':
            sortDirection === 'desc',
          'border-b-subtle border-b-[6px] translate-y-[2px]':
            sortDirection === 'asc',
        },
      )}
    />
  </div>
)

export const SortArrowCellBorder: FC<SortArrowCellBorderProps> = ({
  testId = 'cell-border',
  isSorted,
  isHovered,
  sortDirection,
  className,
}) => {
  const borderClassName = clsx(
    'border-b-2 border-content-action-default flex-1 mr-1',
    className,
  )

  return (
    <div
      data-testid={testId}
      className="absolute inset-x-0 flex items-center w-full h-3 bottom-1"
    >
      {isSorted || isHovered ? (
        <>
          <div className={clsx('ml-0 -mr-3', borderClassName)} />
          <SortArrow sortDirection={sortDirection} />
          <div className={clsx('mr-1 -ml-3', borderClassName)} />
        </>
      ) : (
        <div className={borderClassName} />
      )}
    </div>
  )
}
