'use client'

import type { FC } from 'react'

import type { RequestSubmittedModalProps } from './types'
import { Modal, Typography } from '@sylveraio/design-system'
import { MODAL_BUTTON, MODAL_CONTENT, TITLE } from './constants'

export const RequestSubmittedModal: FC<RequestSubmittedModalProps> = ({
  testId = 'request-submitted-modal',
  modalContentOverwrite,
  onClose,
}) => (
  <Modal
    title={TITLE}
    size="sm"
    onClose={onClose}
    primaryActionProps={{
      label: MODAL_BUTTON,
      variant: 'primary',
      onClick: onClose,
    }}
    testId={testId}
  >
    <Typography size="base" className="text-subtle">
      {modalContentOverwrite || MODAL_CONTENT}
    </Typography>
  </Modal>
)
