import { captureException } from '@sylveraio/react-utils'
import { addBreadcrumb } from '@sentry/minimal'
import useSWR, { type Fetcher } from 'swr'
import { useUser } from '../useUser'
import { fetchAndDeserialize, getUserPreference } from '../services'
import type {
  ProjectList,
  UserPreferenceKey,
  UserPreferenceObj,
  UserPreferences,
} from '../services/transformers/preferencesDetails/types'
import type { UsePreferences } from './types'

/**
 * Attempts to fetch user preferences
 */
export function usePreferences<T extends UserPreferenceKey>(
  preferenceKey: T,
  isProtectedRoute = true,
): UsePreferences<T> {
  const { data: userData } = useUser(isProtectedRoute)

  const fetcher: Fetcher<
    UserPreferenceObj<T> | undefined,
    [string, UserPreferenceKey]
  > = async ([key, preferenceKey]) =>
    await getUserPreference<T>(key, preferenceKey)

  const { data, isValidating, error, mutate, isLoading } = useSWR(
    ['/preferences', preferenceKey],
    fetcher,
    {
      revalidateOnFocus: false,
    },
  )

  const upsertPreference = async (
    preferenceValue: UserPreferenceObj<T>['value'],
  ) => {
    if (
      typeof preferenceKey === 'undefined' ||
      typeof userData?.userId === 'undefined'
    )
      return

    let value: { [x: string]: boolean | number | string | Array<string> } = {}

    switch (preferenceKey) {
      case 'projectShortlist':
      case 'unratedProjectShortlist': {
        const projectId = Object.keys(data?.value || {}).reduce((acc, cv) => {
          if (typeof (preferenceValue as ProjectList)[cv] !== 'undefined')
            return cv

          return acc
        }, '')

        if (projectId !== '') {
          value = { ...(data?.value as ProjectList) }

          addBreadcrumb({
            message: `Removing a ${preferenceKey} entry`,
            data: {
              key: projectId,
              value: value?.[projectId],
            },
          })

          delete value?.[projectId]
          break
        }

        addBreadcrumb({
          message: `Adding a new ${preferenceKey} entry`,
          data: {
            key: projectId,
            value: (preferenceValue as ProjectList)?.[projectId],
          },
        })

        value = {
          ...(data?.value as ProjectList),
          ...(preferenceValue as ProjectList),
        }

        break
      }

      default:
        value = {
          ...(data?.value as UserPreferences[Exclude<
            UserPreferenceKey,
            'projectShortlist' | 'unratedProjectShortlist'
          >]),
          ...(preferenceValue as UserPreferences[Exclude<
            UserPreferenceKey,
            'projectShortlist' | 'unratedProjectShortlist'
          >]),
        }
        break
    }

    const body = {
      data: {
        id: `${userData?.userId}.${preferenceKey}`,
        type: 'userPreference',
        attributes: {
          preferenceKey,
          value,
        },
      },
    }

    try {
      addBreadcrumb({
        message: 'Attempting to upsert preference',
        data: {
          preferenceKey,
        },
      })

      await fetchAndDeserialize(
        `/users/me/preferences/${preferenceKey}`,
        undefined,
        false,
        undefined,
        'PATCH',
        body,
      )

      // @ts-ignore
      await mutate({ ...(data || {}), value }, { revalidate: true })
    } catch (e) {
      captureException(e, 'usePreferences - upsertPreference')
    }
  }

  const deletePreference = async () => {
    if (typeof preferenceKey === 'undefined') return

    try {
      addBreadcrumb({
        message: 'Attempting to delete preference',
        data: {
          preferenceKey,
        },
      })

      await fetchAndDeserialize(
        `/users/me/preferences/${preferenceKey}`,
        undefined,
        false,
        undefined,
        'DELETE',
      )

      mutate()
    } catch (e) {
      captureException(e, 'usePreferences - deletePreference')
    }
  }

  return {
    data,
    isValidating,
    isLoading,
    error,
    mutate,
    isFetching: isValidating && !data,
    upsertPreference,
    deletePreference,
  }
}
