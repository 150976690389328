import { FC } from 'react'
import clsx from 'clsx'

import type LogoutProps from './types'

const Logout: FC<LogoutProps> = ({ size = 16, className }) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path d="M9.74453 19.6919C7.97796 19.6876 6.25219 19.1637 4.78471 18.1861C3.31724 17.2085 2.17372 15.8211 1.49826 14.1987C0.82281 12.5762 0.645649 10.7913 0.989106 9.06898C1.33256 7.34661 2.18127 5.76383 3.42827 4.52011C3.91375 4.03754 4.45327 3.61187 5.03628 3.25143C5.21427 3.14147 5.42892 3.10629 5.633 3.15363C5.83709 3.20097 6.01389 3.32695 6.12452 3.50386C6.23515 3.68077 6.27055 3.89411 6.22292 4.09696C6.17529 4.29981 6.04854 4.47554 5.87056 4.5855C4.92717 5.16824 4.12852 5.9554 3.53433 6.88814C2.94013 7.82088 2.56577 8.87507 2.43922 9.97188C2.31267 11.0687 2.43722 12.1797 2.80354 13.222C3.16987 14.2642 3.7685 15.2106 4.55467 15.9905C5.93669 17.3641 7.81111 18.1358 9.76558 18.1358C11.7201 18.1358 13.5945 17.3641 14.9765 15.9905C16.3585 14.6168 17.1349 12.7538 17.1349 10.8112C17.1349 8.86854 16.3585 7.00548 14.9765 5.63183C14.5719 5.23307 14.1224 4.88197 13.6369 4.5855C13.5476 4.53182 13.4698 4.46105 13.4081 4.37729C13.3465 4.29353 13.3022 4.19845 13.2777 4.09755C13.2533 3.99664 13.2492 3.89193 13.2658 3.78945C13.2823 3.68698 13.3192 3.58879 13.3742 3.50057C13.4292 3.41235 13.5012 3.33585 13.5862 3.27549C13.6711 3.21514 13.7673 3.17213 13.8691 3.14896C13.9708 3.12579 14.0762 3.12291 14.1791 3.1405C14.282 3.15809 14.3804 3.1958 14.4686 3.25143C15.6187 3.9563 16.5934 4.91091 17.3193 6.04365C18.0452 7.17639 18.5037 8.45783 18.6602 9.79182C18.8167 11.1258 18.6672 12.4777 18.2229 13.7461C17.7786 15.0144 17.0511 16.1663 16.095 17.1153C15.261 17.9415 14.2701 18.5947 13.1799 19.0371C12.0897 19.4794 10.922 19.702 9.74453 19.6919Z" />
    <path d="M9.74412 9.94013C9.53473 9.94013 9.33391 9.85745 9.18584 9.71028C9.03777 9.56312 8.95459 9.36351 8.95459 9.15538V0.784747C8.95459 0.681693 8.97501 0.579647 9.01469 0.484437C9.05437 0.389228 9.11252 0.302718 9.18584 0.229847C9.25915 0.156977 9.34619 0.0991727 9.44198 0.0597355C9.53777 0.0202983 9.64044 0 9.74412 0C9.84781 0 9.95047 0.0202983 10.0463 0.0597355C10.1421 0.0991727 10.2291 0.156977 10.3024 0.229847C10.3757 0.302718 10.4339 0.389228 10.4736 0.484437C10.5132 0.579647 10.5337 0.681693 10.5337 0.784747V9.15538C10.5337 9.36351 10.4505 9.56312 10.3024 9.71028C10.1543 9.85745 9.95352 9.94013 9.74412 9.94013Z" />
  </svg>
)

export default Logout
