import type { FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const PlusInRing: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'plus-in-ring',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-content-action-default', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.4C17.1915 21.4 21.4 17.1915 21.4 12C21.4 6.80852 17.1915 2.6 12 2.6C6.80852 2.6 2.6 6.80852 2.6 12C2.6 17.1915 6.80852 21.4 12 21.4ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70027 11.8429C5.70027 11.401 6.05844 11.0429 6.50027 11.0429L11.2 11.0429L11.2 6.34315C11.2 5.90132 11.5582 5.54315 12 5.54315C12.4418 5.54315 12.8 5.90132 12.8 6.34315V11.0429H17.4997C17.9415 11.0429 18.2997 11.401 18.2997 11.8429C18.2997 12.2847 17.9415 12.6429 17.4997 12.6429L12.8 12.6429L12.8 17.6569C12.8 18.0987 12.4418 18.4569 12 18.4569C11.5582 18.4569 11.2 18.0987 11.2 17.6569L11.2 12.6429L6.50027 12.6429C6.05844 12.6429 5.70027 12.2847 5.70027 11.8429Z"
    />
  </svg>
)
