import { useMemo } from 'react'
import useSWR, { type Fetcher } from 'swr'
import type {
  EnhancedRetirementsAnalysis,
  UseRetirementsAnalysisObject,
} from './types'
import type {
  FilterableFieldsKey,
  GroupableFieldsKey,
  RetirementsAnalysisDoubleGroupedBy,
  RetirementsAnalysisSingleGroupedBy,
} from 'types'
import { getRetirementsAnalysisData } from '../services'
import type { RetirementsAnalyticsResponse } from '../services/types'

/**
 * useRetirementsAnalysis is a function that fetches data from the '/retirements/analysis' endpoint.
 *
 * @param {Object} [filter] - An object containing filterable fields.
 * @param {boolean} [bypass] - A boolean value to bypass the fetching of data.
 * @param {GroupableFieldsKey | Array<GroupableFieldsKey> | undefined} [groupBy] - A groupable field or an array of groupable fields.
 *
 * @returns {UseRetirementsAnalysisObject<EnhancedRetirementsAnalysis | RetirementsAnalysisSingleGroupedBy<EnhancedRetirementsAnalysis> | RetirementsAnalysisDoubleGroupedBy<EnhancedRetirementsAnalysis>> } - An object containing the fetched data, a boolean value indicating if the data is validating, an error message and a mutate function.
 */
export function useRetirementsAnalysis(
  filter?: Partial<Record<FilterableFieldsKey, unknown>>,
  bypass?: boolean,
  groupBy?: GroupableFieldsKey | Array<GroupableFieldsKey>,
): UseRetirementsAnalysisObject<
  | EnhancedRetirementsAnalysis
  | RetirementsAnalysisSingleGroupedBy<EnhancedRetirementsAnalysis>
  | RetirementsAnalysisDoubleGroupedBy<EnhancedRetirementsAnalysis>
> {
  const paramStr = useMemo(() => {
    return {
      filter,
      ...(groupBy ? { groupBy } : {}),
    }
  }, [filter, groupBy])

  const fetcher: Fetcher<
    RetirementsAnalyticsResponse,
    [string, string]
  > = async ([key, params]) => await getRetirementsAnalysisData(key, params)

  const { data, isValidating, error, mutate, isLoading } = useSWR(
    bypass ? null : ['/retirements/analysis', paramStr],
    fetcher,
    {},
  )

  return {
    data,
    isValidating,
    isLoading,
    error,
    mutate,
    isFetching: isValidating && !data,
  }
}
