import type { FC } from 'react'
import type { FooterProps } from './types'
import { FooterAnalytics } from './FooterAnalytics'
import { FooterCountryProfiles } from './FooterCountryProfiles'
import { FooterServer } from './FooterServer'

export const Footer: FC<FooterProps> = ({ appName, className }) => {
  return (
    <FooterServer className={className}>
      {appName === 'analytics' && <FooterAnalytics />}
      {appName === 'country-profiles' && <FooterCountryProfiles />}
    </FooterServer>
  )
}

export default Footer
