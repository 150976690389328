import { type FC, useState, useEffect, useRef } from 'react'
import Map, {
  Popup,
  Source,
  Layer,
  AttributionControl,
  type LayerProps,
  type MapLayerMouseEvent,
  MapRef,
  ViewState,
  type MapboxGeoJSONFeature,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import type { ChoroplethMapProps, PopupState } from './types'
import type { FeatureCollection, Geometry } from 'geojson'
import {
  DEFAULT_ATTRIBUTION,
  DEFAULT_ATTRIBUTION_STYLING,
  DEFAULT_LOGO_POSITION,
  DEFAULT_ZOOM_SETTINGS,
  INITIAL_VIEW_STATE,
} from './constants'
import clsx from 'clsx'
import { MapControls } from '../MapControls'
import { primitives } from '@sylveraio/tailwind'
import { roundForComparison } from './utils'

export const ChoroplethMap: FC<ChoroplethMapProps> = ({
  testId = 'choropleth-map',
  data,
  token,
  mapStyle,
  filter,
  colorScaleForData,
  legendComponent,
  legendWrapperClassName,
  attributionControls,
  tooltipConditionProperty,
  renderPopupContent,
  clickableConditionProperty,
  onClickElement,
}) => {
  const [mapData, setMapData] = useState<FeatureCollection<Geometry>>()
  const [popupInfo, setPopupInfo] = useState<PopupState | null>(null)
  const [cursor, setCursor] = useState<string>('default')
  const mapRef = useRef<MapRef>(null)
  const [viewState, setViewState] = useState<ViewState>(INITIAL_VIEW_STATE)
  const [isResetDisabled, setIsResetDisabled] = useState<boolean>(true)

  useEffect(() => {
    setMapData(data)
  }, [data])

  useEffect(() => {
    const isSameViewport =
      roundForComparison(viewState.latitude, 5) ===
        INITIAL_VIEW_STATE.latitude &&
      roundForComparison(viewState.longitude, 5) ===
        INITIAL_VIEW_STATE.longitude &&
      roundForComparison(viewState.zoom, 2) === INITIAL_VIEW_STATE.zoom

    setIsResetDisabled(isSameViewport)
  }, [viewState, INITIAL_VIEW_STATE])

  const shouldShowPopup = (feature?: MapboxGeoJSONFeature) => {
    const layerIsAreaFill =
      typeof feature?.properties !== 'undefined' &&
      feature?.layer?.id === 'areaFill'
    return (
      layerIsAreaFill &&
      (!tooltipConditionProperty ||
        feature?.properties?.[tooltipConditionProperty])
    )
  }

  const shouldElementBeClickable = (feature?: MapboxGeoJSONFeature) => {
    const layerIsAreaFill =
      typeof feature?.properties !== 'undefined' &&
      feature?.layer?.id === 'areaFill'
    return (
      layerIsAreaFill &&
      (!clickableConditionProperty ||
        feature?.properties?.[clickableConditionProperty])
    )
  }

  const handleOnHover = (event: MapLayerMouseEvent) => {
    const feature = event?.features && event?.features[0]
    setCursor(
      feature && shouldElementBeClickable(feature) && shouldShowPopup(feature)
        ? 'pointer'
        : 'default',
    )
  }

  const zoomIn = () => {
    if (viewState.zoom < DEFAULT_ZOOM_SETTINGS.maxZoom) {
      setViewState((prevState) => ({
        ...prevState,
        zoom: prevState.zoom + 1,
      }))
    }
  }

  const zoomOut = () => {
    if (viewState.zoom > DEFAULT_ZOOM_SETTINGS.minZoom) {
      setViewState((prevState) => ({
        ...prevState,
        zoom: prevState.zoom - 1,
      }))
    }
  }

  const resetZoom = () => {
    setViewState({
      ...INITIAL_VIEW_STATE,
    })
  }

  const handleOnClickElement = (event: MapLayerMouseEvent) => {
    const feature = event?.features && event?.features[0]

    if (shouldElementBeClickable(feature) && shouldShowPopup(feature)) {
      setPopupInfo(null) // Reset popupInfo to ensure state change
      setTimeout(() => {
        if (shouldShowPopup(feature) && feature) {
          setPopupInfo({
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat,
            areaFillData: feature.properties,
          })
        }
      }, 0)

      onClickElement?.(feature?.properties?.['name'])
      setCursor('pointer')
    } else {
      setCursor('default')
    }
  }

  const dataLayer: LayerProps = {
    id: 'areaFill',
    type: 'fill',
    source: 'mapData',
    paint: {
      'fill-color': {
        property: filter,
        stops: colorScaleForData,
      },
      'fill-outline-color': `${primitives['forest'][200]}`,
    },
  }

  const backgroundColorLayer: LayerProps = {
    id: 'background',
    type: 'background',
    paint: {
      'background-color': `${primitives['off-white-3']}`,
    },
  }

  return (
    <Map
      {...viewState}
      minZoom={DEFAULT_ZOOM_SETTINGS.minZoom}
      maxZoom={DEFAULT_ZOOM_SETTINGS.maxZoom}
      onMove={(e) => setViewState(e?.viewState)}
      style={{ width: '100%', height: '100%' }}
      interactiveLayerIds={['areaFill']}
      mapStyle={mapStyle}
      attributionControl={!attributionControls?.showAttribution}
      mapboxAccessToken={token}
      onMouseMove={handleOnHover}
      onClick={handleOnClickElement}
      cursor={cursor}
      logoPosition={attributionControls?.logoPosition || DEFAULT_LOGO_POSITION}
      ref={mapRef}
      reuseMaps
    >
      <Source type="geojson" data={mapData}>
        <Layer {...backgroundColorLayer} />
        <Layer {...dataLayer} />
      </Source>
      {popupInfo && renderPopupContent && (
        <Popup
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeButton={false}
          offset={-5}
        >
          {renderPopupContent(popupInfo.areaFillData)}
        </Popup>
      )}
      {legendComponent && (
        <div
          data-testid={`${testId}-legend`}
          className={clsx('absolute p-2 w-48 bottom-0', legendWrapperClassName)}
        >
          {legendComponent}
        </div>
      )}
      <div
        className={clsx(
          'absolute p-2 right-0',
          attributionControls?.attributionControlWrapperClassName,
        )}
      >
        <MapControls
          onResetMapClick={resetZoom}
          onZoomDecreaseClick={zoomOut}
          onZoomIncreaseClick={zoomIn}
          disableZoomIn={viewState.zoom >= DEFAULT_ZOOM_SETTINGS.maxZoom}
          disableZoomOut={
            roundForComparison(viewState.zoom) <= DEFAULT_ZOOM_SETTINGS.minZoom
          }
          disableResetMap={isResetDisabled}
        />
      </div>
      {attributionControls?.showAttribution && (
        <AttributionControl
          customAttribution={
            attributionControls?.attributionText || DEFAULT_ATTRIBUTION
          }
          style={DEFAULT_ATTRIBUTION_STYLING}
          compact={attributionControls?.useCompactAttribution}
        />
      )}
    </Map>
  )
}

export default ChoroplethMap
