import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type Categories = {
  categories: {
    nodes: Array<{ id: string; name: string; shortName: string }>
  }
}
export const GET_PROJECT_CATEGORIES: TypedDocumentNode<
  Categories,
  {
    first: number
  }
> = gql`
  query ProjectCategories($first: Int!) {
    categories(first: $first) {
      nodes {
        id
        name
        shortName
      }
    }
  }
`
