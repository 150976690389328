import clsx from 'clsx'
import { FC } from 'react'
import type { UnlockButtonProps } from './types'
import { UNLOCK_BUTTON_APP_TEXT } from './constants'

export const UnlockButton: FC<UnlockButtonProps> = ({
  testId = 'unlock-button',
  onClick,
  className,
  condensed = false,
  textSize,
  appOrResourceName,
}) => (
  <button
    data-testid={testId}
    className={clsx(
      ' font-semibold text-sm rounded bg-primary-lime px-3.5 py-1 hover:brightness-95 transition-colors',
      {
        'text-xs rounded': condensed,
        '!text-base rounded-full': !condensed,
      },
      {
        '!text-base': textSize === 'md',
        '!text-sm': textSize === 'sm',
      },
      className,
    )}
    // @ts-ignore
    onClick={onClick}
  >
    {appOrResourceName &&
    typeof UNLOCK_BUTTON_APP_TEXT[appOrResourceName] !== 'undefined'
      ? `Unlock to see ${UNLOCK_BUTTON_APP_TEXT[appOrResourceName]}`
      : condensed
      ? 'Unlock'
      : 'Unlock now'}
  </button>
)
