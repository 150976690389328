import clsx from 'clsx'
import React, { FC } from 'react'
import IconsProps from '../types'

const LogoSearch: FC<IconsProps> = ({ size = 16, className }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('stroke-primary-neon-blue', className)}
  >
    <g>
      <circle cx="9.6" cy="9.6" r="7.6" strokeWidth={2.3} />
      <line
        x1="17.5"
        y1="17.5"
        x2="21.1"
        y2="21.1"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2.3}
      />
      <line
        x1="16.8"
        y1="16.8"
        x2="20.3"
        y2="20.3"
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth={2.3}
      />
    </g>
  </svg>
)

export default LogoSearch
