import type {
  ConditionFn,
  FormPage,
  ValueBase,
  FormData,
} from '../utils/createForm/types'
import Ratings from '@sylveraio/untitled-ui-icons-react/build/esm/Ratings'
import FilterFunnel01 from '@sylveraio/untitled-ui-icons-react/build/esm/FilterFunnel01'
import CubeOutline from '@sylveraio/untitled-ui-icons-react/build/esm/CubeOutline'
import List from '@sylveraio/untitled-ui-icons-react/build/esm/List'
import IntersectCircle from '@sylveraio/untitled-ui-icons-react/build/esm/IntersectCircle'
import UserSquare from '@sylveraio/untitled-ui-icons-react/build/esm/UserSquare'
import HelpCircle from '@sylveraio/untitled-ui-icons-react/build/esm/HelpCircle'
import Calendar from '@sylveraio/untitled-ui-icons-react/build/esm/Calendar'
import Package from '@sylveraio/untitled-ui-icons-react/build/esm/Package'
import Coins04 from '@sylveraio/untitled-ui-icons-react/build/esm/Coins04'
import CoinsHand from '@sylveraio/untitled-ui-icons-react/build/esm/CoinsHand'
import Clock from '@sylveraio/untitled-ui-icons-react/build/esm/Clock'
import Repeat01 from '@sylveraio/untitled-ui-icons-react/build/esm/Repeat01'
import File05 from '@sylveraio/untitled-ui-icons-react/build/esm/File05'
import BarChart05 from '@sylveraio/untitled-ui-icons-react/build/esm/BarChart05'
import { minMaxTupleCheck } from '../utils/createForm/validationRules/minMaxTupleCheck'
import Bank from '@sylveraio/untitled-ui-icons-react/build/esm/Bank'
import MessageSquare02 from '@sylveraio/untitled-ui-icons-react/build/esm/MessageSquare02'
import { extractIdFromBase64Hash, RATINGS_ARRAY } from '@sylveraio/react-utils'
import AlertTriangle from '@sylveraio/untitled-ui-icons-react/build/esm/AlertTriangle'
import Certificate01 from '@sylveraio/untitled-ui-icons-react/build/esm/Certificate01'
import ClipboardCheck from '@sylveraio/untitled-ui-icons-react/build/esm/ClipboardCheck'
import FilterLines from '@sylveraio/untitled-ui-icons-react/build/esm/FilterLines'
import Globe05 from '@sylveraio/untitled-ui-icons-react/build/esm/Globe05'
import MinusCircle from '@sylveraio/untitled-ui-icons-react/build/esm/MinusCircle'
import Activity from '@sylveraio/untitled-ui-icons-react/build/esm/Activity'
import CalendarArrow from '@sylveraio/untitled-ui-icons-react/build/esm/CalendarArrow'
import BankNote02 from '@sylveraio/untitled-ui-icons-react/build/esm/BankNote02'
import { CriteriaTooltip } from './CriteriaTooltip'
import {
  DebtTooltip,
  EquityTooltip,
  OfftakeTooltip,
} from './FinanceAgreementTooltips'

const NO_MIN: ValueBase = { text: 'No min', value: 'no-min' }
const NO_MAX: ValueBase = { text: 'No max', value: 'no-max' }
const QTY_VALUES = [
  { value: '5000', text: '5,000' },
  { value: '10000', text: '10,000' },
  { value: '15000', text: '15,000' },
  { value: '20000', text: '20,000' },
  { value: '25000', text: '25,000' },
  { value: '50000', text: '50,000' },
  { value: '100000', text: '100,000' },
  { value: '200000', text: '200,000' },
]
const FORWARD_SUPPLY_YEAR = [...Array(50)].map((v, i) => {
  const currentYear = new Date().getFullYear()
  return {
    text: (currentYear + i).toString(),
    value: currentYear + i,
  }
})
function getAcronym(registry: string): string {
  switch (registry) {
    case 'American Carbon Registry':
      return 'ACR'
    case 'Climate Action Reserve':
      return 'CAR'
    case 'Emissions Reduction Fund':
      return 'ERF'
    case 'International Carbon Registry':
      return 'ICR'
    case 'Clean Development Mechanism':
      return 'CDM'
    case 'BioCarbon Standard':
      return 'BioCarbon'
    default:
      return registry
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const searchFn = () => {}
export const isProjectBased: ConditionFn = (f) =>
  f['supplyRequestType'] === 'project'
export const isEarly: ConditionFn = (f) => f['supplyRequestType'] === 'early'
export const isCriteria: ConditionFn = (f) =>
  f['supplyRequestType'] === 'criteria'

export const REQUEST_DETAILS: FormPage = {
  title: 'Request details',
  fields: [
    {
      key: 'supplyRequestType',
      type: 'radio',
      title: 'Request type:',
      label: 'Request type',
      icon: {
        component: FilterFunnel01,
        show: 'review',
      },
      values: [
        {
          value: 'project',
          text: 'Specific project(s)',
          icon: {
            component: Ratings,
            show: 'form',
          },
        },
        {
          value: 'criteria',
          text: 'Projects that meet my criteria',
          icon: {
            component: FilterFunnel01,
            show: 'form',
          },
          tooltip: <CriteriaTooltip />,
        },
        {
          value: 'early',
          text: 'Early-stage pre-issuance projects that meet my criteria',
          icon: {
            component: CubeOutline,
            show: 'form',
          },
          description: '',
        },
      ],
      required: true,
    },
    {
      key: 'supplierTypes',
      type: 'checkbox',
      title: 'Receive quotes from:',
      label: 'Supplier types',
      description: 'Select all that apply',
      icon: {
        component: UserSquare,
      },
      values: [
        { value: 'developer', text: 'Project developer' },
        { value: 'available', text: 'Vendors' },
      ],
      required: true,
    },
    {
      key: 'includeProjects',
      type: 'tags',
      title: 'Select all projects you want quotes for',
      description: `Enter up to 10 projects you'd like offers for`,
      placeholderText: 'Search projects',
      label: 'Projects',
      icon: {
        component: List,
      },
      condition: isProjectBased,
      searchFn,
      opts: [],
      limitSelected: 10,
      limitDisplayed: 5,
      required: true,
      stringifyTagFn({ text }) {
        const [registryInfo] = text.split(':')
        const [registry, id] = registryInfo.split(',').map((t) => t.trim())
        return `${getAcronym(registry)}, ${id}`
      },
    },
    {
      key: 'useSimilarProjects',
      type: 'radio',
      title: 'Are you open to quotes from similar projects?',
      label: 'Similar projects',
      icon: {
        component: IntersectCircle,
      },
      values: [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' },
      ],
      condition: isProjectBased,
      required: true,
      isBoolean: true,
    },
    {
      key: 'projectStages',
      type: 'checkbox',
      title: 'Project stage preference:',
      label: 'Project stage',
      description: 'Select all that apply',
      icon: {
        component: File05,
      },
      values: [
        {
          value: 'feasibility',
          text: 'Feasibility study',
          description: 'Project selection and feasibility',
        },
        {
          value: 'pre-validation',
          text: 'Pre-validation',
          description: 'Draft project documentation',
        },
        {
          value: 'pre-monitoring',
          text: 'Pre-monitoring',
          description: 'Completed project documentation',
        },
        {
          value: 'pre-verification',
          text: 'Pre-verification',
          description: 'Full project documentation & monitoring',
        },
      ],
      condition: isEarly,
      required: true,
    },
  ],
}

export const SET_CRITERIA: FormPage = {
  title: 'Set your criteria',
  condition: [isCriteria, isEarly],
  fields: [
    {
      key: 'includeProjectTypes',
      type: 'checkbox',
      title: `Project type preference:`,
      label: 'Project types',
      icon: {
        component: FilterLines,
      },
      values: [],
      opts: [],
      placeholderText: 'Other...',
      searchFn,
      transformValue(v) {
        return (v as Array<string>).map(extractIdFromBase64Hash)
      },
    },
    {
      key: 'includeCountries',
      type: 'tags',
      title: `Location preference:`,
      label: 'Location preference',
      icon: {
        component: Globe05,
      },
      placeholderText: 'Search countries',
      opts: [],
      searchFn,
      condition: [isCriteria, isEarly],
      transformValue(v) {
        return (v as Array<string>).map(extractIdFromBase64Hash)
      },
    },
    {
      key: 'excludeCountries',
      type: 'tags',
      title: `Locations to exclude:`,
      label: 'Excluded locations',
      icon: {
        component: MinusCircle,
      },
      placeholderText: 'Search countries',
      opts: [],
      searchFn,
      condition: [isCriteria, isEarly],
      transformValue(v) {
        return (v as Array<string>).map(extractIdFromBase64Hash)
      },
    },
    {
      key: 'excludeActivities',
      type: 'text-area',
      title: `Are there any project activities within the project type you would not want to invest in?`,
      description:
        'For example, for ARR you might be looking for native reforestation only and not plantation.',
      label: 'Activity types',
      icon: {
        component: Activity,
      },
      limit: 2000,
      placeholderText: 'Tell us more...',
      condition: isEarly,
    },
    {
      key: 'includeAccreditations',
      type: 'tags',
      title: `Accreditation preference:`,
      label: 'Accreditations',
      icon: {
        component: Certificate01,
      },
      placeholderText: 'Search accreditations',
      opts: [],
      searchFn,
      transformValue(v) {
        return (v as Array<string>).map(extractIdFromBase64Hash)
      },
    },
    {
      key: 'includeRegistries',
      type: 'tags',
      title: `Registry preference:`,
      label: 'Registries',
      icon: {
        component: ClipboardCheck,
      },
      placeholderText: 'Search registries',
      opts: [],
      searchFn,
      transformValue(v) {
        return (v as Array<string>).map(extractIdFromBase64Hash)
      },
    },
    {
      key: 'includeRatings',
      type: 'checkbox',
      title: `Sylvera Rating preference:`,
      label: 'Sylvera Ratings',
      icon: {
        component: Ratings,
      },
      values: [...RATINGS_ARRAY]
        .map((rating) => ({ value: rating, text: rating }))
        .reverse(),
      condition: isCriteria,
    },
    {
      key: 'includeScreenings',
      type: 'checkbox',
      title: 'Sylvera Screening preference:',
      label: 'Sylvera Screenings',
      icon: {
        component: AlertTriangle,
      },
      values: [
        {
          text: 'Low risk',
          value: 'low-risk',
        },
        {
          text: 'Mixed risk',
          value: 'mixed-risk',
        },
        {
          text: 'High risk',
          value: 'high-risk',
        },
      ],
      condition: isCriteria,
    },
  ],
}

export const VINTAGES_AND_VOLUMES: FormPage = {
  title: 'Vintages & volumes',
  fields: [
    {
      key: 'vintageYear',
      type: 'tuple',
      title: 'Vintage preference:',
      description: 'If you prefer credits 2016+, select the min year as 2016.',
      label: 'Vintage preference',
      icon: {
        component: Calendar,
      },
      fields: [
        {
          label: 'Min year',
          values: [
            NO_MIN,
            ...[...Array(50)].map((v, i) => ({
              text: (2016 + i).toString(),
              value: (2016 + i).toString(),
            })),
          ],
          defaultValue: NO_MIN,
          suffix: 'Min',
        },
        {
          label: 'Max year',
          values: [
            NO_MAX,
            ...[...Array(50)].map((v, i) => ({
              text: (2016 + i).toString(),
              value: (2016 + i).toString(),
            })),
          ],
          defaultValue: NO_MAX,
          suffix: 'Max',
        },
      ],
      validationFn: minMaxTupleCheck,
      required: true,
    },
    {
      key: 'supplyVolume',
      type: 'tuple',
      title: 'Volume required:',
      label: 'Volume required',
      description: `By selecting a range, suppliers will be able to share volume-based discounts, for higher volumes.`,
      icon: {
        component: Package,
      },
      fields: [
        {
          label: 'Min volume',
          values: [NO_MIN, ...QTY_VALUES],
          defaultValue: NO_MIN,
          suffix: 'Min',
        },
        {
          label: 'Max volume',
          values: [NO_MAX, ...QTY_VALUES],
          defaultValue: NO_MAX,
          suffix: 'Max',
        },
      ],
      condition: [isProjectBased, isCriteria],
      validationFn: minMaxTupleCheck,
      required: true,
    },
    {
      key: 'volumeInterestedIn',
      type: 'input',
      title: 'Volume required:',
      label: 'Credit volume',
      description: `Use the additional comments section below to add more context to the specific breakdown you're interested in.`,
      icon: {
        component: BarChart05,
      },
      condition: isEarly,
      withSelect: {
        key: 'volumeInterestedInUnit',
        values: [
          { text: 'Per year', value: 'year' },
          { text: 'Total', value: 'total' },
        ],
        defaultValue: { text: 'Per year', value: 'year' },
      },
      transformValue(v) {
        return typeof v === 'string' ? parseInt(v) : v
      },
      required: true,
    },
    {
      key: 'priceCap',
      type: 'input',
      title: 'Do you have a price cap?',
      label: 'Price cap',
      icon: {
        component: Coins04,
      },
      prefix: '$',
      suffix: 'Per credit',
      toStringFn(value) {
        return `$${value} per ton`
      },
      transformValue: (v) => parseInt(v as string),
    },
    {
      key: 'vintagesVolumesComments',
      type: 'text-area',
      title: 'Additional comments',
      label: 'Comment',
      limit: 2000,
      icon: {
        component: MessageSquare02,
      },
      condition: isEarly,
    },
  ],
}

export const ADDITIONAL_REQUEST_INFORMATION: FormPage = {
  title: 'Additional request information',
  fields: [
    {
      key: 'quoteType',
      type: 'checkbox',
      title: 'Type of contract:',
      label: 'Contract type',
      icon: {
        component: HelpCircle,
      },
      values: [
        {
          value: 'spot',
          text: 'Spot',
        },
        {
          value: 'forward',
          text: 'Forward',
        },
      ],
      required: true,
    },
    {
      key: 'forwardSupplyDeliveryYear',
      label: 'Delivery date',
      title: 'Expected delivery date:',
      description:
        'If you selected forward contract, this is the date you expect credits to be delivered.',
      type: 'select',
      values: FORWARD_SUPPLY_YEAR,
      icon: {
        component: CalendarArrow,
      },
      required: true,
      defaultValue: FORWARD_SUPPLY_YEAR[0],
      condition: (curForm) =>
        ((curForm?.['quoteType'] as Array<string>) || []).includes('forward'),
    },
    {
      key: 'intendedPurchaseDate',
      type: 'radio',
      title: 'Procurement timeline:',
      label: 'Procurement timeline',
      icon: {
        component: Clock,
      },
      values: [
        {
          value: '<2w',
          text: 'Less than 2 weeks',
        },
        {
          value: '2-4w',
          text: '2-4 weeks',
        },
        {
          value: '5-8w',
          text: '5-8 weeks',
        },
        {
          value: '9-12w',
          text: '9-12 weeks',
        },
        {
          value: '3+m',
          text: 'More than 3 months',
        },
      ],
      required: true,
      condition: [isCriteria, isProjectBased],
    },
    {
      key: 'intendedPurchaseDateEarly',
      type: 'radio',
      title: 'When do you plan to purchase credits?',
      label: 'Investment timeline',
      icon: {
        component: Clock,
      },
      values: [
        {
          value: '0-3',
          text: '0-3 months',
        },
        {
          value: '3-6',
          text: '3-6 months',
        },
        {
          value: '6-9',
          text: '6-9 months',
        },
        {
          value: '9-12',
          text: '9-12 months',
        },
        {
          value: '12+',
          text: '12 months+',
        },
      ],
      required: true,
      condition: isEarly,
    },
    {
      key: 'intendedPurchaseFrequency',
      type: 'radio',
      title: 'How often do you plan to purchase credits?',
      label: 'Purchase frequency',
      icon: {
        component: Repeat01,
      },
      values: [
        {
          text: 'Monthly',
          value: 'monthly',
        },
        {
          text: 'Quarterly',
          value: 'quarterly',
        },
        { text: 'Annually', value: 'annually' },
        { text: 'This is a one-off purchase', value: 'one-off' },
      ],
      condition: [isCriteria, isProjectBased],
    },
    {
      key: 'financingAgreement',
      type: 'radio',
      title: 'Financing agreement preference:',
      label: 'Financing agreement',
      values: [
        {
          text: 'Offtake',
          value: 'offtake',
          tooltip: <OfftakeTooltip />,
        },
        {
          text: 'Equity',
          value: 'equity',
          tooltip: <EquityTooltip />,
        },
        {
          text: 'Debt',
          value: 'debt',
          tooltip: <DebtTooltip />,
        },
      ],
      icon: {
        component: Bank,
      },
      condition: isEarly,
      required: true,
    },
    {
      key: 'offtakePaymentType',
      type: 'radio',
      title: 'What is your preferred payment method?',
      label: 'Payment type',
      icon: {
        component: BankNote02,
        show: 'review',
      },
      values: [
        { text: 'Pre-payment', value: 'pre-payment' },
        { text: 'Payment at delivery', value: 'payment-at-delivery' },
      ],
      condition: (currForm) =>
        ((currForm?.['financingAgreement'] as Array<string>) || []).includes(
          'offtake',
        ),
      required: true,
    },
    {
      key: 'reasonForPurchase',
      type: 'radio',
      title: 'Why are you buying these credits?',
      label: 'Intention',
      icon: {
        component: CoinsHand,
      },
      values: [
        { text: 'Buy to retire', value: 'retire' },
        { text: 'Buy to resell', value: 'resell' },
        { text: 'Other', value: 'other' },
      ],
      condition: [isEarly, isCriteria, isProjectBased],
    },
  ],
}

export const SUBMIT: FormPage = {
  title: 'Review and submit your request',
  fields: [
    {
      type: 'submit',
      onSubmit: (f) => console.log(f),
    },
    {
      key: 'remainAnonymous',
      type: 'radio',
      title: 'Would you like to be anonymous to suppliers?',
      values: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      isBoolean: true,
      required: true,
    },
    {
      key: 'otherInfo',
      type: 'text-area',
      title: 'Any other info?',
      description:
        'Let us know if there are any other specific requirements you have.',
      placeholderText: 'Tell us more...',
      limit: 2000,
    },
  ],
}

export const DEFAULT_VALUES: FormData = {
  remainAnonymous: {
    text: 'Yes',
    value: 'yes',
  },
}
