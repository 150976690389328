/* eslint-disable @typescript-eslint/no-explicit-any */
import { addBreadcrumb } from '@sentry/minimal'
import {
  escapeFromRight,
  wrapObjectValues,
  captureException,
} from '@sylveraio/react-utils'
import { transformUserData } from './transformers/userDetails'
import { transformUserPreferences } from './transformers/preferencesDetails'
import type {
  UserPreferenceKey,
  UserPreferenceObj,
} from './transformers/preferencesDetails/types'
import type { UserDetails } from 'types'
import { transformRetirementsAnalysis } from './transformers/retirementsAnalysis'
import QueryString from 'qs'
import type { FetcherResponse } from 'swr/dist/_internal'
import type { RetirementsAnalyticsResponse } from './types'
import { fetchAndDeserialize } from '../fetchAndDeserialize'

export const getUserDetails = async (): Promise<UserDetails> => {
  let result: UserDetails = transformUserData()
  let userResult: Api.User | undefined
  let organisationId: string | undefined
  let organisationResult: Api.OrganisationInbound | undefined

  const relationships = {
    organisation: {
      valueForRelationship(relationship: { id: string }) {
        return {
          id: relationship.id,
        }
      },
    },
  }

  try {
    userResult = await fetchAndDeserialize(
      '/users/me',
      undefined,
      false,
      relationships,
    )

    if (userResult) organisationId = userResult.organisation?.id

    addBreadcrumb({
      message: 'Org',
      data: {
        organisationId,
      },
    })
  } catch (e) {
    captureException(e, 'data - services - getUserDetails - userResult')
  }

  if (organisationId) {
    const organisationsUrl = `/organisations/${organisationId}`

    try {
      organisationResult = await fetchAndDeserialize(
        organisationsUrl,
        undefined,
        false,
      )
    } catch (e) {
      captureException(
        e,
        'data - services - getUserDetails - organisationResult',
      )
    }
  }

  result = transformUserData(userResult, organisationResult)

  return result
}

export const getUserPreference = async <
  InbUserPreferenceKey extends UserPreferenceKey,
>(
  key: string,
  preferenceKey: UserPreferenceKey,
): Promise<
  FetcherResponse<UserPreferenceObj<InbUserPreferenceKey> | undefined>
> => {
  try {
    const result: UserPreferenceObj<InbUserPreferenceKey> =
      await fetchAndDeserialize(
        `/users/me/preferences/${preferenceKey}`,
        transformUserPreferences,
        true,
      )
    return result
  } catch (e) {
    if (e === 404) {
      addBreadcrumb({
        message: 'Preference key does not exist',
        data: {
          key: preferenceKey,
        },
      })
    } else {
      addBreadcrumb({
        message: 'Error fetching preferences',
        data: {
          key: preferenceKey,
        },
      })
      captureException(e, 'data - services - getUserPreference')
    }
  }
  return undefined
}

export const getRetirementsAnalysisData = async (
  key: string,
  params: string,
): Promise<FetcherResponse<RetirementsAnalyticsResponse>> => {
  let result: RetirementsAnalyticsResponse = {
    analysis: {},
  }

  let newParams = ''

  if (typeof params === 'object') {
    newParams = QueryString.stringify(
      escapeFromRight(wrapObjectValues(params, 'filter')),
      {
        arrayFormat: 'comma',
        encode: false,
        indices: false,
      },
    )
  }

  try {
    const response: any = await fetchAndDeserialize(
      `/retirements/analysis${newParams !== '' ? `?${newParams}` : newParams}`,
      undefined,
      true,
      undefined,
      'GET',
      undefined,
      true,
    )

    result = await transformRetirementsAnalysis(response, params)
  } catch (e) {
    captureException(e, 'data - services - getRetirementsAnalysisData')
  }

  return result
}
