import * as PreferencesHook from '../usePreferences/usePreferences'
import type { TransformUserPreference } from '../services/transformers/preferencesDetails'

export function mockUsePreferencesReturnValue(
  data?: Array<TransformUserPreference> | TransformUserPreference,
) {
  const upsertPreferenceSpy = jest.fn()
  const deletePreferenceSpy = jest.fn()

  jest.spyOn(PreferencesHook, 'usePreferences').mockReturnValue({
    data: data as TransformUserPreference,
    isValidating: false,
    isLoading: false,
    isFetching: true,
    upsertPreference: upsertPreferenceSpy,
    deletePreference: deletePreferenceSpy,
    mutate: () => Promise.resolve(undefined),
    error: {},
  })

  return {
    upsertPreferenceSpy,
    deletePreferenceSpy,
  }
}
