import { UnratedProjectsEntry } from 'types'

export type CompanySelection = {
  id: string
}

export type Acknowledge = {
  acknowledged: boolean
}

export type TilesOrder = {
  tiles: Array<string>
}

export interface EmailNotifications {
  sendEmails?: boolean
}

export type ProjectList = Record<string, string>

export enum ProjectAssessmentType {
  Screening = 'screening',
  Rating = 'rating',
}

export enum ProjectInterestType {
  // 'interest' used here to maintain legacy value
  ScreeningFramework = 'interest',
  RatingFramework = 'rating-framework',
}

export type ProjectRequestType = ProjectAssessmentType | ProjectInterestType

export type ProjectRequest = Record<
  NonNullable<UnratedProjectsEntry['id']>,
  ProjectRequestType
>

export type SelectedPolicyFilters = {
  policyTags?: Array<string>
  countries?: Array<string>
  projectTypes?: Array<string>
}

interface UserModalsPreferences {
  disableScreenInfoModal: boolean
}

type HideCTSFAQ = {
  hide: boolean
}

export interface UserPreferences {
  cca_defaultCompany: CompanySelection
  cca_dataDisclaimer: Acknowledge
  dashboard_tiles_order: TilesOrder
  emailNotifications: EmailNotifications
  projectShortlist: ProjectList
  unratedProjectShortlist: ProjectList
  requestedForScreening: ProjectList
  projectRequestedStatus: ProjectRequest
  policy_article_filters: SelectedPolicyFilters
  marketHeadlinesFilters: { [key: string]: boolean }
  modals: UserModalsPreferences
  hide_cts_faq: HideCTSFAQ
}

export type UserPreferenceKey = keyof UserPreferences
export interface TransformUserPreference {
  id: string
  key: UserPreferenceKey
  value: UserPreferences[UserPreferenceKey]
}

export interface UserPreferenceObj<
  InbUserPreferenceKey extends UserPreferenceKey,
> {
  id: string
  key: InbUserPreferenceKey
  value: UserPreferences[InbUserPreferenceKey]
}
export type UserPreferencesObj = Array<TransformUserPreference>
