import { KVPairs } from 'types'
import truncate from 'lodash/truncate'

/**
 * Returns a specific filter label name
 * @param name - the filter name
 * @param baseDataArr - the base array to lookup
 * @param arr - the array of selected items
 * @param defaultValue - the default value to be shown if there are no selected items
 * @param truncationLength - the number of characters to truncate the filter label
 * @returns a filter label name
 */
export function getTruncatedFilterLabelName(
  name: string,
  baseDataArr: KVPairs,
  arr: Array<string> = [],
  defaultValue = 'All',
  truncationLength = 15,
): string {
  if (arr.length === 0) return `${name}: ${defaultValue}`
  if (arr.length === 1) {
    const filterSelectionName = baseDataArr?.find(
      ({ id }) => id === arr[0],
    )?.value
    return filterSelectionName
      ? truncate(`${name}: ${filterSelectionName}`, {
          length: truncationLength,
        })
      : name
  }
  return `${name}: Custom`
}
