import React, {
  FC,
  BaseSyntheticEvent,
  useState,
  ChangeEvent,
  useContext,
} from 'react'
import { useRouter } from 'next/router'
import { getAuthError, routes } from '@sylveraio/auth-utils'
import {
  Input,
  Button,
  Form,
  FormHeading,
  FormSubheading,
} from '@sylveraio/react-ui'
import { getInputError } from '@sylveraio/react-utils'
import ResendCode from './components/ResendCode'
import constants from './VerifyPasscodeForm.constants'

import type {
  VerifyPasscodeFormProps,
  VerifyPasscodeFormInputNameTypes,
  VerifyPasscodeFormKeys,
} from './types'
import { UserContext } from '../contexts'

export const VerifyPasscodeForm: FC<VerifyPasscodeFormProps> = ({
  testId = 'verify-passcode-form',
}) => {
  const router = useRouter()
  const { email } = useContext(UserContext)
  const [passcode, setPasscode] = useState<string>('')
  const [authError, setAuthError] = useState<Error>()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const onSubmit = (
    data: VerifyPasscodeFormKeys,
    e: BaseSyntheticEvent<object, any, any> | undefined,
  ) => {
    e?.preventDefault()
    setSubmitting(true)

    if (!email) {
      setAuthError({
        message: 'User is not authorised to use this form.',
      } as Error)
      setSubmitting(false)
      return
    }

    try {
      router.push({
        pathname: routes['setPassword'],
        query: {
          passcode: data['verify-passcode'],
        },
      })
    } finally {
      setSubmitting(false)
    }
  }

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback?: () => void,
  ) => {
    switch (e.currentTarget.name) {
      case 'verify-passcode':
        setPasscode(e.currentTarget.value)
        break
      default:
        break
    }
    callback?.()
  }

  return (
    <Form<VerifyPasscodeFormKeys> testId={testId} onSubmit={onSubmit}>
      {({ register, formState, clearErrors }) => (
        <>
          <FormHeading
            testId={`${testId}-heading`}
            className="mb-4 text-center"
          >
            We&apos;ve sent a code to your email
          </FormHeading>

          <FormSubheading testId={`${testId}-subheading`} className="mb-5">
            Enter the 6 digit verification code sent to your email.
          </FormSubheading>

          <div className="relative flex flex-col w-full gap-y-6">
            <Input
              testId={`${testId}-passcode-input`}
              type="text"
              label="6 digit code"
              hideBorder
              error={
                authError
                  ? getAuthError(authError)?.context
                  : getInputError<
                      VerifyPasscodeFormKeys,
                      VerifyPasscodeFormInputNameTypes
                    >(formState.errors, 'verify-passcode')
              }
              {...register('verify-passcode', {
                value: passcode,
                required: 'Please enter a 6 digit code',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter a 6 digit code',
                },
                minLength: {
                  value: 6,
                  message: 'Please enter a 6 digit code',
                },
                maxLength: {
                  value: 6,
                  message: 'Please enter a 6 digit code',
                },
                onChange: (e) => onInputChange(e, clearErrors),
              })}
            />
          </div>

          <div className="flex flex-col mt-5 mb-14 gap-y-5">
            <ResendCode />
            <Button
              testId={`${testId}-sign-in-button`}
              type="submit"
              className="!w-[240px] !h-12 mx-auto !text-xl font-medium"
              spinner={submitting}
              solid
              color="lime"
              shape="pill"
            >
              Submit
            </Button>
          </div>

          <div className="text-center text-default-on-dark">
            {constants.noEmailInbox}
          </div>
        </>
      )}
    </Form>
  )
}

export default VerifyPasscodeForm
