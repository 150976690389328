import { FC } from 'react'
import clsx from 'clsx'
import { ProgressRingDefault } from './components/ProgressRingDefault'
import { ProgressRingOuterRing } from './components/ProgressRingOuterRing'
import { ProgressRingSegmented } from './components/ProgressRingSegmented'
import { ProgressRingNotApplicable } from './components/ProgressRingNotApplicable'
import { ProgressRingLabel } from './components/ProgressRingLabel'
import type { ProgressRingProps } from './types'
import { ProgressBarGradient } from './components/ProgressBarGradient'

export const ProgressRing: FC<ProgressRingProps> = ({
  testId = 'progress-ring',
  type = 'default',
  size = 'sm',
  wrapperClassName,
  value,
  label,
  labelPosition,
  labelClassName,
  innerLabel,
  trailColor,
  stroke = 8,
  removeRatingOuterRing,
  withTooltip,
  notApplicable,
  segmentColor,
  outerRingProvisionalVariant,
  progressRingId,
  onClick,
}) => {
  const getProgressRingComponent = () => {
    if (notApplicable) {
      return (
        <ProgressRingNotApplicable
          testId={`${testId}-not-applicable`}
          size={size}
        />
      )
    }

    switch (type) {
      case 'segmented':
        return (
          <ProgressRingSegmented
            testId={`${testId}-segmented`}
            size={size}
            value={value}
            innerLabel={innerLabel}
            trailColor={trailColor}
            segmentColor={segmentColor}
            onClick={onClick}
          />
        )
      case 'outer-ring':
        return (
          <ProgressRingOuterRing
            testId={`${testId}-outer-ring`}
            size={size}
            value={value}
            innerLabel={innerLabel}
            onClick={onClick}
            removeRatingOuterRing={removeRatingOuterRing}
            outerRingProvisionalVariant={outerRingProvisionalVariant}
          />
        )
      default:
        return (
          <ProgressRingDefault
            testId={`${testId}-default`}
            size={size}
            value={value}
            innerLabel={innerLabel}
            stroke={stroke}
            trailColor={trailColor}
            onClick={onClick}
          />
        )
    }
  }

  return (
    <>
      <ProgressBarGradient />
      <div
        data-testid={testId}
        className={clsx(
          'inline-flex flex-col items-center w-min',
          wrapperClassName,
        )}
      >
        {labelPosition === 'top' && (
          <ProgressRingLabel
            label={label}
            labelClassName={labelClassName}
            value={value}
            size={size}
            withTooltip={withTooltip}
            progressRingId={progressRingId}
          />
        )}
        {getProgressRingComponent()}
        {labelPosition === 'bottom' && (
          <ProgressRingLabel
            label={label}
            labelClassName={labelClassName}
            value={value}
            size={size}
            withTooltip={withTooltip}
            progressRingId={progressRingId}
          />
        )}
      </div>
    </>
  )
}

export default ProgressRing
