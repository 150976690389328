'use client'

import { addBreadcrumb } from '@sentry/minimal'
import { useCallback, useEffect } from 'react'
import { useRouter, usePathname } from 'next/navigation'
import { signOut } from '../../signOut'
import { getBearerToken } from '../../getBearerToken'
import { checkUserSession } from '../../checkUserSession'

export function useAuth(
  { signInUrl, navigateTo } = { signInUrl: '/sign-in', navigateTo: '/' },
): {
  checkUserSession(): Promise<boolean>
} {
  const { push } = useRouter()
  const asPath = usePathname()

  // Hydrate access and refresh from cookie, then check for a valid access
  const accessCheckFlow = useCallback(async () => {
    const bearerToken = await getBearerToken()
    if (!bearerToken) {
      addBreadcrumb({
        message: 'Automatically logging out',
      })
      signOut()
      return
    }

    if (asPath === signInUrl) push(navigateTo)
  }, [navigateTo, asPath])

  // Check if we have a valid token already on load
  useEffect(() => {
    accessCheckFlow()
  }, [])

  return {
    checkUserSession,
  }
}
