import type { FC } from 'react'
import type { GradientLegendProps } from './types'
import { Typography, TypographyLegacy } from '@sylveraio/design-system'

export const GradientLegend: FC<GradientLegendProps> = ({
  colors,
  lowestValue,
  highestValue,
  title,
}) => {
  const gradientStops = colors.join(', ')
  const legendLabelStyle = 'text-subtle-on-dark -translate-y-1'

  return (
    <div className="flex flex-col space-y-2 justify-center px-3 h-20 bg-medium rounded-md border-solid border border-default">
      <Typography size="xs" weight="medium" className="mt-2">
        {title}
      </Typography>
      <div
        className="rounded-full h-2"
        style={{ background: `linear-gradient(to right, ${gradientStops})` }}
      />
      <div className="flex flex-row justify-between">
        <Typography size="xs" weight="medium" className={legendLabelStyle}>
          {lowestValue}
        </Typography>
        <Typography size="xs" weight="medium" className={legendLabelStyle}>
          {highestValue}
        </Typography>
      </div>
    </div>
  )
}

export default GradientLegend
