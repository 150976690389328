import { FieldErrors } from 'react-hook-form'

export function getInputError<
  I extends { [key: string]: string },
  T extends keyof I,
>(errors: FieldErrors<I>, name: T) {
  return Object.keys(errors)[0] === name
    ? errors[name as string]?.message
    : undefined
}

export default getInputError
