import { FC, useState } from 'react'

import { Tooltip } from '@sylveraio/react-ui'
import type { RequirementsTooltipProps } from './types'

export const RequirementsTooltip: FC<RequirementsTooltipProps> = ({
  testId = 'requirements-tooltip',
  content,
  children,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLSpanElement | null>(null)

  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div data-testid={`${testId}-wrapper`} className="inline-flex mb-8">
      <span
        data-testid={`${testId}-reference`}
        className="text-lg font-medium leading-normal text-center cursor-default text-content-discovery-default"
        ref={(ref) => setReferenceElement(ref)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </span>

      <Tooltip
        testId={testId}
        referenceElement={referenceElement}
        show={showTooltip}
        placement="bottom"
        offset={[0, 10]}
        className="relative z-50 px-5 py-3 rounded-lg shadow-lg bg-opacity-90 w-60"
      >
        {content}
      </Tooltip>
    </div>
  )
}

export default RequirementsTooltip
