import { FC } from 'react'
import { Button } from '../Button'
import { DEFAULT_ROLE_NAME } from './constants'
import { SidebarToggleButtonProps } from './types'
import Menu01 from '@sylveraio/untitled-ui-icons-react/build/esm/Menu01'
import XClose from '@sylveraio/untitled-ui-icons-react/build/esm/XClose'

export const SidebarToggleButton: FC<SidebarToggleButtonProps> = ({
  roleName = DEFAULT_ROLE_NAME,
  isOpen,
  onClick,
  ...buttonProps
}) => (
  <Button
    shape="square"
    {...buttonProps}
    data-role={roleName}
    onClick={onClick}
  >
    {isOpen ? <XClose className="w-5 h-5" /> : <Menu01 className="w-5 h-5" />}
  </Button>
)
