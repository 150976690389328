'use client'

import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'

const s3Client = new S3Client({
  region: process.env['NEXT_PUBLIC_AMP_REGION'],
})

export function getStatusAndMsgFromS3Error(err: any): {
  code: number
  message: string
} {
  if (typeof err === 'string') {
    return {
      code: 500,
      message: err,
    }
  }

  const { httpStatusCode } = err?.['$metadata'] || {}
  const { Code, Key } = err || {}

  if (httpStatusCode && Code && Key) {
    return {
      code: httpStatusCode,
      message: `${Code} - ${Key}`,
    }
  }

  return {
    code: 500,
    message: 'Server error',
  }
}

export async function getS3Object<T = unknown>(
  bucketName: string,
  key: string,
): Promise<T> {
  const command = new GetObjectCommand({
    Bucket: bucketName,
    Key: key,
  })

  const response = await s3Client.send(command)

  const str = await response.Body?.transformToString()

  return JSON.parse(str ?? '')
}
