import clsx from 'clsx'
import type { ButtonShape } from '../../types'

/**
 * Get the shape of a button
 * @param {ButtonShape} shape - The shape of the button
 * @returns {string} - The class name for the button shape
 */
export function getButtonShape(shape: ButtonShape) {
  return clsx({
    'rounded-full': shape === 'pill',
    rounded: shape === 'rounded',
  })
}
