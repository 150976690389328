import type { FC } from 'react'
import clsx from 'clsx'
import type { AccordionProps } from './types'
import { AccordionSummary } from './Components/AccordionSummary'
import ChevronDown from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronDown'

export const Accordion: FC<AccordionProps> = ({
  wrapperClassName,
  testId = 'accordion',
  isExpanded,
  handleExpand,
  handleNavigate,
  title,
  secondaryText,
  iconClassName,
  children,
  titleClassName,
  summaryInfoTooltipLabel,
}) => {
  const handleExpandAndNavigate = () => {
    handleExpand?.()
    handleNavigate?.()
  }
  return (
    <div
      data-testid={testId}
      className={clsx('flex flex-col', wrapperClassName)}
    >
      <div
        className="flex cursor-pointer flex-row items-center justify-between"
        onClick={(ev) => {
          ev.stopPropagation()
          handleExpandAndNavigate()
        }}
        onKeyDown={(ev) => {
          ev.stopPropagation()
          handleExpandAndNavigate()
        }}
        role="button"
        tabIndex={0}
      >
        <AccordionSummary
          isExpanded={isExpanded}
          title={title}
          secondaryText={secondaryText}
          className={titleClassName}
          infoTooltipLabel={summaryInfoTooltipLabel}
        />
        <div
          onClick={(ev) => {
            ev.stopPropagation()
            handleExpand?.()
          }}
          onKeyDown={(ev) => {
            ev.stopPropagation()
            handleExpand?.()
          }}
          role="button"
          tabIndex={0}
        >
          <ChevronDown
            data-testid={`${testId}-chevron-icon`}
            className={clsx('w-4 h-4 transition-transform', iconClassName, {
              'transform rotate-180': isExpanded,
            })}
          />
        </div>
      </div>
      <div
        data-testid={`${testId}-details`}
        className={clsx('overflow-hidden ease-in-out duration-200', {
          'max-h-0': !isExpanded,
          'pt-1': isExpanded,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
