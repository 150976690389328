import type { FC, ForwardedRef } from 'react'
import { useEffect, useState, forwardRef, useRef } from 'react'
import {
  useIntersectionObserver,
  useUniqueObject,
} from '@sylveraio/react-utils'
import { Pill } from '../Pill'
import { Spinner } from '@sylveraio/design-system'
import type {
  InfiniteScrollListItemsProps,
  ListItemsProps,
  ListWithInfiniteScrollProps,
  TextListItemProps,
} from './types'
import clsx from 'clsx'

export const TextListItem = forwardRef<HTMLDivElement, TextListItemProps>(
  ({ value }, ref) => (
    <div ref={ref} className="text-xs text-default">
      {value}
    </div>
  ),
)

export const ListItems = forwardRef<
  HTMLDivElement | HTMLButtonElement,
  ListItemsProps
>(({ listItemVariant, id, value, isSelected, onClick }, ref) =>
  listItemVariant === 'pill' ? (
    <Pill
      name={'listItem'}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      key={id}
      // @ts-ignore
      onClick={onClick}
      color="off-white-3"
      className="mb-2 h-5 w-fit !text-xs"
      selected={isSelected}
      value={id}
      dataLabelName={value}
    >
      {value}
    </Pill>
  ) : (
    <TextListItem value={value} ref={ref as ForwardedRef<HTMLDivElement>} />
  ),
)

export const InfiniteScrollListItems: FC<InfiniteScrollListItemsProps> = ({
  listItemVariant,
  id,
  value,
  isSelected,
  onClick,
  onLoadMoreData,
  isLast,
  intersectionObserverControls,
}) => {
  const ref = useRef<HTMLButtonElement | HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {
    ...intersectionObserverControls,
  })
  const lastItemIsVisible = !!entry?.isIntersecting && isLast

  useEffect(() => {
    if (!lastItemIsVisible) return

    onLoadMoreData?.()
  }, [entry])

  return (
    <ListItems
      listItemVariant={listItemVariant}
      id={id}
      value={value}
      ref={(instance) => (ref.current = instance)}
      isSelected={isSelected}
      onClick={onClick}
    />
  )
}

export const ListWithInfiniteScroll: FC<ListWithInfiniteScrollProps> = ({
  testId = 'list-items',
  listItemVariant,
  entries = [],
  selectedEntry,
  useInfiniteScroll = false,
  intersectionObserverControls,
  loading,
  onLoadMoreData,
  onClick,
}) => {
  const [filteredEntries, setFilteredEntrySet] = useState<
    ListWithInfiniteScrollProps['entries']
  >([])

  const uniqueEntries = useUniqueObject(entries)

  useEffect(() => {
    setFilteredEntrySet(uniqueEntries)
  }, [uniqueEntries])

  return (
    <>
      <ul
        data-testid={testId}
        className={clsx('mt-3 pt-2 pb-5 overflow-y-scroll')}
      >
        {(filteredEntries ?? []).map(({ id, value }, i) => {
          return (
            <li key={`list-item-${id}-${i}`}>
              {useInfiniteScroll ? (
                <InfiniteScrollListItems
                  onLoadMoreData={onLoadMoreData}
                  id={id}
                  value={value}
                  listItemVariant={listItemVariant}
                  isLast={
                    typeof filteredEntries !== 'undefined'
                      ? filteredEntries?.length > 0 &&
                        !loading &&
                        i === filteredEntries?.length - 1
                      : false
                  }
                  onClick={onClick}
                  isSelected={selectedEntry === id}
                  intersectionObserverControls={intersectionObserverControls}
                />
              ) : (
                <ListItems
                  listItemVariant={listItemVariant}
                  id={id}
                  value={value}
                  isSelected={selectedEntry === id}
                  onClick={onClick}
                />
              )}
            </li>
          )
        })}
      </ul>
      {loading && (
        <div className="z-20 flex h-8 w-full items-center justify-center bg-high">
          <Spinner className="!h-8 !w-8" />
        </div>
      )}
    </>
  )
}
