'use client'

import { useLDContext, useUser } from '@sylveraio/data'
import { LDProvider as LaunchDarklyProvider } from 'launchdarkly-react-client-sdk'

export const LDProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useUser()
  const context = useLDContext(data)

  return (
    <LaunchDarklyProvider
      context={context}
      clientSideID={process.env.NEXT_PUBLIC_LD_CS_KEY || ''}
      deferInitialization={true}
    >
      {children}
    </LaunchDarklyProvider>
  )
}
