import { useContext } from 'react'
import { captureException } from '@sylveraio/react-utils'
import { Auth } from 'aws-amplify'
import type { SetNewPassword } from './types'
import { UserContext } from '../../../contexts'

function useSetNewUserPassword() {
  const { user } = useContext(UserContext)

  async function setNewUserPassword({
    password,
  }: SetNewPassword): Promise<void> {
    try {
      if (typeof user === 'undefined')
        throw { message: 'It looks like something went wrong.' } as Error

      await Auth.completeNewPassword(user, password)
    } catch (err) {
      captureException(err, 'useSetNewUserPassword')
      throw err
    }
  }

  return { setNewUserPassword }
}

export default useSetNewUserPassword
