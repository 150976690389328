import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Twitter: FC<{ withBorder?: boolean } & IconsProps> = ({
  size = 19,
  className,
  withBorder = true,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-transparent', className)}
  >
    <g
      clipPath="url(#clip0_3776_3983)"
      id="g11"
      transform="matrix(1.0916854,0,0,1.0848939,-1.1460672,-1.0187264)"
    >
      <path
        d="m 16.3027,7 h 1.8035 L 14.1668,11.2444 18.8182,17 H 15.1636 L 12.3158,13.5111 9.04089,17 H 7.23731 L 11.4615,12.4667 7,7 h 3.7495 l 2.5868,3.2 z m -0.6408,8.9778 h 0.9967 L 10.2037,7.95556 H 9.11208 Z"
        fill="#01311d"
        id="path9"
      />
    </g>
    {withBorder && (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 12.5,22.198002 C 18.167485,22.198002 22.761843,17.632227 22.761843,12 22.761843,6.3677952 18.167485,1.8019977 12.5,1.8019977 6.8325372,1.8019977 2.2381575,6.3677952 2.2381575,12 c 0,5.632227 4.5943797,10.198002 10.2618425,10.198002 z M 24.508539,12 c 0,6.590839 -5.376441,11.933833 -12.008539,11.933833 C 5.8678694,23.933833 0.49146088,18.590839 0.49146088,12 0.49146088,5.4091287 5.8678694,0.06616748 12.5,0.06616748 19.132098,0.06616748 24.508539,5.4091287 24.508539,12 Z"
        fill="#01311d"
        id="path13"
        strokeWidth={1}
      />
    )}
    <defs id="defs18">
      <clipPath id="clip0_3776_3983">
        <rect
          width="11.8182"
          height="10"
          fill="white"
          transform="translate(7 7)"
          id="rect15"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Twitter
