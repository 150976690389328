import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { ContentfulChartConfig } from './types'
import { gql } from '@apollo/client'

export const GET_CONTENTFUL_CHART: TypedDocumentNode<
  { chart: ContentfulChartConfig },
  {
    id: string
  }
> = gql`
  query chartEntryQuery($id: String!) {
    chart(id: $id) {
      title
      showTitle
      description {
        json
      }
      enableFullscreen
      enableZoom
      showGridLines
      enableFiltering
      enableDownload
      showNoDataOverlay
      legendPosition
      xAxisOptions {
        axisLabel
        minValue
        maxValue
        isOffset
      }
      yAxisOptions {
        axisLabel
        minValue
        maxValue
        isOffset
      }
      y1AxisOptions {
        axisLabel
        minValue
        maxValue
        isOffset
        isRightAxis
      }
      chartTypeCollection {
        items {
          ... on BarChart {
            chartId
            backgroundColors
            stack
            enablePanning
            query
            staticData
          }
          ... on LineChart {
            chartId
            borderColors
            enableHighlighting
            query
            staticData
            segmentAfter
            isRightYAxis
            isScatterGraph
          }
        }
      }
      labelAnnotationsCollection {
        items {
          xValueVariableName
          labelText
        }
      }
      staticData
    }
  }
`
