import clsx from 'clsx'
import type { FC } from 'react'
import { Typography } from '../Typography'
import {
  getCalloutColors,
  getCalloutTextColors,
} from './utils/getCalloutColors'
import { getCalloutIcon } from './utils/getCalloutIcon'
import type { CalloutProps } from './types'

export const Callout: FC<CalloutProps> = ({
  variant = 'neutral',
  text,
  description,
  testId = 'callout',
}) => {
  const calloutBaseClass =
    'w-full p-2 flex items-start justify-start gap-x-2 rounded-lg border'
  const colorClass = getCalloutColors(variant)
  const textColorClass = getCalloutTextColors(variant)
  const icon = getCalloutIcon(variant, textColorClass)

  return (
    <div className={clsx(calloutBaseClass, colorClass)} data-testid={testId}>
      <span className="h-5 w-5" data-testid={`${testId}-icon`}>
        {icon}
      </span>
      <div className="w-full flex flex-col items-start justify-center">
        {text && (
          <Typography
            size="sm"
            weight="semibold"
            className={textColorClass}
            testId={`${testId}-text`}
          >
            {text}
          </Typography>
        )}
        {description && (
          <Typography
            size="sm"
            className={textColorClass}
            testId={`${testId}-description`}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
  )
}
