import { FC } from 'react'
import clsx from 'clsx'
import { RangeSlider } from '../../../RangeSlider'
import { ProgressRing } from '../../../ProgressRing'
import { SylveraRatingFilterRangeSliderProps } from './types'

export const SylveraRatingFilterRangeSlider: FC<
  SylveraRatingFilterRangeSliderProps
> = ({ minRating, maxRating, values, max, onChange, disabled = false }) => (
  <div
    className={clsx('flex items-center justify-start', {
      'cursor-default': disabled,
    })}
    data-testid="sylvera-rating-filter-range-slider"
  >
    <ProgressRing
      type="outer-ring"
      label="Min"
      labelPosition="bottom"
      stroke={8}
      value={minRating}
      innerLabel={minRating}
      size="xs"
      wrapperClassName={clsx('gap-y-1', {
        'opacity-20': disabled,
      })}
      labelClassName="!text-content-action-subtle"
    />
    <div className="-mt-5">
      <RangeSlider
        rtl={false}
        values={values}
        max={max}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
    <ProgressRing
      label="Max"
      type="outer-ring"
      labelPosition="bottom"
      stroke={8}
      value={maxRating}
      innerLabel={maxRating}
      size="xs"
      wrapperClassName={clsx('gap-y-1', {
        'opacity-20': disabled,
      })}
      labelClassName="!text-content-action-subtle"
    />
  </div>
)

export default SylveraRatingFilterRangeSlider
