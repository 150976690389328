import { type FC } from 'react'
import clsx from 'clsx'
import { Sizes, type IconLibraryProps } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'

export const Selected: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'selected',
  size = ICON_DEFAULT_SIZE,
  className,
}) => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('fill-current text-content-action-default', className)}
  >
    <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.4C17.1915 21.4 21.4 17.1915 21.4 12C21.4 6.80852 17.1915 2.6 12 2.6C6.80852 2.6 2.6 6.80852 2.6 12C2.6 17.1915 6.80852 21.4 12 21.4ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
    />
    <path
      d="M7.37587 11.431C7.04679 11.1224 6.52961 11.1391 6.22106 11.4682C5.9125 11.7977 5.92884 12.3149 6.2583 12.6234L10.6165 16.7092C10.9673 17.0379 11.5251 16.9942 11.8204 16.6146L18.4091 8.31857C18.6861 7.96251 18.6219 7.44913 18.2659 7.17212C17.9098 6.8951 17.3964 6.95932 17.119 7.31539L11.0805 14.9039L7.37587 11.431Z"
      fill="white"
    />
  </svg>
)
