'use client'
import { FC } from 'react'
import NavItem from './NavItem'
import { NavProps } from './types'

const Nav: FC<NavProps> = ({ navigationRoutes = [], activePageId }) => {
  const navigation = navigationRoutes
    .filter(({ active }) => active)
    .map(({ id, href, title, count }, index) => (
      <NavItem
        key={id}
        id={id}
        href={href}
        tabIndex={index}
        active={id === activePageId}
        count={count}
      >
        {title}
      </NavItem>
    ))

  return (
    <nav className="flex flex-1 items-center space-x-6 flex-row">
      {navigation}
    </nav>
  )
}

export default Nav
