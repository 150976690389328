import type {
  ApolloClient,
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client'
import { useSylveraQuery } from '@sylveraio/react-utils'
import { useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import type { Values } from '../createForm/types'
import type { DebouncedFunc } from 'lodash'

export function useFormSearch<T>(
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  client: ApolloClient<any>,
  transformFn: (data: T) => Values,
  variables?: Record<string, string | number>,
): [Values, DebouncedFunc<(value: string) => void>] {
  const [searchFuzzy, setSearchFuzzy] = useState<string>('')
  const [opts, setOpts] = useState<Values>([])
  useSylveraQuery<T>(query, client, {
    variables: {
      search: searchFuzzy,
      ...variables,
    },
    skip: searchFuzzy.length < 3,
    onCompleted: (data: T) => {
      if (!data) setOpts([])
      else setOpts(transformFn(data))
    },
  })
  const handleSearch = useCallback(
    debounce((value: string) => {
      if (value === '') {
        setSearchFuzzy('')
        setOpts([])
      }
      if (value.length >= 3) {
        setSearchFuzzy(value)
      }
    }, 500),
    [],
  )

  return [opts, handleSearch]
}
