import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type Registries = {
  registries: {
    nodes: Array<{
      name: string
      id: string
    }>
  }
}

export const GET_REGISTRIES: TypedDocumentNode<
  Registries,
  {
    first: number
  }
> = gql`
  query Registries($first: Int!) {
    registries(first: $first) {
      nodes {
        name
        id
      }
    }
  }
`
