import { type FC, useMemo } from 'react'
import clsx from 'clsx'
import {
  Sizes,
  type IconLibraryProps,
  type TransitionalIconTypes,
} from '../../types'
import { ICON_DEFAULT_SIZE, iconTransistionStyles } from '../../constants'

export const Chevron: FC<
  Omit<IconLibraryProps & TransitionalIconTypes, 'name'>
> = ({
  testId = 'chevron',
  size = ICON_DEFAULT_SIZE,
  className,
  direction = 'right',
  transitionOn,
  transitionDirection,
}) => {
  const directionalStyles = useMemo(
    () => iconTransistionStyles(direction, transitionOn, transitionDirection),
    [direction, transitionOn, transitionDirection],
  )

  return (
    <svg
      data-testid={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: Sizes[size] }}
      className={clsx(
        'fill-current text-content-action-default',
        { ...directionalStyles },
        className,
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39898 3.89898C7.73093 3.56704 8.26912 3.56704 8.60107 3.89898L16.1011 11.399C16.433 11.7309 16.433 12.2691 16.1011 12.6011L8.60107 20.1011C8.26912 20.433 7.73093 20.433 7.39898 20.1011C7.06704 19.7691 7.06704 19.2309 7.39898 18.899L14.2979 12L7.39898 5.10107C7.06704 4.76912 7.06704 4.23093 7.39898 3.89898Z"
      />
    </svg>
  )
}
