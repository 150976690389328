import xor from 'lodash/xor'

/**
 * Adds or removes a value from an array
 * @param array
 * @param item
 * @returns
 */
export function toggleArrayValue<T>(array: T[], item: T): T[] {
  return xor(array, [item])
}

export default toggleArrayValue
