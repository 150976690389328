import { FC } from 'react'
import type IconsProps from '../types'

const ExclamationMark: FC<IconsProps> = ({ size = 16 }) => (
  <svg
    width="86"
    height="86"
    viewBox="0 0 86 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
  >
    <circle cx="43" cy="43" r="34.5" stroke="#E2E6E2" strokeWidth="5" />
    <path
      d="M43 26V48"
      stroke="#E2E6E2"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <path
      d="M43 58V59"
      stroke="#E2E6E2"
      strokeWidth="5"
      strokeLinecap="round"
    />
  </svg>
)

export default ExclamationMark
