import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { ContentShadow } from '../ContentShadow'
import type { FormProps } from './types'

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  testId = 'form',
  onSubmit,
  children,
  mode = 'onSubmit',
  noWrapper = false,
  formClassName,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>({ mode })

  if (noWrapper) {
    return (
      <form
        data-testid={testId}
        className={clsx('flex flex-col items-center w-full', formClassName)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children(methods)}
      </form>
    )
  }
  return (
    <ContentShadow
      className="relative flex-col w-[490px] min-h-[650px] pt-20 pb-16 px-20 rounded-[25px]"
      bgColor="bg-high/40"
    >
      <form
        data-testid={testId}
        className={clsx('flex flex-col items-center w-full', formClassName)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children(methods)}
      </form>
    </ContentShadow>
  )
}

export default Form
