import isArray from 'lodash/isArray'
import type { ConditionFn } from '../types'

export function evaluateConditions(
  currentForm: Record<string, unknown>,
  condition?: ConditionFn | Array<ConditionFn>,
): boolean {
  if (!condition) return true
  if (isArray(condition)) {
    return condition.some((c) => c(currentForm))
  } else {
    return condition(currentForm)
  }
}
