import { AppName } from 'types'
import { getDomain } from '../getDomain'

const RATINGS = 3000
const DASHBOARD = 3001
const PORTFOLIO_ANALYTICS = 3002

/**
 * Returns the url of a given app based on the APP_ENV
 * @return {string} String
 */
export function getAppUrl(
  appName: AppName,
  opts: { ratingsSubdomain?: boolean } = {
    ratingsSubdomain: true,
  },
): string {
  const app = appName === 'ratings' && !opts.ratingsSubdomain ? 'app' : appName

  if (process.env['NODE_ENV'] === 'development') {
    switch (appName) {
      case 'analytics':
        return `http://localhost:${PORTFOLIO_ANALYTICS}`
      case 'ratings':
        return `http://localhost:${RATINGS}`
      case 'dashboard':
        return `http://localhost:${DASHBOARD}`
      case 'box':
        return `http://localhost:${DASHBOARD}/jurisdictional-intel`
      case 'market-commentary':
        return `http://localhost:${DASHBOARD}/market-commentary`
      default:
        break
    }
  }

  const domain = getDomain()

  switch (appName) {
    case 'insights':
      return 'https://sylvera.com/insights'
    case 'box':
      return `https://dashboard.${domain}/jurisdictional-intel`
    case 'market-commentary':
      return `https://dashboard.${domain}/market-commentary`
    default:
      return `https://${app}.${domain}`
  }
}

export default getAppUrl
