import { forwardRef } from 'react'
import clsx from 'clsx'
import { FormErrorTooltip } from '../FormErrorTooltip'
import type { InputProps } from './types'
import Check from '@sylveraio/untitled-ui-icons-react/build/esm/Check'

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      testId = 'input',
      name,
      label,
      placeholder,
      value,
      error,
      success,
      hideBorder,
      wrapperClassName,
      className,
      ...rest
    },
    ref,
  ) => (
    <div
      data-testid={`${testId}-container`}
      className={clsx(
        'relative w-full h-14 rounded-md bg-black-25',
        {
          border: !hideBorder,
          'border-content-action-subtle': !error && !success && !hideBorder,
          'border border-content-alert-default': error,
          'border-content-positive-subtle': !error && success && !hideBorder,
        },
        wrapperClassName,
      )}
    >
      <input
        data-testid={testId}
        ref={ref}
        name={name}
        defaultValue={value}
        placeholder={placeholder ?? label}
        className={clsx(
          'relative z-10 w-full h-full px-2 pt-4 text-lg font-medium placeholder-transparent bg-transparent text-placeholder peer focus:outline-none',
          {
            '!placeholder-black-200': placeholder && !label,
            'focus:placeholder-black-200': placeholder && label,
          },
          className,
        )}
        {...rest}
      />
      <label
        data-testid={`${testId}-label`}
        htmlFor={name}
        className="absolute z-20 text-sm transition-all top-1 text-placeholder left-2 peer-placeholder-shown:text-lg peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:translate-y-0 peer-focus:top-1 peer-focus:text-sm peer-focus:!z-30 pointer-events-none"
      >
        {label}
      </label>
      {!error && success && (
        <div className="absolute -translate-y-1/2 right-4 top-1/2">
          <Check className="w-5 h-5 stroke-content-positive-default" />
        </div>
      )}
      {error && (typeof error === 'object' || typeof error === 'string') && (
        <FormErrorTooltip
          testId={`${testId}-error`}
          className="absolute z-30 -translate-x-1/2 translate-y-4 top-full left-1/2"
        >
          {error}
        </FormErrorTooltip>
      )}
    </div>
  ),
)

export default Input
