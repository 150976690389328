import clsx from 'clsx'
import React, { FC, memo } from 'react'
import { TextProps } from './types'

const renderButton = (
  content: string | React.ReactNode,
  level: number | undefined,
  className: string | undefined,
  testId: string | undefined,
) => {
  switch (level) {
    case 2:
      return (
        <p
          className={clsx('font-semibold text-b2', className)}
          lang="en"
          data-testid={testId}
        >
          {content}
        </p>
      )
    case 3:
      return (
        <p
          className={clsx('font-semibold text-b3', className)}
          lang="en"
          data-testid={testId}
        >
          {content}
        </p>
      )
    case 1:
    default:
      return (
        <p
          className={clsx('font-semibold text-b1', className)}
          lang="en"
          data-testid={testId}
        >
          {content}
        </p>
      )
  }
}

const ButtonText: FC<TextProps> = memo(
  ({ content, level, className, testId }) => (
    <>{renderButton(content, level, className, testId)}</>
  ),
)

export default ButtonText
