import React, { FC } from 'react'
import type { RowCount } from '../types'
import type { RowsProps } from './types'

export const Rows: FC<RowsProps> = ({
  rowsPerPage = 50,
  onChangeRowCount,
  customRowCount,
  rowCountLabel = 'Rows per page:',
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value, 10) as RowCount
    onChangeRowCount?.(newValue)
  }

  return (
    <div className="flex mr-10 text-subtle">
      <label htmlFor="rowsPerPage" className="mr-2">
        {rowCountLabel}
      </label>
      <select
        id="rowsPerPage"
        name="rowsPerPage"
        className="pl-1 bg-transparent cursor-pointer text-content-action-dark"
        value={rowsPerPage}
        onChange={handleOnChange}
        data-testid="pagination-rows"
      >
        {!customRowCount ? (
          <>
            <option>100</option>
            <option>50</option>
            <option>25</option>
            <option>10</option>
          </>
        ) : (
          customRowCount.map((rowCount) => (
            <option key={rowCount}>{rowCount}</option>
          ))
        )}
      </select>
    </div>
  )
}

export default Rows
