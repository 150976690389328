import { FC } from 'react'
import { Dropdown, Search, DropdownListItem } from '@sylveraio/design-system'
import type { SearchableDropdownListProps } from './types'
import clsx from 'clsx'

export const SearchableDropdownList: FC<SearchableDropdownListProps> = ({
  testId = 'searchable-dropdown-list',
  isOpen,
  title,
  label,
  name,
  size,
  items,
  isMultiSelect = true,
  selected = [],
  wrapperClassName,
  modalClassName,
  className,
  placeholder = 'Search all...',
  searchValue = '',
  searchWrapperClassName,
  onOpen,
  onReset,
  onChange,
  onSearchChange,
}) => {
  return (
    <Dropdown
      testId={`${testId}-dropdown`}
      isOpen={isOpen}
      onOpen={onOpen}
      label={label}
      variant="rounded"
      title={title}
      wrapperClassName={wrapperClassName}
      modalClassName={modalClassName}
      className={className}
      size={size}
    >
      {onSearchChange && (
        <Search
          testId={`${testId}-search`}
          placeholder={placeholder}
          wrapperClassName={clsx('!bg-medium mb-2', searchWrapperClassName)}
          value={searchValue}
          onChange={onSearchChange}
          onReset={onReset}
        />
      )}
      <ul
        data-testid={testId}
        className="flex flex-col max-h-[186px] overflow-y-auto"
      >
        {items.map(({ id, value }, idx) => (
          <li key={`dropdown-list-item-${idx}`} className="py-1">
            <DropdownListItem
              testId={`${testId}-item-${idx}`}
              id={id}
              name={name}
              value={id}
              label={value}
              checked={selected?.includes(id)}
              onChange={onChange}
              multiSelect={isMultiSelect}
            />
          </li>
        ))}
      </ul>
    </Dropdown>
  )
}
