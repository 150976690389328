import type { FC } from 'react'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import useSubmitProjectAccessForm from './utils/useSubmitProjectAccessForm'
import projectAccess from '../../assets/projectAccess.svg'
import { GenericCustomHubspotForm } from '../GenericCustomHubspotForm'
import type { CustomProjectAccessHubspotFormProps } from './types'
import CONST from './constants'

export const CustomProjectAccessHubspotForm: FC<
  CustomProjectAccessHubspotFormProps
> = ({ formCustomOverride, onClose, setSubmitting, setSuccess, setError }) => {
  const { submitProjectAccessForm } = useSubmitProjectAccessForm()

  const handleFormSubmitClick = async () => {
    setSubmitting(true)

    sendAnalyticsEvent({
      eventName: 'app-project-access-requested',
    })

    try {
      await submitProjectAccessForm({ ...formCustomOverride?.data })
      setSuccess(true)
      setError(false)
    } catch {
      setSuccess(false)
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  const handleAbandonFormSubmitClick = async () => {
    setSubmitting(true)

    sendAnalyticsEvent({
      eventName: 'app-project-access-aborted',
    })

    onClose()

    try {
      await submitProjectAccessForm({ ...formCustomOverride?.data }, true)
    } catch {
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <GenericCustomHubspotForm
      image={{
        src: projectAccess,
        alt: 'Project not included.',
      }}
      text={CONST.PROJECT_NOT_INCLUDED}
      submitButtonText={CONST.REQUEST_ACCESS}
      handleFormSubmitClick={handleFormSubmitClick}
      handleAbandonFormSubmitClick={handleAbandonFormSubmitClick}
    />
  )
}
