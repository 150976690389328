import type { FC } from 'react'
import clsx from 'clsx'
import type { SylveraRatingRingProps, SylveraRatingProps } from './types'
import { sylveraRatingPadding, sylveraRatingTextVariant } from './constants'
import { DEFAULT_SCORE_SIZE, globalSylveraScoreSize } from '../../constants'
import { Typography } from '../../../Typography'

const Ring: FC<SylveraRatingRingProps> = ({
  testId,
  size,
  hasRating,
  children,
  ringType = 'outer',
  className,
}) => {
  return (
    <div
      data-testid={testId}
      className={clsx(
        'relative rounded-full flex items-center justify-center',
        {
          'bg-progress-ring-gradient': hasRating && ringType === 'middle',
          '!bg-[#B8B8B8] !bg-none': !hasRating && ringType === 'middle',
          [globalSylveraScoreSize[size]]: ringType === 'outer',
        },
        sylveraRatingPadding[ringType][size],
        className,
      )}
    >
      {children}
    </div>
  )
}

export const SylveraRating: FC<Omit<SylveraRatingProps, 'type'>> = ({
  testId = 'sylvera-rating',
  rating,
  size = DEFAULT_SCORE_SIZE,
}) => {
  const hasRating = Boolean(rating)

  return (
    <Ring testId={testId} size={size} ringType="outer">
      <div className="absolute top-0 left-0 w-full h-full rounded-full shadow-rating" />
      <Ring
        size={size}
        hasRating={hasRating}
        ringType="middle"
        className="z-10 w-full h-full shrink-0"
      >
        <div className="flex items-center justify-center w-full h-full bg-high rounded-full">
          {hasRating && (
            <Typography
              testId="ring-label"
              size={sylveraRatingTextVariant[size]}
              weight="semibold"
              className="!text-content-action-dark"
            >
              {rating}
            </Typography>
          )}
        </div>
      </Ring>
    </Ring>
  )
}
