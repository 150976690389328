'use client'

import type { FC } from 'react'
import type { TooltipProps } from './types'
import { TooltipProvider } from './TooltipProvider'
import { TooltipTrigger } from './TooltipTrigger'
import { TooltipContent } from './TooltipContent'

export const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  rootNodeId,
  rootElement,
  ...options
}) => (
  <TooltipProvider {...options}>
    <TooltipTrigger>{children}</TooltipTrigger>
    <TooltipContent rootNodeId={rootNodeId} rootElement={rootElement}>
      {content}
    </TooltipContent>
  </TooltipProvider>
)
