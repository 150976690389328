import type { FC } from 'react'

import type { TabGroupProps, TabProps } from './types'
import clsx from 'clsx'
import { Label } from '../Label'
import { Button } from '../Button'

export const TabGroup: FC<TabGroupProps> = ({
  testId = 'tab-group',
  className,
  tabs,
  selected,
  onClick,
}) => {
  return (
    <ul
      className={clsx('flex mb-8 border-b border-default w-full', className)}
      data-testid={testId}
    >
      {tabs.map(({ id, ...tabProps }, i) => (
        <li key={`tab-${i}`}>
          <Tab
            {...tabProps}
            onClick={() => onClick(id)}
            selected={selected === id}
            testId={`${testId}-${i}`}
          />
        </li>
      ))}
    </ul>
  )
}

export const Tab: FC<TabProps> = ({
  testId = 'tab',
  className,
  onClick,
  disabled,
  selected,
  label,
  badge,
}) => {
  return (
    <Button
      variant="subtle"
      onClick={onClick}
      disabled={disabled}
      label={label}
      className={clsx(
        'gap-x-2 hover:rounded-none focus:rounded-none border-b-2 border-transparent hover:border-default focus:outline-2 hover:bg-inherit',
        {
          '!text-content-action-default border-b-2 !border-selected rounded-none':
            selected && !disabled,
        },
        className,
      )}
      testId={testId}
      aria-selected={selected}
      role="tab"
    >
      {badge && (
        <Label
          label={badge.toString()}
          color="neutral"
          testId={`${testId}-badge`}
        />
      )}
    </Button>
  )
}
