import type { FC } from 'react'
import { TooltipContext } from './utils/useTooltipContext'
import { useTooltip } from './utils/useTooltip'
import type { TooltipProviderProps } from './types'

export const TooltipProvider: FC<TooltipProviderProps> = ({
  children,
  ...options
}) => {
  const tooltip = useTooltip(options)

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}
