import Slider, { type Settings } from 'react-slick'
import { CAROUSEL_EARLY_STAGE_PROJECTS } from '../constants'
import { ProjectCard } from './ProjectCard'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SLIDER_SETTINGS: Settings = {
  arrows: false,
  draggable: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 750,
  centerMode: true,
  centerPadding: '40px', // p-10 equivalent
}

export const Carousel = () => (
  <Slider {...SLIDER_SETTINGS}>
    {CAROUSEL_EARLY_STAGE_PROJECTS.map((project) => (
      <div className="px-3 w-9/12">
        <ProjectCard key={project.projectType} {...project} />
      </div>
    ))}
  </Slider>
)
