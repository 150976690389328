'use client'

import { useEffect, useState } from 'react'
import { getAppUrl } from '@sylveraio/react-utils'
import { addBreadcrumb } from '@sentry/nextjs'

const signInRoute = '/sign-in'

/**
 * @returns the correct sign in route when dashboardSignIn is defined, or undefined when dashboardSignIn is undefined
 */
export function useDashboardSignInRoute(): string | undefined {
  const [route, setRoute] = useState<string>()
  useEffect(() => {
    const base = getAppUrl('dashboard')
    addBreadcrumb({
      data: {
        base,
      },
    })
    setRoute(`${base}${signInRoute}`)
  }, [])

  return route
}
