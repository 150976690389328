import { FC } from 'react'
import clsx from 'clsx'

import type MenuIconProps from './types'

const Menu: FC<MenuIconProps> = ({ size = 16, className }) => (
  <svg
    width="5"
    height="5"
    viewBox="0 0 5 5"
    fill="none"
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-current', className)}
  >
    <path d="M0.2,3h4.6V2H0.2V3z" />
    <path d="M0.2,1.1h4.6V0.2H0.2V1.1z" />
    <path d="M0.2,4.8h4.6V3.9H0.2V4.8z" />
  </svg>
)

export default Menu
