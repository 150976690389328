import { FC } from 'react'
import type { ChartPanningProps } from './type'
import ChevronLeft from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronLeft'
import ChevronRight from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronRight'

export const ChartPanning: FC<ChartPanningProps> = ({
  testId = 'chart-panning',
  chartInstance,
}) => (
  <div
    data-testid={testId}
    className="absolute bottom-0 left-0 flex justify-between w-full mb-3"
  >
    <span className="ml-20">
      <button
        type="button"
        data-testid={`${testId}-button-left`}
        onClick={() => {
          chartInstance?.pan?.({ x: 100 }, undefined, 'normal')
        }}
      >
        <ChevronLeft className="w-5 h-5" />
      </button>
    </span>
    <span>
      <button
        type="button"
        data-testid={`${testId}-button-right`}
        onClick={() => {
          chartInstance?.pan?.({ x: -100 }, undefined, 'normal')
        }}
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    </span>
  </div>
)

export default ChartPanning
