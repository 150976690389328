import { FC } from 'react'
import clsx from 'clsx'
import IconsProps from '../types'

const Cog: FC<{ withBorder?: boolean } & IconsProps> = ({
  size = 19,
  className,
  withBorder = true,
}) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: size }}
    className={clsx('fill-transparent', className)}
  >
    {withBorder && (
      <path
        d="M9.49997 0.5C14.4708 0.5 18.5 4.52974 18.5 9.50003C18.5 14.4703 14.4708 18.5 9.49997 18.5C4.52913 18.5 0.5 14.4703 0.5 9.50003C0.5 4.52981 4.5292 0.5 9.49997 0.5Z"
        className="stroke-selection-forest"
      />
    )}
    <path
      d="M5.35327 13.0527H7.13216V7.12348H5.35327V13.0527ZM12.2835 6.9181C11.4202 6.9181 10.6477 7.23338 10.0998 7.92928V7.10398H8.31435V13.0528H10.0998V9.83581C10.0998 9.15595 10.7226 8.49273 11.5028 8.49273C12.283 8.49273 12.4754 9.15595 12.4754 9.81918V13.0522H14.2543V9.68678C14.2542 7.34915 13.1475 6.9181 12.2835 6.9181ZM6.2344 6.53096C6.72604 6.53096 7.12506 6.13194 7.12506 5.6403C7.12506 5.14867 6.72604 4.74972 6.2344 4.74972C5.74277 4.74972 5.34375 5.14874 5.34375 5.64037C5.34375 6.132 5.74277 6.53096 6.2344 6.53096Z"
      className="fill-selection-forest"
    />
  </svg>
)

export default Cog
