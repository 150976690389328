import type { FormFieldTuple } from '../types'

export const minMaxTupleCheck: FormFieldTuple['validationFn'] = (v) => {
  const [a, b] = v
  const firstValue =
    typeof a?.value === 'string'
      ? Number.parseInt(a?.value)
      : (a?.value as number | undefined)
  const secondValue =
    typeof b?.value === 'string'
      ? Number.parseInt(b?.value)
      : (b?.value as number | undefined)
  if (
    firstValue &&
    secondValue &&
    Number.isInteger(firstValue) &&
    Number.isInteger(secondValue)
  ) {
    if (secondValue < firstValue) return 'Minimum must be less than the Maximum'
  }
  return undefined
}
