import type { FC } from 'react'
import type { ZoomButtonProps } from './types'
import { ButtonLegacy } from '../ButtonLegacy'
import { getZoomButtonSize, getZoomButtonRadius } from './utils'
import clsx from 'clsx'
import Minus from '@sylveraio/untitled-ui-icons-react/build/esm/Minus'
import Plus from '@sylveraio/untitled-ui-icons-react/build/esm/Plus'

export const ZoomButton: FC<ZoomButtonProps> = ({
  testId = 'zoom-button',
  type = 'zoomIn',
  disabled,
  onClick,
  size = 'md',
}) => {
  const classNameStates = {
    'stroke-icon-disabled': disabled,
    'stroke-icon-default': !disabled,
  }
  const icon =
    type === 'zoomIn' ? (
      <Plus className={clsx(classNameStates)} />
    ) : (
      <Minus className={clsx(classNameStates)} />
    )

  return (
    <ButtonLegacy
      testId={testId}
      align="none"
      appearance="none"
      disabled={disabled}
      className={clsx(
        'flex items-center justify-center box-border bg-high-hover hover:!bg-default',
        getZoomButtonSize(size),
        getZoomButtonRadius(type),
      )}
      onClick={onClick}
    >
      {icon}
    </ButtonLegacy>
  )
}
