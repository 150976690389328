import clsx from 'clsx'
import { type FC } from 'react'
import type { RadioButtonProps } from './types'
import { Typography } from '../Typography'

export const RadioButton: FC<RadioButtonProps> = ({
  testId = 'radio-button',
  id,
  label,
  name,
  value,
  checked,
  disabled,
  className,
  labelClassName,
  hideRadio,
  onChange,
  wrapperClassName,
}) => (
  <label
    data-testid={`${testId}-wrapper`}
    className={clsx(
      'flex items-center gap-x-2',
      {
        'cursor-pointer': !disabled,
      },
      wrapperClassName,
    )}
    role="radio"
    aria-checked={checked}
  >
    <input
      type="radio"
      data-testid="default-radio-button"
      data-label={label}
      id={id}
      name={name}
      aria-describedby={name}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={clsx(
        'sr-only [&:checked+div]:bg-content-action-default [&:checked+div>div]:block [&:disabled+div]:bg-content-action-light [&:disabled+div]:border-none',
      )}
    />

    {!hideRadio && (
      <div
        data-testid={testId}
        className={clsx(
          'w-5 h-5 border-[1.2px] border-content-action-default rounded-full flex items-center justify-center',
          {
            'bg-content-action-default': checked,
            'bg-content-action-light border-none': disabled,
          },
          className,
        )}
      >
        <div
          className={clsx('w-2	h-2 bg-high rounded-full', {
            '!hidden': !checked || (!checked && disabled),
          })}
        />
      </div>
    )}

    {label && typeof label === 'string' ? (
      <Typography
        testId={`${testId}-label`}
        className={labelClassName}
        size="xs"
        weight="medium"
      >
        {label}
      </Typography>
    ) : (
      label
    )}
  </label>
)
