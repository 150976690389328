import { type FC, useMemo } from 'react'
import clsx from 'clsx'
import { ICON_DEFAULT_SIZE, iconTransistionStyles } from '../../constants'
import {
  Sizes,
  TransitionalIconTypes,
  type IconLibraryProps,
} from '../../types'

export const Arrow: FC<
  Omit<IconLibraryProps & TransitionalIconTypes, 'name'>
> = ({
  testId = 'arrow',
  size = ICON_DEFAULT_SIZE,
  className,
  direction = 'right',
  transitionOn,
  transitionDirection,
}) => {
  const directionalStyles = useMemo(
    () => iconTransistionStyles(direction, transitionOn, transitionDirection),
    [direction, transitionOn, transitionDirection],
  )

  return (
    <svg
      data-testid={testId}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: 'auto', width: Sizes[size] }}
      className={clsx(
        'fill-current text-content-action-default',
        { ...directionalStyles },
        className,
      )}
    >
      <path d="M7.33339 3.00006L6.39339 3.94006L10.1134 7.66673L2.00006 7.66673L2.00006 9.00006H10.1134L6.39339 12.7267L7.33339 13.6667L12.6667 8.33339L7.33339 3.00006Z" />
    </svg>
  )
}
