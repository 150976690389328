import { useState } from 'react'
import difference from 'lodash/difference'
import type { FieldSearchFn, Values } from '../createForm/types'
import {
  type Categories,
  GET_PROJECT_CATEGORIES,
} from '../../queries/GET_PROJECT_CATEGORIES'
import { useSylveraQuery } from '@sylveraio/react-utils'
import type { ApolloClient } from '@apollo/client'

const LIST_CATEGORIES = [
  'ARR',
  'REDD+',
  'IFM',
  'Biochar',
  'DAC',
  'Cookstoves',
  'Croplands',
]

export function useProjectCategories(client: ApolloClient<any>) {
  const [projectAllCategories, setProjectAllCategories] = useState<Values>([])
  const [projectCategoryList, setProjectCategoryList] = useState<Values>([])
  const [projectCategoryOpts, setProjectCategoryOpts] = useState<Values>([])
  const handleProjectCategorySearch: FieldSearchFn = (
    value,
    key,
    currentValues = [],
  ) => {
    if (value === '') {
      setProjectCategoryOpts([])
    } else {
      const optsMinusSelected: Values = difference(
        projectAllCategories,
        currentValues,
      )
      setProjectCategoryOpts(
        optsMinusSelected.filter(
          ({ text, description }) =>
            text.toLowerCase().match(value.toLowerCase()) ||
            (typeof description !== 'undefined' &&
              description.toLowerCase().match(value.toLowerCase())),
        ),
      )
    }
  }

  useSylveraQuery<Categories>(GET_PROJECT_CATEGORIES, client, {
    variables: {
      first: 100,
    },
    onCompleted: (data: Categories) => {
      if (!data) {
        setProjectAllCategories([])
        setProjectCategoryList([])
        return
      }

      const categoriesValues = data.categories.nodes.map(
        ({ id, name, shortName }) => {
          return {
            description: name,
            text: shortName,
            value: id,
          }
        },
      )

      setProjectCategoryList(
        categoriesValues.filter(({ text }) => LIST_CATEGORIES.includes(text)),
      )
      setProjectAllCategories(
        categoriesValues.filter(({ text }) => !LIST_CATEGORIES.includes(text)),
      )
    },
  })

  return {
    projectCategoryList,
    projectCategoryOpts,
    handleProjectCategorySearch,
  }
}
