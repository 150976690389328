import type { FC } from 'react'
import Image from 'next/image'
import { Button } from '@sylveraio/react-ui'
import type { GenericCustomHubspotFormCompletionProps } from './types'
import CONST from './constants'
import submittedSuccessfully from '../../assets/success.svg'

export const GenericCustomHubspotFormCompletion: FC<
  GenericCustomHubspotFormCompletionProps
> = ({ handleClose }) => (
  <>
    <div className="py-8">
      <Image
        src={submittedSuccessfully}
        alt="Form submitted successfully"
        layout="fixed"
        width={262}
        height={106}
      />
    </div>
    <p className="mb-8 w-full max-w-sm px-8 text-center text-xl font-semibold text-default">
      {CONST.THANK_YOU}
      <span className="mt-2 block text-sm font-medium">
        {CONST.GET_IN_TOUCH}
      </span>
    </p>
    <div className="flex gap-10">
      <Button
        className="!w-max shrink-0 !rounded-full text-lg font-medium"
        testId="upsell-modal-completion-accept-button"
        color="dark"
        size="md"
        onClick={handleClose}
        solid
      >
        {CONST.OKAY}
      </Button>
    </div>
  </>
)
