import { FC } from 'react'
import { Button } from '../Button'
import type { SubmitResetButtonsProps } from './types'

export const SubmitResetButtons: FC<SubmitResetButtonsProps> = ({
  handleSubmit,
  handleReset,
  disableSubmitButton,
  disableResetButton,
  submitButtonText = 'Go',
  resetButtonText = 'Reset',
}) => (
  <div className="flex flex-row justify-end space-x-5">
    {handleReset && (
      <Button
        size="md"
        className="!w-24 !text-base !text-default"
        bordered={false}
        color="white"
        onClick={handleReset}
        disabled={disableResetButton}
        solid
        iconName="reset"
      >
        {resetButtonText}
      </Button>
    )}
    {handleSubmit && (
      <Button
        size="md"
        className="!w-24 !text-base"
        bordered={false}
        onClick={handleSubmit}
        disabled={disableSubmitButton}
        color="dark"
        solid
      >
        {submitButtonText}
      </Button>
    )}
  </div>
)
