import { gql } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'

export type Accreditations = {
  accreditations: {
    nodes: Array<{
      name: string
      id: string
      abbreviation: string
      distinctions: {
        nodes: Array<{
          id: string
          name: string
          abbreviation: string
        }>
      }
    }>
  }
}

export const GET_PROJECT_ACCREDITATIONS: TypedDocumentNode<
  Accreditations,
  {
    first: number
  }
> = gql`
  query Accreditations($first: Int!) {
    accreditations(first: $first) {
      nodes {
        name
        id
        abbreviation
        distinctions {
          nodes {
            id
            name
            abbreviation
          }
        }
      }
    }
  }
`
