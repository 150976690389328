import { FC } from 'react'
import Logout from './Logout'
import { UserNavMenuProps } from './types'
import { APIKeys } from './APIKeys'

const UserNavMenu: FC<UserNavMenuProps> = ({
  children,
  signInUrl,
  showAPIKeys,
}) => (
  <div
    className="absolute right-0 z-40 w-40 py-4 mt-2 origin-top-right bg-high shadow-lg rounded-xl"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabIndex={-1}
    data-testid="user-nav-menu"
  >
    <div className="flex flex-col gap-2" role="none">
      {children}
      {showAPIKeys && <APIKeys testId="user-nav-menu-api-key" />}
      <Logout signInUrl={signInUrl} />
    </div>
  </div>
)

export default UserNavMenu
