import { FC } from 'react'

interface UserNavMenuItemProps {
  onClick(): void
  icon?: React.ReactNode
  text?: string
  testId?: string
}

export const UserNavMenuItem: FC<UserNavMenuItemProps> = ({
  onClick,
  icon,
  text,
  testId = 'user-nav-menu-item',
}) => (
  <button
    className="flex items-center px-3.5 py-1 text-xs font-medium text-default transition delay-75 ease-in hover:bg-selection-forest/10"
    type="button"
    onClick={onClick}
    data-testid={testId}
  >
    <span className="mr-2 flex w-8 justify-center text-content-action-default">
      {icon}
    </span>{' '}
    {text}
  </button>
)

export default UserNavMenuItem
