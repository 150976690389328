import { type FC, useMemo } from 'react'
import { Spinner } from '@sylveraio/design-system'
import { GenericCustomHubspotFormCompletion } from './components/GenericCustomHubspotFormCompletion'
import { CustomProjectAccessHubspotForm } from './components/CustomProjectAccessHubspotForm'
import { CustomTierUpgradeHubspotForm } from './components/CustomTierUpgradeHubspotForm'
import type { CustomHubspotFormProps } from './types'

export const CustomHubspotForm: FC<CustomHubspotFormProps> = ({
  formCustomOverride,
  onClose,
  handleClose,
  setSubmitting,
  setSuccess,
  setError,
  submitting,
  success,
  error,
}) => {
  const upsellModal = useMemo(() => {
    switch (formCustomOverride?.variant) {
      case 'project':
        return (
          <CustomProjectAccessHubspotForm
            formCustomOverride={formCustomOverride}
            onClose={onClose}
            setSubmitting={setSubmitting}
            setSuccess={setSuccess}
            setError={setError}
          />
        )
      case 'tier':
        return (
          <CustomTierUpgradeHubspotForm
            formCustomOverride={formCustomOverride}
            onClose={onClose}
            setSubmitting={setSubmitting}
            setSuccess={setSuccess}
            setError={setError}
          />
        )
      default:
        return null
    }
  }, [formCustomOverride?.variant])

  if (submitting) {
    return <Spinner />
  }
  if (success && !error) {
    return <GenericCustomHubspotFormCompletion handleClose={handleClose} />
  }
  return upsellModal
}
