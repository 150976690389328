import type { FC } from 'react'
import {
  EarlyStageProjectsModalStep,
  type EarlyStageProjectsModalProps,
} from './types'
import { FeaturedIcon, Modal, Typography } from '@sylveraio/design-system'
import { Carousel } from './Carousel'
import { UI_STRINGS } from './constants'
import ImageUserCheck from '@sylveraio/untitled-ui-icons-react/build/esm/ImageUserCheck'

export const EarlyStageProjectsModal: FC<EarlyStageProjectsModalProps> = ({
  hasAccessToEarlyStageProjects = false,
  isSubmitting = false,
  modalStep = EarlyStageProjectsModalStep.Form,
  onSubmit,
  onClose,
}) => {
  if (modalStep === EarlyStageProjectsModalStep.Form) {
    const uiStrings = UI_STRINGS[modalStep]
    return (
      <Modal
        title={uiStrings.title(hasAccessToEarlyStageProjects)}
        onClose={onClose}
        size="lg"
        primaryActionProps={{
          onClick: onSubmit,
          label: uiStrings.buttonLabels.primary(
            isSubmitting,
            hasAccessToEarlyStageProjects,
          ),
          variant: 'primary',
          disabled: isSubmitting,
        }}
        secondaryActionProps={{
          onClick: onClose,
          label: uiStrings.buttonLabels.secondary,
          variant: 'subtle',
        }}
      >
        <div className="space-y-6">
          <Typography size="sm">
            {uiStrings.copy(hasAccessToEarlyStageProjects)[0]}
          </Typography>
          <div className="-mx-4">
            <Carousel />
          </div>
          <div className="space-y-4">
            {uiStrings
              .copy(hasAccessToEarlyStageProjects)
              .slice(1)
              .map((text) => (
                <Typography size="sm">{text}</Typography>
              ))}
          </div>
        </div>
      </Modal>
    )
  }
  const uiStrings = UI_STRINGS[modalStep]
  return (
    <Modal
      title={uiStrings.title}
      size="sm"
      onClose={onClose}
      primaryActionProps={{
        onClick: onClose,
        label: uiStrings.buttonLabel,
        variant: 'primary',
      }}
    >
      <div className="flex space-x-6 items-center">
        <FeaturedIcon size="xl" color="positive" icon={<ImageUserCheck />} />
        <Typography size="sm" className="flex-1">
          {uiStrings.copy}
        </Typography>
      </div>
    </Modal>
  )
}
