'use client'

import React, { RefObject } from 'react'

interface UseAutoCloseProps {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  ref: RefObject<HTMLDivElement | HTMLButtonElement>
  closureTest?(event: any): boolean
}

export const useAutoClose = ({
  isOpen,
  setIsOpen,
  ref,
  closureTest,
}: UseAutoCloseProps) => {
  const handleClosure = React.useCallback(
    // @ts-ignore
    (event) => {
      if (closureTest) {
        if (closureTest(event))
          return (
            !ref.current?.contains(event.target) &&
            !event.target.matches(
              '[data-ignore-outside-click], [data-ignore-outside-click] *',
            ) &&
            setIsOpen(false)
          )
      } else {
        return !ref.current?.contains(event.target) && setIsOpen(false)
      }
    },
    [setIsOpen, ref],
  )

  const handleClosureEscapeKey = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') setIsOpen(false)
  }

  const handleClosureBrowserActivity = (): void => {
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (isOpen === false) return

    window.addEventListener('click', handleClosure)
    window.addEventListener('focusin', handleClosure)
    window.addEventListener('keyup', handleClosureEscapeKey)
    window.addEventListener('popstate', handleClosureBrowserActivity)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('click', handleClosure)
      window.removeEventListener('focusin', handleClosure)
      window.addEventListener('keyup', handleClosureEscapeKey)
      window.removeEventListener('popstate', handleClosureBrowserActivity)
    }
  }, [isOpen, handleClosure, ref])
}

export default useAutoClose
