import { FC, useRef } from 'react'
import clsx from 'clsx'
import { useAutoClose } from '@sylveraio/react-utils'
import { CloseButton } from '../CloseButton'
import type SidebarProps from './types'
import { DEFAULT_ROLE_NAME } from './constants'

export const Sidebar: FC<SidebarProps> = ({
  testId = 'sidebar',
  className,
  children,
  isOpen,
  handleClose,
  showCloseButton,
  roleName = DEFAULT_ROLE_NAME,
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null)

  useAutoClose({
    isOpen,
    setIsOpen: handleClose,
    ref: sidebarRef,
    closureTest: (ev) => {
      const overrideClose = ev?.srcElement?.dataset?.overrideClose === 'true'
      const isDirectlyToggleBtn = ev?.srcElement?.dataset?.role === roleName
      if (isDirectlyToggleBtn || overrideClose) return false
      return true
    },
  })

  return (
    <div
      ref={sidebarRef}
      data-testid={testId}
      className={clsx(
        'h-full w-full bg-high ease-in-out duration-1000 p-6 overflow-auto will-change-auto top-0 left-0 fixed z-[60]',
        {
          '-translate-x-full': !isOpen,
          'translate-x-0': isOpen,
        },
        className,
      )}
    >
      <div
        className={clsx('flex justify-end relative mb-6', {
          invisible: !showCloseButton,
        })}
      >
        <CloseButton
          testId={`${testId}-close-button`}
          onClick={handleClose}
          className="!right-0"
        />
      </div>
      {children}
    </div>
  )
}
export default Sidebar
