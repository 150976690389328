import React, { FC, memo } from 'react'

interface ListRendererProps {
  elements: {
    content: string
  }[]
}

const ListRenderer: FC<ListRendererProps> = memo(({ elements }) =>
  typeof elements !== 'undefined' ? (
    <ul className="list-none" data-testid="rich-commentary-list">
      {elements.map(({ content }, index) => (
        <li key={`list-item-${index}`} className="flex items-baseline mb-4">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAWCAYAAAAmaHdCAAAA0UlEQVQ4Ea2TwQ3CMAxFOwIjMJZ96whsAIcm4dZuABswCkiQtLeOwAhFljCqKhw7lEpRKlV6fd8/qap/PaGHrU94oUXvP3FdxNFFnN7r6RPuikEzAIMmP2DbjvXGDHN3OHwDkWFRvOYBZwFUFi8DKovnE14FI5qXLR4NUwHZ4hFoUfunNbY0tUetaCBTPBeh5j9n9i57llZDVscxAjrxWtCHJuItMwO9YgWgH7YQ4ZQxkPW5nuOArQDQ9QkSetgLAF2fLQSArs8A2hcXzqY/A7wAasrGgWzv4+IAAAAASUVORK5CYII="
            alt=">"
            className="h-3 w-2x"
          />
          <span className="ml-2 prose">{content}</span>
        </li>
      ))}
    </ul>
  ) : null,
)

export default ListRenderer
