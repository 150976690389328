import clsx from 'clsx'
import type { ButtonSize } from '../../types'

/**
 * Get the size of the button component
 * @param {ButtonSize} size - The size of the button component
 * @param {boolean} isIconButton - Whether or not the button is an icon button
 * @returns {string} - The class name for the button size
 */
export function getButtonSize(size: ButtonSize, isIconButton?: boolean) {
  if (isIconButton) {
    return clsx({
      'h-8 w-8 p-2': size === 'xs',
      'h-9 w-9 p-2': size === 'sm',
      'h-10 w-10 p-2.5': size === 'md',
      'h-12 w-12 p-3.5': size === 'lg',
    })
  }

  return clsx('w-fit', {
    'h-8 min-w-14 p-2 gap-1': size === 'xs',
    'h-9 min-w-20 py-2 px-3 gap-1': size === 'sm',
    'h-10 min-w-24 py-2.5 px-3.5 gap-1': size === 'md',
    'h-12 min-w-32 py-3 px-4 gap-1.5': size === 'lg',
  })
}
