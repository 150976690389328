import type { FC } from 'react'
import { IconLibraryProps, Sizes } from '../../types'
import { ICON_DEFAULT_SIZE } from '../../constants'
import clsx from 'clsx'

export const Clock: FC<Omit<IconLibraryProps, 'name'>> = ({
  testId = 'clock',
  size = ICON_DEFAULT_SIZE,
  className,
}): JSX.Element => (
  <svg
    data-testid={testId}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 'auto', width: Sizes[size] }}
    className={clsx('stroke-current', className)}
  >
    <circle cx="12" cy="12" r="8.2" strokeWidth="1.6" />
    <path
      d="M12 7.5L12 12.6429H15.8571"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
)

export default Clock
