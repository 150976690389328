import * as Icon from './Icons'
import type {
  IconLibraryProps,
  IconsTypes,
  IconDirection,
  IconSizes,
} from './types'

export const ICON_DEFAULT_SIZE: IconSizes = 'sm'

export const Icons: {
  [x in IconsTypes]: (
    props: Omit<IconLibraryProps, 'name'>,
  ) => React.ReactElement
} = {
  arrow: (props) => <Icon.Arrow {...props} />,
  bulletList: (props) => <Icon.BulletList {...props} />,
  chevron: (props) => <Icon.Chevron {...props} />,
  clock: (props) => <Icon.Clock {...props} />,
  close: (props) => <Icon.Close {...props} />,
  cog: (props) => <Icon.Cog {...props} />,
  download: (props) => <Icon.Download {...props} />,
  externalLink: (props) => <Icon.ExternalLink {...props} />,
  info: (props) => <Icon.Info {...props} />,
  loader: (props) => <Icon.Loader {...props} />,
  location: (props) => <Icon.Location {...props} />,
  minus: (props) => <Icon.Minus {...props} />,
  newTab: (props) => <Icon.NewTab {...props} />,
  plus: (props) => <Icon.Plus {...props} />,
  plusInRing: (props) => <Icon.PlusInRing {...props} />,
  request: (props) => <Icon.Request {...props} />,
  ratings: (props) => <Icon.Ratings {...props} />,
  search: (props) => <Icon.Search {...props} />,
  selected: (props) => <Icon.Selected {...props} />,
  smallChevron: (props) => <Icon.SmallChevron {...props} />,
  solidArrow: (props) => <Icon.SolidArrow {...props} />,
  supply: (props) => <Icon.Supply {...props} />,
  target: (props) => <Icon.Target {...props} />,
  tour: (props) => <Icon.Tour {...props} />,
}

export const iconTransistionStyles = (
  direction?: IconDirection,
  transitionOn?: boolean,
  transitionDirection?: IconDirection,
) => ({
  ...(direction
    ? {
        '-rotate-90': direction === 'up',
        'rotate-90': direction === 'down',
        '-rotate-180': direction === 'left',
      }
    : {}),
  ...(transitionOn && transitionDirection
    ? {
        'transition duration-150 ease-in-out': transitionOn,
        '!-rotate-90': transitionOn && transitionDirection === 'up',
        '!rotate-90': transitionOn && transitionDirection === 'down',
        '!rotate-180': transitionOn && transitionDirection === 'left',
        '!rotate-0': transitionOn && transitionDirection === 'right',
      }
    : {}),
})
